import React from "react";
import PropTypes from "prop-types";
import { Control, Input, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import "./SearchBar.scss";

const SearchBar = ({ value, onSearch }) => (
  <div id="searchContainer">
    <Control iconRight>
      <Input
        type="text"
        value={value}
        onChange={(e) => onSearch(e.target.value)}
      />
      <Icon align="right">
        <FontAwesomeIcon icon={faSearch} />
      </Icon>
    </Control>
  </div>
);

SearchBar.propTypes = {
  value: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
};
SearchBar.defaultProps = {
  value: "",
};

export default SearchBar;
