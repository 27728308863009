/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNewspaper } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import Section from "../../../components/Section";
import Text from "../../../components/Text";
import Button from "../../../components/Button";
import ImageItem from "../../../components/Image-Item";
import { useAuth } from "../../../context/AuthContext";
import "./MessageBoard.scss";

import Loading from "../../../components/Loading";

import { WordPressService, DocumentService } from "../../../services";

const MessageBoardPage = () => {
  const { state: authState, permissions } = useAuth();

  const [webinarCount, setWebinarCount] = useState(3);

  // const { data: posts, isLoading: isPostsLoading } = useQuery("posts", () =>
  //   WordPressService.getPosts(3),
  // );

  const { data: documents = [] } = useQuery("documentsByProgram", () =>
    DocumentService.getDocumentsByProgram(authState.programRecId)
  );

  // DOCUMENT DOWNLOAD
  const [trueFileName, setTrueFileName] = useState("");
  // this will take the most recent by overwriting the older ones
  useEffect(() => {
    if (documents.length >= 1) {
      documents.forEach((document) => {
        if (
          document.DocumentId === "Message Boards and Forums_Newsletter" // is actually related to Code; a bit confusing
        ) {
          const fileName = document.FilePath;
          setTrueFileName(
            fileName.slice(fileName.lastIndexOf("/")).replace(/\//i, "")
          );
        }
      });
    }
  }, [documents]);

  const { data: File = [], refetch: refetchFile } = useQuery(
    "documentDownload",
    () =>
      DocumentService.DocumentDownload(
        trueFileName,
        `${authState.clientName} Newsletter.pdf`
      ),
    { enabled: false }
  );

  const handleGetFile = (e) => {
    if (trueFileName.length > 2) {
      e.preventDefault();
      refetchFile();
    } else {
      toast.error("No Document Found");
    }
  };
  // DOCUMENT DOWNLOAD


  const {
    data: webinars,
    isLoading: isWebinarsLoading,
    refetch,
    isFetching: isWebinarsFetching,
  } = useQuery("webinars", () => WordPressService.getWebinars(webinarCount), {
    enabled: false,
  });

  let sortedWebinars = [];
  if (webinars) {
    sortedWebinars = webinars.sort(
      (a, b) =>
        Date.parse(a.webinar_date.valueOf) - Date.parse(b.webinar_date.valueOf)
    );
  }

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    refetch();
  }, [webinarCount]);

  return (
    <div id="MessageBoardContainer">
      {/* No longer Used */}
      {/* Featured Posts
      {posts.length > 0 && (
        <div className="FeaturedSection">
          <Text.H1>Featured Posts</Text.H1>
          <Section>
            {posts.map((post) => {
              return(
                <ImageItem
                  image={post._embedded["wp:featuredmedia"][0].source_url}
                  topText={post.title.rendered}
                  bottomText="Read More"
                  href={post.link}
                />
            )})}
          </Section>
        </div>
      )} */}
      {/* Newsleetter */}
      <Text.H1>Newsletter</Text.H1>
      <div className="NewsLetter">
        <Section>
          <Text.H2>Quarterly Newsletter</Text.H2>
          <FontAwesomeIcon icon={faNewspaper} size="3x" onClick={(e) => handleGetFile(e)} />
        </Section>
      </div>
      {/* Webinars */}
      {//5/4/2022 changed to static link for webinar
        permissions.includes("005-001-002") && (
          <>
            <Text.H1>Webinars</Text.H1>
            <div className="NewsLetter">
              <Section>
                <Text.H2>Loss Prevention & Safety Webinars</Text.H2>
                <a href='https://www.emaxxgroup.com/webinars/' target='_blank' rel='noopener noreferrer'><FontAwesomeIcon icon={faNewspaper} size="3x" /></a>
              </Section>
            </div>
          </>
        )
      }
      {/*//5/4/2022 changed to static link for webinar
      {permissions.includes("005-001-002") && (
        <>
          <Text.H1>Webinars</Text.H1>
          <div className="WebinarSection">
            {webinars?.length > 0 &&
            !isWebinarsFetching &&
            !isWebinarsLoading ? (
              <>
                {sortedWebinars.map((webinar) => (
                  <Section>
                    <ImageItem
                      // image={webinar._embedded["wp:featuredmedia"][0].source_url}
                      date={webinar.webinar_date}
                      topText={webinar.post_title}
                      bottomText="Register"
                      href={webinar.go_to_meeting_link}
                    />
                  </Section>
                ))}
                <Button.Round
                  onClick={() => {
                    setWebinarCount(webinarCount + 3);
                  }}
                >
                  View More
                </Button.Round>
              </>
            ) : (
              <Loading.SmallLoading />
            )}
          </div>
        </>
            )}*/}
    </div>
  );
};

MessageBoardPage.propTypes = {};

export default MessageBoardPage;
