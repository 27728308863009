import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";

import { ModalContext } from "../../../components/Modals/ModalProvider";
import ConfirmationModal from "../../../components/Modals";

export const EditUserIcon = ({ cellData }) => {
  const history = useHistory();
  const { groupName, clientId } = useParams();

  const handleEditClick = () => {
    groupName
      ? history.push(
          `/user-settings/${groupName}/${clientId}/${cellData.row.values.RecId}`
        )
      : history.push(
          `/user-settings/emaxx/${clientId}/${cellData.row.values.RecId}`
        );
  };

  return <FontAwesomeIcon icon={faEdit} onClick={() => handleEditClick()} />;
};

EditUserIcon.propTypes = {
  cellData: PropTypes.object.isRequired
};

export const DeleteUserIcon = ({ cellData, handleDelete }) => {
  const { toggleModal } = useContext(ModalContext);

  return (
    <FontAwesomeIcon
      icon={faTrashAlt}
      onClick={() =>
        toggleModal(
          true,
          <ConfirmationModal
            entity="user"
            toggleModal={toggleModal}
            handleAction={() => handleDelete(cellData)}
            actionText="DELETE"
          />
        )
      }
      style={{ marginLeft: "0.5em" }}
    />
  );
};

DeleteUserIcon.propTypes = {
  cellData: PropTypes.object.isRequired,
  handleDelete: PropTypes.func.isRequired
};
