import React from "react";
import PropTypes from "prop-types";
import { format, parseISO } from "date-fns";
import TextButton from "../Button/Text";
import ExternalTextButton from "../Button/ExternalText";
import Text from "../Text";
import CalendarSquare from "../CalendarSquare";
import "./Image-Item.scss";

function ImageItem({
  image,
  topText,
  bottomText,
  boldTopText = false,
  href = false,
  date = false,
}) {
  let dateArr = [];
  if (date) {
    const readableDate = format(parseISO(date), "MMMM/dd/yyyy");
    dateArr = readableDate.split("/");
  }
  let imageToUse = "";
  if (typeof image === "undefined" || !image) {
    imageToUse = `https://www.emaxxgroup.com/wp-content/uploads/2021/01/Highway-2-2.jpg`;
  } else {
    imageToUse = image;
  }
  return (
    <div className="imageItem">
      {!date && <img className="image" src={imageToUse} alt={imageToUse} />}
      {date && <CalendarSquare month={dateArr[0]} day={dateArr[1]} />}
      <div className="image-item-text">
        {boldTopText && <Text.H3>{boldTopText}</Text.H3>}
        <div className="topText">{topText}</div>
        {!href && (
          <div className="bottomText">
            <TextButton>{bottomText}</TextButton>
          </div>
        )}
        {href && (
          <div className="bottomText">
            <ExternalTextButton
              href={href}
              rel="noopener noreferrer"
              target="_blank"
            >
              {bottomText}
            </ExternalTextButton>
          </div>
        )}
      </div>
    </div>
  );
}

ImageItem.propTypes = {
  image: PropTypes.string,
  topText: PropTypes.string,
  bottomText: PropTypes.string,
  boldTopText: PropTypes.string,
  href: PropTypes.string,
  date: PropTypes.string,
};

ImageItem.defaultProps = {
  image: "",
  topText: "",
  bottomText: "",
  boldTopText: "",
  href: "",
  date: "",
};

export default ImageItem;
