import { format, parseISO } from "date-fns";

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
})
// need parse ISO to convert the value given by sql into a date

export const AssessmentDistributionColumns = [
  {
    Header: "Premium Year",
    accessor: "ECaptivProgramYear",
    id: "ECaptivProgramYear",
    //Cell: ({ value }) => (value ? `${format(parseISO(value), "yyyy")} - ${parseInt(format(parseISO(value), "yyyy")) + 1}` : "--"),
  },
  {
    Header: "Type",
    accessor: "DisplayType",
    id: "DisplayType",
  },
  {
    Header: "Total Amount",
    accessor: "TotalAmount",
    id: "TotalAmount",
    Cell: ({ value }) => {
      if (isNaN(value)) {
        return 'Pending';
      }
      else { return formatter.format(value); }
    },
  }
];



// {
//   Header: "Expiration Date",
//   accessor: "ExpDate",
//   id: "ExpDate",
//   Cell: ({ value }) => (value ? format(parseISO(value), "MM/dd/yyyy") : "--"),
// },