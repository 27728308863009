import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Text from "../../../components/Text";
import UserService from "../../../services/UserService";
import UserForm from "./UserForm";
import "./Users.scss";

const UserAddPage = () => {
  const { clientId } = useParams();
  const history = useHistory();
  const [user, setUser] = useState({});

  const handleInputChange = (field, val) => {
    let value = val;
    if (val === true) {
      value = 1;
    } else if (val === false) {
      value = 0;
    }
    setUser(prev => ({ ...prev, [field]: value }));
  };

  // creates new user and userRoleGroup
  const handleSubmit = async roleGroupsRecId => {
    try {
      //  check if the email is already in the database
      const { data: existingUser } = await UserService.getUserByEmail(
        user.Email
      );
      if (existingUser.Payload) {
        throw new Error("This email already exists.");
      }

      // create user
      try {
        delete user.roleGroupsRecId;
        user.IsActive = 1;
        user.ClientId = clientId;

        const {
          CreatedBy,
          CreatedDateTime,
          ModifiedBy,
          ModifiedComments,
          ModifiedDateTime,
          ...rest
        } = user;
        const { data: userData } = await UserService.createUser(
          { ...rest },
          user.Email,
          window.location.origin
        );
        if (userData.Error.ErrorMessage) {
          throw new Error(userData.Error.ErrorMessage);
        }

        // create userRoleGroup
        const userRoleGroup = {
          UsersRecId: userData.Payload.RecId,
          RoleGroupsRecId: roleGroupsRecId,
          IsActive: 1,
          CreatedBy,
          CreatedDateTime,
          ModifiedBy,
          ModifiedComments,
          ModifiedDateTime
        };
        const {
          data: userRoleGroupData
        } = await UserService.createUserRoleGroup({ ...userRoleGroup });
        if (userRoleGroupData.Error.ErrorMessage) {
          throw new Error(userRoleGroupData.Error.ErrorMessage);
        }

        history.goBack();
        toast.success("User Added Successfully");
      } catch (err) {
        console.log(err.message);
        toast.error("Whoops! We couldn't create your user");
      }
    } catch (err) {
      console.log(err.message);
      toast.error("Whoops! This email already exists");
    }
  };
  return (
    <div>
      <Text.H1>New User</Text.H1>
      <UserForm
        user={user}
        onChange={handleInputChange}
        onSubmit={handleSubmit}
        onCancel={() => history.goBack()}
        adding
      />
    </div>
  );
};

export default UserAddPage;
