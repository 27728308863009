import React from "react";

import "./Loading.scss"
import OrangeXLight from "../../assets/images/orange-x-light.png";

const Loading = (props) => {
  return (
    <div className="loading">
      <img src={OrangeXLight} alt="orange-x-light" />
    </div>
  );
};

Loading.propTypes = {};

export default Loading;
