/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { Generic } from "rbx";
import "./ExternalText.scss";

export default function ExternalText({ children, disabled, ...props }) {
  return disabled ? (
    <Generic
      as="span"
      tooltip="You do not have permission to access to this element."
      tooltipPosition="left"
      className="textButton isDisabled"
    >
      {children}
    </Generic>
  ) : (
    <a {...props} className="textButton">
      {children}
    </a>
  );
}

ExternalText.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

ExternalText.defaultProps = {
  disabled: false,
};

ExternalText.displayName = "ExternalTextButton";
