import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import watermark from "../../assets/images/orange-x-light.png";
import Header from "../Header";
import Sidebar from "../Sidebar";
import Footer from "../Footer";
import { ModalProvider, ModalContext } from "../Modals/ModalProvider";
import "./PageContainer.scss";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const Page = ({ children }) => {
  const history = useHistory();
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  // mobile sizing stuff
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen((prev) => !prev);
  };

  // force sidebar closed for Client Locator page (select member company)
  const activeRoute = history.location.pathname
    .slice(history.location.pathname.lastIndexOf("/"))
    .replace(/\//gi, "");
  const clientRequired = activeRoute === "select-company";

  const toggleModal = (open = true, content = "") => {
    setModalOpen(open);
    setModalContent(content);
  };

  return (
    <div className="page-container">
      <ModalContext.Provider value={{ isModalOpen, modalContent, toggleModal }}>
        {!clientRequired && (
          <Sidebar
            toggleSidebar={() => toggleSidebar()}
            open={isSidebarOpen}
            isMobile={windowDimensions.width < 768}
          />
        )}
        <Header isMobile={windowDimensions.width < 768} />
        <div
          className={`content-section ${
            isSidebarOpen && !clientRequired ? "shrink" : ""
          }`}
        >
          <img src={watermark} alt="eMaxx-logo" id="watermark" />
          {children}
        </div>
        <Footer />
        <ModalProvider />
      </ModalContext.Provider>
    </div>
  );
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Page;
