import React, { useEffect, useState, useMemo } from "react";
import { useQuery } from "react-query";
import { format, parseISO } from "date-fns";
import { useAuth } from "../../../context/AuthContext";
import ClientService from "../../../services/ClientService";
import ProgramService from "../../../services/ProgramService";
import AssessmentAndDistributionService from "../../../services/AssessmentAndDistributionService";
import Text from "../../../components/Text";
import Section from "../../../components/Section";
import DataTable from "../../../components/DataTable";
import "./FinancialProfitability.scss";
import Loading from "../../../components/Loading";
import { groupBy, random } from "lodash";
import { AssessmentDistributionColumns } from './columns';




const AssessmentAndDistribution = ({ passDownCurrentProg }) => {



    const { state: authState, permissions } = useAuth();
    //const [currentProgram, setCurrentProgram] = useState(passDownCurrentProg); //Li 08-04-2022 program dropdown contorl aduits/unauditis docs

    const [IfDisplay, setIfDisplay] = useState(true);




    const { data: clientsHistory = [] } = useQuery("clientsHistory", () =>
        ClientService.getClientsChangeHistory()
    );
    const { data: programs = [] } = useQuery("programs", () =>
        ProgramService.getPrograms()
    );

    const {
        data: Assessments = [],
        loading: isLoading,
        error,
    } = useQuery("assessment", () =>
        AssessmentAndDistributionService.getAssessment(authState.clientId),
        // { cacheTime: 2000 }
    );

    const {
        data: AFundDistributions = [],
        loading: isDistriLoading,
    } = useQuery("distribution", () =>
        AssessmentAndDistributionService.getAFundDistribution(authState.clientId),
        // { cacheTime: 2000 }
    );








    useEffect(() => {
        //debugger;
        //if (passDownCurrentProg === 3 || passDownCurrentProg === 6 || passDownCurrentProg === 2) {
        if (passDownCurrentProg !== 1) {
            //since the current requirment is incomplete, once bussiness is sure about the future descision for other programs 03/07/2023
            //setLoadingBool(true);
            setIfDisplay(false);
        }
        else {
            setIfDisplay(true);
        }
    }, [passDownCurrentProg]);


    let clientProHis = clientsHistory.filter(f => f.ProgramsRecId && f.ClientId === authState.clientId);



    //concatinate assessments and distributions for same client
    let assAndDis = [];
    Assessments.forEach(item => {
        assAndDis.push({
            ClientId: item.ClientId,
            PolicyId: item.PolicyId,
            PolicyTerm: item.PolicyTerm,
            PolicyNumber: item.PolicyNumber,
            EffDate: item.EffDate,
            ExpDate: item.ExpDate,
            ECaptivProgramYear: item.ECaptivProgramYear,
            TotalAmount: item.TotalAssessmentAmount,
            DisplayType: item.DisplayType,
            CategoryCode: item.CategoryCode
        })
    })

    AFundDistributions.forEach(item => {
        assAndDis.push({
            ClientId: item.ClientId,
            PolicyId: item.PolicyId,
            PolicyTerm: item.PolicyTerm,
            PolicyNumber: item.PolicyNumber,
            EffDate: item.EffDate,
            ExpDate: item.ExpDate,
            ECaptivProgramYear: item.ECaptivProgramYear,
            TotalAmount: item.TotalDistributionAmount ? item.TotalDistributionAmount : 'Pending',
            DisplayType: item.DisplayType,
            CategoryCode: item.CategoryCode
        })


    })

    let sortedAssessAndDistrib = [];
    if ((!isLoading && !isDistriLoading) && (AFundDistributions.length > 0 || Assessments.length > 0)) {

        //concatinate assessments and distributions for same client
        sortedAssessAndDistrib = assAndDis.sort((a, b) => format(parseISO(a.EffDate), "yyyy") - format(parseISO(b.EffDate), "yyyy"));

        //mix policy program into program dropdown
        sortedAssessAndDistrib = sortedAssessAndDistrib.filter(f => Number(f.CategoryCode?.match(/(\d+)/)[0]) === passDownCurrentProg);

        /* remove it since Megan want to mix policy program into program dropdown
        //TODO: need to improve considering ECAP8 col policies could appear in both current and history programs, below using time to work around, but could have better way
        if (clientProHis.length > 0) {

            //this will need to be for the current Program and also currenProgram was a history
            let maxHisEndDate = clientProHis.sort((a, b) => { return new Date(b.EndDate) - new Date(a.EndDate) })[0]?.EndDate;

            let tempCol = [];
            clientProHis.filter(f => f.ProgramsRecId === passDownCurrentProg).forEach((his) => {
                tempCol = tempCol.concat(sortedAssessAndDistrib.filter(f => f.EffDate >= his.StartDate && f.EffDate <= his.EndDate && f.ExpDate <= his.EndDate))
            });

            if (passDownCurrentProg === authState.programRecId) {//add to the list for currentProgram's Collateral
                tempCol = tempCol.concat(sortedAssessAndDistrib.filter(f => f.ExpDate > maxHisEndDate));
            }
            sortedAssessAndDistrib = tempCol;//tempCol.filter(f => f.PolicyNumber.includes(`ECAP${currentProgram}COL`));

        }*/
    }


    if (isLoading) {
        return (
            <div className="headerContainer">
                <Text.H1>Assessments & Distributions</Text.H1>
                <Loading.SmallLoading />
            </div>
        );
    }
    if (error) {
        return null;
    }




    return (
        <div className="AssAndDistriContainer" id='AssAndDistriContainer'>

            {(permissions.includes("002-004-004") && IfDisplay) && (
                <>
                    <Text.H1>Assessments & Distributions</Text.H1>
                    <h4>(Reflects past 5 years)</h4>

                    {sortedAssessAndDistrib.length ? (
                        <div className="assAndDistriTable">
                            <DataTable
                                columns={AssessmentDistributionColumns}
                                data={sortedAssessAndDistrib}
                                sortBy={[
                                    {
                                        id: "EcaptivPremiumYear",
                                        desc: false,
                                    },
                                ]}
                            />
                        </div>
                    ) : (
                        <Section>No Assessments & Distributions Found.</Section>
                    )}
                </>
            )
            }
        </div >
    )

};


export default AssessmentAndDistribution;