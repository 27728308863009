import React from "react";
import PropTypes from "prop-types";

import Text from "../Text";
import RoundButton from "../Button/Round";

const ConfirmationModal = ({
  entity,
  toggleModal,
  handleAction,
  optionalText = false,
  actionText,
}) => (
  <div className="modalContainer">
    <Text.H3>
      {optionalText ||
        `Are you sure you would like to delete this 
    ${entity} ?`}
    </Text.H3>
    <div className="buttonContainer">
      <RoundButton
        classNames={["secondary"]}
        onClick={() => toggleModal(false, "")}
      >
        Cancel
      </RoundButton>
      <RoundButton onClick={(e) => handleAction(e)}>{actionText}</RoundButton>
    </div>
  </div>
);

ConfirmationModal.propTypes = {
  entity: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleAction: PropTypes.func.isRequired,
  optionalText: PropTypes.string,
  actionText: PropTypes.string.isRequired,
};
ConfirmationModal.defaultProps = {
  optionalText: "",
};

export default ConfirmationModal;
