import React from "react";

import "./PrivacyPage.scss";

const PrivacyPolicyPage = () => {
  return (
    <>
      <div>Privacy Policy</div>
    </>
  );
};

PrivacyPolicyPage.propTypes = {};

export default PrivacyPolicyPage;
