import React from "react";

import WeatherDashboard from "./WeatherDashboard";
import NewsDashboard from "./NewsDashboard";
import InsuranceDashboard from "./InsuranceDashboard";
import ClaimsDashboard from "./ClaimsDashboard";
import { useAuth } from "../../context/AuthContext";

const DashboardPage = () => {
  const { permissions } = useAuth();

  return (
    <>
      {permissions.includes("001-001") && permissions.includes("001-002") && permissions.includes("001-003") && <WeatherDashboard />}
      {permissions.includes("001-004") && <NewsDashboard />}
      {(permissions.includes("001-005") || permissions.includes("001-006")) && <InsuranceDashboard />} {/*from Gravoc for Phase1 it was 001-006, Li changed to 001-005 8/18, for member supvisor who needs to see days renewal but summary to get into this component at least */}
      {permissions.includes("001-007") && <ClaimsDashboard />}
    </>
  );
};

DashboardPage.propTypes = {};

export default DashboardPage;
