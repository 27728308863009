import React from "react";
import { useQuery } from "react-query";
import { useState } from "react";
import { Select } from "rbx";
import { Switch, Route } from "react-router-dom";
import GovernancePage from "./GovernancePage";
import MembershipDirectoryPage from "./MembershipDirectoryPage";
import MemberReferencesPage from "./MemberReferencesPage";
import OfficerAndCommitteePage from "./OfficerAndCommitteePage";
import MeetingsPage from "./MeetingsPage";
import OrganizationalDocumentationPage from "./OrganizationalDocumentationPage";
import BoardOfDirectorsPage from "./BoardOfDirectorsPage";
import StandingCommitteesPage from "./StandingCommitteesPage";
import { useAuth } from "../../../context/AuthContext";
import ClientService from "../../../services/ClientService";
import ProgramService from "../../../services/ProgramService";
import PolicyService from "../../../services/PolicyService";
import { groupBy } from "lodash";


// order matters so the current governance page should go last or have an exact path
const GovernanceRouter = () => {

  const { state: authState, permissions } = useAuth();
  //Li Program history dropdown
  const [currentProgram, setCurrentProgram] = useState(authState.programRecId);

  const { data: clientsHistory = [] } = useQuery("clientsHistory", () =>
    ClientService.getClientsChangeHistory()
  );
  const { data: programs = [] } = useQuery("programs", () =>
    ProgramService.getPrograms()
  );


  //mix pc8,9(policy program) to regular program list by getting from the polciies
  const { data: Policies = [] } = useQuery("policies", () =>
    PolicyService.getPoliciesByClientNoFilter(authState.clientId)
  );




  const changeCurrentProgram = e => {
    e.persist();

    const { value } = e.target;
    setCurrentProgram(Number(value));
  }


  let ProgramLst = [];
  ProgramLst.push(programs.find(f => f.RecId === authState.programRecId));//insert the current

  let clientProHis = clientsHistory.filter(f => f.ProgramsRecId && f.ProgramsRecId !== authState.programRecId && f.ClientId === authState.clientId);

  if (clientProHis.length > 0 && programs.length > 0) {

    let grouping = clientProHis;//clientsHistory.filter(f => f.ProgramRecId !== authState.programRecId && f.ClientId === authState.clientId);
    let grouped = groupBy(grouping, 'ProgramsRecId');


    Object.keys(grouped).forEach(key => ProgramLst.push(programs.find(f => f.RecId.toString() === key.toString())));
  }
  //Li Program history dropdown

  // add policy program to the drop down
  if (programs.length > 0 && Policies.length > 0) {

    let groupedPolicyPrograms = groupBy(Policies, 'CategoryCode')

    Object.keys(groupedPolicyPrograms).forEach(key => {
      var existing = ProgramLst.find(f => f?.Code === key)
      if (!existing) {

        let prog = programs.find(f => f.Code === key);

        ProgramLst.push(prog);
      }
    });
  }
  // add policy program to the drop down






  return (
    <>
      <div style={{ paddingBottom: '0.8rem' }}>
        <Select.Container size="medium">
          {/* <Select value={currentProgram} onChange={programChange}> */}
          <Select onChange={changeCurrentProgram}>
            {ProgramLst
              //.sort((a, b) => b - a)
              .map(p => (
                <Select.Option key={p?.RecId} value={p?.RecId}>{p?.Code}</Select.Option>
              ))}
          </Select>
        </Select.Container>
      </div>
      <Switch>
        {permissions.includes("002-002") && (
          <Route exact path="/insurance/governance">
            <GovernancePage passDownCurrentProg={currentProgram} changeCurrentProgram={changeCurrentProgram} />
          </Route>
        )}
        {permissions.includes("002-002-002") && (
          <Route path="/insurance/governance/membership/membership-directory">
            <MembershipDirectoryPage passDownCurrentProg={currentProgram} changeCurrentProgram={changeCurrentProgram} />
          </Route>
        )}
        {permissions.includes("002-002-003") && (
          <Route path="/insurance/governance/membership/member-references">
            <MemberReferencesPage passDownCurrentProg={currentProgram} changeCurrentProgram={changeCurrentProgram} />
          </Route>
        )}
        {permissions.includes("002-002-004") && (
          <Route path="/insurance/governance/membership/officer-&-committee-slate">
            <OfficerAndCommitteePage passDownCurrentProg={currentProgram} changeCurrentProgram={changeCurrentProgram} />
          </Route>
        )}
        {permissions.includes("002-002-005") && (
          <Route path="/insurance/governance/organization/organizational-documentation">
            <OrganizationalDocumentationPage passDownCurrentProg={currentProgram} changeCurrentProgram={changeCurrentProgram} />
          </Route>
        )}
        {permissions.includes("002-002-006") && (
          <Route path="/insurance/governance/organization/meetings">
            <MeetingsPage passDownCurrentProg={currentProgram} changeCurrentProgram={changeCurrentProgram} />
          </Route>
        )}
        {permissions.includes("002-002-007") && (
          <Route path="/insurance/governance/organization/board-of-directors">
            <BoardOfDirectorsPage passDownCurrentProg={currentProgram} changeCurrentProgram={changeCurrentProgram} />
          </Route>
        )}
        {permissions.includes("002-002-008") && (
          <Route path="/insurance/governance/organization/standing-committees">
            <StandingCommitteesPage passDownCurrentProg={currentProgram} changeCurrentProgram={changeCurrentProgram} />
          </Route>
        )}
      </Switch>
    </>
  );
};

export default GovernanceRouter;
