import React from "react";

import "./LiveChat.scss";

const LiveChatPage = () => {
  return (
    <>
      <div>Live Chat</div>
    </>
  );
};

LiveChatPage.propTypes = {};

export default LiveChatPage;
