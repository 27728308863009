import React from "react";
import PropTypes from "prop-types";

import { ConfirmPasswordInput } from "../inputs";
import Button from "../Button";

const ResetForm = ({
  inputs,
  loading,
  message,
  onChange,
  onSubmit,
  onCancel,
}) => {
  return (
    <form id="reset-password-form" onSubmit={onSubmit}>
      <ConfirmPasswordInput
        password={inputs.password}
        onChange={onChange}
        required
      />
      <div className="button-container">
        <Button.Round
          type="button"
          classNames={["secondary"]}
          onClick={onCancel}
        >
          Cancel
        </Button.Round>

        <Button.Round
          form="reset-password-form"
          type="submit"
          className={
            loading || !inputs.isValid
              ? "roundButton primary disabled"
              : "roundButton primary"
          }
          disabled={loading || !inputs.isValid}
          state={loading ? "loading" : ""}
        >
          Submit
        </Button.Round>
      </div>
    </form>
  );
};

ResetForm.propTypes = {
  inputs: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  message: PropTypes.object,
};

ResetForm.defaultProps = {
  inputs: {},
  loading: false,
  message: {},
  onSubmit: () => null,
  onCancel: () => null,
  onChange: () => null,
};

export default ResetForm;
