import React from "react";

import { useQuery } from "react-query";

import { useAuth } from "../../../context/AuthContext";
import Text from "../../../components/Text";
import Section from "../../../components/Section";
import ColorDivider from "../../../components/Divider";
import MemberFundAnalysisService from "../../../services/MemberFundAnalysisService";
import Loading from "../../../components/Loading";

import "./ClaimsDashboard.scss";

// const calculateRenewalDays = (policies) => {
//   // find the policy set to expire next and calculate the days until expiration (renewal)
//   const expirationDates = policies
//     .map((policy) => new Date(policy.ExpirationDate))
//     .filter((date) => isAfter(date, new Date()));
//   const closestIndex = closestIndexTo(new Date(), expirationDates);
//   const nextExpirationDate = expirationDates[closestIndex];
//   const daysToExpire = differenceInCalendarDays(
//     nextExpirationDate,
//     startOfToday()
//   );

//   return daysToExpire;
// };

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
  //currencySign: 'accounting',
});

const ClaimsDashboard = () => {
  const { state: authState, permissions } = useAuth();

  let {
    data: funds = [],
    isLoadingFunds,
    errorFunds
  } = useQuery("memberFundAnalysis", () =>
    MemberFundAnalysisService.getMemberFundAnalysis(authState.clientId)
  );

  if (isLoadingFunds) {
    return (
      <div className="headerContainer">
        <Text.H1>Claims</Text.H1>
        <Loading.SmallLoading />
      </div>
    );
  }
  if (errorFunds) {
    return null;
  }

  // change this to dynamically look for the month where there is data and also return an object with keys for the original array and current year and month as well

  let closestQuarter = 0;
  let closestYear = 0;
  if (funds.length >= 1) {
    //program history, for dashboard display current program, may need to change with dropdown
    funds = funds.filter(f => Number(f.ProgramCd ? f.ProgramCd?.toString().match(/(\d+)/)[0] : 0) === authState.programRecId)
    if (funds.length >= 1) {
      const sortedDateFunds = funds.sort((a, b) => b.YearQuarter - a.YearQuarter);
      closestYear = sortedDateFunds[0].YearQuarter.toString().slice(0, 4);
      closestQuarter = sortedDateFunds[0].YearQuarter.toString().slice(4);
    }
  }

  // find current quarter, below is not actually the month value but a helpful translation of it
  //  const currentMonth = (Number(closestMonth)+ 1) / 4;
  //  let currentQuarter;
  //  switch (true) {
  //    case currentMonth < 0.8:
  //      currentQuarter = 1;
  //      break;

  //    case currentMonth >= 1 && currentMonth < 1.6:
  //      currentQuarter = 2;
  //      break;

  //    case currentMonth >= 1.75 && currentMonth < 2.3:
  //      currentQuarter = 3;
  //      break;

  //    case currentMonth > 2.4:
  //      currentQuarter = 4;
  //      break;

  //    default:
  //      currentQuarter = 0;
  //      break;
  //  }
  /// /
  const currentYearQuarterFund = funds
    .filter(fund => {
      return (
        fund.YearQuarter ===
        Number(closestYear) * 100 + Number(closestQuarter) &&
        (fund.PremiumYearFrom === Number(closestYear) ||
          fund.PremiumYearTo === Number(closestYear) ||
          fund.PremiumYearTo === Number(closestYear) - 1)
      );
    })
    .sort((a, b) => (a.PremiumYearTo < b.PremiumYearTo ? 1 : -1));
  let mostRecenFunds = [];
  if (currentYearQuarterFund[0] && currentYearQuarterFund[1]) {
    mostRecenFunds = [currentYearQuarterFund[0], currentYearQuarterFund[1]];
  } else if (currentYearQuarterFund[0]) {
    mostRecenFunds = [currentYearQuarterFund[0]];
  }

  //Li, get the last month of the quarter for MFA data on claim dashboard
  const quarterMonthObj = {
    '01': "03/31",
    '02': "06/30",
    '03': "09/30",
    '04': "12/31"
  };


  // expenses is spelled expenCes in the table / endpoint it is not a typo do not change
  return (
    <div id="claimsDash">
      <div className="headerContainer" style={{ display: 'block' }}>
        {permissions.includes("001-007") && (
          <Text.H1>Claims</Text.H1>
        )}
        {mostRecenFunds.length > 0 &&
          <h4>
            <span>
              {'As of ' + quarterMonthObj[mostRecenFunds[0].YearQuarter.toString().slice(4)] + '/'
                + mostRecenFunds[0].YearQuarter.toString().slice(0, 4)}
            </span>
          </h4>}
      </div>
      {funds.length >= 1 && mostRecenFunds.length >= 1 ? (
        <>
          {permissions.includes("001-007") && (
            <div className="claimsSection">
              {mostRecenFunds.map(fund => (
                <Section key={fund.RecId}>
                  <div className="sectionTitles">
                    <Text.H3>
                      Premium Year
                      {' '}
                      {fund.PremiumYearFrom}
                      -
                      {fund.PremiumYearTo}
                    </Text.H3>
                  </div>
                  <ColorDivider />
                  {(authState.programRecId === 1 ||
                    authState.programRecId === 3 || authState.programRecId === 6
                    || authState.programRecId === 7) && (
                      <>
                        <div className="tableData">
                          <div>A Fund Frequency Claims & Expenses Paid</div>
                          {" "}
                          <span>
                            {currentYearQuarterFund.length >= 1
                              ? formatter.format(
                                fund.AFundFrequencyClaimsAndExpencesPaid < 0
                                  ? fund.AFundFrequencyClaimsAndExpencesPaid * -1
                                  : fund.AFundFrequencyClaimsAndExpencesPaid
                              )
                              : "--"}
                          </span>
                        </div>
                        <div className="tableData">
                          <div>A Fund Frequency Claims & Expenses Reserves</div>
                          {" "}
                          <span>
                            {currentYearQuarterFund.length >= 1
                              ? formatter.format(
                                fund.AFundFrequencyClaimsAndExpencesReserves < 0
                                  ? fund.AFundFrequencyClaimsAndExpencesReserves *
                                  -1
                                  : fund.AFundFrequencyClaimsAndExpencesReserves
                              )
                              : "--"}
                          </span>
                        </div>
                        <div className="tableData">
                          <div>B Fund Severity Claims & Expenses Paid</div>
                          {" "}
                          <span>
                            {currentYearQuarterFund.length >= 1
                              ? formatter.format(
                                fund.BFundSeverityClaimsAndExpencesPaid < 0
                                  ? fund.BFundSeverityClaimsAndExpencesPaid * -1
                                  : fund.BFundSeverityClaimsAndExpencesPaid
                              )
                              : "--"}
                          </span>
                        </div>
                        <div className="tableData">
                          <div>B Fund Severity Claims & Expenses Reserves</div>
                          {" "}
                          <span>
                            {currentYearQuarterFund.length >= 1
                              ? formatter.format(
                                fund.BFundSeverityClaimsAndExpencesReserves < 0
                                  ? fund.BFundSeverityClaimsAndExpencesReserves * -1
                                  : fund.BFundSeverityClaimsAndExpencesReserves
                              )
                              : "--"}
                          </span>
                        </div>
                      </>
                    )}
                  {(authState.programRecId === 2 ||
                    authState.programRecId === 4) && (
                      <>
                        <div className="tableData">
                          <div>Group Fund Frequency Claims & Expenses Paid</div>
                          {" "}
                          <span>
                            {currentYearQuarterFund.length >= 1
                              ? formatter.format(
                                fund.AFundFrequencyClaimsAndExpencesPaid < 0
                                  ? fund.AFundFrequencyClaimsAndExpencesPaid * -1
                                  : fund.AFundFrequencyClaimsAndExpencesPaid
                              )
                              : "--"}
                          </span>
                        </div>
                        <div className="tableData">
                          <div>Group Fund Frequency Claims & Expenses Reserves</div>
                          {" "}
                          <span>
                            {currentYearQuarterFund.length >= 1
                              ? formatter.format(
                                fund.AFundFrequencyClaimsAndExpencesReserves < 0
                                  ? fund.AFundFrequencyClaimsAndExpencesReserves * -1
                                  : fund.AFundFrequencyClaimsAndExpencesReserves
                              )
                              : "--"}
                          </span>
                        </div>
                      </>
                    )}
                </Section>
              ))}
              {currentYearQuarterFund.length === 1 && (
                <Section style={{ visibility: "hidden" }} />
              )}
            </div>
          )}
        </>
      ) : (
        <Section>
          <span>No Claims Found.</span>
        </Section>
      )
      }
    </div >
  );
};

ClaimsDashboard.propTypes = {};

export default ClaimsDashboard;
