import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Select } from "rbx";
//import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";

import logo from "../../assets/images/eMaxxLogo2021.png";
import { startCase } from "../../utils";
import { useAuth } from "../../context/AuthContext";
import "./Header.scss";

function Header({ isMobile }) {
  const history = useHistory();
  const { pathname } = history.location;
  const { state: authState, permissions } = useAuth();



  /* Google Analytics
  const pageViewsTracking = () => {

    let pageView;
    if (pathname === "*") {
      pageView = '/not-found'
    } else {
      pageView = pathname;
    }
    ReactGA.pageview(pageView);
  }*/
  //Google Analyics 4
  const pageViewsTracking = () => {

    let pageView;
    if (pathname === "*") {
      pageView = '/not-found'
    } else {
      pageView = pathname;
    }

    //ReactGA4.send({ hitType: "pageview", page: pageView })


    //use gtag.js to send by specifying the page title since SPA index.html page only loads once
    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }

    gtag('event', 'page_view', {
      page_title: activeRoute || 'Dashboard',
      page_location: window.location.href, // Include the full URL
      page_path: window.location.href,
      //send_to: 'G-LFFXEZ1G9W'
    });
  }




  let activeRoute = startCase(
    history.location.pathname
      .slice(history.location.pathname.lastIndexOf("/"))
      .replace(/\//gi, "")
      .replace(/[-]/g, " ")
  );
  if (!isNaN(parseFloat(activeRoute))) {
    activeRoute = " ";
  } else if (activeRoute === "Elive Connect") {
    activeRoute = "eLive Connect";
  } else if (activeRoute === "Emaxx Directory") {
    activeRoute = "eMaxx Directory";
  }

  const handleSelectChange = (val) => {
    history.push(val);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => pageViewsTracking(), [history, pathname, activeRoute])


  return (
    // add this here to differentiate the dev site from prod 07212023
    <div className="header" style={{ background: window.location.hostname.toLocaleLowerCase() === "portal.emaxxgroup.com" ? '' : 'orange' }}>
      <Link to="/">
        <img src={logo} alt="eMaxx-logo" />
      </Link>
      <div>
        <h1 className="active-route">{activeRoute || "Dashboard"}</h1>
        {permissions.includes("000-001") && (
          <div id="settings-menu">
            {/* settings menu */}
            <Select.Container id="settings-select-container">
              <Select
                className="settings-select"
                value="/"
                onChange={(e) => handleSelectChange(e.target.value)}
              >
                <Select.Option value="/" style={{ display: "none" }}>
                  {isMobile ? "" : authState.clientName}
                </Select.Option>
                {permissions.includes("000-001-001") && (
                  <Select.Option value="/select-company">
                    Change Member Company
                  </Select.Option>
                )}
                {/* for eMaxx users (can see multiple clieunts) */}
                {permissions.includes("000-001-002") && (
                  <Select.Option value="/user-settings">
                    Manage Users
                  </Select.Option>
                )}
                {/* for Insured users (single client) */}
                {permissions.includes("000-001-003") && (
                  <Select.Option
                    value={`/user-settings/member/${authState.clientId}`}
                  >
                    Manage Users
                  </Select.Option>
                )}
                {permissions.includes("000-001-004") && (
                  <Select.Option value="/settings/document-upload">
                    Document Upload
                  </Select.Option>
                )}
                {permissions.includes("000-001-005") && (
                  <Select.Option value="/settings/activity-log">
                    Activity Log
                  </Select.Option>
                )}
              </Select>
            </Select.Container>
          </div>
        )}
        {/* show name for people without settings */}
        {!permissions.includes("000-001") && (
          <span id="settings-menu-placeHolder">{isMobile ? "" : authState.clientName}</span>
        )}
      </div>
    </div>
  );
}


Header.defaultProps = {
  isMobile: false,
};

export default Header;
