import React, { useEffect, useState } from "react";
// import { useQuery } from "react-query";

import Section from "../../../components/Section";
import Text from "../../../components/Text";
import Button from "../../../components/Button";
import { useAuth } from "../../../context/AuthContext";
// import { DocumentService } from "../../../services/index";
import "./MemberKiosk.scss";

const data = [
  {
    title: "Member Kiosk",
    items: [
      {
        label: 'Membership Agreement',
        action: "Execute",
        link: "link",
        code: "002-003-001",
      },

      {
        label: "eCaptiv PC8-IC Membership Agreement",
        action: "Execute",
        link: "link",
        code: "002-003-001",
      },

      {
        label: "eCaptiv PC8-IC Association Membership Agreement",
        action: "Execute",
        link: "link",
        code: "002-003-001",
      },

      // { label: "Certificates of Insurance", action: "View",link: "link", code: "002-003-001" },
      {
        label: "Endorsements/Policy Changes",
        action: "View",
        link: "/insurance/member-kiosk/endorsements",
        code: "002-003-002",
      },

      {
        label: "Renewal Documentation",
        action: "View",
        link: "/insurance/member-kiosk/renewal-Documentation",
        code: "002-003-005",
      },
      // {
      //   label: "Surety Bond",
      //   action: "Execute",
      //   link: "link",
      //   code: "002-003-003",
      // },
      // {
      //   label: "Loss Run",
      //   action: "Request",
      //   link: "link",
      //   code: "002-003-004",
      // },
    ],
  },
];
//the index + 1 is the PC code in order
const memberSignUpLinks = [
  { program: 1, link: "https://app.hellosign.com/signer/getStartedInPerson?guid=f1462109" },//pc1
  { program: 2, link: "https://app.hellosign.com/signer/getStartedInPerson?guid=31ea6a1a" },//pc2
  { program: 3, lnk: "https://app.hellosign.com/signer/getStartedInPerson?guid=3Q18nF3h" },//pc3
  { program: 4, link: "https://app.hellosign.com/signer/getStartedInPerson?guid=LKwRQ68K" },//pc4
  { program: 5, link: "" },
  //  { program: 6, link: "https://app.hellosign.com/home/reusableLink?guid=8Ca9M98R&in_person=1" }, //pc6
  { program: 6, link: "https://app.hellosign.com/home/reusableLink?guid=8uU6bsTH&in_person=1" }, //pc6
  { program: 7, link: "https://app.hellosign.com/home/reusableLink?guid=IGnghPCn&in_person=1" }, //pc7
  { program: 600, link: "https://app.hellosign.com/home/reusableLink?guid=8uU6bsTH&in_person=1" }, //pc6s 600

]
const Excess2MemberSignupLink = 'https://app.hellosign.com/home/reusableLink?guid=6JRuhzlA&in_person=1'; //pc8(excess2)
const Excess2MemberAssociationSignupLink = 'https://app.hellosign.com/home/reusableLink?guid=8SbhuqAA&in_person=1'; //pc8(excess2) association

const MemberKioskPage = () => {
  const { state: authState, permissions } = useAuth();

  // const { data: documents = [] } = useQuery("documentsByClient", () =>
  //   DocumentService.getDocumentsByClient(authState.clientId)
  // );

  // const [trueFileName, setTrueFileName] = useState("");

  // useEffect(() => {
  //   if (documents.length >= 1) {
  //     documents.forEach((document) => {
  //       if (document.DocumentId === "Member Kiosk_Membership Agreement") {
  //         const fileName = document.FilePath;
  //         setTrueFileName(
  //           fileName.slice(fileName.lastIndexOf("/")).replace(/\//i, "")
  //         );
  //       }
  //     });
  //   }
  // }, [documents]);


  // const { data: File = [], refetch } = useQuery(
  //   "documentDownload",
  //   () =>
  //     DocumentService.DocumentDownload(
  //       trueFileName,
  //       `${authState.clientName} Membership Agreement.pdf`
  //     ),
  //   { enabled: false }
  // );

  // const handleGetFile = (e) => {
  //   if (trueFileName.length > 2) {
  //     e.preventDefault();
  //     refetch();
  //   } else {
  //     toast.error("No Document Found");
  //   }
  // };

  return (
    <div id="MemberKioskPage">
      {data.map((group) => (
        <React.Fragment key={group.title}>
          <Text.H1>{group.title}</Text.H1>
          {group.items.map((item) => (
            <Section key={item.code}>
              <Text.H3
                classNames={
                  !permissions.includes(item.code) ? ["isDisabled"] : [""]
                }
              >
                {item.label === "Membership Agreement" ? `eCaptiv PC${authState.programRecId}-IC ${item.label}` : item.label}
              </Text.H3>
              {item.label === "Membership Agreement" ? (
                <Button.ExternalText
                  href={memberSignUpLinks.find(f => f.program === authState.programRecId)?.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  disabled={!permissions.includes(item.code)}
                >
                  {item.action}
                </Button.ExternalText>
              ) :
                (item.label.includes('PC8') ? (
                  (item.label.includes('Association') ?
                    <Button.ExternalText
                      href={Excess2MemberAssociationSignupLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      disabled={!permissions.includes(item.code)}
                    >
                      {item.action}
                    </Button.ExternalText> : (
                      <Button.ExternalText
                        href={Excess2MemberSignupLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        disabled={!permissions.includes(item.code)}
                      >
                        {item.action}
                      </Button.ExternalText>
                    )
                  )
                ) :
                  (
                    <Button.Text
                      to={item.link}
                      disabled={!permissions.includes(item.code)}
                    >
                      {item.action}
                    </Button.Text>
                  )
                )
              }
            </Section>
          ))}
        </React.Fragment>
      ))}
    </div>
  );
};

MemberKioskPage.propTypes = {};

export default MemberKioskPage;
