import React, { useEffect, useState, useMemo } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";

import { Select } from "rbx";

import { useAuth } from "../../../context/AuthContext";
import { DocumentService } from "../../../services/index";
import Button from "../../../components/Button";
import Text from "../../../components/Text";
import Section from "../../../components/Section";
import "./OrganizationalDocumentation.scss";

import DocumentDelete from '../../../components/DocumentDelete/DocumentDelete';

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const BoardOfDirectorsPage = ({ passDownCurrentProg }) => {
  const { state: authState, permissions } = useAuth();

  const [trueFileName, setTrueFileName] = useState("");
  const [docName, setDocName] = useState("");
  const [docName2, setDocName2] = useState("");

  const yearStart = new Date().getFullYear();

  const [currentYear, setCurrentYear] = useState(yearStart);

  // gets all documents associated with current program
  const { data: documents = [], refetch: refetchDocumentsByProgram } = useQuery("documentsByProgram", () =>
    DocumentService.getDocumentsByProgram(passDownCurrentProg)
  );

  // gets only the documents that will be Board of Directors and current year selected by the select
  const sortedDocuments = documents.filter(
    (doc) =>
      doc.DocumentId.startsWith("Governance_Organization_Board of Directors")
      && doc.Year === Number(currentYear)
  );

  // downloads file
  const { data: File = [], refetch } = useQuery(
    "documentDownload",
    () =>
      DocumentService.DocumentDownload(
        trueFileName,
        `${docName2} ${docName}`
      ),
    { enabled: false }
  );

  // Year Stuff

  const sortedDocumentsYear = documents.filter((doc) =>
    doc.DocumentId.startsWith("Governance_Organization_Board of Directors")
  );

  const yearHash = {};
  yearHash[yearStart] = ["blank"];
  if (sortedDocumentsYear.length >= 1) {
    sortedDocumentsYear.forEach((document) => {
      const headerYear = document.Year;
      if (Object.keys(yearHash).includes(headerYear)) {
        yearHash[headerYear].push("blank");
      } else {
        yearHash[headerYear] = ["blank"];
      }
    });
  }


  const fileDownloadSetup = (fileName, DocName) => {
    if (sortedDocuments.length >= 1) {
      let fileLst = sortedDocuments.sort((a, b) => new Date(b.CreatedDateTime) - new Date(a.CreatedDateTime));
      for (let document of fileLst) {
        if (document.FileName === fileName) {
          setDocName2(DocName);
          setDocName(fileName);
          const filePath = document.FilePath;
          setTrueFileName(
            filePath.slice(filePath.lastIndexOf("/")).replace(/\//i, "")
          );
          break;//find the most recent one and break out
        }
      }
      /*
      sortedDocuments.forEach((document) => {
        setDocName2(DocName);
        setDocName(fileName);
        if (document.FileName === fileName) {
          const filePath = document.FilePath;
          setTrueFileName(
            filePath.slice(filePath.lastIndexOf("/")).replace(/\//i, "")
          );
        }
      });*/
    }
  };

  // calls refetch for the document to be downloaded, changes when trueFileName state is updated after button click, this trueFileName is used in the download query
  // after a successful refetch teh file the state is reset
  // if statements separated so that toast not called at beginning
  useEffect(() => {
    if (docName.length > 2) {
      if (trueFileName.length > 2) {
        refetch();
        setDocName("");
        setTrueFileName("");
      } else {
        toast.error("No Document Found");
      }
    }
  }, [docName, trueFileName, refetch]);

  const formChange = (e) => {
    e.persist();
    const { value } = e.target;
    setCurrentYear(value);
    //refetchDocumentsByProgram();
  };

  useMemo(() => {
    refetchDocumentsByProgram();
    setCurrentYear(yearStart);// with the year dropdown, need to force the component to refresh with resetting the currentYear state
  }, [passDownCurrentProg]);

  //resolve the document delete issue for the year that no doc left and current year not updated when select element goes back to default Li 04/20/2023
  if (Object.keys(yearHash).length > 0) {
    let curYear = Object.keys(yearHash).find(f => f == currentYear);
    if (!curYear) {
      setCurrentYear(Number(Object.keys(yearHash).sort((a, b) => b - a)[0]));
    }
  }
  //resolve the document delete issue for the year that no doc left


  return (
    <>
      <Select.Container size="medium">
        <Select value={currentYear} onChange={formChange}>
          {Object.keys(yearHash)
            .sort((a, b) => b - a)
            .map((year) => (
              <Select.Option key={year}>{year}</Select.Option>
            ))}
        </Select>
      </Select.Container>
      <div className="statementContainer">
        {sortedDocuments.length < 1 ? (
          <Text.H3 style={{ margin: "1rem" }}>
            No Files Found For this Year
          </Text.H3>
        ) : (
          <>
            <div className="fileGroup">
              {sortedDocuments
                .sort((a, b) => {
                  const dateA = new Date(a.Year, a.Month, a.Day);
                  const dateB = new Date(b.Year, b.Month, b.Day);
                  // console.log(dateA - dateB);
                  return dateB - dateA;
                })
                .map((doc) => (
                  <Section>
                    <div className="statementCard">
                      <FontAwesomeIcon
                        icon={faFileAlt}
                        className="fileIcon"
                        size="6x"
                      />
                      <Text.H3>
                        {`${monthNames[doc.Month - 1]} ${doc.Day}, ${doc.Year
                          }`}
                      </Text.H3>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",//Li changed it to row from column to make layout better
                          paddingBottom: "0.5rem",
                        }}
                      >
                        <Button.ExternalText
                          onClick={(e) => {
                            fileDownloadSetup(
                              doc.FileName,
                              `${monthNames[doc.Month - 1]} ${doc.Day
                              }, ${doc.Year}`
                            );
                          }}
                        >
                          {`View ${doc.DocumentId.slice(
                            doc.DocumentId.lastIndexOf("_")
                          ).replace(/_/i, "")}`}
                        </Button.ExternalText>

                        {permissions.includes("002-002-007-001") && (<DocumentDelete
                          name=''
                          fileName={doc.FileName}
                          docObj={doc}
                          isIcon={false}
                          handleRefresh={() => refetchDocumentsByProgram()}
                        />)}
                      </div>
                    </div>
                  </Section>
                ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default BoardOfDirectorsPage;
