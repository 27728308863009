import React, { useEffect, useState, useMemo } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { Select } from "rbx";
import { format, parseISO } from "date-fns";

import { useAuth } from "../../../context/AuthContext";
import { DocumentService } from "../../../services/index";
import MemberFundAnalysisService from "../../../services/MemberFundAnalysisService";
import Text from "../../../components/Text";
import Button from "../../../components/Button";
import Section from "../../../components/Section";
import { formatCurrency, startCase } from "../../../utils";
import DocumentDelete from '../../../components/DocumentDelete/DocumentDelete';
import "./CollateralStatements.scss";

const CollateralStatements = ({ passDownCurrentProg }) => {
  const { state: authState, permissions } = useAuth();

  const [trueFileName, setTrueFileName] = useState("");
  const [docName, setDocName] = useState("");
  const [docName2, setDocName2] = useState("");
  const [toggleReached, setToggleReached] = useState(false);

  const yearStart = new Date().getFullYear();

  const [currentYear, setCurrentYear] = useState(yearStart);
  const [refresh, setRefresh] = useState(false);

  // gets all documents associated with current Client
  const { data: documents = [], refetch: refetchDocumentsByClient } = useQuery("documentsByClient", () =>
    DocumentService.getDocumentsByClient(authState.clientId)
  );

  //  get all document types
  const { data: documentTypes = [] } = useQuery("documentTypes", () =>
    DocumentService.getDocumentTypes()
  );

  // downloads file
  const { data: File = [], refetch } = useQuery(
    "documentDownload",
    () =>
      DocumentService.DocumentDownload(trueFileName, `${docName2} ${docName}`),
    { enabled: false }
  );


  useEffect(() => {
    if (docName.length > 2) {
      if (trueFileName.length > 2) {
        refetch();
        setDocName("");
        setTrueFileName("");
      } else {
        toast.error("No Document Found");
      }
    }
  }, [docName, trueFileName, refetch]);

  // gets only the documents that will be PPC and current year selected by the select
  const sortedDocuments = documents.filter(
    (doc) =>
      doc.DocumentId.startsWith("Premium Payment and Collateral") && doc.ProgramsRecId === passDownCurrentProg &&//Li program history
      doc.Year === Number(currentYear)
  );

  const abbrevCode = (Code) => {
    return startCase(
      Code.slice(Code.lastIndexOf("_")).replace(/_/i, "").replace(/_/i, " ")
    );
  };

  // gets the titles for the sections
  let sortedDocumentTypes = [];

  if (documentTypes.length >= 1) {
    const filteredDocumentTypes = documentTypes.filter((docType) =>
      docType.Code.startsWith("Premium Payment and Collateral")
    );
    // puts them in alphabetical order
    sortedDocumentTypes = filteredDocumentTypes.sort((a, b) =>
      abbrevCode(a.Code).localeCompare(abbrevCode(b.Code))
    );
  }

  // UI HEADER STUFF

  const abbrevCodeHash = {};
  if (sortedDocuments.length >= 1) {
    sortedDocuments.sort((a, b) => new Date(b.Year, b.Month, b.Day) - new Date(a.Year, a.Month, a.Day)).forEach((document) => {
      const codeArr = document.DocumentId.split("_");
      const header = codeArr[codeArr.length - 1].replace(/_/i, "");
      if (Object.keys(abbrevCodeHash).includes(header)) {
        abbrevCodeHash[header].push(document);
      } else {
        abbrevCodeHash[header] = [document];
      }
    });
  }
  // UI HEADER STUFF

  // for use to set up the year hash below
  const sortedDocumentsYear = documents.filter(
    doc => doc.DocumentId.startsWith("Premium Payment and Collateral") && doc.ProgramsRecId === passDownCurrentProg //Li program history
  );

  // had a remnant where a function would return a null above this hook, having a top level return creates a situation where the hooks below it coud not be rendered, which will cause failure
  // beacause hooks need to be consistently rendered

  const yearHash = useMemo(() => {
    let documentYearhash = {};
    documentYearhash["   "] = ["blank"];
    if (sortedDocumentsYear.length >= 1) {
      // get years with documents
      documentYearhash = {};
      if (sortedDocumentsYear.length >= 1) {
        sortedDocumentsYear.forEach(document => {
          const headerYear2 = document.Year;
          if (Object.keys(documentYearhash).includes(headerYear2)) {
            documentYearhash[headerYear2].push("blank");
          } else {
            documentYearhash[headerYear2] = ["blank"];
          }
        });
      }
    }
    return documentYearhash;
  }, [sortedDocumentsYear]);

  const fileDownloadSetup = (fileName, DocName, docObj) => {
    if (sortedDocuments.length >= 1) {
      let fileLst = sortedDocuments.sort((a, b) => new Date(b.CreatedDateTime) - new Date(a.CreatedDateTime));
      for (let document of fileLst) {
        //if (document.FileName === fileName) {
        if (document.FilePath === docObj.FilePath) {//if the cloud is changed, could have issue here. however if solves all the file downloading problem
          setDocName2(DocName);
          setDocName(fileName);
          const filePath = document.FilePath;
          setTrueFileName(
            filePath.slice(filePath.lastIndexOf("/")).replace(/\//i, "")
          );
          break;//find the most recent one and break out
        }
      }


      /*
      sortedDocuments.forEach(document => {
        setDocName2(DocName);
        setDocName(fileName);
        if (document.FileName === fileName) {
          const filePath = document.FilePath;
          setTrueFileName(
            filePath.slice(filePath.lastIndexOf("/")).replace(/\//i, "")
          );
        }
      });*/
    }
  };

  // calls refetch for the document to be downloaded, changes when trueFileName state is updated after button click, this trueFileName is used in the download query
  // after a successful refetch teh file the state is reset
  // if statements separated so that toast not called at beginning

  const formChange = e => {

    e.persist();
    // prevents below function from changing year once the user has interacted with the select
    setToggleReached(true);
    const { value } = e.target;
    setCurrentYear(value);
  };
  // creates a good variable for useMemo to run based off since yearHash.length didnt work
  // adding yearHash to dependencies will create infinite loop
  const shouldChange = Object.keys(yearHash)[Object.keys(yearHash).length - 1];
  useMemo(() => {
    if (!toggleReached) {
      const newYear = Object.keys(yearHash)[Object.keys(yearHash).length - 1];
      setCurrentYear(newYear);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldChange]);



  useMemo(() => {

    let mostCurrentDocYear = Object.keys(yearHash)
      .sort((a, b) => b - a)[0];

    setCurrentYear(mostCurrentDocYear);// force re render and have the most current year at driopdwon and corresponding files at all the time when program change
    refetchDocumentsByClient();// Prop changed did not rerender instead of using key for the child component, use useMemo to detect and retech docs with new program
  }, [passDownCurrentProg]);

  //resolve the document delete issue for the year that no doc left and current year not updated when select element goes back to default Li 04/20/2023
  if (Object.keys(yearHash).length > 0) {
    let curYear = Object.keys(yearHash).find(f => f == currentYear);
    if (!curYear) {
      setCurrentYear(Number(Object.keys(yearHash).sort((a, b) => b - a)[0]));
    }
  }
  //resolve the document delete issue for the year that no doc left





  return (
    <>
      <Select.Container size="medium">
        <Select value={currentYear} onChange={formChange}>
          {Object.keys(yearHash)
            .sort((a, b) => b - a)
            .map(year => (
              <Select.Option key={year}>{year}</Select.Option>
            ))}
        </Select>
      </Select.Container>
      <div className="statementContainer">
        {sortedDocuments.length < 1 ? (
          <Text.H3 style={{ margin: "1rem" }}>
            No Collateral Documents Found
          </Text.H3>
        ) : (
          <>
            <div className="fileGroup">
              {Object.keys(abbrevCodeHash)
                .sort((a, b) => a.localeCompare(b))
                .map((header) => (
                  <div className="outerStatementHolder" key={header}>
                    <Text.H1>{header}</Text.H1>
                    <div className="innerStatementHolder">
                      {abbrevCodeHash[header]
                        .sort((a, b) => {
                          const dateA = new Date(a.Year, a.Month, a.Day);
                          const dateB = new Date(b.Year, b.Month, b.Day);
                          return dateA - dateB;
                        })
                        .map(document => (
                          <Section key={document.RecId}>
                            <div className="statementCard">
                              <FontAwesomeIcon
                                icon={faFileAlt}
                                className="fileIcon"
                                size="6x"
                              />
                              <Text.H3>{`${document.Month}/${document.Day}/${document.Year}`}</Text.H3>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  paddingBottom: "0.5rem"
                                }}
                              >
                                <Button.ExternalText
                                  onClick={e => {
                                    fileDownloadSetup(
                                      document.FileName,
                                      `${document.Month}/${document.Day}/${document.Year}`,
                                      document
                                    );
                                  }}
                                >
                                  View Collateral Document
                                </Button.ExternalText>
                                {permissions.includes("002-005-003-002") && (<DocumentDelete
                                  name=''
                                  fileName={document.FileName}
                                  docObj={document}
                                  isIcon={false}
                                  handleRefresh={() => refetchDocumentsByClient()}
                                />
                                )}
                              </div>
                            </div>
                          </Section>
                        ))}
                    </div>
                  </div>
                ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CollateralStatements;
