import React from "react";

import PaymentCollateralPage from "./PaymentCollateralPage";
import { useAuth } from "../../../context/AuthContext";

// new feature added, make similar to the F&P page; for data hiding(while loading), moved statements into the old
// P and c page
const OuterPPCPage = () => {
  const { permissions } = useAuth();
  return(
    <div className="PPCPAGE">
      <PaymentCollateralPage />
    </div>
)};

OuterPPCPage.propTypes = {};

export default OuterPPCPage;