import APIService from "./index";

const WeatherService = {
  getWeatherData: (userClientId, userZipCode) =>
    userClientId && userZipCode
      ? APIService.get(
          `/GetWeather?clientId=${userClientId}&zipcd=${userZipCode}`
        ).then(res => res.data.Payload)
      : {}
};

export default WeatherService;
