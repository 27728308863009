import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../../context/AuthContext";
import Section from "../../../components/Section";
import Text from "../../../components/Text";
import ColorDivider from "../../../components/Divider";
import DoughnutChart from "../../../components/Chart/Doughnut/DoughnutChart";
import LPSService from "../../../services/LPSService";
import Loading from "../../../components/Loading";
import { Table, Generic, Field, Control, Checkbox, Select } from "rbx";
import Button from "../../../components/Button";
import { toast } from "react-toastify";
import { DocumentService } from "../../../services/index";
import { groupBy } from "lodash";

import "./LPS.scss";
import COLUMNS from "./columns";
import DataTable from "../../../components/DataTable";


const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

const LPSPage = () => {
  const { state: authState, permissions } = useAuth();

  const yearStart = new Date().getFullYear();

  var cq = Math.floor(new Date().getMonth() / 3) + 1;
  //const cq = m > 4 ? m - 4 : m;

  const yearQuarterStart = new Date().getFullYear() + '/' + cq;



  const [currentYear, setCurrentYear] = useState(yearStart);
  const [currentYearQuarter, setCurrentYearQuarter] = useState(yearQuarterStart);
  const [trueFileName, setTrueFileName] = useState('');
  const [docName, setDocName] = useState("");
  const [docName2, setDocName2] = useState("");





  const {
    data: LossControl = [],
    isLoadingLossControl,
    errorLossControl,
  } = useQuery("LossControlTracker", () =>
    LPSService.getLossControlTracker(authState.clientId)
  );

  const {
    data: BestPractices = [],
    isLoadingBestPractices,
    errorBestPractices,
  } = useQuery("BestPracticesByClientId", () =>
    LPSService.getBestPracticesByClientId(authState.clientId)
  );

  const {
    data: BestPracticeScores = [],
    isLoadingBestPracticeScores,
    errorBestPracticeScores,
  } = useQuery("BestPracticeScoresByProgram", () =>
    LPSService.getBestPracticeScoresByProgram(authState.programRecId)
  );

  //Li Add Warranties And Recommdations
  const {
    data: WarrantiesAndRecommdations = [],
    isLoadingWarrantiesAndRecommdations,
    errorWarrantiesAndRecommdations,
  } = useQuery("WarrantiesAndRecommdationsByClientId", () =>
    LPSService.getWarrantiesRecommdationsByClientId(authState.clientId)
  );

  //Li Add Loss Control Retainer services
  const {
    data: LossControlRetainerServices = [],
    isLoadingLossControlRetainerServices,
    errorLossControlRetainerServices,
  } = useQuery("LossControlRetainerServices", () =>
    LPSService.getLossControlRetainerServices(authState.clientId)
  );

  //Mongoose RM
  const { data: documents = [] } = useQuery("documentsByClient", () =>
    DocumentService.getDocumentsByClient(authState.clientId),
    //{ fetchPolicy: "no-cache", cacheTime: 0 }
    { cacheTime: 2000 });

  const { data: File = [], refetch } = useQuery(
    "documentDownload",
    () =>
      DocumentService.DocumentDownload(
        trueFileName,
        `${authState.clientName}` + ' ' + docName
      ),
    { enabled: false }
  );


  const sortedDocumentsMRMYearly = documents.filter(
    (doc) =>
      doc.DocumentId.startsWith("Loss Prevention and Safety section_Mongoose RM_Mongoose RM Annual Summary")
  ).sort((a, b) => b.Year - a.Year);;

  const sortedDocumentsMRMQuarterly = documents.filter(
    (doc) =>
      doc.DocumentId.startsWith("Loss Prevention and Safety section_Mongoose RM_Mongoose RM Quarterly Summary")
  ).sort((a, b) => b.Year - a.Year);

  // YEAR stuff
  const yearHash = [];
  if (sortedDocumentsMRMYearly.length >= 1) {
    sortedDocumentsMRMYearly.forEach((document) => {
      const headerYear = document.Year;
      if (!yearHash.includes(headerYear)) {
        yearHash.push(headerYear)
      }
    });

  }
  // year stuff
  //year quarter
  const yearQuarterHash = [];
  if (sortedDocumentsMRMQuarterly.length >= 1) {
    sortedDocumentsMRMQuarterly.forEach((document) => {
      const headerYearQuarter = document.Year + '/' + document.Quarter;
      if (!yearQuarterHash.includes(headerYearQuarter)) {
        yearQuarterHash.push(headerYearQuarter)
      }
    });

  }
  //year quarter
  const maxYear = yearHash.length > 0 ? yearHash[0] : '';
  const maxYearQuarter = yearQuarterHash.length > 0 ? yearQuarterHash[0] : '';

  useMemo(() => {
    if (maxYear) {
      setCurrentYear(maxYear);
    }
    if (maxYearQuarter) {
      setCurrentYearQuarter(maxYearQuarter);
    }

  }, [maxYear, maxYearQuarter]);



  const MRMYearly = sortedDocumentsMRMYearly
    .filter(f => f.Year === Number(currentYear)).sort((a, b) => {
      var aa = new Date(a.CreatedDateTime);
      var bb = new Date(b.CreatedDateTime);
      return bb - aa
    }
    )[0];

  const MRMQuarterly = sortedDocumentsMRMQuarterly
    .filter(f => f.Year === Number(currentYearQuarter.split('/')[0]) && f.Quarter === Number(currentYearQuarter.split('/')[1]))
    .sort((a, b) => {
      var aa = new Date(a.CreatedDateTime);
      var bb = new Date(b.CreatedDateTime);
      return bb - aa;
    }
    )[0];



  useEffect(() => {
    if (docName) {
      if (trueFileName.length > 2) {
        //e.preventDefault();

        refetch();
        setTrueFileName('');
        setDocName('');
      } else {
        toast.error("No Document Found");
      }
    }
  }, [docName, trueFileName]);



  const handleGetFile = (e, value, type, document) => {
    let fileNameT = document?.FilePath?.slice(document?.FilePath.lastIndexOf("/")).replace(/\//i, "");
    setTrueFileName(fileNameT);
    setDocName(document?.FileName);
  };
  // DOCUMENT DOWNLOAD

  const formChange = (e, type) => {
    e.persist();
    // prevents below function from changing year once the user has interacted with the select
    //setToggleReached(true);
    const { value } = e.target;

    if (type === 'year') {
      setCurrentYear(value);
    }
    else if (type === 'quarter') {
      setCurrentYearQuarter(value);
    }
  };





  if (
    isLoadingLossControl ||
    isLoadingBestPractices ||
    isLoadingBestPracticeScores ||
    isLoadingWarrantiesAndRecommdations ||//Li Add Warranties And Recommdations
    isLoadingLossControlRetainerServices
  ) {
    return (
      <div className="headerContainer">
        <Loading.SmallLoading />
      </div>
    );
  }
  if (errorLossControl || errorBestPractices || errorBestPracticeScores || errorWarrantiesAndRecommdations || errorLossControlRetainerServices) {//Li Add Warranties And Recommdations
    return null;
  }
  let bestPracticeFinalScore = 0;
  if (BestPractices.length >= 1 && BestPracticeScores >= 1) {
    BestPracticeScores.forEach((score) => {
      BestPractices.forEach((practice) => {
        if (score.Section === practice.Section) {
          bestPracticeFinalScore += score.Weight;
        }
      });
    });
  }

  //Li add Warranties and recommdations
  let warranties = [];
  let recommdations = [];
  WarrantiesAndRecommdations.forEach((item) => {
    if (item.WarrantyStatusCd === 'Closed') return; //Li change to only display not closed warranties and recommdations for all time requested by Justin/Kevin
    if (item.GroupTypeTxt === 'Warranty') {
      warranties.push(item);
    }
    else { recommdations.push(item); }
  });


  //Li Loss Control retainer services
  let LossControlRetainerServicesDistinct = []
  LossControlRetainerServices.forEach(item => {
    let found = LossControlRetainerServicesDistinct.findIndex(f => f.EventTypeCd === item.EventTypeCd);
    if (found < 0) {
      LossControlRetainerServicesDistinct.push(item);
    }
  })




  // console.log("Loss Control", LossControl);
  // console.log("BestPractices", BestPractices);
  // console.log("BestPracticeScores", BestPracticeScores);

  return (
    <div id="LPSContainer">
      {/* heading */}
      <div className="LPSHeader">
        {BestPracticeScores.length
          ? BestPracticeScores.forEach((score) => {
            BestPractices.forEach((practice) => {
              if (score.Section === practice.Section && practice.IsSelectedInd) {
                bestPracticeFinalScore += score.Weight;
              }
            });
          })
          : null}
        {permissions.includes("003-001-001") && (
          <Section>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "baseline",
              }}
            >
              {/* <Text.H3>Overall Score Matrix </Text.H3> */}
              <Text.H3>Best Practices Score Matrix </Text.H3>
              <Icon
                tooltip="Your Safety Score has been derived by identification of Industry Best Practices and applying weighting to each and then determine which best practice your company is in compliance with. Note: Some best practices are mandatory."
                tooltipMultiline
                tooltipPosition="bottom"
                id="iconTooltip"
              >
                <FontAwesomeIcon icon={faQuestionCircle} />
              </Icon>
            </div>
            <DoughnutChart score={bestPracticeFinalScore} />
          </Section>
        )}
        {permissions.includes("003-001-002") && (
          <Section>
            {/* change here to Retainer per request, db and api and the rest stay with Tracker */}
            {/* <Text.H3>Loss Control Retainer</Text.H3> */}
            <Text.H3>Loss Prevention and Technology Services</Text.H3>
            <div className="LCT-List-item">
              <div>Total Amount</div>
              <div>
                {LossControl.length >= 1
                  ? formatter.format(LossControl[0].ContractAmount)
                  : "-"}
              </div>
            </div>
            {/* remove this by request from business 07182024<div className="LCT-List-item">
              <div>Total Spent</div>
              <div>
                {LossControl.length >= 1
                  ? formatter.format(LossControl[0].TotalContractSpent)
                  : "-"}
              </div>
            </div> */}
            <ColorDivider />
            {/*  remove this by request from business 07182024 <div className="LCT-List-item bold">
              <div>Remaining</div>
              <div>
                {LossControl.length >= 1
                  ? formatter.format(LossControl[0].RemainingContractAmount)
                  : "-"}
              </div>
            </div> */}
            {/* <Button.Text to="LossRunsLink">Book Training</Button.Text> */}
          </Section>
        )}
        {permissions.includes("003-001-006") && (
          <Section>
            <div style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}>
              {/* <Text.H3 style={{ marginRight: '0.8em' }}>Retainer Services </Text.H3> */}
              <Text.H3 style={{ marginRight: '0.8em' }}>Loss Prevention and Safety Services </Text.H3>
              {/* <Icon
                tooltip="This displays all of the loss control services types that have been provide during your current policy term excluding the current month."
                tooltipMultiline
                tooltipPosition="left"
                id="iconTooltip"
              >
                <FontAwesomeIcon icon={faQuestionCircle} />
              </Icon> */}
            </div>
            <div>
              {LossControlRetainerServicesDistinct.map(item => (
                <div>{item.EventTypeTxt}</div>
              ))}
            </div>
          </Section>
        )}
      </div>
      <div>
        {permissions.includes("003-001-007") && (
          <>
            <div className='SubSection'>
              <Text.H1>Mongoose RM</Text.H1>
              <div>
                <Text.H3>Quarterly Summary</Text.H3>

                <Select.Container size="medium" style={{ marginBottom: 10 }}>
                  <Select value={currentYearQuarter} onChange={(e) => formChange(e, 'quarter')}>
                    {yearQuarterHash
                      .sort((a, b) => {
                        return b - a
                      })
                      .map((yearQuarter, index) => {
                        return (<Select.Option key={yearQuarter} value={yearQuarter}>{`Q${yearQuarter.split('/')[1]} ${yearQuarter.split('/')[0]}`}</Select.Option>);
                      })}
                  </Select>
                </Select.Container>
                <Section>
                  {yearQuarterHash.length > 0 ? (<>
                    <Text.H3> {currentYearQuarter ? (`Q${currentYearQuarter.split('/')[1]} ${currentYearQuarter.split('/')[0]}`) : ''} -- Mongoose RM</Text.H3>
                    <Button.ExternalText onClick={(e) => handleGetFile(e, currentYearQuarter, 'quarter', MRMQuarterly)}>
                      <FontAwesomeIcon icon={faDownload} size="2x" />
                    </Button.ExternalText> </>) :
                    (<div> No Document Found.</div>)}
                </Section>
              </div>

              {/* <div> hide for now, Business may need this later
                <Text.H3> Mongoose RM Annual Summary</Text.H3>
                <Select.Container size="medium" style={{ marginBottom: 10 }}>
                  <Select value={currentYear} onChange={(e) => formChange(e, 'year')}>
                    {yearHash
                      .sort((a, b) => {
                        return b - a
                      })
                      .map(year =>
                        (<Select.Option key={year} value={year}>{year}</Select.Option>)
                      )}
                  </Select>

                </Select.Container>
                <Section>
                  {yearHash.length > 0 ? (<>
                    <Text.H3> {currentYear ? `${currentYear}` : ''} -- Mongoose RM</Text.H3>
                    <Button.ExternalText onClick={(e) => handleGetFile(e, currentYear, 'year', MRMYearly)}>
                      <FontAwesomeIcon icon={faDownload} size="2x" />
                    </Button.ExternalText></>) :
                    (<div> No Document Found.</div>)}
                </Section>


              </div> */}
            </div>
          </>



        )}
      </div>

      <div id='LPStableContainer'>
        <div className="tableSection SubSection">
          {permissions.includes("003-001-005") && (
            <>
              <Text.H1>Best Practices</Text.H1>
              <h4>(Reflects past 12 months)</h4>
              <Section>
                {BestPractices.length ? (
                  <div className="tableContainer">
                    {BestPractices.map((practice) => (
                      <div className="table-item" key={practice.RecId}>
                        <label>
                          <Checkbox className="inputCheckBox" checked={practice.IsSelectedInd} disabled={!practice.IsSelectedInd} readOnly /> <span>{practice.Section}</span>
                        </label>
                      </div>
                    ))}
                  </div>
                ) : (
                  "No Best Practices Found."
                )}
              </Section></>
          )}
        </div>
      </div>
      <div>
        {permissions.includes("003-001-003") && (
          <div className='SubSection'>
            <Text.H1>Warranties</Text.H1>
            <h4>(Reflects all open Warranties)</h4>
            {warranties.length ? (
              <div className="">
                <DataTable
                  columns={COLUMNS}
                  data={warranties}
                  sortBy={[
                    {
                      //id: "DateInitiated",
                      //desc: false,
                    },
                  ]}
                />
              </div>
            ) : (
              <Section>No Warranties Found.</Section>
            )}
          </div>
        )}
        {permissions.includes("003-001-003") && (
          <div className='SubSection'>
            <Text.H1>Recommendations</Text.H1>
            <h4>(Reflects all open Recommendations)</h4>
            {recommdations.length ? (
              <div className="">
                <DataTable
                  columns={COLUMNS}
                  data={recommdations}
                  sortBy={[
                    {
                      //id: "DateInitiated",
                      //desc: false,
                    },
                  ]}
                />
              </div>
            ) : (
              <Section>No Recommendations Found.</Section>
            )}
          </div>
        )}
      </div>



      {/* Phase 2 */}
      {/* {tableDate.map((group) => (
        <div className="tableSection">
          <Text.H1>{group.title}</Text.H1>
          <Section>
            <div className="table-item bold">
              <div>Item</div>
              <div>Status</div>
            </div>
            <ColorDivider />
            {group.items.map((item) => (
              <div className="table-item">
                <div>{item.item}</div>
                <div>{item.status}</div>
              </div>
            ))}
          </Section>
        </div>
      ))} */}

      {/* Safety Committee  PHASE 2 */}
      {/* <div className="SCSection">
        <Text.H1>Safety Committee</Text.H1>
          {SafetyCommitteeData.map(group => (
            <Section>
          <Text.H2>{group.title}</Text.H2>
          {group.items.map((item) => (
            <ImageItem image={item.image} topText={item.topText} bottomText={item.bottomText}/>
          ))}
        </Section>
          ))}
      </div> */}
      {/* Best Practices */}
    </div>
  );
};


export default LPSPage;
