import React from "react";

import { useQuery } from "react-query";
import {
  closestIndexTo,
  startOfToday,
  differenceInCalendarDays,
  isAfter
} from "date-fns";

import { useAuth } from "../../../context/AuthContext";
import Text from "../../../components/Text";
import Section from "../../../components/Section";
import ColorDivider from "../../../components/Divider";
import PolicyService from "../../../services/PolicyService";
import MemberFundAnalysisService from "../../../services/MemberFundAnalysisService";
import Loading from "../../../components/Loading";

import "./InsuranceDashboard.scss";

const calculateRenewalDays = policies => {
  // find the policy set to expire next and calculate the days until expiration (renewal)
  const expirationDates = policies
    .map(policy => new Date(policy.ExpirationDate))
    .filter(date => isAfter(date, new Date()));
  const closestIndex = closestIndexTo(new Date(), expirationDates);
  const nextExpirationDate = expirationDates[closestIndex];
  const daysToExpire = differenceInCalendarDays(
    nextExpirationDate,
    startOfToday()
  );

  return daysToExpire;
};

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0
});

const InsuranceDashboard = () => {
  const { state: authState, permissions } = useAuth();

  const {
    data: policies = [],
    isLoadingPolicies,
    errorPolicies
  } = useQuery("policiesByClient", () =>
    PolicyService.getPoliciesByClient(authState.clientId)
  );

  let {
    data: funds = [],
    isLoadingFunds,
    errorFunds
  } = useQuery("memberFundAnalysis", () =>
    MemberFundAnalysisService.getMemberFundAnalysis(authState.clientId)
  );

  if (isLoadingPolicies || isLoadingFunds) {
    return (
      <div className="headerContainer">
        <Text.H1>Insurance</Text.H1>
        <Loading.SmallLoading />
      </div>
    );
  }
  if (errorPolicies || errorFunds) {
    return null;
  }

  const daysToRewnewal = calculateRenewalDays(policies);

  let closestQuarter = 0;
  let closestYear = 0;
  if (funds.length >= 1) {

    //program history, for dashboard display current program, may need to change with dropdown
    funds = funds.filter(f => Number(f.ProgramCd ? f.ProgramCd?.toString().match(/(\d+)/)[0] : 0) === authState.programRecId)
    if (funds.length >= 1) {
      const sortedDateFunds = funds.sort((a, b) => b.YearQuarter - a.YearQuarter);
      closestYear = sortedDateFunds[0].YearQuarter.toString().slice(0, 4);
      closestQuarter = sortedDateFunds[0].YearQuarter.toString().slice(4);
    }
  }

  const currentYearQuarterFund = funds
    .filter(fund => {
      return (
        fund.YearQuarter ===
        Number(closestYear) * 100 + Number(closestQuarter) &&
        (fund.PremiumYearFrom === Number(closestYear) ||
          fund.PremiumYearTo === Number(closestYear) ||
          fund.PremiumYearTo === Number(closestYear) - 1)
      );
    })
    .sort((a, b) => (a.PremiumYearTo < b.PremiumYearTo ? 1 : -1));

  let mostRecenFunds = [];
  if (currentYearQuarterFund[0] && currentYearQuarterFund[1]) {
    mostRecenFunds = [currentYearQuarterFund[0], currentYearQuarterFund[1]];
  } else if (currentYearQuarterFund[0]) {
    mostRecenFunds = [currentYearQuarterFund[0]];
  }

  //Li, get the last month of the quarter for MFA data on claim dashboard
  const quarterMonthObj = {
    '01': "03/31",
    '02': "06/30",
    '03': "09/30",
    '04': "12/31"
  };

  return (
    <div id="insuranceDash">
      <div className="headerContainer">
        {permissions.includes("001-006") && (
          <Text.H1>Insurance</Text.H1>
        )}
        {permissions.includes("001-005") && !isNaN(daysToRewnewal) && (
          <span className="renewalText" style={{ fontSize: "2.5rem" }}>
            Renewal in
            {daysToRewnewal && permissions.includes("001-005") && (
              <span className="renewalDays">{` ${daysToRewnewal} Days`}</span>
            )}
          </span>
        )}
      </div>
      <div>
        {(permissions.includes("001-006") && mostRecenFunds.length > 0) &&
          <h4>
            <span>
              {'As of ' + quarterMonthObj[mostRecenFunds[0].YearQuarter.toString().slice(4)] + '/'
                + mostRecenFunds[0].YearQuarter.toString().slice(0, 4)}
            </span>
          </h4>}
      </div>
      {funds.length && mostRecenFunds.length ? (
        <>
          {permissions.includes("001-006") && (
            <div className="insuranceSection">
              {(authState.programRecId === 1 ||
                authState.programRecId === 3 || authState.programRecId === 6
                || authState.programRecId === 7) && (
                  <>
                    <Section style={{ marginRight: "5em" }}>
                      <div className="sectionTitles">
                        <Text.H3>A Fund Balance</Text.H3>
                      </div>
                      <div className="tableTitles">
                        <span>Premium Year</span>
                        {' '}
                        <span>Balance</span>
                      </div>
                      <ColorDivider />
                      {mostRecenFunds.map(fund => (
                        <div className="tableData" key={fund.RecId}>
                          <span>
                            {fund.PremiumYearFrom}
                            -
                            {fund.PremiumYearTo}
                          </span>
                          <span>
                            {currentYearQuarterFund.length >= 1
                              ? formatter.format(fund.AFundBalance)
                              : "--"}
                          </span>
                        </div>
                      ))}
                    </Section>
                    <Section>
                      <div className="sectionTitles">
                        <Text.H3>B Fund Balance</Text.H3>
                      </div>
                      <div className="tableTitles">
                        <span>Premium Year</span>
                        {' '}
                        <span>Balance</span>
                      </div>
                      <ColorDivider />
                      {mostRecenFunds.map(fund => (
                        <div className="tableData" key={fund.RecId}>
                          <span>
                            {fund.PremiumYearFrom}
                            -
                            {fund.PremiumYearTo}
                          </span>
                          <span>
                            {currentYearQuarterFund.length >= 1
                              ? formatter.format(fund.BFundBalance)
                              : "--"}
                          </span>
                        </div>
                      ))}
                    </Section>
                  </>
                )}
              {(authState.programRecId === 2 ||
                authState.programRecId === 4) && (
                  <>
                    <Section style={{ marginRight: "5em" }}>
                      <div className="sectionTitles">
                        <Text.H3>Group Fund Balance</Text.H3>
                      </div>
                      <div className="tableTitles">
                        <span>Premium Year</span>
                        {' '}
                        <span>Balance</span>
                      </div>
                      <ColorDivider />
                      {mostRecenFunds.map(fund => (
                        <div className="tableData" key={fund.RecId}>
                          <span>
                            {fund.PremiumYearFrom}
                            -
                            {fund.PremiumYearTo}
                          </span>
                          <span>
                            {currentYearQuarterFund.length >= 1
                              ? formatter.format(fund.AFundBalance)
                              : "--"}
                          </span>
                        </div>
                      ))}
                    </Section>
                  </>
                )}
            </div>
          )}
        </>
      ) : (
        <>
          <Section>No Insurance Fund Found.</Section>
        </>
      )}
    </div>
  );
};

InsuranceDashboard.propTypes = {};

export default InsuranceDashboard;
