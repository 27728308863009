import APIService from "./index";
import { getRoleGroupData } from "../utils";

const UserService = {
  createUser: (data, email, origin) =>
    APIService.post(`/createUser?Email=${email}&Origin=${origin}`, data),
  createUserRoleGroup: (data) => APIService.post("/createUserRoleGroup", data),
  updateUser: (recId, data) => APIService.put("/updateUser", data, { recId }),
  getUserRoleGroup: (recId) =>
    APIService.get(`/GetUserRoleGroup?UsersRecId=${recId}`),
  updateUserRoleGroup: (recId, data) =>
    APIService.put("/updateUserRoleGroup", data, { recId }),
  deleteUser: (recId) => APIService.delete("/deleteUser", { recId }),
  getUsers: () => APIService.get("/getUsers").then((res) => res.data.Payload),

  getUser: async (recId) => {
    const {
      data: { Payload: user = false },
    } = await APIService.get("/getUser", { recId });

    const {
      data: { Payload: userRoleGroup = false },
    } = await APIService.get(`/GetUserRoleGroup?UsersRecId=${recId}`);

    const roleGroupData = await getRoleGroupData();
    const { roleGroups } = roleGroupData;

    const result = roleGroups.find(
      (roleGroup) => roleGroup.RecId === userRoleGroup.RoleGroupsRecId
    );
    return { ...user, roleGroup: result };
  },

  getUserByEmail: (userEmail) =>
    APIService.get(`/GetUserByEmail?email=${userEmail}`),

  getUsersByGroup: async (groupName, clientId, authRole, authGroup) => {
    const roleGroupData = await getRoleGroupData(groupName);
    const groupUserRoleGroups = groupName
      ? roleGroupData.selectedGroupRoleGroups
      : roleGroupData.emaxxUserRoleGroups;

    const {
      data: { Payload: allUsers = false },
    } = await APIService.get("/getUsers");

    let allClientUsers = allUsers;
    if (clientId) {
      allClientUsers = await allUsers.filter(
        (user) => user?.ClientId?.toString() === clientId
      );
    }

    const {
      data: { Payload: allRoles = false },
    } = await APIService.get(`/GetRoles`);

    const {
      data: { Payload: allRoleGroups = false },
    } = await APIService.get(`/GetRoleGroups`);

    const groupUsers = await Promise.all(
      groupUserRoleGroups.map(async (userRoleGroup) => {
        const associatedUser = await allClientUsers.find(
          (user) => user.RecId === userRoleGroup.UsersRecId
        );

        const associatedRoleGroup = allRoleGroups.find(
          (roleGroup) => roleGroup.RecId === userRoleGroup.RoleGroupsRecId
        );
        const associatedRole = allRoles.find(
          (role) => role.RecId === associatedRoleGroup.RolesRecId
        );

        const getEditableRoles = () => {
          const roles = [...allRoles];
          let result = [];
          // emax admin
          if (authGroup === "eMaxx" && authRole === "Administrator") {
            result = roles;
            // emax manager
          } else if (!groupName) {
            result = roles.filter((role) => role.Name === "Employee");
            // policyholder (member) admin
          } else if (authRole === "Administrator") {
            result = roles.filter((role) => role.Name !== "Administrator");
            // policyholder (member) manager
          } else if (authRole === "Manager") {
            result = roles.filter(
              (role) => role.Name !== "Administrator" && role.Name !== "Manager"
            );
            // policyholder (member) supervisor
          } else {
            result = roles.filter((role) => role.Name === "Employee");
          }
          return result;
        };

        const editableRoles = getEditableRoles();

        const isEditable = !!editableRoles.find(
          (role) => role.RecId === associatedRole.RecId
        );

        const user = {
          FirstName: associatedUser?.FirstName,
          LastName: associatedUser?.LastName,
          Email: associatedUser?.Email,
          Phone: associatedUser?.Phone,
          roleName: associatedRole.Name,
          RecId: associatedUser?.RecId,
          isEditable,
        };

        if (associatedUser) {
          return user;
        }
        return null;
      })
    );

    const result = groupUsers.filter((user) => {
      return user !== null;
    });
    if (result[0]) {
      return result;
    }
  },
};

export default UserService;
