import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const HTTP = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
});

HTTP.interceptors.request.use((config) => {
  const token = window.localStorage.getItem("emaxx_token");
  return {
    ...config,
    headers: token
      ? { ...config.headers, Authorization: `Bearer ${token}` }
      : config.headers,
  };
});

const HTTP2 = axios.create({
  baseURL: API_BASE_URL,
  responseType: "blob",
  headers: {
    "Content-Type": "application/json",
  },
});

HTTP2.interceptors.request.use((config) => {
  const token = window.localStorage.getItem("emaxx_token");
  return {
    ...config,
    headers: token
      ? { ...config.headers, Authorization: `Bearer ${token}` }
      : config.headers,
  };
});

const ApiService = {
  get: (url, params) => HTTP.get(url, { params }),
  post: (url, body, params) => HTTP.post(url, body, { params }),
  put: (url, body, params) => HTTP.put(url, body, { params }),
  delete: (url, params) => HTTP.delete(url, { params }),
  getFile: (url, params) => HTTP2.get(url, { params }),
};

// axios({
//   url: '/DocumentDownload',
//   method: 'GET',
//   responseType: 'blob', // important
// }).then((response) => {
//   const url = window.URL.createObjectURL(new Blob([response.data]));
//   const link = document.createElement('a');
//   link.href = url;
//   link.setAttribute('download', 'file.pdf'); // or maybe
//   document.body.appendChild(link);
//   link.click();
//   document.body.removeChild(link);
// });

export const AuthService = {
  login: ({ username, password }) => {
    return ApiService.post("/LogOn", {
      username,
      password,
      grant_type: "password",
    });
  },
  logout: () => ApiService.delete("/logout"),
  checkAuth: () => ApiService.get("/checkAuth"),
  forgotPassword: (params) => ApiService.post("/ForgotPassword", null, params),
  resetPassword: (params) => ApiService.post("/ResetPassword", null, params),
};

export const DocumentService = {
  getDocumentsByProgram: (programsRecId) =>
    programsRecId
      ? ApiService.get("/getDocumentsByProgram", { programsRecId }).then(
        (res) => res.data.Payload
      )
      : [],
  getDocumentsByClient: (clientId) =>
    ApiService.get("/getDocumentsByClient", { clientId }).then(
      (res) => res.data.Payload
    ),
  getDocument: (documentId) => ApiService.get("/getDocument", { documentId }),
  getDocumentTypes: () =>
    ApiService.get("/GetDocumentTypes").then((res) => res.data.Payload),
  createDocument: (document) =>
    ApiService.post("/createDocument", document).then((res) => res),
  DocumentUpload: (file) =>
    ApiService.post("/DocumentUpload", file).then((res) => res),
  // downloadFileName will be the fileName when downloaded, right now sending it in because the data is coming back as a blob but if
  // Jen can somehow include the original upload file name in a header instead that would probably be better and much more dynamic
  DocumentDownload: (FileName, downloadFileName) =>
    ApiService.getFile("/DocumentDownload", { FileName }).then(
      async (response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", downloadFileName); // got rid of file.pdf default
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return response;
      }
    ),
  DocumentDelete: (FileName, RecId) =>
    ApiService.delete("/DocumentDelete", { FileName, RecId }).then((res) => res),

};

export const ObjectService = {
  getObjects: () => ApiService.get("/getObjects"),
};

// 310 for news
export const WordPressService = {
  getNews: (limit = 3) =>
    axios
      .get(
        `https://www.emaxxgroup.com/wp-json/wp/v2/posts?_embed&categories=310&per_page=${limit}`
      )
      .then((res) => (Array.isArray(res.data) ? res.data.slice(0, limit) : [])),
  getWebinars: (limit = 3) =>
    axios
      .get(`https://www.emaxxgroup.com/wp-json/v1/upcoming-webinars/${limit}`)
      .then((res) => {
        return Array.isArray(res.data) ? res.data.slice(0, limit) : [];
      }),
  // depreciated but was old news
  getArticles: (limit = 3) =>
    axios
      .get(
        `https://www.emaxxgroup.com/wp-json/wp/v2/posts?_embed&per_page=${limit}`
      )
      .then((res) => (Array.isArray(res.data) ? res.data.slice(0, limit) : [])),
};

export default ApiService;
