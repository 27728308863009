import React, { useState, useEffect } from "react";

import { useParams, useHistory } from "react-router-dom";

import { toast } from "react-toastify";

import { Notification } from "rbx";

import Button from "../../../components/Button";
import Text from "../../../components/Text";
import ResetForm from "../../../components/Login/ResetForm";

import { AuthService } from "../../../services";

// setup password as well as reset password
const ResetPage = () => {
  const history = useHistory();
  const { token } = useParams();
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");
  const [message, setMessage] = useState({
    success: false,
    complete: false,
    text: "",
  });
  const [state, setState] = useState({
    password: "",
    isValid: false,
  });
  useEffect(() => {
    if (!type) {
      const result = history.location.pathname.includes("reset")
        ? "reset"
        : "set";
      setType(result);
    }
  }, [type, history.location.pathname]);
  const handleCancel = () => {
    history.push("/login");
  };
  const handleChange = (name, value) => {
    setState((prev) => ({ ...prev, [name]: value }));
  };
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      await AuthService.resetPassword({
        Password: state.password,
        Token: token,
      });
      toast.success(`Password ${type} successfully.`);
      setMessage({
        complete: true,
        success: true,
        text: `Your password has been ${type} successfuly.`,
      });
    } catch (err) {
      toast.error(`Error processing password ${type} request.`);
      setMessage({
        complete: true,
        success: false,
        text: `There was an error processing password ${type} request.`,
      });
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };
  const handleClick = () => {
    history.push("/login");
  };
  return (
    <div className="reset">
      <div className="title-holder">
        <Text.H1>
          {`${type.charAt(0).toUpperCase() + type.slice(1)} Password`}
        </Text.H1>
      </div>
      {message.complete ? (
        <div>
          <Notification color="success">{message.text}</Notification>
          <Button.Round onClick={handleClick}>Okay</Button.Round>
        </div>
      ) : (
        <ResetForm
          loading={loading}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          onChange={handleChange}
          inputs={state}
        />
      )}
    </div>
  );
};

ResetPage.propTypes = {};

export default ResetPage;
