import React from "react";
import PropTypes from "prop-types";
import { Select } from "rbx";

import STATES from "./states";

function StateSelect({ value, name, onChange, size, disabled, readOnly }) {
  return (
    <Select.Container size={size} fullwidth>
      <Select
        name={name}
        value={value}
        onChange={(e) => onChange(e.target.name, e.target.value)}
        disabled={disabled || readOnly}
        className="selectField"
      >
        <Select.Option value="">State</Select.Option>
        {Object.keys(STATES).map((key) => (
          <Select.Option key={key} value={key}>
            {key}
          </Select.Option>
        ))}
      </Select>
    </Select.Container>
  );
}

StateSelect.propTypes = {
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.string,
  name: PropTypes.string,
};

StateSelect.defaultProps = {
  disabled: false,
  readOnly: false,
  onChange: () => {},
  size: "",
  value: "",
  name: "State",
};
export default StateSelect;
