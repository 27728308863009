import React, { Component, useState } from "react";
import "./MultiStepBar.scss";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import Section from "../../../../components/Section";

const MultiStepBar = (props) => {
	var stepPercentage = 0;

	if (props.currentStep === 1) {
		stepPercentage = 0;
	} else if (props.currentStep === 2) {
		stepPercentage = 33.4;
	} else if (props.currentStep === 3) {
		stepPercentage = 66.7;
	} else if (props.currentStep === 4) {
		stepPercentage = 100;
	} else {
		stepPercentage = 0;
	}

	const stepStyle = {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: "50%",
		width: 20,
		height: 20,
		color: "white",
		backgroundColor: '#f47e20'
	}

	return (
		<>


			<ProgressBar percent={stepPercentage} filledBackground='#f47e20'>
				<Step>
					{({ accomplished, index }) => (
						<>
							<div
								className={`indexedStep ${accomplished ? "accomplished" : null}`}
							>
								{index + 1}
							</div>
						</>
					)}
				</Step>
				<Step>
					{({ accomplished, index }) => (
						<>
							<div
								className={`indexedStep ${accomplished ? "accomplished" : null}`}
							>
								{index + 1}

							</div>
							{/* <div>Personal Info</div> */}
						</>
					)}
				</Step>
				<Step>
					{({ accomplished, index }) => (
						<>
							<div
								className={`indexedStep ${accomplished ? "accomplished" : null}`}
							>
								{index + 1}
							</div>
						</>
					)}
				</Step>
				<Step>
					{({ accomplished, index }) => (
						<>
							<div
								className={`indexedStep ${accomplished ? "accomplished" : null}`}
							>
								{index + 1}
							</div>
						</>
					)}
				</Step>
			</ProgressBar>
		</>
	);
};

export default MultiStepBar;
