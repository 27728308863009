import React from "react";
import { useState } from "react";
import FinancialSummary from "./FinancialSummary";
import FinancialStatements from "./FinancialStatements";
import AssessmentAndDistribution from "./AssessmentAndDistribution";
import { useAuth } from "../../../context/AuthContext";

const FinancialProfitability = () => {
  const { state: authState, permissions } = useAuth();
  const [currentProgram, setCurrentProgram] = useState(authState.programRecId);

  const changeCurrentProgram = (programRecId) => {

    setCurrentProgram(programRecId);

  }

  return (
    <>
      {permissions.includes("002-004-001") && (
        <FinancialSummary passDownCurrentProg={currentProgram} changeCurrentProgram={changeCurrentProgram} />
      )}
      <FinancialStatements passDownCurrentProg={currentProgram} changeCurrentProgram={changeCurrentProgram} />

      <AssessmentAndDistribution passDownCurrentProg={currentProgram} changeCurrentProgram={changeCurrentProgram} />
    </>
  )
};

FinancialProfitability.propTypes = {};

export default FinancialProfitability;
