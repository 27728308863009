import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { Field, Label, Control, Input, Checkbox, Select } from "rbx";
import RoundButton from "../../../components/Button/Round";
import Text from "../../../components/Text";
import ColorDivider from "../../../components/Divider";
import Loading from "../../../components/Loading";
import { getRoleGroupData } from "../../../utils";
import { useAuth } from "../../../context/AuthContext";
import "./Users.scss";

import { StateSelect, TextFieldInput } from "../../../components/inputs";

const buildRoleOptions = (authState, assignedGroup) => {
  const roleGroups = [...assignedGroup.linkedRoleGroups];
  let result = [];
  if (
    authState.roleGroup.GroupName === "eMaxx" &&
    authState.roleGroup.RoleName === "Administrator"
  ) {
    result = roleGroups;
  } else if (assignedGroup.Code === "eMaxx") {
    result = roleGroups.filter(
      (roleGroup) => roleGroup.RoleName === "Employee"
    );
  } else if (authState.roleGroup.RoleName === "Administrator") {
    result = roleGroups.filter(
      (roleGroup) => roleGroup.RoleName !== "Administrator"
    );
  } else if (authState.roleGroup.RoleName === "Manager") {
    result = roleGroups.filter(
      (roleGroup) =>
        roleGroup.RoleName !== "Administrator" &&
        roleGroup.RoleName !== "Manager"
    );
  } else {
    result = roleGroups.filter(
      (roleGroup) => roleGroup.RoleName === "Employee"
    );
  }
  return result;
};

const UserForm = ({ user, onChange, onSubmit, onCancel, adding }) => {
  const { groupName } = useParams();
  const { state: authState } = useAuth();

  const [groups, setGroups] = useState();
  const [emaxxGroupId, setEmaxxGroupId] = useState();
  useEffect(() => {
    const fetchData = async () => {
      const groupData = await getRoleGroupData();
      setEmaxxGroupId(groupData.emaxxGroup.RecId);
      setGroups(groupData.groupsWithRoleGroups);
    };
    if (!groups) {
      fetchData();
    }
  }, [groups]);

  if (!groups) {
    return <Loading.SmallLoading />;
  }
  const assignedGroup = groupName
    ? groups.find((g) => g.Name.toLowerCase() === groupName)
    : groups.find((g) => g.RecId === emaxxGroupId);

  // console.log("assignedGroup", assignedGroup);

  const roleGroups = buildRoleOptions(authState, assignedGroup);

  const canSubmit =
    user.FirstName &&
    user.LastName &&
    user.Email &&
    user.Phone &&
    user.AddressLine1 &&
    user.City &&
    user.State &&
    user.ZipCode &&
    (user.roleGroupsRecId || user.roleGroup?.RecId);

  return (
    <div id="UserFormContainer">
      <Text.H3>User Info</Text.H3>
      <ColorDivider />
      <Field horizontal style={{ marginTop: "20px" }}>
        <TextFieldInput
          label="First Name"
          name="FirstName"
          value={user.FirstName}
          onChange={onChange}
        />
        <TextFieldInput
          label="Last Name"
          name="LastName"
          value={user.LastName}
          onChange={onChange}
        />
      </Field>

      <Field horizontal>
        <Field.Label size="normal">
          <Label>
            Email
            <span className="requiredStar"> *</span>
          </Label>
        </Field.Label>
        <Field.Body>
          <Field>
            <Control>
              <Input
                type="email"
                name="Email"
                value={user.Email}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
        </Field.Body>
        <Field.Label size="normal">
          <Label>
            Phone
            <span className="requiredStar"> *</span>
          </Label>
        </Field.Label>
        <Field.Body>
          <Field>
            <Control>
              <Input
                type="tel"
                name="Phone"
                value={user.Phone}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
        </Field.Body>
      </Field>
      <Field horizontal>
        <TextFieldInput
          label="Address 1"
          name="AddressLine1"
          value={user.AddressLine1}
          onChange={onChange}
        />
      </Field>
      <Field horizontal>
        <TextFieldInput
          label="City"
          name="City"
          value={user.City}
          onChange={onChange}
        />
        <Field.Label size="normal">
          <Label>
            State <span className="requiredStar"> *</span>
          </Label>
        </Field.Label>
        <Field.Body>
          <Field>
            <StateSelect
              label="State"
              name="State"
              value={user.State}
              onChange={onChange}
            />
          </Field>
        </Field.Body>
        <TextFieldInput
          label="Zip Code"
          name="ZipCode"
          value={user.ZipCode}
          onChange={onChange}
        />
      </Field>

      {!adding && (
        <Field horizontal>
          <Field.Label size="normal">
            <Label>IsActive</Label>
          </Field.Label>
          <Field.Body>
            <Field>
              <Control>
                <Checkbox
                  name="IsActive"
                  checked={!!user.IsActive}
                  onChange={(e) => onChange(e.target.name, e.target.checked)}
                />
              </Control>
            </Field>
          </Field.Body>
        </Field>
      )}
      <Text.H3>Role Group</Text.H3>
      <ColorDivider style={{ marginBottom: "50px" }} />
      <Field horizontal style={{ marginTop: "20px" }}>
        <Field.Label size="normal">
          <Label>
            Group <span className="requiredStar"> *</span>
          </Label>
        </Field.Label>
        <Field.Body>
          <Field>
            <Select.Container fullwidth>
              <Select className="selectField" disabled>
                <Select.Option value={assignedGroup.RecId}>
                  {assignedGroup.Name}
                </Select.Option>
              </Select>
            </Select.Container>
          </Field>
        </Field.Body>
        <Field.Label size="normal">
          <Label>
            Role <span className="requiredStar"> *</span>
          </Label>
        </Field.Label>
        <Field.Body>
          <Field>
            <Select.Container fullwidth>
              <Select
                className="selectField"
                value={user.roleGroupsRecId || user.roleGroup?.RecId}
                onChange={(e) =>
                  onChange("roleGroupsRecId", parseInt(e.target.value))
                }
              >
                <Select.Option value="">Select Role</Select.Option>
                {roleGroups.map((g) => (
                  <Select.Option key={g.RecId} value={g.RecId}>
                    {g.RoleName}
                  </Select.Option>
                ))}
              </Select>
            </Select.Container>
          </Field>
        </Field.Body>
      </Field>
      <Field>
        <Control className="buttonContainer">
          <RoundButton
            type="button"
            classNames={["secondary"]}
            onClick={onCancel}
          >
            Cancel
          </RoundButton>
          <RoundButton
            type="submit"
            id="submitButton"
            disabled={!canSubmit}
            className={
              canSubmit ? "roundButton primary" : "roundButton primary disabled"
            }
            onClick={() => onSubmit(user.roleGroupsRecId)}
          >
            Submit
          </RoundButton>
        </Control>
      </Field>
    </div>
  );
};

UserForm.propTypes = {
  user: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  adding: PropTypes.bool,
};
UserForm.defaultProps = {
  user: {},
  adding: false,
};

export default UserForm;
