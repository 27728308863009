import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faExclamation, faLaptop, faLaptopCode, faLaptopHouse, faPhone, faPhoneAlt, faPhoneSquareAlt } from "@fortawesome/free-solid-svg-icons";

import Section from "../../components/Section";
import Text from "../../components/Text";
import Button from "../../components/Button";
import { Link, useHistory } from "react-router-dom";


import "./ClaimsReport.scss";
import ExternalText from "../../components/Button/ExternalText";

const ClaimsReportPage = () => {
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  let history = useHistory();

  const goToFNOL = (target) => {
    history.push(`/report-claim/${target}`);
  }
  return (
    <div id="claimsReportContainer">
      {/* heading and button */}
      <div className="claimsHeader">
        <Text.H1>Claims Reporting</Text.H1>
        {/* <div className="buttonContainer">
          <Button.Round
            id="skinny"
            onClick={console.log("notifications clicked")}
          >
            Upload Dashcam Footage
          </Button.Round>
        </div> */}
      </div>
      {/* blue advisory section, same exclamation as other section */}

      <div className='reportClaimIcons'>
        <Section>
          <FontAwesomeIcon icon={faLaptop} size="4x" />
          <div className='iconButton'>
            {/* <a onClick={goToFNOL}>Submit Online</a> */}
            <Text.H3>Online</Text.H3>
            <Button.Round onClick={() => goToFNOL('online-claim')}>Submit</Button.Round>
          </div>
        </Section>
        <Section>
          <FontAwesomeIcon icon={faPhoneAlt} size="4x" />
          <div className='iconButton'>
            {/* <a>1.800.CLAIM.50</a> */}
            <Text.H3>Call</Text.H3>
            <Button.Round>1.888.CLAIM.50</Button.Round>
          </div>
        </Section>
        <Section>
          <FontAwesomeIcon icon={faEnvelope} size="4x" />
          <div className='iconButton'>
            {/* <a>1.800.CLAIM.50</a> */}
            <Text.H3>Email</Text.H3>
            <Button.Round onClick={() => goToFNOL('email-claim')}>Send</Button.Round>
          </div>
        </Section>

      </div>
      <Section id="blueAdvisory">
        {/* <FontAwesomeIcon icon={faExclamation} className="fileIcon" size="2x" /> */}

        <div className="sectionHeader">
          <span style={{ fontSize: 22, paddingTop: 4 }}><b>Members are required to report claims directly and immediately to eMaxx</b></span>
        </div>
        <div className="sectionHeader">
          <span style={{ fontSize: 22, paddingTop: 20 }}>Members are encouraged <b>to include their brokers when reporting claims</b> so that they are made aware of the incident; however, it is <b>crucial that eMaxx receives claims immediately</b> to avoid any delay in handling</span>
        </div>
        <div className="sectionHeader">
          <span style={{ fontSize: 22, paddingTop: 20 }}>Claims need to be reported for <b>all incidents regardless of perceived liability</b></span>
        </div>
        <div className="sectionHeader">
          <span style={{ fontSize: 22, paddingTop: 20 }}><b>High priority claims</b> should be reported immediately via phone</span>
        </div>
        {/* <div className="sectionHeader">
          Workers Compensation Claims
          <span style={{ fontSize: 22, paddingTop: 4 }}>&nbsp;need to be reported by phone or email.</span> 
        </div>*/}
      </Section>
      {/* Claim Steps */}

      {/* <Section>
        <div className="sectionHeader">
          <div className="orangeNumber">1.</div>
          <div className="boldTextBig">Call 1.800.CLAIM.50</div>
        </div>
        <div className="sectionContentWrapper">
          <div className="boldText">(1.888.252.4650)</div>
          <div className="mainText">
            Please call our 24 hour claims hotline to report any type of claim.
            high priority claims need to be reported via phone so they can be
            escalated to the proper person for handling.
          </div>
        </div>
      </Section>
      <Section>
        <div className="sectionHeader">
          <div className="orangeNumber">2.</div>
          <div className="boldTextBig">Complete Forms</div>
        </div>
        <div className="sectionContentWrapper">
          <div className="boldText">
            For claims that are reported to our officer via email or fax, please
            complete a standard Loss Notice.
          </div>
          <div className="mainText">
            Save the file to your computer. Once completed, Save and proceed to
            next step.
          </div>
          <div className="gridWrapper">
            <div className="left-column">
              <div className="boldText">Select Claim Type:</div>
              <div className="mainText">Auto Claims</div>
              <div className="mainText">General Liability Claims</div>
            </div>
            <div className="right-column">
              <div className="displayNone">Placeholder</div>
              <div className="mainText">Property Claims</div>
              <div className="mainText">Workers Compensation Claims</div>
            </div>
          </div>
        </div>
      </Section>
      <Section>
        <div className="sectionHeader">
          <div className="orangeNumber">3.</div>
          <div className="boldTextBig">Submit Forms</div>
        </div>
        <div className="sectionContentWrapper">
          <div className="gridWrapper">
            <div className="left-column">
              <div className="mainText">
                Submit completed documents by faxing them to:
              </div>
              <div className="boldText">978.531.4847</div>
            </div>
            <div className="right-column">
              <div className="mainText">Email completed documents to:</div>
              <div className="boldText">ClaimsReporting@emaxxgroup.com</div>
            </div>
          </div>
        </div>
      </Section> */}
      {/* Bottom Links */}

      {/* <div className="bottomLinksWrapper">
        <div className="section">
          <div className="flex-rowHolder">
            <Text.H3>Documentation to Gather</Text.H3>
            
            <Button.ExternalText onClick={() => setOpen1(!open1)}>
              {open1 ? "-" : "+"}
            </Button.ExternalText>
          </div>
          <div className={`collapsible ${open1 ? "open" : "closed"}`}>
            <p className="textSection">
              You may be asked to provide a copy of a police report, estimates,
              invoices, contracts, agreements, maintenance records, photos,
              measurements, personnel files or any other documentation needed to
              investigate the claim. *This information does NOT need to be
              gathered prior to submitting a claim.
            </p>
          </div>
        </div>
        <div className="section">
          <div className="flex-rowHolder">
            <Text.H3>
              Notice of Litigation or Correspondence From an Attorney
            </Text.H3>
            
            <Button.ExternalText onClick={() => setOpen2(!open2)}>
              {open2 ? "-" : "+"}
            </Button.ExternalText>
          </div>
          <div className={`collapsible ${open2 ? "open" : "closed"}`}>
            <p className="textSection">
              The first notice of a claim may come to you in the form of a
              letter from an attorney advising of an accident or incident that
              occurred from your business operations. If this occurs,
              immediately forward that correspondence to eMaxx.
            </p>
            <p className="textSection">
              If you are served with a Summons and Complaint, these documents
              need to be submitted to eMaxx immediately. Be sure to identify the
              date and method that the Summons was served upon you. In either of
              these instances, be sure to only discuss the facts of the claim
              with an eMaxx representative and/or the attorneys that we have
              retained to represent you.
            </p>
          </div>
        </div>
        <div className="section">
          <div className="flex-rowHolder">
            <Text.H3>Comments, Concerns, Questions</Text.H3>
            
            <Button.ExternalText onClick={() => setOpen3(!open3)}>
              {open3 ? "-" : "+"}
            </Button.ExternalText>
          </div>
          <div className={`collapsible ${open3 ? "open" : "closed"}`}>
            <p className="textSection">
              If you have any questions regarding these procedures, please
              contact the eMaxx claims team.
            </p>

            <p className="textSection">Email: ClaimsReporting@emaxxgroup.com</p>
            <p>Phone: 978.531.1822</p>
            <p>Fax: 978.531.4847</p>
          </div>
        </div>
      </div>*/}
    </div>
  );
};

ClaimsReportPage.propTypes = {};

export default ClaimsReportPage;
