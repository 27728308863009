/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React from "react";
import { Message } from "rbx";
import { format, parseISO } from "date-fns";

import "./ClaimsPanel.scss";

const ClaimsPanelTest = ({ data }) => {
  return (
    <>
      {data.map((note) => (
        <Message color="#c7ecff">
          <Message.Header>
            <p>
              {`${note.Subject} ${format(
                parseISO(note.NoteEffDate),
                "MM/dd/yyyy"
              )}`}
            </p>
          </Message.Header>
          <Message.Body>{note.Notes}</Message.Body>
        </Message>
      ))}
    </>
  );
};

ClaimsPanelTest.propTypes = {};

export default ClaimsPanelTest;
