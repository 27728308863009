import MainRoutes from "./Main";
import InsuranceRoutes from "./Insurance";
import RiskManagementRoutes from "./RiskManagement";
import ClaimsManagementRoutes from "./ClaimsManagement";
import CommunicationRoutes from "./Communication";

export default [
  MainRoutes,
  InsuranceRoutes,
  RiskManagementRoutes,
  ClaimsManagementRoutes,
  CommunicationRoutes,
];
