import "./Login.scss";
import LoginForm from "./LoginForm";
import ForgotForm from "./ForgotForm";
import ResetForm from "./ResetForm";

export default {
  Form: LoginForm,
  ForgotForm,
  ResetForm,
};
