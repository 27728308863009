import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import LPSPage from "./LossPreventionAndSafety";
import TelematicsPage from "./TelematicsExchange";
import ELivePage from "./ELive";
import MotorVehicleRecordsPage from "./MotorVehicleRecords";
import GetStarted from "./MotorVehicleRecords/MVRGetStarted";
import FMCSAPage from "./FMCSA";
import { useAuth } from "../../context/AuthContext";

const RiskManagementRouter = () => {
  const { permissions } = useAuth();
  return (
    <Switch>
      {permissions.includes("003-001") && (
        <Route path="/risk-management/loss-prevention-&amp;-safety">
          <LPSPage />
        </Route>
      )}
      {permissions.includes("003-002") && (
        <Route path="/risk-management/telematics-exchange">
          <TelematicsPage />
        </Route>
      )}
      {permissions.includes("003-003") && (
        <Route path="/risk-management/elive-connect">
          <ELivePage />
        </Route>
      )}
      {permissions.includes("003-004") && (
      <Route path="/risk-management/motor-vehicle-records/get-started">
        <GetStarted />
      </Route>
      )}
      {permissions.includes("003-004") && (
        <Route path="/risk-management/motor-vehicle-records">
          <MotorVehicleRecordsPage />
        </Route>
      )}
      {permissions.includes("003-005") && (
        <Route path="/risk-management/fmcsa">
          <FMCSAPage />
        </Route>
      )}
      <Redirect to="/" />
    </Switch>
  );
};

export default RiskManagementRouter;
