import APIService from "./index";

const CollateralService = {
  getCollaterals: (clientId) =>
    APIService.get("/getCollaterals", { clientId }).then(
      (res) => res.data.Payload
    ),
    getFIFAccountInfoByClientId: (clientId) =>
    APIService.get("/GetFIFAccountInfoByClientId", { clientId }).then(
      (res) => res.data.Payload
    ),
};

// .then((res) =>
//       res.data.Payload.reduce(
//         (acc, curr) => [...acc, ...curr.Collaterals],
//         []
//       )
//     ),

// .filter((collateral) => new Date(collateral.ExpDate) > new Date())
export default CollateralService;
