/* eslint-disable no-underscore-dangle */
import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";

import Text from "../../../components/Text";
import Section from "../../../components/Section";
import ImageItem from "../../../components/Image-Item";
import Loading from "../../../components/Loading";
import { useAuth } from "../../../context/AuthContext";
import "./NewsDashboard.scss";

import { WordPressService } from "../../../services";

const ArticleSection = ({ article }) => {
  // console.log("SINGLE ARTICLE", article);
  // do they want a default image here for articles
  return (
    <ImageItem
      image={article._embedded["wp:featuredmedia"] ? article._embedded["wp:featuredmedia"][0].source_url : null}
      topText={article.title.rendered?.includes('&#038;') ? article.title.rendered.replace('&#038;', '&') : article.title.rendered}
      bottomText="Read More"
      href={article.link}
    />
  );
};
ArticleSection.propTypes = {
  article: PropTypes.object.isRequired,
};

const NewsDashboard = () => {
  const { data: articles, isLoading: articlesLoading } = useQuery(
    "articles",
    () => WordPressService.getNews(3)
  );
  const { permissions } = useAuth();

  if (articlesLoading) {
    return (
      <>
        <Text.H1>News</Text.H1>
        <Loading.SmallLoading />
      </>
    );
  }
  return (
    <div id="NewsDashContainer">
      {/* Featured Posts */}
      {permissions.includes("001-004") && (
        <div className="FeaturedSection">
          <Text.H1>News</Text.H1>
          <Section>
            {articles?.length
              ? articles.map((article) => (
                <ArticleSection key={article.id} article={article} />
              ))
              : "No News Articles Found."}
          </Section>
        </div>
      )}
    </div>
  );
};

export default NewsDashboard;
