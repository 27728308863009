import React from "react";
import "./SmallLoading.scss";
import OrangeXLight from "../../assets/images/orange-x-light.png";

const SmallLoading = (props) => {
  return (
    <div className="smallLoadingContainer">
      <div className="smallLoading">
        <img src={OrangeXLight} alt="orange-x-light" />
      </div>
    </div>
  );
};

SmallLoading.propTypes = {};

export default SmallLoading;
