import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { format, parseISO } from "date-fns";
import Section from "../../../components/Section";
import Text from "../../../components/Text";
import Button from "../../../components/Button";
import DataTable from "../../../components/DataTable";
import Loading from "../../../components/Loading";
import TwoColumnData from "../../../components/TwoColumnData";
import COLUMNS from "./columns";
import { Select } from "rbx";

import ProgramService from "../../../services/ProgramService";
import ClientService from "../../../services/ClientService";
import CollateralService from "../../../services/CollateralService";
import { useAuth } from "../../../context/AuthContext";
// import {data} from "./PremiumPaymentStaticData";
import PolicyService from "../../../services/PolicyService";

import "./PaymentCollateral.scss";
import CollateralStatements from "./CollateralStatements";
import { groupBy } from "lodash";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

const PaymentCollateralPage = (props) => {
  const { state: authState, permissions } = useAuth();
  const [loadingBool, setLoadingBool] = useState(false);
  const [fifAccountState, setfifAccountState] = useState("");
  const [currentProgram, setCurrentProgram] = useState(authState.programRecId);



  const { data: clientsHistory = [] } = useQuery("clientsHistory", () =>
    ClientService.getClientsChangeHistory()
  );
  const { data: programs = [] } = useQuery("programs", () =>
    ProgramService.getPrograms()
  );

  const {
    data: collaterals = [],
    loading: isLoading,
    error,
  } = useQuery("collaterals", () =>
    CollateralService.getCollaterals(authState.clientId),
    { cacheTime: 2000 }
  );

  const {
    data: fifAccount = [],
    loading: isLoadingFIF,
    refetch,
    error: errorFIF,
  } = useQuery(
    "FIFAccountInfoByClientId",
    () => CollateralService.getFIFAccountInfoByClientId(authState.clientId),
    { enabled: false, cacheTime: 2000 }
  );

  //mix pc8,9(policy program) to regular program list by getting from the polciies
  const { data: Policies = [] } = useQuery("policies", () =>
    PolicyService.getPoliciesByClientNoFilter(authState.clientId)
  );






  useEffect(() => {
    if (collaterals.length < 1) {
      setLoadingBool(true);
      refetch().then(() => {
        setLoadingBool(false);
      });
    }
  }, [collaterals, refetch]);




  //Li program changed
  let ProgramLst = [];
  ProgramLst.push(programs.find(f => f.RecId === authState.programRecId));//insert the current

  let clientProHis = clientsHistory.filter(f => f.ProgramsRecId && f.ProgramsRecId !== authState.programRecId && f.ClientId === authState.clientId);

  if (clientProHis.length > 0 && programs.length > 0) {

    let grouping = clientProHis;//clientsHistory.filter(f => f.ProgramRecId !== authState.programRecId && f.ClientId === authState.clientId);
    let grouped = groupBy(grouping, 'ProgramsRecId');

    Object.keys(grouped).forEach(key => ProgramLst.push(programs.find(f => f.RecId.toString() === key.toString())));
  }

  // add policy program to the drop down
  if (programs.length > 0 && Policies.length > 0) {

    let groupedPolicyPrograms = groupBy(Policies, 'CategoryCode')

    Object.keys(groupedPolicyPrograms).forEach(key => {
      var existing = ProgramLst.find(f => f?.Code === key)
      if (!existing) {

        let prog = programs.find(f => f.Code === key);

        ProgramLst.push(prog);
      }
    });
  }
  // add policy program to the drop down

  const changeCurrentProgram = e => {
    e.persist();

    const { value } = e.target;
    setCurrentProgram(Number(value));
  }
  //Li program changed


  let data = [];
  if (!isLoadingFIF && fifAccount) {
    data = [
      {
        title: "LeftColumn",
        items: [
          {
            left: "Total Premium:",
            right:
              fifAccount.totalPremiumField ||
                fifAccount.accountBalanceField === 0
                ? formatter.format(fifAccount.totalPremiumField)
                : "--",
            leftClass: "",
            rightClass: "",
          },
          {
            left: "Down Payment:",
            right:
              fifAccount.downPaymentField ||
                fifAccount.accountBalanceField === 0
                ? formatter.format(fifAccount.downPaymentField)
                : "--",
            leftClass: "",
            rightClass: "",
          },
          {
            left: "Amount Financed:",
            right:
              fifAccount.amountFinancedField ||
                fifAccount.accountBalanceField === 0
                ? formatter.format(fifAccount.amountFinancedField)
                : "--",
            leftClass: "",
            rightClass: "",
          },
          {
            left: "Non-Refundable Fee:",
            right:
              fifAccount.nonRefundableFeeField ||
                fifAccount.accountBalanceField === 0
                ? formatter.format(fifAccount.nonRefundableFeeField)
                : "--",
            leftClass: "",
            rightClass: "",
          },
          {
            left: "Finance Charge:",
            right:
              fifAccount.financeChargeField ||
                fifAccount.accountBalanceField === 0
                ? formatter.format(fifAccount.financeChargeField)
                : "--",
            leftClass: "",
            rightClass: "",
          },
          {
            left: "No. of Installments:",
            right: fifAccount.numberOfInstallmentsField,
            leftClass: "",
            rightClass: "",
          },
        ],
      },
      {
        title: "MiddleColumnBottom",
        items: [
          {
            left: "APR:",
            right: `${fifAccount.aPRField}%`,
            leftClass: "",
            rightClass: "",
          },
          {
            left: "Installments Made / Remaining:",
            right: `${fifAccount.installmentsMadeField}/${fifAccount.installMentsRemainingField}`,
            leftClass: "",
            rightClass: "",
          },
          {
            left: "Account Balance (inc. fees)",
            right:
              fifAccount.accountBalanceField ||
                fifAccount.accountBalanceField === 0
                ? formatter.format(fifAccount.accountBalanceField)
                : "--",
            leftClass: "boldText",
            rightClass: "boldText",
          },
        ],
      },
    ];
  }

  if (loadingBool) {
    return (
      <div className="headerContainer">
        <Text.H1>Summary</Text.H1>
        <Loading.SmallLoading />
      </div>
    );
  }
  if (error || errorFIF) {
    return null;
  }

  let sortedCollaterals = [];
  if (collaterals.length > 0) {
    sortedCollaterals = collaterals.sort((a, b) => format(parseISO(a.EffDate), "yyyy") - format(parseISO(b.EffDate), "yyyy"));


    //mix policy program into program dropdown
    /* remove it since Megan want to mix policy program into program dropdown

    //TODO: need to improve considering ECAP8 col policies could appear in both current and history programs, below using time to work around, but could have better way
    if (clientProHis.length > 0) {

      //this will need to be for the current Program and also currenProgram was a history
      let maxHisEndDate = clientProHis.sort((a, b) => { return new Date(b.EndDate) - new Date(a.EndDate) })[0]?.EndDate;

      let tempCol = [];
      clientProHis.filter(f => f.ProgramsRecId === currentProgram).forEach((his) => {
        tempCol = tempCol.concat(sortedCollaterals.filter(f => f.EffDate >= his.StartDate && f.EffDate <= his.EndDate && f.ExpDate <= his.EndDate))
      });

      if (currentProgram === authState.programRecId) {//add to the list for currentProgram's Collateral
        tempCol = tempCol.concat(sortedCollaterals.filter(f => f.ExpDate > maxHisEndDate));
      }
      sortedCollaterals = tempCol;//tempCol.filter(f => f.PolicyNumber.includes(`ECAP${currentProgram}COL`));
    }*/





    //08-15-2023 added program column to show PC1-PC8 or PCx-PC8
    sortedCollaterals.map(

      item => {
        if (item.PolicyNumber.toString().toLowerCase().includes('col')) {
          const categoryCd = 'PC' + item.PolicyNumber.toString().toLowerCase().split('col')[0].match(/\d/g);
          item.CategoryCode = categoryCd + '-IC';
          item.ProgramCd = Number(item.PolicyNumber.toString().toLowerCase().split('col')[0].match(/\d/g));

        }
        else {
          item.CategoryCode = null;
          item.ProgramCd = null;
        }
      }
    )
    //mix policy program into program dropdown
    sortedCollaterals = sortedCollaterals.filter(f => f.ProgramCd === currentProgram);



  }
  return (
    <>
      <div id="PaymentAndCollateralContainer">
        {/* Payment Summary */}
        <div className="summaryHeader">
          {permissions.includes("002-005-002") && <Text.H1>Summary</Text.H1>}
          {permissions.includes("002-005-001") && (
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.firstinsurancefunding.com"
            >
              <Button.Round>FIRST Insurance Funding</Button.Round>
            </a>
          )}
        </div>
        {permissions.includes("002-005-002") &&
          (fifAccount?.name1Field ? (
            <div className="summaryReport">
              <Section>
                <Text.H3>{`Account #: ${fifAccount?.accountNumberField}`}</Text.H3>
                <div className="three-column-grid">
                  <div className="left-column">
                    <TwoColumnData data={data[0]} />
                  </div>
                  <div className="middle-column">
                    <TwoColumnData data={data[1]} />
                  </div>
                </div>
              </Section>
            </div>
          ) : (
            <Section>No Summary Found.</Section>
          ))}
        {/* Collateral */}
        {permissions.includes("002-005-003") && (
          <>
            <Text.H1>Collateral</Text.H1>
            <div style={{ paddingBottom: '0.8rem' }}>
              <Select.Container size="medium">
                {/* <Select value={currentProgram} onChange={programChange}> */}
                <Select onChange={changeCurrentProgram}>
                  {ProgramLst
                    //.sort((a, b) => b - a)
                    .map(p => (
                      <Select.Option key={p?.RecId} value={p?.RecId}>{p?.Code}</Select.Option>
                    ))}
                </Select>
              </Select.Container>
            </div>
            {sortedCollaterals.length ? (
              <div className="collateralTable">
                <DataTable
                  columns={COLUMNS}
                  data={sortedCollaterals}
                  sortBy={[
                    {
                      id: "LOB",
                      desc: false,
                    },
                  ]}
                />
              </div>
            ) : (
              <Section>No Collateral Found.</Section>
            )}
          </>
        )}
      </div>
      <CollateralStatements passDownCurrentProg={currentProgram} />
    </>
  );
};


export default PaymentCollateralPage;
