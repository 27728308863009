export default [
  {
    Header: "Company Name",
    accessor: "ClientName",
    id: "ClientName",
  },
  {
    Header: "Program",
    accessor: "Program",
    id: "Program",
  },
  {
    Header: "Type",
    accessor: "TypeName",
    id: "TypeName",
  },
  {
    Header: "State",
    accessor: "Address.State",
    id: "Address.State",
  },
  {
    Header: "Address",
    accessor: "Address.Line1",
    id: "Address.Line1",
  },
  {
    Header: "City",
    accessor: "Address.City",
    id: "Address.City",
  },
];
