import APIService from "./index";

const ProgramService = {
  getPrograms: () =>
    APIService.get(`/GetPrograms`).then(
      (res) => res.data.Payload
    ),
};

export default ProgramService;
