import React from "react";
import ReactDOM from "react-dom";
//import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import "./index.scss";
// import { QueryCache, ReactQueryCacheProvider } from "react-query";
import {
  ReactQueryConfigProvider,
  QueryCache,
  ReactQueryCacheProvider,
} from "react-query";
import { ToastContainer } from "react-toastify";
import { ReactQueryDevtools } from "react-query-devtools";
import "react-toastify/dist/ReactToastify.css";
import "rbx/index.css";
import Pages from "./pages";
import LandingPage from "./pages/Landing";

import "./icons";

import { AuthProvider, useAuth } from "./context/AuthContext";


// Google Analytics
// const TRACKING_ID = "UA-78066491-11";
// ReactGA.initialize(TRACKING_ID, { standardImplementation: true });
// ReactGA.pageview(window.location.pathname + window.location.search);

// history.listen((location) => {
//   ReactGA.set({ page: location.pathname });
//   ReactGA.pageview(location.pathname);
// });

/*
// Google Analytics
const TRACKING_ID = "UA-125344680-2";
let host = window.location.hostname.toLocaleLowerCase();
if (host === "portal.emaxxgroup.com") {//Li added for local dev environment
  //uncomment for Prod push
  ReactGA.initialize(TRACKING_ID, { standardImplementation: true });
  ReactGA.pageview(window.location.pathname + window.location.search);
}*/
//Google Analytics 4
let host = window.location.hostname.toLocaleLowerCase();
if (host === "portal.emaxxgroup.com") {//Li added for local dev environment
  //uncomment for Prod push
  //ReactGA4.initialize([{ trackingId: "G-LFFXEZ1G9W" }]);
  //ReactGA4.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
}




function IsLoggedIn() {
  const { state } = useAuth();
  return state.isLoggedIn ? <Pages /> : <LandingPage />;
}
// npm package for react query for react Query 3 is in alpha, at this point use config and cache provider together instead of combined client to be released
// set the policy for no cache and low cache time to prevent issues wich data between different admin-selected clients
// NO CACHE made the cacheTime 0
//const queryCache = new QueryCache(); Li commented out for setting the refetchOnWindowFocus to false below. observing the performance to see if need to set back 6/17/2021
const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      refetchOnWindowFocus: false,
      //cacheTime: 0//works here 09/29/2022 Li. however without cacheTime set up here globally, it causes the problem in mobile devices screen frashing issue and no data rendered issue, neede to remove it 10/14/2022
    },
  },
})

const queryConfig = {
  cacheTime: 0,//// not working here, the setup 09/29/2022 Li
  fetchPolicy: "no-cache"
};

// const App = () => (
//   <Router>
//     <ToastContainer />
//     <AuthProvider>
//       <ReactQueryCacheProvider queryCache={queryCache}>
//         <div className="mainContentContainer">
//           <ReactQueryDevtools initialIsOpen />
//           <IsLoggedIn />
//         </div>
//       </ReactQueryCacheProvider>
//     </AuthProvider>
//   </Router>
// );

const App = () => (
  <Router>
    <ToastContainer />
    <AuthProvider>
      <ReactQueryConfigProvider config={queryConfig}>
        <ReactQueryCacheProvider queryCache={queryCache}>
          <div className="mainContentContainer">
            <ReactQueryDevtools initialIsOpen />
            <IsLoggedIn />
          </div>
        </ReactQueryCacheProvider>
      </ReactQueryConfigProvider>
    </AuthProvider>
  </Router>
);

ReactDOM.render(<App />, document.getElementById("root"));
