import React, { useEffect, useMemo, useState } from "react";
import { Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "react-query";
import { toast } from "react-toastify";

import TelematicsService from "../../../services/TelematicsService";
import { useAuth } from "../../../context/AuthContext";
import Section from "../../../components/Section";
import Text from "../../../components/Text";
import { DocumentService } from "../../../services/index";
import ClientService from "../../../services/ClientService";
import Button from "../../../components/Button";
import { Select } from "rbx";

import "./Telematics.scss";


const TelematicsPage = () => {
  const { state: authState, permissions } = useAuth();
  const [currentClients, setcurrentClients] = useState([]);
  const [finalRanks, setfinalRanks] = useState([]);
  const [i, setI] = useState(0);
  let filteredRanks = [];
  let sortedRanks = [];
  let supText = "";
  let currentPlace = 0;


  // fetch clients
  const {
    data: clients = [],
    isLoadingClients,
  } = useQuery("clientsByProgram", () =>
    ClientService.getClientsByProgram(authState.programRecId),
    //{ fetchPolicy: "no-cache", cacheTime: 0 }
    { cacheTime: 2000 }
  );

  const { data: documents = [] } = useQuery("documentsByClient", () =>
    DocumentService.getDocumentsByClient(authState.clientId),
    //{ fetchPolicy: "no-cache", cacheTime: 0 }
    { cacheTime: 2000 }
  );
  //additonal fields 04-2024
  const {
    data: powerUnit = {},
    isLoadingPowerUnit,
  } = useQuery("powerUnitByClient", () =>
    ClientService.getClientPolicyPowerUnit(authState.clientId),
    //{ fetchPolicy: "no-cache", cacheTime: 0 }
    { cacheTime: 2000 }
  );

  const {
    data: teVehicleCount = {},
    isTeVehicleCount,
    refetch: refetchTEVehicleCount
  } = useQuery("teVehicleCountByClient", () =>
    ClientService.getClientTEVehicleCount(authState.clientId, Number(ranks.year + '' + ranks.month)),//202304),//Number(ranks.year + '' + ranks.month)),
    //{ fetchPolicy: "no-cache", cacheTime: 0 }
    { cacheTime: 2000, enabled: false }
  );

  const {
    data: teStatus = {},
    isLoadingTEStatus,
  } = useQuery("teStatusByClient", () =>
    ClientService.getClientTEStatus(authState.clientId),
    //{ fetchPolicy: "no-cache", cacheTime: 0 }
    { cacheTime: 2000 }
  );

  const {
    data: clientTSPs = [],
    isLoadingClientTSPs,
  } = useQuery("TSPsByClient", () =>
    ClientService.getClientTSPs(authState.clientId),
    //{ fetchPolicy: "no-cache", cacheTime: 0 }
    { cacheTime: 2000 }
  );



  //additonal fields 04-2024





  // make clients their Ids
  //const clientIdList = clients.map((client) => { return client.ClientId }); //Li addade the clientType filter to not send propsects under same program for speeding data
  const clientIdList = clients.filter(f => f.ClientType !== 'Prospect').map((client) => { return client.ClientId });


  // returns a list of all program members and the current month speeding percents in an object with their clientId
  // changed to grab most recent month where the data is available
  const {
    data: ranks = [],
    isLoadingRanks,
    errorRanks,
    refetch: refetchSpeeding,
  } = useQuery("speedingMonthlySummaries", () =>
    TelematicsService.getSpeedingMonthlySummariesData(currentClients),
    //{ enabled: false, fetchPolicy: "no-cache", cacheTime: 0 }
    { enabled: false, cacheTime: 2000 }
  );
  // updates the finalranks with next 50 or less
  useMemo(() => {
    if (ranks.returnArr?.length > 0) {
      // setfinalRanks(...ranks.returnArr);
      setfinalRanks(prev => ([...prev, ...ranks.returnArr]));

      //additional fields
      refetchTEVehicleCount();
    }
  }, [ranks.returnArr]);
  // adds 50 clients and calls query
  if (clientIdList.length > i) {
    //Li increased the i to add all cients in one call, because the current bug that does not fire the calls for next sets of 50 clients when refetching in useMemo
    //temp fix
    //const tempClients = clientIdList.slice(i, i + 50);
    const tempClients = clientIdList.slice(i, clientIdList.length);
    const otherClients = tempClients.join('&clientIds=');
    setcurrentClients(otherClients);
    setI(i + clientIdList.length);
    //setI(i + 50);
  }

  useMemo(() => {
    // this was triggering 3 times for some reason added if statment to prevent that
    if (currentClients.length > 0) {
      refetchSpeeding();
    }
  }, [currentClients, refetchSpeeding])


  // DOCUMENT DOWNLOAD
  const [trueFileName, setTrueFileName] = useState("");
  const [trueFileNameLst, setTrueFileNameLst] = useState([]); //Li a list of TE driver score card
  const [currentYearMonth, setCurrentYearMonth] = useState("");//Li a list of TE driver score card

  useEffect(() => {
    if (documents.length >= 1) {
      documents.sort((a, b) => new Date(b.CreatedDateTime) - new Date(a.CreatedDateTime)).forEach((document) => {
        if (
          document.DocumentId ===
          "Telematics Exchange_Driver Score Cards_Month-to-Date Driver Score Card"// is actually related to Code a bit confusing
        ) {

          //Li test a list of TE driver score card
          let fileNameT = document.FilePath.slice(document.FilePath.lastIndexOf("/")).replace(/\//i, "");
          let indivFile = {
            yearMonth: document.Year + '/' + (document.Month < 10 ? `0${document.Month}` : document.Month),
            fileName: fileNameT,
            createdDateTime: document.CreatedDateTime
          }
          if (!trueFileNameLst.find(f => f.yearMonth === indivFile.yearMonth)) {
            //setTrueFileNameLst(preArr => [...preArr, indivFile]);
          }
          setTrueFileNameLst(preArr =>

            !preArr.find(f => f.yearMonth === indivFile.yearMonth) ? [...preArr, indivFile] : [...preArr]
          );
          //Li test a list of TE driver score card



          /*original 
            const fileName = document.FilePath;
            setTrueFileName(
            fileName.slice(fileName.lastIndexOf("/")).replace(/\//i, "")
            );*/
        }
      });
    }
  }, [documents]);

  const { data: File = [], refetch } = useQuery(
    "documentDownload",
    () =>
      DocumentService.DocumentDownload(
        trueFileName,
        `${authState.clientName} Month-to-Date Driver Score Card.pdf`
      ),
    { enabled: false }
  );

  const handleGetFile = (e) => {
    if (trueFileName.length > 2) {
      e.preventDefault();
      refetch();
    } else {
      toast.error("No Document Found");
    }
  };
  // DOCUMENT DOWNLOAD

  const formChange = (e, file) => {//Li multiple month driver score card download
    e.persist();
    // prevents below function from changing year once the user has interacted with the select
    //setToggleReached(true);
    const { value } = e.target;
    setCurrentYearMonth(value);
    let fileNameSelected = trueFileNameLst.find(f => f.yearMonth === value);
    setTrueFileName(fileNameSelected.fileName);
  };


  if (finalRanks.length > 0) {
    // debugger;
    filteredRanks = finalRanks.filter((rank) =>
      Object.keys(rank).length !== 0
    );

    sortedRanks = filteredRanks.sort((a, b) =>
      Object.values(a)[0] > Object.values(b)[0] ? 1 : -1
    );


    currentPlace = sortedRanks.findIndex(
      (x) => Object.keys(x)[0] === authState.clientId.toString()
    ) + 1;


    switch (currentPlace % 10) {
      case 1:
        supText = "st";
        break;
      case 2:
        supText = "nd";
        break;
      case 3:
        supText = "rd";
        break;
      default:
        supText = "th";
        break;
    }
  }


  if (!finalRanks.length > 0 || currentPlace === 0) {
    return (
      <div className="headerContainer" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <Text.H2>
          <p>No Data Found for:</p>
          <span style={{ color: "#1871ad" }}>{authState.clientName}</span>
        </Text.H2>
        {permissions.includes("003-002-002") && (
          <a
            href="https://www.telematicexchange.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button.Round>Telematics Exchange</Button.Round>
          </a>
        )}
      </div>
    );
  }


  if (errorRanks) {
    return null;
  }

  return (
    <div id="TelematicsContainer">
      {/* heading */}
      <div className="TelematicsHeader">
        <div>
          {permissions.includes("003-002-001") && (
            <>
              <Text.H1>Total Speed Ranking</Text.H1>
              <Text.H3>{`${ranks.month}/${ranks.year}`}</Text.H3>
            </>
          )}

          {permissions.includes("003-002-001") && (
            <div>
              <Section>

                <div className="BigPrimary">
                  {/* <p> */}
                  <Text.H3>
                    {currentPlace + ' '}
                    {/* <sup>{supText}</sup> */}
                    of
                    {' '}
                    {sortedRanks.length}
                    {' '}
                    Members
                  </Text.H3>
                  {/* ?</p> */}
                </div>
              </Section>
            </div>
          )}
        </div>
        {permissions.includes("003-002-002") && (
          <a
            href="https://www.telematicexchange.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button.Round>Telematics Exchange</Button.Round>
          </a>
        )}
      </div>
      {/* Speeding Trending Reports */}
      {permissions.includes("003-002-003") && (
        <>
          <div className="DriverScore" style={{ paddingTop: 15 }}>
            <div style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "baseline"
            }}>
              <Text.H1>Driver Scorecards</Text.H1>
              <Icon
                tooltip="The most recent twelve months of Driver Score Cards are saved on this portal.  If you choose to keep longer, you can download and save locally."
                tooltipMultiline
                tooltipPosition="right"
                id="iconTooltip"
              >
                <FontAwesomeIcon icon={faQuestionCircle} />
              </Icon>
            </div>
            <Select.Container size="medium" style={{ marginBottom: 10 }}>
              <Select value={currentYearMonth} onChange={(e) => formChange(e)}>
                {trueFileNameLst
                  .sort((a, b) => {
                    let aa = a.yearMonth.split('/')[0] + a.yearMonth.split('/')[1];
                    let bb = b.yearMonth.split('/')[0] + b.yearMonth.split('/')[1];
                    return bb - aa
                  })
                  .slice(0, 12)//only display most recent 12 months
                  .map((file, index) => {
                    if (index == 0 && file && !trueFileName) { setTrueFileName(file.fileName); setCurrentYearMonth(file.yearMonth); }//Li set first option of the dropdown to be default download
                    return (<Select.Option key={file.fileName} value={file.yearMonth}>{`${file.yearMonth.split('/')[1]}/${file.yearMonth.split('/')[0]}`}</Select.Option>);
                  })}
              </Select>
            </Select.Container>
            <Section>
              <Text.H3> {currentYearMonth ? (`${currentYearMonth.split('/')[1]}/${currentYearMonth.split('/')[0]}`) : ''} -- Driver Scorecard</Text.H3>
              <Button.ExternalText onClick={(e) => handleGetFile(e)}>
                <FontAwesomeIcon icon={faDownload} size="2x" />
              </Button.ExternalText>
            </Section>
          </div>
        </>
      )}
      {/* Driver Score Cards */}

      {/* TE other info */}
      {permissions.includes("003-002-004") && (
        <>
          <div className="TEDetail2Colum">
            <div>
              <Text.H1>Telematics Service Provider</Text.H1>
              <Section>
                {clientTSPs?.length > 0 && clientTSPs.map((item, i) => <Text.H3><span style={{ marginRight: 5 }}>{item?.tsp.trim() + (i == (clientTSPs.length - 1) ? '' : ', ')}</span></Text.H3>)}



              </Section>


            </div>

            <div>
              <Text.H1>Telematics Exchange Status</Text.H1>
              <Section>
                <Text.H3>
                  {teStatus?.statusAtUI}
                </Text.H3>
              </Section>
            </div>
          </div>

          <div className="TEDetail2Colum">
            <div>
              <Text.H1>Telematics Vehicle Count</Text.H1>
              <Section>
                <Text.H3>
                  {teVehicleCount?.vehicleCount}
                </Text.H3>
              </Section>


            </div>

            <div>
              <Text.H1>Policy Power Unit Count</Text.H1>
              <Section>
                <Text.H3>{powerUnit?.dcPowerUnit}</Text.H3>
              </Section>


            </div>
          </div>



        </>



      )}

    </div >
  );
};


export default TelematicsPage;
