import React, { Component, useState, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { Prompt } from 'react-router-dom';

import Section from "../../../../components/Section";
import Text from "../../../../components/Text";
import Button from "../../../../components/Button";
import RoundButton from "../../../../components/Button/Round";

import ClaimType from "./ClaimType";
import PersonalInfo from "./PersonalInfo";
import LossInfo from "./LossInfo";
import UploadClaimDocs from "./UploadClaimDocs";
import MultiStepBar from "./MultiStepBar";
import './OnlineClaims.scss'
import { useAuth } from "../../../../context/AuthContext";
import Loading from "../../../../components/Loading";
import { DocumentService } from "../../../../services/index";
import PolicyService from "../../../../services/PolicyService";
import ClaimsService from "../../../../services/ClaimsService";
import lodash from "lodash";
import { toast } from "react-toastify";
import ConfirmationPage from "../Confirmation";

const CreateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

const OnlineClaimsPage = () => {

  const currentClient = useAuth();
  const [loading, setLoading] = useState(false);
  const [canLeave, setCanLeave] = useState(true);
  const [confirmation, setConfirmation] = useState({
    confirming: '',
    confirmationNum: ''
  });

  const [currentState, setCurrentState] = useState({
    currentStep: 1,
    //personalInfoDetail: { company: "", email: "", policyNumber: "", phone: "", street: "", city: "", state: "", zip: "" },
    personalInfoDetail: {
      //check if login clientId is same as clientId to determin if pre populate the email and phone and correct address(not eMaxx admin or Broker address)
      company: currentClient.state.clientName,
      email: currentClient.state.clientId === currentClient.state.loginClientId ? currentClient.state.userEmail : "",
      policyNumber: "",
      phone: currentClient.state.clientId === currentClient.state.loginClientId ? currentClient.state.userPhone : "",
      street: currentClient.state.clientId === currentClient.state.loginClientId ? currentClient.state.loggedInClientAddress : currentClient.state.clientAddress,
      city: currentClient.state.clientId === currentClient.state.loginClientId ? currentClient.state.loggedInClientCity : currentClient.state.clientCity,
      state: currentClient.state.clientId === currentClient.state.loginClientId ? currentClient.state.loggedInClientState : currentClient.state.clientState,
      zip: currentClient.state.clientId === currentClient.state.loginClientId ? currentClient.state.loggedInClientZipCode : currentClient.state.clientZipCode,
      confirmed: "",
      policyList: []
    },
    claimTypeDetail: {
      claimType: ""
    },
    lossInfoDetail: {
      additionalInfo: {},
      otherVehicles: [],
      additionalInfo_WC: {}
    },
    upload: []
  });

  const {
    data: policies = [],

  } = useQuery(
    "policiesByClientNoFilter",
    () => PolicyService.getPoliciesByClientNoFilter(currentClient.state.clientId),
    // { fetchPolicy: "no-cache", cacheTime: 0 } carefully use the no cache setup, in the mobile browser, dropdown will not render
  );

  const { data: controls = [], isLoading, error } = useQuery(
    "FNOLControls",
    () => ClaimsService.getFNOLControls()
  );
  const { data: controlDisplayValues = [] } = useQuery(
    "FNOLControlDisplayValues",
    () => ClaimsService.getFNOLControlDisplayValues()
  );

  const { data: controlDependencies = [] } = useQuery(
    "FNOLControlDependencies",
    () => ClaimsService.getFNOLControlDependencies()
  );


  //autoPolicies.sort((a, b) => { return new Date(a.EffectiveDate) - new Date(b.EffectiveDate) })[0]?.PolicyNumber;
  const fnolControls = controls;
  let grouped = lodash.groupBy(controls, g => g.ParentControlsRecId);
  let LossInfoControls = controls.filter((f) => f.ParentControlsRecId == 27 && f.ControlTypeCode !== 'page' && f.ControlTypeCode !== 'section' && f.IsActive)
    .sort((a, b) => { return a.ControlOrder - b.ControlOrder });
  let AdditionalInfoControls = controls.filter((f) => f.ParentControlsRecId == 9 && f.ControlTypeCode !== 'page' && f.ControlTypeCode !== 'section' && f.IsActive)
    .sort((a, b) => { return a.ControlOrder - b.ControlOrder });
  let OtherVehiclesControls = controls.filter((f) => f.ParentControlsRecId == 19 && f.ControlTypeCode !== 'page' && f.ControlTypeCode !== 'section' && f.IsActive)
    .sort((a, b) => { return a.ControlOrder - b.ControlOrder });

  //additional_WC controls including section
  //section
  let addtional_WC_sections = controls.filter((f) => f.ParentControlsRecId == 34 && f.ControlTypeCode !== 'page' && f.IsActive);

  let AdditionalInfo_WCControls = []
  //child controls
  addtional_WC_sections.map(
    section => {
      AdditionalInfo_WCControls = AdditionalInfo_WCControls.concat(controls.filter((f) => f.ParentControlsRecId == section.RecId && f.IsActive)
        .sort((a, b) => { return a.ControlOrder - b.ControlOrder }));

    }
  );
  AdditionalInfo_WCControls = AdditionalInfo_WCControls.concat(addtional_WC_sections);

  // AdditionalInfo_WCControls = controls.filter((f) => f.ParentControlsRecId == 34 && f.ControlTypeCode !== 'page' && f.IsActive)
  // .sort((a, b) => { return a.ControlOrder - b.ControlOrder });

  const ControlDisplayValues = controlDisplayValues.filter(f => f.IsActive);
  const ControlDependencies = controlDependencies.filter(f => f.IsActive);

  useEffect(() => {
    let currentStateLossControl = JSON.parse(JSON.stringify(currentState));
    LossInfoControls.forEach(
      item => {
        //setCurrentState();
        if (item.ControlTypeCode === 'section') return;
        currentStateLossControl.lossInfoDetail[item.ControlLabelCode] = "";
        //setCurrentState(currentStateLossControl);
      }
    );

    AdditionalInfoControls.forEach(
      item => {
        if (item.ControlTypeCode === 'section') return;
        currentStateLossControl.lossInfoDetail.additionalInfo[item.ControlLabelCode] = "";
      }
    );

    AdditionalInfo_WCControls.forEach(
      item => {

        if (item.ControlTypeCode === 'section') return;
        currentStateLossControl.lossInfoDetail.additionalInfo_WC[item.ControlLabelCode] = "";
      }
    )

    setCurrentState(currentStateLossControl)

  }, [controls]);


  // Use the submitted data to set the state
  const handleChange = (name, value, key, ob) => {

    setCurrentState({ ...currentState, [key]: { ...currentState[key], ...ob } });
    //console.log("handle Change");
    //console.log(currentState);
  }

  const handleChangeRadio = (e, key, ob) => {

    let name = e.target.name;
    let value = e.target.value;

    if (name === 'claimType') {//claimType radio button specific, and prefill the policynumber based on the selection
      let lob = '';
      let policyNumber = '';
      let currentStateStringfied = JSON.parse(JSON.stringify(currentState));

      let policyLst = [];

      if (value === 'Auto') {
        lob = 'Commercial Auto';
        let autoPolicies = policies.filter(f => f.LOB === lob);
        policyNumber = autoPolicies.sort((a, b) => { return new Date(b.EffectiveDate) - new Date(a.EffectiveDate) })[0]?.PolicyNumber;
        //policyNumber = lodash.orderBy(autoPolicies, (s) => new Date(s.EffectiveDate), ['asc']);

        policyLst = autoPolicies.sort((a, b) => { return new Date(b.EffectiveDate) - new Date(a.EffectiveDate) })?.map(m => m.PolicyNumber);
      }
      else if (value === 'GeneralLiability') {
        lob = 'General Liability';
        let GLPolicies = policies.filter(f => f.LOB === lob);
        //policyNumber = lodash.sortBy(GLPolicies, s => new Date(s.EffectiveDate));
        policyNumber = GLPolicies.sort((a, b) => { return new Date(b.EffectiveDate) - new Date(a.EffectiveDate) })[0]?.PolicyNumber;

        policyLst = GLPolicies.sort((a, b) => { return new Date(b.EffectiveDate) - new Date(a.EffectiveDate) })?.map(m => m.PolicyNumber);
      }
      else if (value === 'Property') {
        lob = 'Excess';
        let PropertyPolicies = policies.filter(f => f.LOB === lob);
        //no populate property for now
        //policyNumber = PropertyPolicies.sort((a, b) => { return new Date(a.EffectiveDate) - new Date(b.EffectiveDate) })[0].PolicyNumber;
        policyNumber = '';

        policyLst = [];
      }
      else if (value === 'WorkersCompensation') {
        lob = 'Workers Compensation';
        let WCPolicies = policies.filter(f => f.LOB === lob);
        //policyNumber = lodash.sortBy(GLPolicies, s => new Date(s.EffectiveDate));
        policyNumber = WCPolicies.sort((a, b) => { return new Date(b.EffectiveDate) - new Date(a.EffectiveDate) })[0]?.PolicyNumber;

        policyLst = WCPolicies.sort((a, b) => { return new Date(b.EffectiveDate) - new Date(a.EffectiveDate) })?.map(m => m.PolicyNumber);
      }

      currentStateStringfied.personalInfoDetail.policyNumber = policyNumber;
      currentStateStringfied.claimTypeDetail.claimType = value;

      currentStateStringfied.personalInfoDetail.policyList = policyLst;

      setCurrentState(currentStateStringfied);
    }
    else {//for general purposes
      setCurrentState({ ...currentState, [key]: { [name]: value } });
    }

    //setCurrentState({ ...currentState, [key]: { [name]: value } }); good one
    setCanLeave(false);//for the change that after submmitting the page stays at uploading. but allow use to leave the page without notification
  }

  const handleChangeUpload = (name, value, key, ob) => {
    setCurrentState({ ...currentState, [key]: ob });
  }

  const nextButton = () => {
    let currentStep = currentState.currentStep;
    // If the current step is not 3, then render the "next" button
    if (currentStep < 4) {
      return (
        <RoundButton onClick={_next}>
          Next
        </RoundButton>
      );
    }
    // ...else render nothing
    else {
      return null;
    }
  }
  const prevButton = () => {
    let currentStep = currentState.currentStep;
    // If the current step is not 3, then render the "next" button
    if (currentStep !== 1) {
      return (
        <RoundButton onClick={_prev}>
          Previous
        </RoundButton>
      );
    }
    else {
      return null;
    }
  }
  const submitButton = () => {
    let currentStep = currentState.currentStep;

    // If the current step is the last step, then render the "submit" button
    if (currentStep > 3) {
      return (
        <RoundButton>Submit </RoundButton>
      );
    }
    else { return null; }

  }

  const uint8ToString = (buf) => {
    var i, length, out = '';
    for (i = 0, length = buf.length; i < length; i += 1) {
      out += String.fromCharCode(buf[i]);
    }
    return out;
  }

  const handleSubmit = async e => {

    e.preventDefault();
    setLoading(true);

    let url = [];

    /*
    for (let i = 0; i < currentState.upload.length; i++) {
      let formData = new FormData();
      formData.append("file", currentState.upload[i]);
      let itemUrl = await DocumentService.DocumentUpload(formData);

      url.push(itemUrl.data.Payload);
    }*/


    let data = JSON.parse(JSON.stringify(currentState));
    data.upload = [].concat(currentState.upload);//after stringfy file in the array is gone, so do another assignment to new data obj

    let base64Files = [];
    for (let j = 0; j < data.upload.length; j++) {
      let fileToConvert = data.upload[j];
      let buffer = await fileToConvert.arrayBuffer();
      const bufferByteLength = buffer.byteLength;
      const bufferUint8Array = new Uint8Array(buffer, 0, bufferByteLength);

      let blob = btoa(uint8ToString(bufferUint8Array));

      //upload to S3 to get filePath
      let formData = new FormData();
      formData.append("file", data.upload[j]);
      let itemUrl = await DocumentService.DocumentUpload(formData);
      //upload to S3 to get filePath

      base64Files.push({ name: fileToConvert.name, base64: blob, filePath: itemUrl.data.Payload });
    }
    data.upload = base64Files;

    //build email template
    let t1 = '<table width="600" cellpadding="3" cellspacing="3">';

    const cur = new Date();
    const mm = cur.getMonth() + 1 <= 9 ? '0' + (cur.getMonth() + 1) : cur.getMonth() + 1;
    const dd = cur.getDate() <= 9 ? '0' + cur.getDate() : cur.getDate();
    const yy = cur.getFullYear().toString().substring(2);
    const ss = cur.getSeconds();
    const confirmationNum = 'CN' + mm + dd + yy + ss + (Math.floor(1000000 + Math.random() * 9000000)).toString();
    t1 += `<tr><td style='font-weight:bold'>Confirmation Number : </td> <td>${confirmationNum}</td></tr>`;

    //Claim Type
    t1 += `<tr><td style='font-weight:bold'>Claim Type</td></tr>`;
    t1 += `<tr><td>Claim Type : </td> <td>${data.claimTypeDetail.claimType}</td></tr>`;
    t1 += `<tr><td></td></tr>`;
    //Claim Type

    //personal info
    t1 += `<tr><td style='font-weight:bold'>Personal Info</td></tr>`;
    Object.keys(data.personalInfoDetail).forEach(key => {
      if (key === 'policyList') return;
      let t = `<tr><td>${key.charAt(0).toUpperCase() + key.slice(1)} : </td> <td>${data.personalInfoDetail[key]}</td></tr>`;
      t1 += t;
    })
    t1 += `<tr><td></td></tr>`;
    //personal info


    //lossInfo
    t1 += `<tr><td style='font-weight:bold'>Loss Info</td></tr>`;
    Object.keys(data.lossInfoDetail).forEach(key => {
      if (key === 'additionalInfo' || key === 'otherVehicles' || key === 'additionalInfo_WC') return;
      let label = LossInfoControls.find(f => f.ControlLabelCode === key)?.ControlLabel;
      let t = `<tr><td>${label} : </td> <td>${data.lossInfoDetail[key]}</td></tr>`;
      t1 += t;
    })
    t1 += `<tr><td></td></tr>`;

    if (data.claimTypeDetail.claimType === 'Auto') {
      t1 += `<tr><td>Additional Info</td></tr>`;
      Object.keys(data.lossInfoDetail.additionalInfo).forEach(key => {
        let label = AdditionalInfoControls.find(f => f.ControlLabelCode === key)?.ControlLabel;
        let t = `<tr><td>${label} : </td> <td>${data.lossInfoDetail.additionalInfo[key]}</td></tr>`;
        t1 += t;
      })
      t1 += `<tr><td></td></tr>`;

      data.lossInfoDetail.otherVehicles.forEach(
        item => {
          Object.keys(item).forEach(key => {
            let label = OtherVehiclesControls.find(f => f.ControlLabelCode === key)?.ControlLabel;

            let t = '';
            if (key === 'otherVehicleId') {// since otherVehicleId is derived from array index, add 1 for email template
              t = `<tr><td>${'Other Vehicle Id:'} : </td> <td>${item[key] + 1}</td></tr>`;
            }
            else {
              t = `<tr><td>${label ? label : key} : </td> <td>${item[key]}</td></tr>`;
            }
            t1 += t;
          })
          t1 += `<tr><td></td></tr>`;
        }
      );
    }

    if (data.claimTypeDetail.claimType === 'WorkersCompensation') {
      t1 += `<tr><td>Additional Info</td></tr>`;
      Object.keys(data.lossInfoDetail.additionalInfo_WC).forEach(key => {
        let control = AdditionalInfo_WCControls.find(f => f.ControlLabelCode === key);
        let label = control?.ControlLabel;
        let t = `<tr><td>${label} : </td> <td>${control?.IsConfidential ? control?.MaskDisplay : data.lossInfoDetail.additionalInfo_WC[key]}</td></tr>`;
        t1 += t;
      })
      t1 += `<tr><td></td></tr>`;

    }
    //lossInfo

    data.template = t1 + '</table>';
    //build email template
    //append control value data separately for save
    let savingControlValues = controls.map(
      (item) => {

      }
    );

    //LossInfoControls = controls.filter((f) => f.ParentControlsRecId == 1 && f.ControlTypeCode !== 'page' && f.ControlTypeCode !== 'section');
    let LossInfoControlValues = LossInfoControls.filter(f => f.ControlTypeCode !== 'section').map(item => {
      item.DataValue = currentState.lossInfoDetail[item.ControlLabelCode];
      return item;
    });
    let AdditionalInfoControlValues = AdditionalInfoControls.filter(f => f.ControlTypeCode !== 'section').map(item => {
      item.DataValue = currentState.lossInfoDetail.additionalInfo[item.ControlLabelCode];
      return item;
    });

    let AdditionalInfoControl_WCValues = AdditionalInfo_WCControls.filter(f => f.ControlTypeCode !== 'section').map(item => {
      item.DataValue = currentState.lossInfoDetail.additionalInfo_WC[item.ControlLabelCode];
      return item;
    });




    let OtherVehicleInfoControlValues = [];
    data.lossInfoDetail.otherVehicles.map(
      item => {
        let repeatControlId = CreateUUID();
        Object.keys(item).forEach(
          key => {
            let foundControl = OtherVehiclesControls.find(f => f.ControlLabelCode === key);
            if (foundControl) {
              let newfoundControl = JSON.parse(JSON.stringify(foundControl));
              newfoundControl.DataValue = item[key];
              newfoundControl.RepeatControlMapping = repeatControlId;
              OtherVehicleInfoControlValues.push(newfoundControl);
            }
          }
        );
      }
    );

    data.LossInfoControlValues = LossInfoControlValues;
    if (data.claimTypeDetail.claimType === 'Auto') {
      data.AdditionalInfoControlValues = AdditionalInfoControlValues;
      data.OtherVehicleInfoControlValues = OtherVehicleInfoControlValues;
    }

    if (data.claimTypeDetail.claimType === 'WorkersCompensation') {
      data.AdditionalInfoControl_WCValues = AdditionalInfoControl_WCValues;

    }

    //05/03/2022 add confirmation num to email sending to LP team and confirmation page, same to db in the modified comments for now
    data.confirmationNum = confirmationNum;

    let clientId = currentClient.state.clientId;
    let reportingClientId = currentClient.state.loginClientId;


    //Rmat integration, remove the properties not needed for Rmat
    var fnolForRmat = JSON.parse(JSON.stringify(data));

    delete fnolForRmat.template;
    delete fnolForRmat.currentStep;
    delete fnolForRmat.personalInfoDetail.policyList;
    delete fnolForRmat.LossInfoControlValues;

    if (fnolForRmat.claimTypeDetail.claimType !== 'Auto') {
      delete fnolForRmat.lossInfoDetail.additionalInfo;
      delete fnolForRmat.lossInfoDetail.otherVehicles;
    }

    if (fnolForRmat.claimTypeDetail.claimType !== 'WorkersCompensation') {
      delete fnolForRmat.lossInfoDetail.additionalInfo_WC;
    }


    delete fnolForRmat.AdditionalInfoControlValues;
    delete fnolForRmat.OtherVehicleInfoControlValues;
    delete fnolForRmat.AdditionalInfoControl_WCValues

    data.fnolForRmat = fnolForRmat;
    //Rmat integration






    /*
    //test
    var files = [];
    for (let i = 0; i < currentState.upload.length; i++) {
      var formData2 = new FormData();
      //formData2.append("files", currentState.upload[i]);
      //let itemUrl = await DocumentService.DocumentUpload(formData);
  
      //url.push(itemUrl.data.Payload);
    }
  
    var data2 = JSON.stringify(data);
    var data3 = JSON.stringify(currentState.upload);
    formData2.append("files", data3);
    formData2.append('data2', data2);
  
    let rep = await ClaimsService.fnolSubmit2(formData2).then(
      result => {
        if (result.data.Error.ErrorStatus === 0) {
  
          toast.success("Claim has been Reported. A claim representative will contact you shortly.");
        }
        else {
          toast.error("Claim Report Failed");
        }
  
        // reset state of upload
        handleChangeUpload('', '', 'upload', []);
        //reset the currentState after submitting and go back to first page
        //in case use submit another one right after, reset the value of additional obj to empty for proerties instead of set to null object
        Object.keys(data.lossInfoDetail.additionalInfo).forEach(i => data.lossInfoDetail.additionalInfo[i] = '');
        setCurrentState({
          ...currentState,
          currentStep: 1,
  
          claimTypeDetail: {
            claimType: ""
          },
          lossInfoDetail: {
            ...currentState.lossInfoDetail,
            additionalInfo: data.lossInfoDetail.additionalInfo,
            otherVehicles: []
          },
          upload: []
        })
        setLoading(false);
      }
    ); test here */

    let rep = await ClaimsService.fnolSubmit(clientId, reportingClientId, 'online', data).then(
      result => {
        if (result.data.Error.ErrorStatus === 0) {

          toast.success("Claim has been Reported. A claim representative will contact you shortly.");
          setConfirmation({ ...confirmation, confirming: 'confirming', confirmationNum: confirmationNum });
        }
        else {
          toast.error("Claim Report Failed");
        }

        // reset state of upload
        handleChangeUpload('', '', 'upload', []);
        //reset the currentState after submitting and go back to first page
        //in case use submit another one right after, reset the value of additional obj to empty for proerties instead of set to null object
        Object.keys(data.lossInfoDetail.additionalInfo).forEach(i => data.lossInfoDetail.additionalInfo[i] = '');
        setCurrentState({
          ...currentState,
          currentStep: 1,

          claimTypeDetail: {
            claimType: ""
          },
          lossInfoDetail: {
            ...currentState.lossInfoDetail,
            additionalInfo: data.lossInfoDetail.additionalInfo,
            otherVehicles: []
          },
          upload: []
        })
        setLoading(false);
        setCanLeave(true);
      }
    );
    //ClaimsService.fnolSubmit(2, 27727, '', Props.currentState);

    /*
    for (let i = 0; i < currentState.upload.length; i++) {
      let formData = new FormData();
      formData.append("file", currentState.upload[i]);
      let itemUrl = await DocumentService.DocumentUpload(formData);
  
      url.push(itemUrl.data.Payload);
    }*/

    /*
    const docObj = {
      DocumentId: `${inputState.Category.Code}`,
      DocumentTypesRecId: `${inputState.DocumentsRecId}`,
      FilePath: url, // url.data.Payload,
      FileName: `${file.file.name}`,
      Description: `${inputState.Body}`,
      ClientId: `${inputState.Member}`,
      ProgramsRecId: `${inputState.Program}`,
      Year: null,//docYear,
      Month: null, //docMonth,
      Day: null,//docDay,
      Quarter: null//currentQuarter
    };
    const doc = await DocumentService.createDocument(docObj).then(() =>
      toast.success("Document Uploaded Successfully")
    );*/

  };

  const _next = () => {
    let currentStep = currentState.currentStep;

    // If the current step is 1 or 2, then add one on "next" button click
    currentStep = currentStep >= 3 ? 4 : currentStep + 1;
    setCurrentState({ ...currentState, 'currentStep': currentStep });
    //console.log("hit next");
    //console.log(currentState);
  }

  const _prev = () => {
    let currentStep = currentState.currentStep;
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    setCurrentState({ ...currentState, 'currentStep': currentStep });
  }

  if (confirmation.confirming === 'confirming') {
    return (
      <ConfirmationPage confirmation={confirmation} />
    );
  }

  return (
    <>
      <Prompt when={!canLeave} message={'Online report has not finished. Do you want to leave the page?'} />
      <Text.H2>Online</Text.H2>

      <Section id="StepLabel">
        <Text.H3 style={{ textAlign: 'left' }}>Claim Type</Text.H3>
        <Text.H3 style={{ marginRight: 85 }}>Personal Info</Text.H3>
        <Text.H3>Loss Info</Text.H3>
        <Text.H3 style={{ textAlign: 'right' }}>Upload</Text.H3>

      </Section>


      <Section style={{ backgroundColor: 'transparent', marginBottom: 0, marginLeft: '2%', marginRight: '2%' }}>
        <MultiStepBar currentStep={currentState.currentStep} />
      </Section>

      <Section style={{ display: 'block' }}>
        <ClaimType currentState={currentState} handleChange={handleChangeRadio} />
        <PersonalInfo currentState={currentState} handleChange={handleChange} />
        <LossInfo currentState={currentState} handleChange={handleChange}
          lossInfoControls={LossInfoControls}
          additionalInfoControls={AdditionalInfoControls}
          otherVehiclesControls={OtherVehiclesControls}

          additionalInfo_WCControls={AdditionalInfo_WCControls}
          controlDisplayValues={ControlDisplayValues}
          controlDependencies={ControlDependencies}
        />
        <UploadClaimDocs currentState={currentState} handleChange={handleChangeUpload} />

      </Section>
      <div>
        {currentState.currentStep !== 1 && (
          <RoundButton style={{ marginRight: 2 }} onClick={_prev}>Previous</RoundButton>)}
        {currentState.currentStep < 4 && (
          <RoundButton onClick={_next}>Next</RoundButton>)}
        {currentState.currentStep > 3 && (
          <RoundButton
            disabled={loading}
            className={
              !loading ? "roundButton primary" : "roundButton primary disabled"}
            onClick={(e) => handleSubmit(e)}>{loading ? 'Submitting' : 'Submit'} </RoundButton>)}
        {loading && (<Loading.SmallLoading />)}

        {/* {prevButton()}
          {nextButton()}
          {submitButton} */}
      </div>
    </>
  );
}

export default React.memo(OnlineClaimsPage)

