import React, { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import DataTable from "../../../components/DataTable";
import Section from "../../../components/Section";
import Text from "../../../components/Text";
import PolicyService from "../../../services/PolicyService";
import { useAuth } from "../../../context/AuthContext";
import { DocumentService } from "../../../services/index";
import "./DocumentsLibrary.scss";

const DocumentsLibraryPage = () => {
  const { state: authState } = useAuth();

  const [trueFileName, setTrueFileName] = useState("");
  const [docName, setDocName] = useState("");
  const [docName2, setDocName2] = useState("");
  const [loadingBool, setLoadingBool] = useState(false);

  const style = loadingBool ? { color: "grey" } : { color: "#f47e20" };

  const COLUMNS = [
    {
      Header: "Coverage",
      accessor: "LOB",
      id: "LOB"
    },
    // {
    //   Header: "Code",
    //   accessor: "CarrierCode",
    //   id: "CarrierCode"
    // },
    {
      Header: "Policy Number",
      accessor: "PolicyNumber",
      id: "PolicyNumber"
    },
    {
      Header: "Effective",
      accessor: "EffectiveDate",
      id: "EffectiveDate",
      Cell: ({ value }) =>
        value ? format(parseISO(value), "MM/dd/yyyy") : "--"
    },
    {
      Header: "Expiration",
      accessor: "ExpirationDate",
      id: "ExpirationDate",
      Cell: ({ value }) =>
        value ? format(parseISO(value), "MM/dd/yyyy") : "--"
    },

    {
      Header: "Program",
      accessor: "CategoryCode",
      id: "CategoryCode"

    },
    {
      Header: "Premium Year",
      accessor: "ECaptivPremiumYear",
      id: "ECaptivPremiumYear",

    },
    {
      Header: "Policy",//"Document",
      accessor: "policyDocument",
      id: "policyDocument",
      Cell: ({ value }) =>
        value ? (
          <span disabled={loadingBool} style={style}>
            {value === docName ? "Downloading..." : "View"}
          </span>
        ) : (
          ""
        )
    },

  ];

  // FILE STUFF
  const { data: documents = [], isLoadingDocs } = useQuery(
    "documentsByClient",
    () => DocumentService.getDocumentsByClient(authState.clientId)
    //{ fetchPolicy: "no-cache", cacheTime: 0 } casue mobile device issue that not loading properly 10/14/2022
  );

  const {
    data: File = [],
    isLoadingFile,
    refetch,
    errorFile
  } = useQuery(
    "documentDownload",
    () =>
      DocumentService.DocumentDownload(trueFileName, `${docName2} ${docName}`),
    { enabled: false, fetchPolicy: "no-cache", cacheTime: 0 }
  );

  useEffect(() => {
    if (docName.length > 2) {
      if (trueFileName.length > 2) {
        refetch().then(() => {
          setDocName("");
          setTrueFileName("");
          setLoadingBool(false);
        });
      } else {
        toast.error("No Document Found");
        setLoadingBool(false);
      }
    }
  }, [docName, trueFileName, refetch]);

  const sortedDocuments = documents.filter(doc =>
    doc.DocumentId.startsWith("Documents Library_Insurance Policies_PolicyPDF")
  );
  // BASING SECONDARY DOCUMENT MATCH ON THE FILENAME ATTRIBUTE instead of DocumentId which is not unique
  const fileDownloadSetup = (fileName, DocName) => {
    setLoadingBool(true);
    if (sortedDocuments.length >= 1) {
      sortedDocuments.forEach(document => {
        setDocName2(DocName);
        setDocName(fileName);
        if (document.FileName === fileName) {
          const filePath = document.FilePath;
          setTrueFileName(
            filePath.slice(filePath.lastIndexOf("/")).replace(/\//i, "")
          );
        }
      });
    }
  };

  // changed to include expired policies
  const {
    data: policies = [],
    isLoading,
    error
  } = useQuery(
    "policiesByClientNoFilter",
    () => PolicyService.getPoliciesByClientNoFilter(authState.clientId),
    //{ fetchPolicy: "no-cache", cacheTime: 0 }
    { cacheTime: 2000 }
  );

  const onCellClick = cell => {
    // added second qualifier to make undefined cells do nothing
    // add third to disable download while other download running
    if (cell.column.id === "policyDocument" && cell.value && !loadingBool) {
      fileDownloadSetup(cell.value, "Policy");
    }
  };

  const fullPolicies = [];
  // BASING THE DOCUMENT MATCH ON POLICY NUMBER
  //
  if (policies.length > 0) {
    policies.map(policy => {
      if (sortedDocuments.length) {
        sortedDocuments.forEach(document => {
          if (policy.PolicyNumber === document.PolicyNumber) {
            policy.policyDocument = document.FileName;
          }
        });
      }
      fullPolicies.push(policy);
      return policy;
    });
  }
  if (error) {
    return null;
  }
  return (
    <div id="policyContainer">
      <div className="headerContainer">
        <Text.H1>Insurance Policies</Text.H1>
        {loadingBool && (
          <span>***Larger files may take a moment to download</span>
        )}
      </div>
      {/* table for insurance policies by client */}
      {fullPolicies.length > 0 ? (
        <DataTable
          columns={COLUMNS}
          data={fullPolicies}
          onCellClick={onCellClick}
          sortBy={[
            {
              id: "LOB",
              desc: false
            }
          ]}
        />
      ) : (
        <Section>No Policies Found.</Section>
      )}
    </div>
  );
};

DocumentsLibraryPage.propTypes = {};

export default DocumentsLibraryPage;
