import React, { useEffect, useState, useMemo } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { Select } from "rbx";
import { format, parseISO } from "date-fns";

import { useAuth } from "../../../context/AuthContext";
import { DocumentService } from "../../../services/index";
import MemberFundAnalysisService from "../../../services/MemberFundAnalysisService";
import ClientService from "../../../services/ClientService";
import ProgramService from "../../../services/ProgramService";
import PolicyService from "../../../services/PolicyService";
import Text from "../../../components/Text";
import Button from "../../../components/Button";
import Section from "../../../components/Section";
import { formatCurrency, startCase } from "../../../utils";
import "./FinancialProfitability.scss";
import Loading from "../../../components/Loading";
import { groupBy, random } from "lodash";

import DocumentDelete from '../../../components/DocumentDelete/DocumentDelete';



const FinancialStatements = ({ passDownCurrentProg }) => {



  const { state: authState, permissions } = useAuth();
  //const [currentProgram, setCurrentProgram] = useState(passDownCurrentProg); //Li 08-04-2022 program dropdown contorl aduits/unauditis docs

  const [trueFileName, setTrueFileName] = useState("");
  const [docName, setDocName] = useState("");
  const [docName2, setDocName2] = useState("");
  const [toggleReached, setToggleReached] = useState(false);

  const yearStart = new Date().getFullYear();

  const [currentYear, setCurrentYear] = useState(yearStart);
  const [iterableFunds, setIterableFunds] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loadingBool, setLoadingBool] = useState(false);


  const { data: programs = [] } = useQuery("programs", () =>
    ProgramService.getPrograms()
  );

  //get client history for the programs change
  const { data: clientsHistory = [] } = useQuery("clientsHistory", () =>
    ClientService.getClientsChangeHistory()
  );

  // gets all documents associated with current program //Li 08-04-2022 add Program history docs
  const { data: documents = [], refetch: refetchDocs } = useQuery("documentsByProgram", () =>
    //DocumentService.getDocumentsByProgram(authState.programRecId)
    DocumentService.getDocumentsByProgram(passDownCurrentProg)
  );

  //  get all document types
  const { data: documentTypes = [] } = useQuery("documentTypes", () =>
    DocumentService.getDocumentTypes()
  );

  // downloads file
  const { data: File = [], refetch } = useQuery(
    "documentDownload",
    () =>
      DocumentService.DocumentDownload(trueFileName, `${docName2} ${docName}`),
    { enabled: false }
  );



  const {
    data: funds = [],
    isLoadingFunds,
    errorFunds,
    refetch: refetchAfterDownload
  } = useQuery("memberFundAnalysis", () =>
    MemberFundAnalysisService.getMemberFundAnalysis(authState.clientId),
    { fetchPolicy: "no-cache", cacheTime: 0 }
  );

  // changed to include expired policies
  const {
    data: policies = [],
    isLoading,
    error
  } = useQuery(
    "policiesByClientNoFilter",
    () => PolicyService.getPoliciesByClientNoFilter(authState.clientId),
    { fetchPolicy: "no-cache", cacheTime: 0 }
    //{ cacheTime: 2000 }
  );

  //Li change to pdf
  async function GetMemberFundAnalysisPDF(body) {

    //below line is fix for the premiumYear sorting issue in the asynchrous loop above 
    body = body.sort((a, b) => { return b.PremiumYear.split('-')[0] - a.PremiumYear.split('-')[0] });

    let result = await MemberFundAnalysisService.getMemberFundAnalysisPDF(authState.clientId, body);

    const promise1 = new Promise((resolve, reject) => {
      resolve(window.atob(result));
    });

    promise1.then((byteCharacters) => {
      /// //////////////////////////////////////////////////////////////////////////////
      /* original,open in new tab directly. since opening multi tabs, would be blocked by browsers, using downloading instead
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const file = new Blob([byteArray], { type: "application/pdf;base64" });
      const fileURL = URL.createObjectURL(file);

      setLoadingBool(false);
      window.open(fileURL, '_blank');*/

      /// //////////////////////////////////////////////////////////////////////////////

      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const file = new Blob([byteArray], { type: "application/pdf;base64" });
      const fileURL = URL.createObjectURL(file);


      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download",
        authState.clientName + '_' + body[0]?.YearQuarter?.toString().substring(0, 4) + ' _Q' + body[0]?.YearQuarter?.toString().slice(-1) + '_' + body[0]?.ProgramCode + '.pdf'); // got rid of file.pdf default
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setLoadingBool(false);
    });
  }



  const { data: programClients = [] } = useQuery("getClientsByProgram", () =>
    ClientService.getClientsByProgram(authState.programRecId)
  );

  let { data: allClients = [] } = useQuery("getAllClients", () =>
    ClientService.getClients()
  );

  // const { data: iterableFunds = [], refetch: fundRefetch } = useQuery(
  //   ["memberFundAnalysis2", iterableClient],
  //   () => MemberFundAnalysisService.getMemberFundAnalysis(iterableClient)
  // );


  // function that loop calls for query summation
  async function GetFunds(clientID) {
    //const result = await MemberFundAnalysisService.getMemberFundAnalysis(clientID); 05252023 Li do not need to call MFA api for every account
    const result = await MemberFundAnalysisService.getMemberFundAnalysisAll();
    return result;
  }

  // sum is dependent on
  const createGroupFundData = async (yearQuarter, PremiumYearTo, lookupProgram) => {
    let allFunds = await GetFunds();

    //const yearQuarterFundsIterable = programClients.map(async client => {
    const yearQuarterFundsIterable = allClients.map(async client => {

      //if (excludedClients.find(f => f.ClientId === client.ClientId)) return false;//program history Li
      if (client.ClientType === 'Prospect' || client.ClientType === 'Broker') return false; //Li Prospect and Brokerdoes not have MFA, no need to route to API

      //let newIterableFunds = await GetFunds(client.ClientId); 05252023 Li do not need to call MFA api for every account

      //Program history
      //newIterableFunds = newIterableFunds.filter(f => f.ProgramCd.trim() === programs.find(f => f.RecId === lookupProgram)?.Code.trim());
      let newIterableFunds = allFunds.filter(f => f.ProgramCd.trim() === programs.find(f => f.RecId === lookupProgram)?.Code.trim() && f.ClientId === client.ClientId);


      // data for correct yearQuarter and premium year
      const finalFunds = newIterableFunds.filter(
        fund =>
          fund.YearQuarter === yearQuarter &&
          fund.PremiumYearTo === PremiumYearTo
      );
      if (finalFunds && finalFunds.length > 0) {
        return finalFunds;
      }
    });
    return yearQuarterFundsIterable;
  };

  // NewFunc for adding
  const sumGroupData = iterableFunds2 => {
    // assign values to object
    const groupDataObj = {
      AFundGrossPremium: 0,
      AFundFrequencyClaimsAndExpencesPaid: 0,
      AFundFrequencyClaimsAndExpencesReserves: 0,
      AFundSurplus: 0,
      AFundPremiumAssessment: 0,
      AFundBalance: 0,
      BFundGrossPremium: 0,
      BFundSeverityClaimsAndExpencesPaid: 0,
      BFundSeverityClaimsAndExpencesReserves: 0,
      BFundSurplus: 0,
      BFundPremiumAssessment: 0,
      BFundBalance: 0,
      //05232023 Megan requested for new fields
      AFundPremiumAssessmentCollected: 0,
      AFundAllocationToBFund: 0,
      AFundReturnToMember: 0,
      BFundAFundDeficit: 0
    };
    iterableFunds2.forEach(fund => {
      if (fund && fund[0]) {
        groupDataObj.AFundGrossPremium += fund[0].AFundGrossPremium || 0;
        groupDataObj.AFundFrequencyClaimsAndExpencesPaid +=
          fund[0].AFundFrequencyClaimsAndExpencesPaid || 0;
        groupDataObj.AFundFrequencyClaimsAndExpencesReserves +=
          fund[0].AFundFrequencyClaimsAndExpencesReserves || 0;
        groupDataObj.AFundSurplus += fund[0].AFundSurplus || 0;
        groupDataObj.AFundPremiumAssessment +=
          fund[0].AFundPremiumAssessment || 0;
        groupDataObj.AFundBalance += fund[0].AFundBalance || 0;
        groupDataObj.BFundGrossPremium += fund[0].BFundGrossPremium || 0;
        groupDataObj.BFundSeverityClaimsAndExpencesPaid +=
          fund[0].BFundSeverityClaimsAndExpencesPaid || 0;
        groupDataObj.BFundSeverityClaimsAndExpencesReserves +=
          fund[0].BFundSeverityClaimsAndExpencesReserves || 0;
        groupDataObj.BFundSurplus += fund[0].BFundSurplus || 0;
        groupDataObj.BFundPremiumAssessment +=
          fund[0].BFundPremiumAssessment || 0;
        groupDataObj.BFundBalance += fund[0].BFundBalance || 0;

        //05232023 Megan requested for new fields
        groupDataObj.AFundPremiumAssessmentCollected += fund[0].AFundPremiumAssessmentCollected || 0;
        groupDataObj.AFundAllocationToBFund += fund[0].AFundAllocationToBFund || 0;
        groupDataObj.AFundReturnToMember += fund[0].AFundReturnToMember || 0;
        groupDataObj.BFundAFundDeficit += fund[0].BFundAFundDeficit || 0;
      }
    });
    return groupDataObj;
  };

  // Data is just an array or arrays where the first row is the header columns and then the following rows would just be an array of data
  const exportToCSV = (data, title) => {
    const csvContent = `data:text/csv;charset=utf-8,${data
      .map(e => e.join(","))
      .join("\n")}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${title}.csv`);
    document.body.appendChild(link); // Required for FF
    link.click(); // This will download the data file named "my_data.csv".
  };

  let closestQuarter = 0;
  let closestYear = 0;
  if (funds.length >= 1) {
    const sortedDateFunds = funds.sort((a, b) => b.YearQuarter - a.YearQuarter);
    closestYear = sortedDateFunds[0].YearQuarter.toString().slice(0, 4);
    closestQuarter = sortedDateFunds[0].YearQuarter.toString().slice(4);
  }

  // Set up the csv data(doing this for member only atm to make sure it works, also unsure how to easily get the group data would be -> Get all members, get fund for each, sort and take most recent, sum all categories ... seems slow, GroupFundAnalysis endpoint?)
  // cannot call format currency inside of string literal ${} will break, separated out into push calls when testing will leave for now to make a bit more readable, although maybe slighlty slower

  const downLoadMultiFiles = yearQuarter => {
    //for program history in split quarter, 03/31/2023 retire this logic that Megan wants all MFA data no matter when the client change the program as long as MFA excel has data for any programs the client was in
    /*let clientHistory = clientsHistory.filter(f => f.ClientId === authState.clientId && f.ProgramsRecId);

    clientHistory.sort((a, b) => {
      let aa = new Date(a.CreatedDateTime);
      let bb = new Date(b.CreatedDateTime);
      return bb - aa;
    }).map(item => {
      item.ChangeOnYearQuarter = Number(new Date(item.CreatedDateTime).getFullYear() + '0' + Math.floor(new Date(item.CreatedDateTime).getMonth() / 3 + 1));
      item.StartYearQuarter = Number(new Date(item.StartDate).getFullYear() + '0' + Math.floor(new Date(item.StartDate).getMonth() / 3 + 1));
      item.EndYearQuarter = Number(new Date(item.EndDate).getFullYear() + '0' + Math.floor(new Date(item.EndDate).getMonth() / 3 + 1));
    })

    // later on need to find a way to present to client for the quarter they changed for both Programs of MFA
    let historyProgram = clientHistory.filter(f => f.StartYearQuarter <= yearQuarter && f.EndYearQuarter >= yearQuarter).sort((a, b) => {
      let aa = new Date(a.CreatedDateTime);
      let bb = new Date(b.CreatedDateTime);
      return aa - bb;
    });

    let historyProgramGrouped = groupBy(historyProgram, 'ProgramsRecId');
    let multiPrograms = []
    Object.keys(historyProgramGrouped).forEach(key => { multiPrograms.push(Number(key)) })
    let currentProgram = authState.programRecId;

    //if currentProgram is existing in the history before, do not append
    if (!multiPrograms.find(f => f === currentProgram)) { multiPrograms.push(currentProgram) }


    for (let i = 0; i < multiPrograms.length; i++) {

      csvDownloadSetup(yearQuarter, multiPrograms[i]);
    }*/

    //for program history in split quarter

    //03/31/2023 Megan wants all MFA data no matter when the client change the program as long as MFA excel has data for any programs the client was in
    let yearQuarterPrograms = funds
      .filter(fund => fund.YearQuarter === yearQuarter).map(item => item.ProgramCd.trim());

    yearQuarterPrograms = [...new Set(yearQuarterPrograms)]//make unique program in a set

    for (let i = 0; i < yearQuarterPrograms.length; i++) {
      csvDownloadSetup(yearQuarter, Number(yearQuarterPrograms[i].toString().match(/(\d+)/)[0]));
    }
    //03/31/2023 Megan wants all MFA data no matter when the client change the program as long as MFA excel has data for any programs the client was in



  }
  // there may be a better way to do things here to optimize
  const csvDownloadSetup = async (yearQuarter, lookupProgram) => {
    const csvDataArray = [];
    const downloadYear = yearQuarter.toString().slice(0, 4);
    const downloadQuarter = Number(yearQuarter.toString().slice(4));
    const pdfDataArray = []; //Li change to PDF

    // const dateNow = format(new Date(), "PP");
    const quarterDateObj = {
      1: "March 31",
      2: "June 30",
      3: "September 30",
      4: "December 31"
    };

    const yearQuarterFunds = funds
      .filter(fund => fund.YearQuarter === yearQuarter && Number(fund.ProgramCd ? fund.ProgramCd?.toString().match(/(\d+)/)[0] : 0) === lookupProgram)
      .sort((a, b) => (a.PremiumYearTo < b.PremiumYearTo ? 1 : -1));
    const cleanedClientName = replaceCommas(authState.clientName);
    // const cleanedDate = replaceCommas(dateNow);
    //give client mfa for the program based on the history Li

    //let lookupProgram = historyProgram ? historyProgram : authState.programRecId;
    const programName = programs.find(f => f.RecId === lookupProgram)?.Name;
    const programCode = programs.find(f => f.RecId === lookupProgram)?.Code;
    //give client mfa for the program based on the history Li



    let i = 1;
    if (
      yearQuarterFunds &&
      //(authState.programRecId === 1 || authState.programRecId === 3)
      (lookupProgram === 1 || lookupProgram === 3 || lookupProgram === 6
        || lookupProgram === 7) //give client mfa for the program based on the history Li
    ) {
      //Promise.resolve(
      yearQuarterFunds.forEach(async fund => {
        const groupData = await createGroupFundData(
          yearQuarter,
          fund.PremiumYearTo,
          lookupProgram
        );

        let groupData2 = {};
        Promise.all(groupData).then(values => {

          const fundSumInput = values.filter(value => Array.isArray(value));

          groupData2 = sumGroupData(fundSumInput);

          csvDataArray.push([`eCaptiv ${programCode.replace('IC', '-IC')} Inc.`]);
          csvDataArray.push([
            `Member Fund Analysis as of ${quarterDateObj[downloadQuarter]} ${downloadYear}`
          ]);
          csvDataArray.push([
            `Premium Year ${fund.PremiumYearFrom} - ${fund.PremiumYearTo}`
          ]);
          csvDataArray.push(["", "GROUP TOTAL", `${cleanedClientName}`]);
          csvDataArray.push([`A Fund (Frequency)`]);
          csvDataArray.push([
            `Gross Premium`,
            `$${groupData2.AFundGrossPremium}`,
            `$${fund.AFundGrossPremium}`
          ]);
          csvDataArray.push([
            `Frequency Claims & Expenses Paid`,
            `${groupData2.AFundFrequencyClaimsAndExpencesPaid}`,
            `${fund.AFundFrequencyClaimsAndExpencesPaid}`
          ]);
          csvDataArray.push([
            `Frequency Claims & Expense Reserves`,
            `${groupData2.AFundFrequencyClaimsAndExpencesReserves}`,
            `${fund.AFundFrequencyClaimsAndExpencesReserves}`
          ]);
          csvDataArray.push([
            `Surplus / (Deficit) on Frequency Fund`,
            `${groupData2.AFundSurplus}`,
            `${fund.AFundSurplus}`
          ]);
          csvDataArray.push([
            `Premium Assessment`,
            `${groupData2.AFundPremiumAssessment}`,
            `${fund.AFundPremiumAssessment}`
          ]);
          csvDataArray.push([
            `A Fund BALANCE`,
            `$${groupData2.AFundBalance}`,
            `$${fund.AFundBalance}`
          ]);
          csvDataArray.push([``, ``, ``]);
          csvDataArray.push([`B Fund (Frequency)`]);
          csvDataArray.push([
            `Gross Premium`,
            `$${groupData2.BFundGrossPremium}`,
            `$${fund.BFundGrossPremium}`
          ]);
          csvDataArray.push([
            `Severity Claims & Expenses Paid`,
            `${groupData2.BFundSeverityClaimsAndExpencesPaid}`,
            `${fund.BFundSeverityClaimsAndExpencesPaid}`
          ]);
          csvDataArray.push([
            `Severity Claims & Expense Reserves`,
            `${groupData2.BFundSeverityClaimsAndExpencesReserves}`,
            `${fund.BFundSeverityClaimsAndExpencesReserves}`
          ]);
          csvDataArray.push([
            `Surplus / (Deficit) on Severity Fund`,
            `${groupData2.BFundSurplus}`,
            `${fund.BFundSurplus}`
          ]);
          csvDataArray.push([
            `Premium Assessment`,
            `${groupData2.BFundPremiumAssessment}`,
            `${fund.BFundPremiumAssessment}`
          ]);
          csvDataArray.push([
            `B Fund BALANCE`,
            `$${groupData2.BFundBalance}`,
            `$${fund.BFundBalance}`
          ]);
          csvDataArray.push([``, ``, ``]);
          csvDataArray.push([``, ``, ``]);
          csvDataArray.push([``, ``, ``]);
          csvDataArray.push([``, ``, ``]);
          csvDataArray.push([``, ``, ``]);
          csvDataArray.push([``, ``, ``]);

          //Li change to PDF
          let fundAndGroup = {
            "Program": lookupProgram,
            "ProgramName": programName,
            "ProgramCode": programCode,
            "ProgramDisplay": `eCaptiv ${programCode.replace('IC', '-IC')} Inc.`,
            "MemberFundAnalysisasof": quarterDateObj[downloadQuarter] + ', ' + downloadYear,
            "PremiumYear": fund.PremiumYearFrom + " - " + fund.PremiumYearTo,
            "YearQuarter": yearQuarter,
            "ClientName": authState.clientName,
            "AFundGrossPremium": fund.AFundGrossPremium || 0,
            "AFundFrequencyClaimsAndExpencesPaid": fund.AFundFrequencyClaimsAndExpencesPaid || 0,
            "AFundFrequencyClaimsAndExpencesReserves": fund.AFundFrequencyClaimsAndExpencesReserves || 0,
            "AFundSurplus": fund.AFundSurplus || 0,
            "AFundPremiumAssessment": fund.AFundPremiumAssessment || 0,
            "AFundBalance": fund.AFundBalance || 0,
            "BFundGrossPremium": fund.BFundGrossPremium || 0,
            "BFundSeverityClaimsAndExpencesPaid": fund.BFundSeverityClaimsAndExpencesPaid || 0,
            "BFundSeverityClaimsAndExpencesReserves": fund.BFundSeverityClaimsAndExpencesReserves || 0,
            "BFundSurplus": fund.BFundSurplus || 0,
            "BFundPremiumAssessment": fund.BFundPremiumAssessment || 0,
            "BFundBalance": fund.BFundBalance || 0,
            //05232023 Megan requested new fields from MFA excel
            "AFundPremiumAssessmentCollected": fund.AFundPremiumAssessmentCollected || 0,
            "AFundAllocationToBFund": fund.AFundAllocationToBFund || 0,
            "AFundReturnToMember": fund.AFundReturnToMember || 0,
            "BFundAFundDeficit": fund.BFundAFundDeficit || 0,

            groupData: { ...groupData2 }
          }
          pdfDataArray.push(fundAndGroup);
          //Li change to PDF

          if (i === yearQuarterFunds.length) {
            setRefresh(true);
            //exportToCSV(csvDataArray, `Member Fund ${yearQuarter}`);
            GetMemberFundAnalysisPDF(pdfDataArray);//Li change to PDF
          } else {
            i += 1;
          }
        })
      })
      //)
    }
    else if (lookupProgram === 2 || lookupProgram === 4) //give client mfa for the program based on the history Li 
    {
      yearQuarterFunds.forEach(fund => {
        csvDataArray.push([`eCaptiv ${programCode.replace('IC', '-IC')} Inc.`]);
        csvDataArray.push([
          `Member Fund Analysis as of ${quarterDateObj[downloadQuarter]} ${downloadYear}`
        ]);
        csvDataArray.push([
          `Premium Year ${fund.PremiumYearFrom} - ${fund.PremiumYearTo}`
        ]);
        csvDataArray.push(["", `${cleanedClientName}`]);
        csvDataArray.push([`Group Fund`]);
        csvDataArray.push([`Gross Premium`, `$${fund.AFundGrossPremium}`]);
        csvDataArray.push([
          `Frequency Claims & Expenses Paid`,
          `${fund.AFundFrequencyClaimsAndExpencesPaid}`
        ]);
        csvDataArray.push([
          `Frequency Claims & Expense Reserves`,
          `${fund.AFundFrequencyClaimsAndExpencesReserves}`
        ]);
        csvDataArray.push([`Group Fund BALANCE`, `$${fund.AFundBalance}`]);
        csvDataArray.push([``, ``, ``]);
        csvDataArray.push([``, ``, ``]);
        csvDataArray.push([``, ``, ``]);
        csvDataArray.push([``, ``, ``]);
        csvDataArray.push([``, ``, ``]);
        csvDataArray.push([``, ``, ``]);

        let fundAndGroup = {
          "Program": lookupProgram,
          "ProgramName": programName,
          "ProgramCode": programCode,
          "ProgramDisplay": `eCaptiv ${programCode.replace('IC', '-IC')} Inc.`,
          "MemberFundAnalysisasof": quarterDateObj[downloadQuarter] + ', ' + downloadYear,
          "PremiumYear": fund.PremiumYearFrom + " - " + fund.PremiumYearTo,
          "YearQuarter": yearQuarter,
          "ClientName": authState.clientName,
          "AFundGrossPremium": fund.AFundGrossPremium,
          "AFundFrequencyClaimsAndExpencesPaid": fund.AFundFrequencyClaimsAndExpencesPaid,
          "AFundFrequencyClaimsAndExpencesReserves": fund.AFundFrequencyClaimsAndExpencesReserves,
          "AFundBalance": fund.AFundBalance,
          //PC2 and 4 only has Group
        }
        pdfDataArray.push(fundAndGroup);

      });
      setRefresh(true);
      //exportToCSV(csvDataArray, `Member Fund ${yearQuarter}`);
      if (pdfDataArray.length > 0) GetMemberFundAnalysisPDF(pdfDataArray);//Li change to PDF
      //GetMemberFundAnalysisPDF(pdfDataArray.sort((a, b) => { return b.PremiumYear.split('-')[0] - a.PremiumYear.split('-')[0] }));//Li change to PDF
    }

  }





  // don't need Maybe?
  const replaceCommas = (str, replaceWith = " -") =>
    str ? str.replace(/,/g, replaceWith) : str;

  useEffect(() => {
    if (docName.length > 2) {
      if (trueFileName.length > 2) {
        refetch();
        setDocName("");
        setTrueFileName("");
      } else {
        toast.error("No Document Found");
      }
    }
  }, [docName, trueFileName, refetch]);

  // gets only the documents that will be financial profitability and current year selected by the select
  const sortedDocuments = documents.filter(
    (doc) =>
      doc.DocumentId.startsWith("Financial and Profitability_Financial Statements") &&
      doc.Year === Number(currentYear)
  );

  const abbrevCode = (Code) => {
    return startCase(
      Code.slice(Code.lastIndexOf("_")).replace(/_/i, "").replace(/_/i, " ")
    );
  };

  // gets the titles for the sections
  let sortedDocumentTypes = [];

  if (documentTypes.length >= 1) {
    const filteredDocumentTypes = documentTypes.filter((docType) =>
      docType.Code.startsWith("Financial and Profitability_Financial Statements")
    );
    // puts them in alphabetical order
    sortedDocumentTypes = filteredDocumentTypes.sort((a, b) =>
      abbrevCode(a.Code).localeCompare(abbrevCode(b.Code))
    );
  }

  /* remove for the policy program to be added to program dropdown
  //PC8 Unaudited and Audited 03/14/2023
  // gets only the documents that will be financial profitability and current year selected by the select
  let sortedDocuments_PC8 = documents.filter(
    (doc) =>
      doc.DocumentId.startsWith("PC8-Financial and Profitability_Financial Statements") &&
      doc.Year === Number(currentYear)
  );

  const abbrevCode_PC8 = (Code) => {
    return startCase(
      Code.slice(Code.lastIndexOf("_")).replace(/_/i, "").replace(/_/i, " ")
    );
  };

  // gets the titles for the sections
  
  let sortedDocumentTypes_PC8 = [];

  if (documentTypes.length >= 1) {
    const filteredDocumentTypes = documentTypes.filter((docType) =>
      docType.Code.startsWith("PC8-Financial and Profitability_Financial Statements")
    );
    // puts them in alphabetical order
    sortedDocumentTypes_PC8 = filteredDocumentTypes.sort((a, b) =>
      abbrevCode_PC8(a.Code).localeCompare(abbrevCode(b.Code))
    );
  }*/
  //PC8 Unaudited and Audited 03/14/2023


  // UI HEADER STUFF

  const abbrevCodeHash = {};
  if (sortedDocuments.length >= 1) {
    sortedDocuments.sort((a, b) => new Date(b.Year, b.Month, b.Day) - new Date(a.Year, a.Month, a.Day)).forEach((document) => {
      const codeArr = document.DocumentId.split("_");
      const header = codeArr[codeArr.length - 1].replace(/_/i, "");
      if (Object.keys(abbrevCodeHash).includes(header)) {
        abbrevCodeHash[header].push(document);
      } else {
        abbrevCodeHash[header] = [document];
      }
    });
  }


  /* /* remove for the policy program to be added to program dropdown
  //PC8 Unaudited and Audited 03/14/2023
  const abbrevCodeHash_PC8 = {};
  if (sortedDocuments_PC8.length >= 1 && policies.length > 0) {

    //Megan want to limit the access PC8 documents if client do not have PC8 policy. 03/22/2023
    const policyCurrentProgram = policies.filter(f => f.CategoryCode === `PC${passDownCurrentProg}IC`).sort((a, b) => new Date(b.ExpirationDate) - new Date(a.ExpirationDate))[0];


    const eariestPolicyEffCurrentProgram = new Date(Math.min(...policies.filter(f => f.CategoryCode === `PC${passDownCurrentProg}IC`).map(m => new Date(m.EffectiveDate))));
    const recentPolicyExpCurrentProgram = new Date(Math.max(...policies.filter(f => f.CategoryCode === `PC${passDownCurrentProg}IC`).map(m => new Date(m.ExpirationDate))));

    if (policyCurrentProgram) {
      const PC8PolicyCurrentProgram = policies.filter(f => f.CategoryCode === 'PC8IC'
        //&& new Date(f.EffectiveDate) >= eariestPolicyEffCurrentProgram
        //&& new Date(f.ExpirationDate) <= recentPolicyExpCurrentProgram)[0];
        //use only effectivedate to determine which PC8 policy belonging to current program is with the program period. 
        //since some case like BlackShirt company they bought the PC8 policy after they have underlying policies and expiration date is later than underlying ones' expirationDate
        && new Date(f.EffectiveDate) >= eariestPolicyEffCurrentProgram
        && new Date(f.EffectiveDate) < recentPolicyExpCurrentProgram)[0];
      if (!PC8PolicyCurrentProgram) {
        sortedDocuments_PC8 = [];
      }
    }
    else {
      sortedDocuments_PC8 = [];
    }
    //Megan want to limit the access PC8 documents if client do not have PC8 policy.03/22/2023

    sortedDocuments_PC8.sort((a, b) => new Date(b.Year, b.Month, b.Day) - new Date(a.Year, a.Month, a.Day)).forEach((document) => {
      const codeArr = document.DocumentId.split("_");
      const header = codeArr[codeArr.length - 1].replace(/_/i, "");
      if (Object.keys(abbrevCodeHash_PC8).includes(header)) {
        abbrevCodeHash_PC8[header].push(document);
      } else {
        abbrevCodeHash_PC8[header] = [document];
      }
    });
  }
  //PC8 Unaudited and Audited
  */

  // UI HEADER STUFF


  // gets only the funds that will be financial profitability and current year selected by the select
  const sortedFunds = [];
  const sortedFundsTemp = funds.filter(
    fund =>
      Number(fund.YearQuarter.toString().slice(0, 4)) === Number(currentYear)
  );
  // get rid of dublicates from the various premium years so that only one file card shows
  const uniqueFundQuartersFilter = [];
  if (sortedFundsTemp?.length > 0) {
    sortedFundsTemp.forEach(fund => {
      if (!uniqueFundQuartersFilter.includes(fund.YearQuarter)) {
        uniqueFundQuartersFilter.push(fund.YearQuarter);
        sortedFunds.push(fund);
      }
    });
  }

  // for use to set up the year hash below
  const sortedDocumentsYear = documents.filter(
    doc => doc.DocumentId.startsWith("Financial and Profitability_Financial Statements")
    //remove for the policy program to be added to program dropdown|| doc.DocumentId.startsWith("PC8-Financial and Profitability_Financial Statements")
    //PC8 unAudited and Audited 03/14/2023 added or clause
  );
  // had a remnant where a function would return a null above this hook, having a top level return creates a situation where the hooks below it coud not be rendered, which will cause failure
  // beacause hooks need to be consistently rendered
  const yearHash = useMemo(() => {
    let documentYearhash = {};
    documentYearhash["   "] = ["blank"];
    if (sortedDocumentsYear.length >= 1 || funds.length >= 1) {
      // get years with documents
      documentYearhash = {};
      if (sortedDocumentsYear.length >= 1) {
        sortedDocumentsYear.forEach(document => {
          const headerYear2 = document.Year;
          if (Object.keys(documentYearhash).includes(headerYear2)) {
            documentYearhash[headerYear2].push("blank");
          } else {
            documentYearhash[headerYear2] = ["blank"];
          }
        });
      }
      // get all years for the year select using the document
      if (funds.length >= 1) {
        funds.forEach(fund => {
          const headerYear = Number(fund.YearQuarter.toString().slice(0, 4));
          if (Object.keys(documentYearhash).includes(headerYear)) {
            documentYearhash[headerYear].push("blank");
          } else {
            documentYearhash[headerYear] = ["blank"];
          }
        });
      }
    }
    return documentYearhash;
  }, [funds, sortedDocumentsYear, yearStart]);

  const fileDownloadSetup = (fileName, DocName, docObj) => {
    if (sortedDocuments.length >= 1) {
      let fileLst = sortedDocuments.sort((a, b) => new Date(b.CreatedDateTime) - new Date(a.CreatedDateTime));
      for (let document of fileLst) {
        //if (document.FileName === fileName) {
        if (document.FilePath === docObj.FilePath) {//if the cloud is changed, could have issue here. however if solves all the file downloading problem
          setDocName2(DocName);
          setDocName(fileName);
          const filePath = document.FilePath;
          setTrueFileName(
            filePath.slice(filePath.lastIndexOf("/")).replace(/\//i, "")
          );
          break;//find the most recent one and break out
        }
      }



      /*
      sortedDocuments.forEach(document => {
        setDocName2(DocName);
        setDocName(fileName);
        if (document.FileName === fileName) {
          const filePath = document.FilePath;
          setTrueFileName(
            filePath.slice(filePath.lastIndexOf("/")).replace(/\//i, "")
          );
        }
      });*/
    }

    //PC8 unAudited and Audited 03/14/2023
    /* remove for the policy program to be added to program dropdown
    if (sortedDocuments_PC8.length >= 1) {
      let fileLst = sortedDocuments_PC8.sort((a, b) => new Date(b.CreatedDateTime) - new Date(a.CreatedDateTime));
      for (let document of fileLst) {
        //if (document.FileName === fileName) {
        if (document.FilePath === docObj.FilePath) {//if the cloud is changed, could have issue here. however if solves all the file downloading problem
          setDocName2(DocName);
          setDocName(fileName);
          const filePath = document.FilePath;
          setTrueFileName(
            filePath.slice(filePath.lastIndexOf("/")).replace(/\//i, "")
          );
          break;//find the most recent one and break out
        }
      }}*/
    //PC8 unAudited and Audited 03/14/2023

  };

  // calls refetch for the document to be downloaded, changes when trueFileName state is updated after button click, this trueFileName is used in the download query
  // after a successful refetch teh file the state is reset
  // if statements separated so that toast not called at beginning

  const formChange = e => {
    e.persist();
    // prevents below function from changing year once the user has interacted with the select
    setToggleReached(true);
    const { value } = e.target;
    setCurrentYear(value);
  };
  // creates a good variable for useMemo to run based off since yearHash.length didnt work
  // adding yearHash to dependencies will create infinite loop
  const shouldChange = Object.keys(yearHash)[Object.keys(yearHash).length - 1];
  useMemo(() => {
    if (!toggleReached) {
      const newYear = Object.keys(yearHash)[Object.keys(yearHash).length - 1];
      setCurrentYear(newYear);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldChange]);

  // data for funds disapears due to possible DoS refetch to bring it back after csv download
  if (refresh && funds.length < 1) {
    refetchAfterDownload();
    setRefresh(false);
  }

  useMemo(() => {
    refetchDocs();// Prop changed did not rerender instead of using key for the child component, use useMemo to detect and refetch docs with new program

  }, [passDownCurrentProg]);


  /*
    const deleteDocument = (fileName, docObj) => {
   
      fileName = docObj.FilePath.slice(docObj.FilePath.lastIndexOf("/")).replace(/\//i, "")
      
   
      DocumentService.DocumentDelete(fileName, docObj.RecId).then(r => {
        if (r.data.Payload) {
          refetchDocs();
        }
      })
    }*/

  //resolve the document delete issue for the year that no doc left and current year not updated when select element goes back to default Li 04/20/2023
  if (Object.keys(yearHash).length > 0) {
    let curYear = Object.keys(yearHash).find(f => f == currentYear);
    if (!curYear) {
      setCurrentYear(Number(Object.keys(yearHash).sort((a, b) => b - a)[0]));
    }
  }
  //resolve the document delete issue for the year that no doc left


  return (
    <>
      {(permissions.includes("002-004-002") || permissions.includes("002-004-003")) && (
        <Select.Container size="medium">
          <Select value={currentYear} onChange={formChange}>
            {Object.keys(yearHash)
              .sort((a, b) => b - a)
              .map(year => (
                <Select.Option key={year}>{year}</Select.Option>
              ))}
          </Select>
        </Select.Container>)}
      {/* <Text.H1>Financial Statements</Text.H1> */}
      {/* <Button.Round onClick={() => exportToCSV(csvDataArray, "Member Fund Analysis")}>Download CSV</Button.Round> */}

      {permissions.includes("002-004-002") && (
        <div className="statementContainer">
          {sortedDocuments.length < 1 ? (
            <Text.H3 style={{ margin: "1rem" }}>
              {`No eCaptiv PC${passDownCurrentProg}-IC Audited or Unaudited Files Found For this Year`}
            </Text.H3>
          ) : (
            <div className="fileGroup">
              {Object.keys(abbrevCodeHash)
                .sort((a, b) => a.localeCompare(b))
                .map((header) => (
                  <div className="outerStatementHolder" key={header}>
                    <Text.H1>{`eCaptiv PC${passDownCurrentProg}-IC ${header}`}</Text.H1>
                    <div className="innerStatementHolder">
                      {abbrevCodeHash[header]
                        .sort((a, b) => {
                          const dateA = new Date(a.Year, a.Month, a.Day);
                          const dateB = new Date(b.Year, b.Month, b.Day);
                          return dateA - dateB;
                        })
                        .map(document => (
                          <Section key={document.RecId}>
                            <div className="statementCard">
                              <FontAwesomeIcon
                                icon={faFileAlt}
                                className="fileIcon"
                                size="6x"
                              />
                              <Text.H3>{header === "Audited Financials" ? `${document.Month}/${document.Day}/${document.Year}` : `Q${document.Quarter} ${document.Year}`}</Text.H3>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  paddingBottom: "0.5rem"
                                }}
                              >
                                <Button.ExternalText
                                  onClick={e => {
                                    fileDownloadSetup(
                                      document.FileName,
                                      header === "Audited Financials" ? `${document.Month}/${document.Day}/${document.Year}` : `Q${document.Quarter} ${document.Year}`,
                                      document
                                    );
                                  }}
                                >
                                  View Financial Statements
                                </Button.ExternalText>

                                {permissions.includes("002-004-002-001") && (<DocumentDelete
                                  name=''
                                  fileName={document.FileName}
                                  docObj={document}
                                  isIcon={false}
                                  handleRefresh={() => refetchDocs()}
                                />)}
                              </div>
                            </div>
                          </Section>
                        ))}
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      )}

      {/* PC8 Audited and Unaudited 04/14/2023 */}{/* remove for mixing the policy program to program dropdown
      {(permissions.includes("002-004-002") && sortedDocuments_PC8.length > 0) && (
        <div className="statementContainer">
          {sortedDocuments_PC8.length < 1 ? (
            <Text.H3 style={{ margin: "1rem" }}>
              No eCaptiv PC8-IC Audited or Unaudited Files Found For this Year
            </Text.H3>
          ) : (
            <div className="fileGroup">
              {Object.keys(abbrevCodeHash_PC8)
                .sort((a, b) => a.localeCompare(b))
                .map((header) => (
                  <div className="outerStatementHolder" key={header}>
                    <Text.H1>eCaptiv PC8-IC {header}</Text.H1>
                    <div className="innerStatementHolder">
                      {abbrevCodeHash_PC8[header]
                        .sort((a, b) => {
                          const dateA = new Date(a.Year, a.Month, a.Day);
                          const dateB = new Date(b.Year, b.Month, b.Day);
                          return dateA - dateB;
                        })
                        .map(document => (
                          <Section key={document.RecId}>
                            <div className="statementCard">
                              <FontAwesomeIcon
                                icon={faFileAlt}
                                className="fileIcon"
                                size="6x"
                              />
                              <Text.H3>{header === "Audited Financials" ? `${document.Month}/${document.Day}/${document.Year}` : `Q${document.Quarter} ${document.Year}`}</Text.H3>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  paddingBottom: "0.5rem"
                                }}
                              >
                                <Button.ExternalText
                                  onClick={e => {
                                    fileDownloadSetup(
                                      document.FileName,
                                      header === "Audited Financials" ? `${document.Month}/${document.Day}/${document.Year}` : `Q${document.Quarter} ${document.Year}`,
                                      document
                                    );
                                  }}
                                >
                                  View Financial Statements
                                </Button.ExternalText>

                                {permissions.includes("002-004-002-001") && (
                                  <DocumentDelete
                                    name=''
                                    fileName={document.FileName}
                                    docObj={document}
                                    isIcon={false}
                                    handleRefresh={() => refetchDocs()}
                                  />)}
                              </div>
                            </div>
                          </Section>
                        ))}
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      )} */}
      {/* PC8 Audited and Unaudited 04/14/2023 */}



      {permissions.includes("002-004-003") && (
        <>
          <Text.H1>Member Fund Analysis</Text.H1>
          <div className="statementContainerMFA">
            {sortedFunds.length < 1 ? (
              // <Text.H3 style={{ margin: "1rem" }}>
              //   No Funds Found For this Year
              // </Text.H3>
              <Section>
                No Funds Found For this Year.
              </Section>
            ) : (
              <div className="fileGroup">
                {sortedFunds
                  .sort((a, b) => {
                    return a.YearQuarter - b.YearQuarter;
                  })
                  .map(fund => (
                    <Section key={fund.YearQuarter}>
                      <div className="statementCard">
                        <FontAwesomeIcon
                          icon={faFileAlt}
                          className="fileIcon"
                          size="6x"
                        />
                        <Text.H3>
                          {`Q${fund.YearQuarter.toString().slice(
                            5,
                            6
                          )} ${fund.YearQuarter.toString().slice(0, 4)}`}
                        </Text.H3>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            paddingBottom: "0.5rem"
                          }}
                        >
                          <Button.ExternalText
                            onClick={e => {
                              setLoadingBool(true);
                              //csvDownloadSetup(fund.YearQuarter);
                              downLoadMultiFiles(fund.YearQuarter)
                            }}
                          >
                            View Member Fund Analysis
                          </Button.ExternalText>
                        </div>
                        {loadingBool && (<div>
                          <Loading.SmallLoading />
                        </div>)}
                      </div>
                    </Section>
                  ))}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default FinancialStatements;

