import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import PropTypes from "prop-types";
import Loading from "../../components/Loading";
import Text from "../../components/Text";
import RoundButton from "../../components/Button/Round";
import { Table, Generic, Field, Control, Checkbox, Icon } from "rbx";

import ClaimsService from '../../services/ClaimsService'
import { useAuth } from "../../context/AuthContext";
import './LossrunBatchModal.scss';

import CustomCheckBox from '../../components/CustomCheckBox';
import ExcelJs from 'exceljs';

const LossrunBatchModal = ({
    policies,
    entity,
    toggleModal,
    handleAction,
    optionalText = false,
    actionText }) => {


    const { state: authState, permissions } = useAuth();
    const [saving, setSaving] = useState("");
    const [selectedLobs, setSelectedLobs] = useState([]);
    const [LOBs, setLOBs] = useState([]);
    const [policyIds, setPolicyIds] = useState([]);







    // batch Loss Runs
    let { data: lossRunBatch = [], isLoading: loadingBulk, refetch: refetchBatch } = useQuery(
        "lossRunReportBatch",
        () => ClaimsService.getLossRunReport(policyIds, authState.clientId),
        { enabled: false, fetchPolicy: "no-cache" }
    );

    //all claims
    const { data: claims = [], isLoading: loadingClaims, refetch: refetchClaims } = useQuery(
        "getClaimsByClient",
        () => ClaimsService.getClaimsByClient(authState.clientId),
        { fetchPolicy: "no-cache" }
    );



    useMemo(async () => {
        if (policyIds.length > 0) {
            //setLoadingBool(true);
            //await refetchBatch();
            refetchBatch();
        }
        //await setToggle(true);
    }, [policyIds]);

    useEffect(() => {

        if (
            typeof lossRunBatch?.Base64 === "string" &&
            !loadingBulk &&
            policyIds.length > 0
        ) {
            getLossRunsBatch(lossRunBatch.Base64);
            toggleModal(false, "");
        }
    }, [lossRunBatch]);

    const getLossRunsBatch2 = (byteCharacters) => {
        //setToggle(false);
        /// //////////////////////////////////////////////////////////////////////////////
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const file = new Blob([byteArray], { type: "application/pdf;base64" });
        const fileURL = URL.createObjectURL(file);

        window.open(fileURL);
        /// //////////////////////////////////////////////////////////////////////////////
    };

    const getLossRunsBatch = (b64) => {
        // eslint-disable-next-line prefer-const

        const promise1 = new Promise((resolve, reject) => {
            resolve(window.atob(b64));
        });

        promise1.then((value) => {
            getLossRunsBatch2(value);
        });
    };

    const handleOnChange = (name, val) => {

        let selectedLobsCopy = JSON.parse(JSON.stringify(selectedLobs));

        if (val) {
            if (!selectedLobsCopy.find(f => f === name)) {
                setSelectedLobs([...selectedLobs, name]);
            }
        }
        else {
            if (selectedLobsCopy.find(f => f === name)) {
                selectedLobsCopy = selectedLobsCopy.filter(f => f !== name);

                setSelectedLobs(selectedLobsCopy);
            }
        }
    }

    const handleExport = () => {

        if (selectedLobs.length > 0) {
            let policyIds = [];

            selectedLobs.sort().forEach(lob => {
                policies.find(p => p.title === lob).items.forEach(
                    policy => policyIds.push(policy.PolicyId)
                )
            }
            )
            setPolicyIds(policyIds); //policyIds change will refetch the query with updated policyIds
        }
    }

    const handleExportExcel = () => {

        if (selectedLobs.length > 0) {
            let lossruns = [];

            selectedLobs.sort().forEach(lob => {
                policies.find(p => p.title === lob).items.forEach(
                    policy => {
                        let claim = claims.filter(f => f.PolicyId === policy.PolicyId);
                        if (claim.length > 0) {
                            claim.forEach(loss => {
                                loss.CarrierName = policy.CarrierName;

                                loss.ClientName = authState.clientName;
                                loss.LOB = policy.LOB;
                                loss.PolicyEff = policy.EffectiveDate;
                                loss.PolicyExp = policy.ExpirationDate;

                                lossruns.push(loss);
                            });
                        }
                    }
                )
            });

            //add backslash and forward slash remove, to avoid the excel downloading issue
            let sheetName = authState.clientName.replace(/\//g, ' ') + "_LossRun.xlsx";


            let workbook = new ExcelJs.Workbook();
            let sheet = workbook.addWorksheet(sheetName);

            sheet.columns = [
                { header: 'ClaimNumber', key: 'ClaimNumber', width: 25 },
                { header: 'PolicyNumber', key: 'PolicyNumber', width: 25 },
                { header: 'PolicyEffDate', key: 'PolicyEffDate', width: 15 },
                { header: 'PolicyExpDate', key: 'PolicyExpDate', width: 15 },
                { header: 'eCaptivPremiumYear', key: 'eCaptivPremiumYear', width: 20 },
                { header: 'ClientName', key: 'ClientName', width: 25 },
                { header: 'CarrierName', key: 'CarrierName', width: 25 },
                { header: 'LOB', key: 'LOB', width: 25 },
                { header: 'LossDate', key: 'LossDate', width: 15 },
                { header: 'LossLocation', key: 'LossLocation', width: 55 },
                { header: 'StateCode', key: 'StateCode', width: 15 },
                { header: 'FactsofLoss', key: 'FactsofLoss', width: 85 },
                { header: 'LossType', key: 'LossType', width: 25 },
                { header: 'AdjusterFirstName', key: 'AdjusterFirstName', width: 20 },
                { header: 'AdjusterLastName', key: 'AdjusterLastName', width: 20 },
                { header: 'ValuationDate', key: 'ValuationDate', width: 15 },
                { header: 'ReportedDate', key: 'ReportedDate', width: 15 },
                { header: 'ClosedDate', key: 'ClosedDate', width: 15 },
                { header: 'StatusCode', key: 'StatusCode', width: 15 },
                { header: 'ClaimantName', key: 'ClaimantName', width: 20 },
                { header: 'DriverName', key: 'DriverName', width: 20 },
                { header: 'TotalIncurred', key: 'TotalIncurred', width: 15 },
                { header: 'LossReserve', key: 'LossReserve', width: 15 },
                { header: 'ExpenseReserve', key: 'ExpenseReserve', width: 20 },
                { header: 'ExpensePaid', key: 'ExpensePaid', width: 15 },
                { header: 'LossPaid', key: 'LossPaid', width: 15 },
                { header: 'Recovery', key: 'Recovery', width: 15 },
                { header: 'MedPaidAmt', key: 'MedPaidAmt', width: 15 },
                { header: 'MedReserveAmt', key: 'MedReserveAmt', width: 20 },
                { header: 'ExpExclMedPaidAmt', key: 'ExpExclMedPaidAmt', width: 25 },
                { header: 'ExpExclMedReserveAmt', key: 'ExpExclMedReserveAmt', width: 25 },
                { header: 'LitigationManager', key: 'LitigationManager', width: 20 },
                { header: 'SourceNm', key: 'SourceNm', width: 25 },

            ];
            sheet.getRow(1).height = 25;
            sheet.getRow(1).eachCell({ inculdeEMpty: true }, (cell) => {
                if (cell?.value) {
                    cell.fill =
                    {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '808080' },
                        bgColor: { argb: '808080' }
                    };

                    cell.border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    };

                    cell.alignment = { wrapText: true };
                    cell.style.font = { bold: true, color: { argb: 'FFFFFFFF' } };
                }
            });

            if (lossruns.length === 0) {
                sheet.getRow(2).getCell(1).value = 'No Loss';
            }

            lossruns.forEach(item => {
                sheet.addRow(
                    {
                        ClaimNumber: item.ClaimNumber,
                        PolicyNumber: item.PolicyNumber,
                        PolicyEffDate: item.PolicyEff?.split("T")[0],
                        PolicyExpDate: item.PolicyExp?.split("T")[0],
                        eCaptivPremiumYear: item.eCaptivPremiumYear,
                        ClientName: item.ClientName,
                        LossDate: item.LossDate?.split("T")[0],
                        CarrierName: item.CarrierName,
                        LOB: item.LOB,
                        StateCode: item.StateCode,
                        ValuationDate: item.ValuationDate?.split("T")[0],
                        ReportedDate: item.ReportedDate?.split("T")[0],
                        ClosedDate: item.ClosedDate?.split("T")[0],
                        StatusCode: item.StatusCode,
                        ClaimantName: item.ClaimantName,
                        DriverName: item.DriverName,
                        TotalIncurred: item.TotalIncurred,
                        LossReserve: item.LossReserve,
                        ExpenseReserve: item.ExpenseReserve,
                        ExpensePaid: item.ExpensePaid,
                        LossPaid: item.LossPaid,
                        Recovery: item.Recovery,
                        FactsofLoss: item.FactsofLoss,
                        LossType: item.LossType,
                        LossLocation: item.LossLocation,
                        AdjusterFirstName: item.AdjusterFirstName,
                        AdjusterLastName: item.AdjusterLastName,
                        MedPaidAmt: item.MedPaidAmt,
                        MedReserveAmt: item.MedReserveAmt,
                        ExpExclMedPaidAmt: item.ExpExclMedPaidAmt,
                        ExpExclMedReserveAmt: item.ExpExclMedReserveAmt,
                        LitigationManager: item.LitigationManagerNm,
                        SourceNm: item.SourceNm
                    }
                );
            });

            workbook.xlsx.writeBuffer().then((buffer) => {
                const link = document.createElement("a");
                const blob = new Blob([buffer], {
                    type: "application/vnd.ms-excel;charset=utf-8;"
                });
                link.download = sheetName;
                link.href = URL.createObjectURL(blob);
                link.click();
            });
        };
    }

    const stopLabelPropagationDown = (e) => { //prevent from checking the checkbox when clicking on the label 
        if (e.target.localName === 'label') e.preventDefault();
    }


    return (
        <div className="modalContainer">
            {
                loadingBulk && (
                    <div className="headerContainer">
                        <Loading.SmallLoading />
                    </div>
                )
            }
            <div id="LossrunBatchModalContainer">
                <Text.H3>
                    {optionalText ||
                        `${entity} ?`}
                </Text.H3>
                <div id='SelectiontableContainer' >
                    <div className="tableContainer" style={{ display: 'flex', flexDirection: 'column' }}>
                        {policies.map((lob) => (
                            <div className="table-item" key={lob.title}>
                                {/* <label onClick={(e) => stopLabelPropagationDown(e)}>
                                    <Checkbox className="inputCheckBox" name={lob.title} onChange={(e) => handleOnChange(e.target.name, e.target.checked)} />
                                    <span>{lob.title}</span>
                                </label> */}

                                <CustomCheckBox name={lob.title} labelInSpanName={lob.title} onChange={handleOnChange} />
                            </div>
                        ))}
                    </div>
                </div>

                <div className="buttonContainer">
                    <RoundButton
                        classNames={["secondary"]}
                        onClick={() => {
                            toggleModal(false, "")
                        }}
                    >
                        Cancel
                    </RoundButton>
                    <RoundButton
                        classNames={selectedLobs.length == 0 ? ['disabled', 'noPermButton'] : ['primary']}
                        disabled={selectedLobs.length == 0 ? true : false}
                        onClick={(e) => handleExport()}>{actionText + ' PDF'}
                    </RoundButton>
                    <RoundButton
                        classNames={(!permissions.includes('004-001-006') || selectedLobs.length == 0) ? ['disabled', 'noPermButton'] : ['primary']}
                        disabled={!permissions.includes('004-001-006') || selectedLobs.length == 0} //use same permission as individual lossrun does

                        onClick={(e) => handleExportExcel()}>{actionText + ' Excel'}
                    </RoundButton>
                </div>
                <div>
                    <p>Displays the last five years plus current term if available.</p>
                </div>
            </div></div>
    )
};

/*
LossrunBatchModal.propTypes = {
  entity: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleAction: PropTypes.func.isRequired,
  optionalText: PropTypes.string,
  actionText: PropTypes.string.isRequired,
};
LossrunBatchModal.defaultProps = {
  optionalText: "",
};*/

export default LossrunBatchModal;
