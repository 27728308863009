import React, { useMemo } from "react";
import { useQuery } from "react-query";
import { groupBy, find, sortBy } from "lodash";
import { useAuth } from "../../../context/AuthContext";
import GovernanceService from "../../../services/GovernanceService";
import ClientService from "../../../services/ClientService";
import Section from "../../../components/Section";
import Text from "../../../components/Text";
import Loading from "../../../components/Loading";

import "./MembershipDirectory.scss";

const OfficerAndCommitteePage = ({ passDownCurrentProg, changeCurrentProgram }) => {
  // COMMITTEE is misspelled with one t on purpose to match endpoint and tables do not correct
  const { state: authState } = useAuth();

  // gets all clients from program set in authState
  const {
    data: officerAndCommiteeSlates = [],
    isLoadingOfficerAndCommiteeSlates,
    errorOfficerAndCommiteeSlates,
    refetch: refetchOfficerAndCommiteeSlates
  } = useQuery("officerAndCommiteeSlates", () =>
    GovernanceService.getOfficerAndCommiteeSlates(passDownCurrentProg)
  );

  const officerAndCommiteeSlatesData2 = sortBy(
    officerAndCommiteeSlates,
    [(o) => { return o.Type.toLowerCase(); }]
  );

  const officerAndCommiteeSlatesData = groupBy(
    officerAndCommiteeSlatesData2,
    "Type"
  );


  const {
    data: clients = [],
    isLoadingClients,
    errorClients,
    refetch: refetchClientsByProgram
  } = useQuery("clientsByProgram", () =>
    ClientService.getClientsByProgram(passDownCurrentProg)
  );

  // console.log("Clients", clients);
  useMemo(() => {
    refetchClientsByProgram();// Prop changed did not rerender instead of using key for the child component, use useMemo to detect and retech docs with new program
    refetchOfficerAndCommiteeSlates();
  }, [passDownCurrentProg]);

  if (isLoadingOfficerAndCommiteeSlates || isLoadingClients) {
    return (
      <div className="headerContainer">
        <Text.H1>Claims</Text.H1>
        <Loading.SmallLoading />
      </div>
    );
  }
  if (errorOfficerAndCommiteeSlates || errorClients) {
    return null;
  }

  return (
    <div id="DirectoryContainer">
      {Object.keys(officerAndCommiteeSlatesData).length ? (
        Object.keys(officerAndCommiteeSlatesData).map(group => (
          <div className="tableSection">
            <Text.H1>{group}</Text.H1>
            <Section id="memberReferences">
              <div className="tableContainer">
                {officerAndCommiteeSlatesData[group].map(person => {
                  const personClient = find(clients, {
                    ClientId: person.ClientId
                  });

                  return (
                    <div className="table-item" key={person.RecId}>
                      <div>
                        {personClient
                          ? personClient.ClientName
                          : person.Company}
                      </div>
                      <div>
                        {person.FirstName}
                        {' '}
                        {person.LastName}
                      </div>
                    </div>
                  );
                })}
              </div>
            </Section>
          </div>
        ))
      ) : (
        <Section>None Found.</Section>
      )}
    </div>
  );
};

export default OfficerAndCommitteePage;
