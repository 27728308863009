import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";

import { Field, Label, Control, Input, Checkbox, Select, Textarea } from "rbx";
import Section from "../../../../components/Section";
import Text from "../../../../components/Text";
import Button from "../../../../components/Button";
import { StateSelect, TextFieldInput } from "../../../../components/inputs";
import STATES from "../../../../components/inputs/StateSelect/states"; //"../../../ states";
import validator from 'validator';

const OtherVehicles = (Props) => {

  //   if (Props.currentState.currentStep !== 3) {
  //     return null;
  //   }

  const [otherVehiclesState, setOtherVehiclesState] = useState({});
  let otherVehiclesControls = Props.otherVehiclesControls;

  // Use the submitted data to set the state
  const handleChange = (name, value, key, ob) => {
    setOtherVehiclesState({ ...otherVehiclesState, ...ob });
  }

  //useEffect to update the parent state after additionalInfo state is finished the change due to asyn useState
  useEffect(() => {
    /*
    const newVehicles = Props.currentState.lossInfoDetail.otherVehicles.map(vehicle => {
      if (vehicle.otherVehicleId === Props.count - 1) {
        if (otherVehiclesState) {
          vehicle = { ...vehicle, ...otherVehiclesState }
        }
      }
      return vehicle;
    });*/


    //Props.handleChange('', '', 'lossInfoDetail', 'otherVehicles', newVehicles);

    setOtherVehiclesState(Props.currentState.lossInfoDetail.otherVehicles[Props.count]);

  }, [Props.currentState.lossInfoDetail.otherVehicles]);

  useEffect(() => {
    let newOtherVehLst = Props.currentState.lossInfoDetail.otherVehicles.map(
      item => {
        if (otherVehiclesState && item.otherVehicleId === otherVehiclesState.otherVehicleId) {
          item = otherVehiclesState
        }
        return item;
      }
    );

    if (otherVehiclesState) { Props.handleChange('', '', 'lossInfoDetail', { 'otherVehicles': newOtherVehLst }); }

  }, [otherVehiclesState]);




  return (
    <>
      {/* <Text.H3>Loss Info</Text.H3> */}
      {/* <TextFieldInput
                label="Company"
                name="company"
                value={Props.currentState.personalInfoDetail.company}
                onChange={Props.handleChange}
            /> */}


      <h4 style={{ 'fontWeight': 700, 'fontSize': '1.3em' }}>{`Other Vehicle ${Props.count + 1}`}</h4>
      <div id='OLOtherPartyInfoContainer'>
        {
          otherVehiclesControls.map(item => {
            if (item.ControlTypeCode === 'textbox') {
              return (<Field horizontal>
                <Field.Label size="normal">
                  <Label>{item.ControlLabel}</Label>
                </Field.Label>
                <Field.Body>
                  <Field>

                    <Input type="text" name={item.ControlLabelCode} value={otherVehiclesState ? otherVehiclesState[item.ControlLabelCode] : ''}
                      onChange={(e) => { handleChange(e.target.name, e.target.value, 'otherVehicles', { [e.target.name]: e.target.value }) }} />
                    {(otherVehiclesState && item.ControlLabelCode?.toLowerCase()?.includes('phone')) &&
                      (<><br />
                        <span
                          style={{
                            color: "red"
                          }}
                        >
                          {!otherVehiclesState[item.ControlLabelCode] ||
                            validator.isMobilePhone(otherVehiclesState[item.ControlLabelCode], 'en-US')
                            ? '' : 'Please enter vaild phone.'}
                        </span></>)
                    }

                  </Field>
                </Field.Body>
              </Field>)
            }
            else if (item.ControlTypeCode === 'dropdown') {
              return (
                <Field horizontal>
                  <Field.Label size="normal">
                    <Label>{item.ControlLabel}</Label>
                  </Field.Label>
                  <Field.Body>
                    <Field>
                      <Select.Container fullwidth>
                        <Select
                          name={item.ControlLabelCode}
                          value={otherVehiclesState ? otherVehiclesState[item.ControlLabelCode] : ''}
                          onChange={(e) => { handleChange(e.target.name, e.target.value, 'otherVehicles', { [e.target.name]: e.target.value }) }}
                          //disabled={disabled || readOnly}
                          className="selectField"
                        >
                          <Select.Option value="">State</Select.Option>
                          {Object.keys(STATES).map((key) => (
                            <Select.Option key={key} value={key}>
                              {key}
                            </Select.Option>
                          ))}
                        </Select>
                      </Select.Container>
                    </Field>
                  </Field.Body>
                </Field>
              )
            }
            else if (item.ControlTypeCode === 'calendar') {
              return (
                <Field horizontal>
                  <Field.Label size="normal">
                    <Label>{item.ControlLabel} {item.IsRequired && (<span className="requiredStar"> *</span>)}</Label>
                  </Field.Label>
                  <Field.Body>
                    <Field>
                      <Control>
                        <Input
                          type="date"
                          name={item.ControlLabelCode}
                          value={Props.currentState.lossInfoDetail.otherVehicles[item.ControlLabelCode]}
                          onChange={(e) => { Props.handleChange(e.target.name, e.target.value, 'otherVehicles', { [e.target.name]: e.target.value }) }}
                        />
                      </Control>
                    </Field>
                  </Field.Body>
                </Field>)
            }
            else if (item.ControlTypeCode === 'textarea') {
              return (
                <Field horizontal>
                  <Field.Label size="normal">
                    <Label>{item.ControlLabel} {item.IsRequired && (<span className="requiredStar"> *</span>)}</Label>
                  </Field.Label>
                  <Field.Body>
                    <Field>
                      <Control>
                        <Textarea
                          name={item.ControlLabelCode}
                          value={Props.currentState.lossInfoDetail.otherVehicles[item.ControlLabelCode]}
                          onChange={(e) => { Props.handleChange(e.target.name, e.target.value, 'otherVehicles', { [e.target.name]: e.target.value }) }}
                        />
                      </Control>
                    </Field>
                  </Field.Body>
                </Field>)
            }
          }
          )

        }


      </div>


    </>
  );
}

export default OtherVehicles;




