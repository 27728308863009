import APIService from "./index";

const AssessmentAndDistributionSerivce = {
    getAssessment: (clientId) =>
        APIService.get("/GetAssessment", { clientId }).then(
            (res) => res.data.Payload
        ),
    getAFundDistribution: (clientId) =>
        APIService.get("/GetAFundDistribution", { clientId }).then(
            (res) => res.data.Payload
        ),
};

// .then((res) =>
//       res.data.Payload.reduce(
//         (acc, curr) => [...acc, ...curr.Collaterals],
//         []
//       )
//     ),

// .filter((collateral) => new Date(collateral.ExpDate) > new Date())
export default AssessmentAndDistributionSerivce;
