import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Generic } from "rbx";

import "./Text.scss";

export default function Text({ children, disabled, ...props }) {
  return disabled ? (
    <Generic
      as="span"
      tooltip="You do not have permission to access to this element."
      tooltipPosition="left"
      className="textButton isDisabled"
    >
      {children}
    </Generic>
  ) : (
    <Link {...props} className="textButton">
      {children}
    </Link>
  );
}

Text.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

Text.defaultProps = {
  disabled: false,
};

Text.displayName = "TextButton";
