import React from "react";

// import Section from "../../components/Section";
// import Text from "../../components/Text";
// import Button from "../../components/Button";
import ColorDivider from "../Divider"
import "./TwoColumn.scss";

// data comes in and has specific class names which come through with the data
// this will be used for special formatting, like bold and buttons
const TwoColumnData = ({data}) => {
  return (
    <div id="TwoColumnDataContainer">
      <ColorDivider /> 
      <div className="tableSection">
        {data.items.map((item) => {
        return(
          <div className="table-item">
            <div className={item.leftClass}>{item.left}</div>
            <div className={item.rightClass}>{!(typeof item.right === "undefined" || item.right ==="") ? item.right : "--"}</div>
          </div>  
        )})}
      </div>
    </div>
  );
};

TwoColumnData.propTypes = {};

export default TwoColumnData;
