import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";

import { Field, Label, Control, Input, Checkbox, Select, Textarea } from "rbx";
import Section from "../../../../components/Section";
import Text from "../../../../components/Text";
import Button from "../../../../components/Button";
import { StateSelect, TextFieldInput } from "../../../../components/inputs";
import STATES from "../../../../components/inputs/StateSelect/states"; //"../../../ states";
import AdditionalInfo from "./AdditinalInfo";
import AdditionalInfo_WC from "./AdditionalInfo_WC";
import validator from 'validator';

let goNext = () => { }
const LossInfo = (Props) => {

  let LossInfoControls = Props.lossInfoControls;
  //let NameOfContact = LossInfoControls.find(f => f.ControlLabelCode === 'NameOfContact');
  let FirstNameOfContact = LossInfoControls.find(f => f.ControlLabelCode === 'FirstNameOfContact');
  let MiddleNameOfContact = LossInfoControls.find(f => f.ControlLabelCode === 'MiddleNameOfContact');
  let LastNameOfContact = LossInfoControls.find(f => f.ControlLabelCode === 'LastNameOfContact');
  let Phone = LossInfoControls.find(f => f.ControlLabelCode === 'Phone');
  let DateOfLoss = LossInfoControls.find(f => f.ControlLabelCode === 'DateOfLoss');
  let TimeOfLoss = LossInfoControls.find(f => f.ControlLabelCode === 'TimeOfLoss');
  let LocationOfOccurrence = LossInfoControls.find(f => f.ControlLabelCode === 'LocationOfOccurrence');
  let City = LossInfoControls.find(f => f.ControlLabelCode === 'City');
  let State = LossInfoControls.find(f => f.ControlLabelCode === 'State');
  let ZipCode = LossInfoControls.find(f => f.ControlLabelCode === 'ZipCode');
  let DescriptionOfOccurrence = LossInfoControls.find(f => f.ControlLabelCode === 'DescriptionOfOccurrence');
  let ReportOnly = LossInfoControls.find(f => f.ControlLabelCode === 'ReportOnly');

  let notRequiredFields = LossInfoControls.filter(f => !f.IsRequired);

  //07-26-2023 add validation
  const [isAllValid, setIsAllValid] = useState(true);

  useEffect(() => {
    //07-26-2023 add validation

    if (Props.currentState.lossInfoDetail[Phone?.ControlLabelCode] && !validator.isMobilePhone(Props.currentState.lossInfoDetail[Phone?.ControlLabelCode], 'en-US')) {
      setIsAllValid(false)
    }
    else {
      setIsAllValid(true)
    }
    //add validation for other fields here when needed
    //07-26-2023 add validation

    goNext();
  }, [Props.currentState.lossInfoDetail]);

  if (Props.currentState.currentStep !== 3) {
    return null;
  }

  //temp solution to disable next button when required fields not filled
  var nextBtn = document.getElementsByTagName('button');
  nextBtn = Array.from(nextBtn).filter(item => item.innerHTML === 'Next');




  let anyEmpty = Object.keys(Props.currentState.lossInfoDetail)
    .find(f => (f !== 'additionalInfo' && f !== 'otherVehicles' &&
      //(Props.currentState.lossInfoDetail[f] === null || Props.currentState.lossInfoDetail[f] === '' || Props.currentState.lossInfoDetail[f] === undefined)));
      (!Props.currentState.lossInfoDetail[f]) && !notRequiredFields.find(ff => ff.ControlLabelCode === f)));// last expression is to filter out the non required fields



  //08/16/2023 add works comp addtionional required fields validation
  if (Props.currentState.claimTypeDetail.claimType === 'WorkersCompensation') {
    let notRequiredFields_Additional_WC = Props.additionalInfo_WCControls.filter(f => !f.IsRequired || f.ControlTypeCode === 'section');
    let anyEmpty_Addtional_WC = Object.keys(Props.currentState.lossInfoDetail.additionalInfo_WC)
      .find(f =>
        (!Props.currentState.lossInfoDetail.additionalInfo_WC[f]) && !notRequiredFields_Additional_WC.find(ff => ff.ControlLabelCode === f)
      );// last expression is to filter out the non required fields

    if (anyEmpty_Addtional_WC) {
      anyEmpty = true;
    }
  }





  let noLossInfoBasic = Object.keys(Props.currentState.lossInfoDetail).length === 2;

  if ((nextBtn.length > 0 && (anyEmpty || noLossInfoBasic)) || !isAllValid) {//07-26-2023 add validation isAllValid
    nextBtn[0].disabled = true;
    nextBtn[0].classList.add("disabled");
  }
  else if (nextBtn.length > 0 && !anyEmpty && !noLossInfoBasic && isAllValid) {//07-26-2023 add validation isAllValid
    nextBtn[0].disabled = false;
    nextBtn[0].classList.remove("disabled");
  }




  goNext = () => {
    if (!anyEmpty && isAllValid) {//07-26-2023 add validation isAllValid
      if (!nextBtn[0]) return;
      nextBtn[0].disabled = false;
      nextBtn[0].classList.remove("disabled");
    }

  }
  //temp solution to disable next button when required fields not filled


  return (
    <>
      <Text.H3>Loss Info</Text.H3>
      <div id='OLLossInfoContainer'>
        {/* <Field horizontal>
          <Field.Label size="normal">
            <Label>{NameOfContact?.ControlLabel} {NameOfContact?.IsRequired && (<span className="requiredStar"> *</span>)}</Label>
          </Field.Label>
          <Field.Body>
            <Field>
              <Input type="text" name={NameOfContact?.ControlLabelCode} value={Props.currentState.lossInfoDetail[NameOfContact?.ControlLabelCode]} onChange={(e) => { Props.handleChange(e.target.name, e.target.value, 'lossInfoDetail', { [e.target.name]: e.target.value }) }} />
            </Field>
          </Field.Body>
        </Field> */}



        <Field horizontal>
          <Field.Label size="normal">
            <Label>{FirstNameOfContact?.ControlLabel} {FirstNameOfContact?.IsRequired && (<span className="requiredStar"> *</span>)}</Label>
          </Field.Label>
          <Field.Body>
            <Field>
              <Input type="text" name={FirstNameOfContact.ControlLabelCode} value={Props.currentState.lossInfoDetail[FirstNameOfContact.ControlLabelCode]}
                onChange={(e) => { Props.handleChange(e.target.name, e.target.value, 'lossInfoDetail', { [e.target.name]: e.target.value }) }} />
            </Field>
          </Field.Body>

          <Field.Label size="normal">
            <Label>{MiddleNameOfContact?.ControlLabel} {MiddleNameOfContact?.IsRequired && (<span className="requiredStar"> *</span>)}</Label>
          </Field.Label>
          <Field.Body>
            <Field>
              <Input type="text" name={MiddleNameOfContact.ControlLabelCode} value={Props.currentState.lossInfoDetail[MiddleNameOfContact.ControlLabelCode]}
                onChange={(e) => { Props.handleChange(e.target.name, e.target.value, 'lossInfoDetail', { [e.target.name]: e.target.value }) }} />
            </Field>
          </Field.Body>

          <Field.Label size="normal">
            <Label>{LastNameOfContact?.ControlLabel} {LastNameOfContact?.IsRequired && (<span className="requiredStar"> *</span>)}</Label>
          </Field.Label>
          <Field.Body>
            <Field>
              <Input type="text" name={LastNameOfContact.ControlLabelCode} value={Props.currentState.lossInfoDetail[LastNameOfContact.ControlLabelCode]}
                onChange={(e) => { Props.handleChange(e.target.name, e.target.value, 'lossInfoDetail', { [e.target.name]: e.target.value }) }} />
            </Field>
          </Field.Body>
        </Field>

        <Field horizontal>
          <Field.Label size="normal">
            <Label>{Phone?.ControlLabel} {Phone?.IsRequired && (<span className="requiredStar"> *</span>)}</Label>
          </Field.Label>
          <Field.Body>
            <Field>
              <Input type="text" name={Phone.ControlLabelCode} value={Props.currentState.lossInfoDetail[Phone.ControlLabelCode]}
                onChange={(e) => { Props.handleChange(e.target.name, e.target.value, 'lossInfoDetail', { [e.target.name]: e.target.value }) }} />
              <br />
              <span
                style={{
                  color: "red"
                }}
              >
                {!Props.currentState.lossInfoDetail[Phone.ControlLabelCode] || validator.isMobilePhone(Props.currentState.lossInfoDetail[Phone.ControlLabelCode], 'en-US')
                  ? '' : 'Please enter vaild phone.'}
              </span>

            </Field>
          </Field.Body>
        </Field>
        <Field horizontal>
          <Field.Label size="normal">
            <Label>{DateOfLoss.ControlLabel} {DateOfLoss.IsRequired && (<span className="requiredStar"> *</span>)}</Label>
          </Field.Label>
          <Field.Body>
            <Field>
              <Control>
                <Input
                  type="date"
                  max={new Date().toISOString().split('T')[0]}
                  name={DateOfLoss.ControlLabelCode}
                  value={Props.currentState.lossInfoDetail[DateOfLoss.ControlLabelCode]}
                  onChange={(e) => { Props.handleChange(e.target.name, e.target.value, 'lossInfoDetail', { [e.target.name]: e.target.value }) }}
                  //readOnly={true}
                  onKeyDown={(e) => e.preventDefault()}
                />
              </Control>
            </Field>
          </Field.Body>
          <Field.Label size="normal">
            <Label>{TimeOfLoss.ControlLabel} {TimeOfLoss.IsRequired && (<span className="requiredStar"> *</span>)}</Label>
          </Field.Label>
          <Field.Body>
            <Field>
              <Control>
                <Input
                  type="time"
                  name={TimeOfLoss.ControlLabelCode}
                  value={Props.currentState.lossInfoDetail[TimeOfLoss.ControlLabelCode]}
                  onChange={(e) => { Props.handleChange(e.target.name, e.target.value, 'lossInfoDetail', { [e.target.name]: e.target.value }) }}
                />
              </Control>
            </Field>
          </Field.Body>
        </Field>
        <Field horizontal>
          <Field.Label size="normal">
            <Label>{LocationOfOccurrence?.ControlLabel} {LocationOfOccurrence?.IsRequired && (<span className="requiredStar"> *</span>)}</Label>
          </Field.Label>
          <Field.Body>
            <Field>
              <Input type="text" name={LocationOfOccurrence.ControlLabelCode} value={Props.currentState.lossInfoDetail[LocationOfOccurrence.ControlLabelCode]} onChange={(e) => { Props.handleChange(e.target.name, e.target.value, 'lossInfoDetail', { [e.target.name]: e.target.value }) }} />
            </Field>
          </Field.Body>
        </Field>
        <Field horizontal>
          <Field.Label size="normal">
            <Label>{City?.ControlLabel} {City?.IsRequired && (<span className="requiredStar"> *</span>)}</Label>
          </Field.Label>
          <Field.Body>
            <Field>
              <Input type="text" name={City.ControlLabelCode} value={Props.currentState.lossInfoDetail[City.ControlLabelCode]} onChange={(e) => { Props.handleChange(e.target.name, e.target.value, 'lossInfoDetail', { [e.target.name]: e.target.value }) }} />
            </Field>
          </Field.Body>

          <Field.Label size="normal">
            <Label>{State.ControlLabel} {State.IsRequired && (<span className="requiredStar"> *</span>)}</Label>
          </Field.Label>
          <Field.Body>
            <Field>
              <Select.Container fullwidth>
                <Select
                  name={State.ControlLabelCode}
                  value={Props.currentState.lossInfoDetail[State.ControlLabelCode]}
                  onChange={(e) => { Props.handleChange(e.target.name, e.target.value, 'lossInfoDetail', { [e.target.name]: e.target.value }) }}
                  className="selectField">
                  <Select.Option value="">State</Select.Option>
                  {Object.keys(STATES).map((key) => (
                    <Select.Option key={key} value={key}>
                      {key}
                    </Select.Option>
                  ))}
                </Select>
              </Select.Container>
            </Field>
          </Field.Body>
          <Field.Label size="normal">
            <Label>{ZipCode?.ControlLabel} {ZipCode?.IsRequired && (<span className="requiredStar"> *</span>)}</Label>
          </Field.Label>
          <Field.Body>
            <Field>
              <Input type="text" name={ZipCode.ControlLabelCode} value={Props.currentState.lossInfoDetail[ZipCode.ControlLabelCode]} onChange={(e) => { Props.handleChange(e.target.name, e.target.value, 'lossInfoDetail', { [e.target.name]: e.target.value }) }} />
            </Field>
          </Field.Body>
        </Field>
        <Field horizontal>
          <Field.Label size="normal">
            <Label>{DescriptionOfOccurrence.ControlLabel} {DescriptionOfOccurrence.IsRequired && (<span className="requiredStar"> *</span>)}</Label>
          </Field.Label>
          <Field.Body>
            <Field>
              <Control>
                <Textarea
                  name={DescriptionOfOccurrence.ControlLabelCode}
                  value={Props.currentState.lossInfoDetail[DescriptionOfOccurrence.ControlLabelCode]}
                  onChange={(e) => { Props.handleChange(e.target.name, e.target.value, 'lossInfoDetail', { [e.target.name]: e.target.value }) }}
                />
              </Control>
            </Field>
          </Field.Body>
        </Field>
        <Field horizontal>
          <Field.Label size="normal">
            <Label>{ReportOnly.ControlLabel} {ReportOnly.IsRequired && (<span className="requiredStar"> *</span>)}</Label>
          </Field.Label>
          <Field.Body>
            <Checkbox className="inputCheckBox"
              name={ReportOnly.ControlLabelCode}
              onChange={(e) => { Props.handleChange(e.target.name, e.target.value, 'lossInfoDetail', { [e.target.name]: e.target.checked }) }}
              checked={Props.currentState.lossInfoDetail[ReportOnly.ControlLabelCode]} />
          </Field.Body>
        </Field>

      </div>
      <div>
        {Props.currentState.claimTypeDetail.claimType === 'Auto'
          && (<AdditionalInfo currentState={Props.currentState} handleChange={Props.handleChange} additionalInfoControls={Props.additionalInfoControls} otherVehiclesControls={Props.otherVehiclesControls} />)}
      </div>
      <div>
        {Props.currentState.claimTypeDetail.claimType === 'WorkersCompensation'
          && (<AdditionalInfo_WC currentState={Props.currentState} handleChange={Props.handleChange} additionalInfo_WCControls={Props.additionalInfo_WCControls}
            controlDisplayValues={Props.controlDisplayValues}
            controlDependencies={Props.controlDependencies} />)}
      </div>

    </>

  );

  //dynamtic rendering, commented out due to the different layout requested
  // return (
  //   <>
  //     <Text.H3>Loss Info</Text.H3>
  //     <div id='OLLossInfoContainer'>
  //       {
  //         LossInfoControls.map(item => {
  //           if (item.ControlTypeCode === 'textbox') {
  //             return (<Field horizontal>
  //               <Field.Label size="normal">
  //                 <Label>{item.ControlLabel} {item.IsRequired && (<span className="requiredStar"> *</span>)}</Label>
  //               </Field.Label>
  //               <Field.Body>
  //                 <Field>
  //                   <Input type="text" name={item.ControlLabelCode} value={Props.currentState.lossInfoDetail[item.ControlLabelCode]} onChange={(e) => { Props.handleChange(e.target.name, e.target.value, 'lossInfoDetail', { [e.target.name]: e.target.value }) }} />
  //                 </Field>
  //               </Field.Body>
  //             </Field>)
  //           }
  //           else if (item.ControlTypeCode === 'dropdown') {
  //             return (
  //               <Field horizontal>
  //                 <Field.Label size="normal">
  //                   <Label>{item.ControlLabel} {item.IsRequired && (<span className="requiredStar"> *</span>)}</Label>
  //                 </Field.Label>
  //                 <Field.Body>
  //                   <Field>
  //                     <Select.Container fullwidth>
  //                       <Select
  //                         name={item.ControlLabelCode}
  //                         value={Props.currentState.lossInfoDetail[item.ControlLabelCode]}
  //                         onChange={(e) => { Props.handleChange(e.target.name, e.target.value, 'lossInfoDetail', { [e.target.name]: e.target.value }) }}
  //                         className="selectField">
  //                         <Select.Option value="">State</Select.Option>
  //                         {Object.keys(STATES).map((key) => (
  //                           <Select.Option key={key} value={key}>
  //                             {key}
  //                           </Select.Option>
  //                         ))}
  //                       </Select>
  //                     </Select.Container>
  //                   </Field>
  //                 </Field.Body>
  //               </Field>
  //             )
  //           }
  //           else if (item.ControlTypeCode === 'calendar') {
  //             return (
  //               <Field horizontal>
  //                 <Field.Label size="normal">
  //                   <Label>{item.ControlLabel} {item.IsRequired && (<span className="requiredStar"> *</span>)}</Label>
  //                 </Field.Label>
  //                 <Field.Body>
  //                   <Field>
  //                     <Control>
  //                       <Input
  //                         type="datetime-local"
  //                         name={item.ControlLabelCode}
  //                         value={Props.currentState.lossInfoDetail[item.ControlLabelCode]}
  //                         onChange={(e) => { Props.handleChange(e.target.name, e.target.value, 'lossInfoDetail', { [e.target.name]: e.target.value }) }}
  //                       />
  //                     </Control>
  //                   </Field>
  //                 </Field.Body>
  //               </Field>)
  //           }
  //           else if (item.ControlTypeCode === 'textarea') {
  //             return (
  //               <Field horizontal>
  //                 <Field.Label size="normal">
  //                   <Label>{item.ControlLabel} {item.IsRequired && (<span className="requiredStar"> *</span>)}</Label>
  //                 </Field.Label>
  //                 <Field.Body>
  //                   <Field>
  //                     <Control>
  //                       <Textarea
  //                         name={item.ControlLabelCode}
  //                         value={Props.currentState.lossInfoDetail[item.ControlLabelCode]}
  //                         onChange={(e) => { Props.handleChange(e.target.name, e.target.value, 'lossInfoDetail', { [e.target.name]: e.target.value }) }}
  //                       />
  //                     </Control>
  //                   </Field>
  //                 </Field.Body>
  //               </Field>)
  //           }
  //         }
  //         )
  //       }
  //     </div>
  //     <div>

  //       {Props.currentState.claimTypeDetail.claimType === 'Auto'
  //         && (<AdditionalInfo currentState={Props.currentState} handleChange={Props.handleChange} additionalInfoControls={Props.additionalInfoControls} otherVehiclesControls={Props.otherVehiclesControls} />)}
  //     </div>
  //   </>
  // );
}

export default LossInfo;




