export default {
  name: "Risk Management",
  code: "003",
  links: [
    {
      name: "Loss Prevention & Safety",
      code: "003-001",
      to: "/risk-management/loss-prevention-&-safety",
    },
    {
      name: "Telematics Exchange",
      code: "003-002",
      to: "/risk-management/telematics-exchange",
    },
    {
      name: "eLive Connect",
      code: "003-003",
      to: "/risk-management/elive-connect",
    },
    {
      name: "Motor Vehicle Records",
      code: "003-004",
      to: "/risk-management/motor-vehicle-records",
    },
    // todo for phase2: add to objects table anf give code 003-005
    // {
    //   name: "FMCSA",
    //   code: "003-005",
    //   to: "/risk-management/fmcsa",
    // },
  ],
};
