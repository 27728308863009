import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import DocumentsLibraryPage from "./DocumentsLibrary";
import GovernanceRouter from "./Governance";
import MemberKioskRouter from "./MemberKiosk";
import FinancialProfitabilityPage from "./FinancialProfitability";
import ProgramInformationPage from "./ProgramInformation";
import PaymentCollateral from "./PaymentCollateral";
import { useAuth } from "../../context/AuthContext";

const InsuranceRouter = () => {
  const { permissions } = useAuth();
  return (
    <Switch>
      {permissions.includes("002-001") && (
        // <Route path="/insurance/documents-library"> 06-24-2024 Megan requested to change to Policy library
        <Route path="/insurance/policy-library">

          <DocumentsLibraryPage />
        </Route>
      )}
      {permissions.includes("002-002") && (
        <Route path="/insurance/governance">
          <GovernanceRouter />
        </Route>
      )}
      {permissions.includes("002-003") && (
        <Route path="/insurance/member-kiosk">
          <MemberKioskRouter />
        </Route>
      )}
      {permissions.includes("002-004") && (
        <Route path="/insurance/financial-&amp;-profitability">
          <FinancialProfitabilityPage />
        </Route>
      )}
      {permissions.includes("002-005") && (
        <Route path="/insurance/premium-payment-&amp;-collateral">
          <PaymentCollateral />
        </Route>
      )}
      {permissions.includes("002-006") && (
        <Route path="/insurance/program-information">
          <ProgramInformationPage />
        </Route>
      )}
      <Redirect to="/" />
    </Switch>
  );
};

export default InsuranceRouter;
