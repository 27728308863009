import React from "react";
import PropTypes from "prop-types";
import { Link, useLocation, useHistory } from "react-router-dom";

import { useAuth } from "../../context/AuthContext";
import routes from "./routes";
import "./Sidebar.scss";

function Sidebar({ toggleSidebar, open, isMobile }) {
  const location = useLocation();
  const history = useHistory();
  const { permissions } = useAuth();

  // force sidebar closed for Client Locator page (select member company)
  const activeRoute = history.location.pathname
    .slice(history.location.pathname.lastIndexOf("/"))
    .replace(/\//gi, "");
  const clientRequired = activeRoute === "select-company";

  return (
    <div className={isMobile ? "mobileSidebar" : "sidebar"}>
      {/* menu */}
      <div className={`sidebar-container ${open ? "open" : "closed"}`}>
        {routes.map(
          (r) =>
            permissions.includes(r.code) && (
              <div key={r.name} className="sidebar-section ">
                {r.to ? (
                  <>
                    <Link
                      className={`dashboardLink ${r.to === location.pathname ? "is-active" : ""
                        }`}
                      to={r.to}
                    >
                      {r.name}
                    </Link>
                    <div className="dashboardDivider" />
                  </>
                ) : (
                  <>
                    <span>{r.name}</span>
                    <div className="divider" />
                  </>
                )}

                {r.links?.length &&
                  r.links.map((l, i) => (
                    <Link
                      disabled
                      key={l.name}
                      // slighlty weird ternary, claims section uses pathname object for logic heavily, so adding claimsManagement/... would be a lot more work than this
                      className={`sidebar-link ${((location.pathname.startsWith(l.to) && location.pathname !== "/claims/claim-resources") || l.to === location.pathname) ? "is-active" : ""
                        } ${!permissions.includes(l.code) ? "isDisabled" : ""}`}
                      to={l.to}
                    >
                      {l.name}
                    </Link>
                  ))}
              </div>
            )
        )}
      </div>
      {/* toggle button */}
      <div
        className="sidebar-toggle"
        tabIndex="-1"
        role="button"
        onClick={clientRequired ? null : toggleSidebar}
        onKeyDown={clientRequired ? null : toggleSidebar}
      >
        <button
          className={
            open
              ? "hamburger hamburger--collapse is-active"
              : "hamburger hamburger--collapse"
          }
          type="button"
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool,
};

Sidebar.defaultProps = {
  isMobile: false,
};

export default Sidebar;
