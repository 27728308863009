import React, { useEffect, useState, useMemo } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";

import { Select } from "rbx";
import Section from "../../components/Section";
import Text from "../../components/Text";
import Button from "../../components/Button";
import { useAuth } from "../../context/AuthContext";
import { DocumentService } from "../../services/index";
import ProgramService from "../../services/ProgramService";
import ClientService from "../../services/ClientService";
import PolicyService from "../../services/PolicyService";

import "./ClaimsResources.scss";
import MedicalProviderLinks from "./MedicalProviderNetworksData"
import { groupBy } from "lodash";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const ClaimsResourcesPage = () => {
  const { state: authState, permissions } = useAuth();
  const [currentProgram, setCurrentProgram] = useState(authState.programRecId);

  const { data: programs = [] } = useQuery("programs", () =>
    ProgramService.getPrograms()
  );

  const { data: clientsHistory = [] } = useQuery("clientsHistory", () =>
    ClientService.getClientsChangeHistory()
  );

  // gets all documents associated with current program /*Li commented, and make Special Handling Instructions' docs Insured specific */
  const { data: documentsProgram = [], refetch: refetchDocumentsByProgram } = useQuery("documentsByProgram", () =>
    DocumentService.getDocumentsByProgram(currentProgram)
  );

  //mix pc8,9(policy program) to regular program list by getting from the polciies
  const { data: Policies = [] } = useQuery("policies", () =>
    PolicyService.getPoliciesByClientNoFilter(authState.clientId)
  );


  // gets all documents associated with Client /*Li make Special Handling Instructions' docs Insured specific */
  const { data: documentsClient = [] } = useQuery("documentsByClient", () =>
    DocumentService.getDocumentsByClient(authState.clientId)
  );

  /*Li commented, and make Special Handling Instructions' docs Insured specific */
  //combine program docs and client docs
  const documents = documentsProgram.filter(f => f.ClientId == null).concat(documentsClient.filter(f => f.ProgramsRecId === currentProgram));
  //documentsProgram.concat(documentsClient);


  /*Li, use this one when upgrade to react-query v3. currently running on v2+
  const { data: results = [] } = useQueries([
    {
      queryKey: "documentsByProgram2", queryFn: () =>
        DocumentService.getDocumentsByProgram(authState.programRecId)
    },
    {
      queryKey: "documentsByClient2", queryFn: () =>
        DocumentService.getDocumentsByClient(authState.clientId),
    },
  ]);*/



  // downloads file
  const { data: File = [], refetch } = useQuery(
    "documentDownload",
    () =>
      DocumentService.DocumentDownload(
        trueFileName,
        `${docName2} ${docName}`
      ),
    { enabled: false }
  );

  const [trueFileName, setTrueFileName] = useState("");
  const [docName, setDocName] = useState("");
  const [docName2, setDocName2] = useState("");

  const fileDownloadSetup = (fileName, DocName, docObj) => {
    if (sortedDocuments.length >= 1) {
      let fileLst = sortedDocuments.sort((a, b) => new Date(b.CreatedDateTime) - new Date(a.CreatedDateTime));
      for (let document of fileLst) {
        if (document.FilePath === docObj.FilePath) {
          //if (document.FileName === fileName) {
          setDocName2(DocName);
          setDocName(fileName);
          const filePath = document.FilePath;
          setTrueFileName(
            filePath.slice(filePath.lastIndexOf("/")).replace(/\//i, "")
          );
          break;//find the most recent one and break out
        }
      }
      // use new code above to break out when find the most current one
      /* sortedDocuments.forEach((document) => {
         setDocName2(DocName);
         setDocName(fileName);
         if (document.FileName === fileName) {
           const filePath = document.FilePath;
           setTrueFileName(
             filePath.slice(filePath.lastIndexOf("/")).replace(/\//i, "")
           );
         }
       });*/



    }
  };

  // calls refetch for the document to be downloaded, changes when trueFileName state is updated after button click, this trueFileName is used in the download query
  // after a successful refetch teh file the state is reset
  // if statements separated so that toast not called at beginning
  useEffect(() => {
    if (docName.length > 2) {
      if (trueFileName.length > 2) {
        refetch();
        setDocName("");
        setTrueFileName("");
      } else {
        toast.error("No Document Found");
      }
    }
  }, [docName, trueFileName, refetch]);

  useMemo(() => {
    refetchDocumentsByProgram();// state change supposed to rerender the componment, this page does not do that. add the refetch here Li

  }, [currentProgram])

  if (!documents?.length) {
    return <Section>No Documents Found.</Section>;
  }

  const sortedDocuments = documents.filter((doc) =>
    doc.DocumentId.startsWith("Claims Resources")
  );


  const sortedDocHash = {};
  // after checking that sorted documents has a length, goes through and makes a hash with headers for the documents to be used in array of object keys for UI
  // also assigning the document to an array beneath the header (in this case should always be one document)
  // helps to not display if not available
  if (sortedDocuments.length >= 1) {
    const oneCopyArr = [];
    //sortedDocuments.sort((a, b) => new Date(b.Year, b.Month, b.Day) - new Date(a.Year, a.Month, a.Day)).forEach((document) => {
    //Li  make Special Handling Instructions' docs Insured specific, only display most recent uploaded one using CreatedDateTime instead of user entered year month day
    sortedDocuments.sort((a, b) => new Date(b.CreatedDateTime) - new Date(a.CreatedDateTime)).forEach((document) => {
      const codeArr = document.DocumentId.split("_");
      const header = codeArr[codeArr.length - 2].replace(/_/i, "");
      // added so only first copy of document(most recent date) returned for each type since we do not want a history
      const header2 = codeArr[codeArr.length - 1].replace(/_/i, "");
      if (Object.keys(sortedDocHash).includes(header)) {
        if (!oneCopyArr.includes(header2)) {
          sortedDocHash[header].push(document);
          oneCopyArr.push(header2);
        }
      } else {
        sortedDocHash[header] = [document];
        oneCopyArr.push(header2);
      }
    });
  }

  // for use in compare function of sorting docs in Special Handling Instructions(hardcoded order requested by eMaxx)
  const customSortObj = {
    "Claims Resources_Special Handling Instructions_Member Special Account Instructions": 3,
    "Claims Resources_Special Handling Instructions_Automobile and General Liability Guidelines": 2,
    "Claims Resources_Special Handling Instructions_Workers Compensation Claims Handling Guidelines": 1
  };

  let ProgramLst = [];
  ProgramLst.push(programs.find(f => f.RecId === authState.programRecId));//insert the current

  let clientProHis = clientsHistory.filter(f => f.ProgramsRecId && f.ProgramsRecId !== authState.programRecId && f.ClientId === authState.clientId);

  if (clientProHis.length > 0 && programs.length > 0) {

    let grouping = clientProHis;//clientsHistory.filter(f => f.ProgramRecId !== authState.programRecId && f.ClientId === authState.clientId);
    let grouped = groupBy(grouping, 'ProgramsRecId');

    Object.keys(grouped).forEach(key => ProgramLst.push(programs.find(f => f.RecId.toString() === key.toString())));
  }

  // add policy program to the drop down
  if (programs.length > 0 && Policies.length > 0) {

    let groupedPolicyPrograms = groupBy(Policies, 'CategoryCode')

    Object.keys(groupedPolicyPrograms).forEach(key => {
      var existing = ProgramLst.find(f => f?.Code === key)
      if (!existing) {

        let prog = programs.find(f => f.Code === key);

        ProgramLst.push(prog);
      }
    });
  }
  // add policy program to the drop down





  const changeCurrentProgram = e => {
    //e.persist();

    const { value } = e.target;
    setCurrentProgram(Number(value));
  }

  return (
    <div className="claimsResourcesPage">
      <div style={{ paddingBottom: '0.8rem' }}>
        <Select.Container size="medium">
          {/* <Select value={currentProgram} onChange={programChange}> */}
          <Select onChange={changeCurrentProgram}>
            {ProgramLst
              //.sort((a, b) => b - a)
              .map(p => (
                <Select.Option key={p?.RecId} value={p?.RecId}>{p?.Code}</Select.Option>
              ))}
          </Select>
        </Select.Container>
      </div>
      {sortedDocuments.length < 1 ? (
        <Text.H3 style={{ margin: "1rem" }}>
          No Files Found
        </Text.H3>
      ) : (
        <>
          {Object.keys(sortedDocHash)
            .sort((a, b) => b.localeCompare(a))
            .map((header) => (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text.H1>{header}</Text.H1>
                {sortedDocHash[header].sort((a, b) => {
                  if (header === "Special Handling Instructions") {
                    return customSortObj[b.DocumentId] - customSortObj[a.DocumentId];
                  }
                  return a.DocumentId.localeCompare(b.DocumentId);
                }).map((document) => (
                  <Section key={document.RecId}>
                    <Text.H3>
                      {`${document.DocumentId.slice(
                        document.DocumentId.lastIndexOf("_")
                      ).replace(/_/i, "")}`}
                    </Text.H3>
                    <Button.ExternalText
                      onClick={(e) => {
                        fileDownloadSetup(
                          document.FileName,
                          `${monthNames[document.Month - 1]} ${document.Day
                          }, ${document.Year}`,
                          document
                        );
                      }}
                    >
                      View
                    </Button.ExternalText>
                  </Section>
                ))}
              </div>
            ))}
        </>
      )}
      <Text.H1>Medical Provider Networks</Text.H1>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {/* <Section>
          <Text.H3>California Medical Provider Network</Text.H3>
          <Button.ExternalText
            //href="http://www.talispoint.com/cvty/nrs"
            href={MedicalProviderLinks.find(f => f.program === authState.programRecId).links.find(f => f.providerType === 'California Medical Provider Network').link}
            target="_blank"
            rel="noopener noreferrer"
          >
            View
          </Button.ExternalText>
        </Section>
        <Section>
          <Text.H3>Medical Provider Directory</Text.H3>
          <Button.ExternalText
            href="https://www-sf.talispoint.com/firsthealth/"
            target="_blank"
            rel="noopener noreferrer"
          >
            View
          </Button.ExternalText>
        </Section>
        <Section>
          <Text.H3>Preferred Provider Panel</Text.H3>
          <Button.ExternalText
            href="https://www-sf.talispoint.com/careworks/mbrppo/"
            target="_blank"
            rel="noopener noreferrer"
          >
            View
          </Button.ExternalText>
        </Section> */}
        {MedicalProviderLinks.find(f => f.program === authState.programRecId) ?
          MedicalProviderLinks.find(f => f.program === authState.programRecId)?.links.map(item => (
            <Section>
              <Text.H3>{item.providerType}</Text.H3>
              <Button.ExternalText
                //href="http://www.talispoint.com/cvty/nrs"
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                View
              </Button.ExternalText>
            </Section>)
          ) : (<Section>No Medical Provider Networks Found</Section>)}
      </div>
    </div>
  );
}


export default ClaimsResourcesPage;
