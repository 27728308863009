import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";

import Section from "../../../components/Section";
import Text from "../../../components/Text";
import Button from "../../../components/Button";
import { useAuth } from "../../../context/AuthContext";
import { DocumentService } from "../../../services/index";
import ClientService from "../../../services/ClientService";
import "./Governance.scss";


const data = [
  {
    title: "Membership",
    items: [
      { label: "Membership Agreement", link: "", code: "002-002-001", documentId: 'Governance_Membership_Membership Agreement' },
      { label: "Association Membership Agreement", link: "", code: "002-002-001", documentId: 'Governance_Membership_Association Membership Agreement' },
      {
        label: "Membership Directory",
        link: "/insurance/governance/membership/membership-directory",
        code: "002-002-002"
      },
      {
        label: "Member References",
        link: "/insurance/governance/membership/member-references",
        code: "002-002-003"
      },
      {
        label: "Officer & Committee Slate",
        link: "/insurance/governance/membership/officer-&-committee-slate",
        code: "002-002-004"
      }
    ]
  },
  {
    title: "Organization",
    items: [
      {
        label: "Organization Documentation",
        link: "/insurance/governance/organization/organizational-documentation",
        code: "002-002-005"
      },
      {
        label: "Meetings",
        link: "/insurance/governance/organization/meetings",
        code: "002-002-006"
      },
      {
        label: "Board of Directors",
        link: "/insurance/governance/organization/board-of-directors",
        code: "002-002-007"
      },
      {
        label: "Standing Committees",
        link: "/insurance/governance/organization/standing-committees",
        code: "002-002-008"
      }
    ]
  },
  /* move pc8 to the dropdown selection
  {
    title: "eCaptiv PC8-IC Membership",
    items: [
      { label: "Membership Agreement", link: "", code: "002-002-001" },

    ]
  },*/
];

const GovernancePage = ({ passDownCurrentProg, changeCurrentProgram }) => {

  const { state: authState, permissions } = useAuth();
  const [trueFileName, setTrueFileName] = useState("");
  // for use in timing like other files eg. doc library
  const [docName, setDocName] = useState("");

  const {
    data: memberNumbers = [],
    isLoadingMemberNumber,
    refetch: refetchMemberNumberByClient,
    errorMemberNumber
  } = useQuery(
    "memberNumberByClient",
    () => ClientService.getClientMemberNumber(authState.clientId),
    { fetchPolicy: "no-cache", cacheTime: 0 }
  );

  const memberNumber = memberNumbers.find(
    num => num.ProgramsRecId === passDownCurrentProg
  );


  const {
    data: documents = [],
    isLoadingDocs,
    refetch: refetchDocumentsByClient,
    errorDocs
  } = useQuery(
    "documentsByClient",
    () => DocumentService.getDocumentsByClient(authState.clientId),
    { fetchPolicy: "no-cache", cacheTime: 0 }
  );

  const sortedDocuments = documents.filter(
    //doc => (doc.DocumentId === "Governance_Membership_Membership Agreement" || doc.DocumentId === "PC8-Governance_Membership_Membership Agreement") remove dedicated PC8, move it to the program list
    doc => doc.DocumentId === "Governance_Membership_Membership Agreement" && doc.ProgramsRecId === passDownCurrentProg

  );

  const {
    data: File = [],
    isLoadingFile,
    refetch,
    errorFile
  } = useQuery(
    "documentDownload",
    () =>
      DocumentService.DocumentDownload(
        trueFileName,
        `${authState.clientName} ${docName}`
      ),
    { enabled: false, fetchPolicy: "no-cache", cacheTime: 0 }
  );

  const documentSetUp = (label) => {
    //setDocName("Membership Agreement.pdf");

    setDocName(label + ".pdf");
    //if (sortedDocuments.length >= 1) 
    if (documents.length >= 1) {
      //const fileName = sortedDocuments.sort((a, b) => new Date(b.CreatedDateTime) - new Date(a.CreatedDateTime))[0].FilePath;
      //const fileName = sortedDocuments.sort((a, b) => new Date(b.CreatedDateTime) - new Date(a.CreatedDateTime))[0]?.FilePath;

      //const fileName = sortedDocuments.sort((a, b) => new Date(b.CreatedDateTime) - new Date(a.CreatedDateTime))[0]?.FilePath;
      const newSortedDocs = documents.filter(
        //doc => (doc.DocumentId === "Governance_Membership_Membership Agreement" || doc.DocumentId === "PC8-Governance_Membership_Membership Agreement") remove dedicated PC8, move it to the program list
        doc => doc.DocumentId === data.find(f => f.title === 'Membership').items.find(f => f.label === label).documentId
          && doc.ProgramsRecId === passDownCurrentProg
      )
      const fileName = newSortedDocs.sort((a, b) => new Date(b.CreatedDateTime) - new Date(a.CreatedDateTime))[0]?.FilePath;

      if (fileName) {
        setTrueFileName(
          fileName.slice(fileName.lastIndexOf("/")).replace(/\//i, "")
        );
      }
    }
  };

  useEffect(() => {
    if (docName.length > 2) {
      if (trueFileName.length > 2) {
        refetch();
        setDocName("");
        setTrueFileName("");
      } else {
        toast.error("No Document Found");
        setDocName("");
      }
    }
  }, [docName, trueFileName, refetch]);

  useMemo(() => {
    refetchDocumentsByClient();

  }, [passDownCurrentProg]);

  return (
    <>
      {data.map(group => (
        <>
          <div style={{ 'display': 'flex', 'justifyContent': 'space-between' }}>
            <Text.H1>{group.title}</Text.H1>
            {group.title === "Membership" && (<div style={{ fontSize: '2.5em', fontWeight: 800 }}>{memberNumber?.Number}</div>)}
          </div>
          {group.items.map(item => (
            <Section>
              <Text.H3
                classNames={
                  !permissions.includes(item.code) ? "isDisabled" : ""
                }
              >
                {item.label}
              </Text.H3>
              {item.label.includes("Membership Agreement") ? (
                (item.label === "Membership Agreement") ?
                  (<Button.ExternalText
                    onClick={e => documentSetUp(item.label)}
                    disabled={!permissions.includes(item.code)}
                  >
                    View
                  </Button.ExternalText>)
                  :
                  (<Button.ExternalText
                    onClick={e => documentSetUp(item.label)}
                    disabled={!permissions.includes(item.code)}
                  >
                    View
                  </Button.ExternalText>)
              ) : (
                <Button.Text
                  to={item.link}
                  disabled={!permissions.includes(item.code)}
                >
                  View
                </Button.Text>
              )}
            </Section>
          ))}
        </>
      ))}
    </>
  );
};

GovernancePage.propTypes = {};

export default GovernancePage;
