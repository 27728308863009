import APIService from "./index";
import { formatPhoneNumber } from "../utils";

const LPSService = {
  getEmaxxDirectory: () =>
    APIService.get(`/GetEmaxxDirectory`).then((res) => res.data.Payload),
  getMembershipReferences: async (ProgramsRecId) => {
    if (ProgramsRecId) {
      const {
        data: { Payload: references = false },
      } = await APIService.get(
        `/GetMembershipReferences?ProgramsRecId=${ProgramsRecId}`
      );
      // format each bussiness phone number
      const results = references.map((reference) => {
        const phoneNumber = formatPhoneNumber(reference.BusinessPhone);
        const result = {
          ...reference,
          BusinessPhone: phoneNumber,
        };

        return result;
      });

      return results;
    }
    return [];
  },
  getMembershipTypes: () =>
    APIService.get(`/GetMembershipTypes`).then((res) => res.data.Payload),
  getMembershipType: (RecId) =>
    APIService.get(`/GetMembershipType?RecId=${RecId}`).then(
      (res) => res.data.Payload
    ),
  // THIS IS NOT A TYPO Committee is spelled with one t to match endpoint and eMaxx tables
  getOfficerAndCommiteeSlates: (ProgramsRecId) =>
    ProgramsRecId
      ? APIService.get(
          `/GetOfficerAndCommiteeSlates?ProgramsRecId=${ProgramsRecId}`
        ).then((res) => res.data.Payload)
      : [],
  getUserClientMemberships: (UsersRecId, ClientId) =>
    APIService.get(
      `/GetUserClientMemberships?ClientId=${ClientId}&UsersRecId=${UsersRecId}`
    ).then((res) => res.data.Payload),
};

export default LPSService;
