import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";

import { Field, Label, Control, Input, Checkbox, Select, Radio, Heading } from "rbx";
import Section from "../../../../components/Section";
import Text from "../../../../components/Text";
import Button from "../../../../components/Button";
//import from "../../../../component";
import { StateSelect, TextFieldInput } from "../../../../components/inputs";
import { NavItem } from "react-bootstrap";
//import './OnlineClaims.scss'



const ClaimType = (Props) => {

    useEffect(() => {
        goNext();
    }, [Props.currentState.claimTypeDetail.claimType]);

    if (Props.currentState.currentStep !== 1) {
        return null;
    }


    //temp solution to disable next button when required fields not filled
    var nextBtn = document.getElementsByTagName('button');
    nextBtn = Array.from(nextBtn).filter(item => item.innerHTML === 'Next');

    if (nextBtn.length > 0 && !Props.currentState.claimTypeDetail.claimType) {
        nextBtn[0].disabled = true;
        nextBtn[0].classList.add("disabled");
    }
    else if (Props.currentState.claimTypeDetail.claimType) {
        nextBtn[0].disabled = false;
        nextBtn[0].classList.remove("disabled");
    }


    const goNext = () => {
        if (Props.currentState.claimTypeDetail.claimType) {
            nextBtn[0].disabled = false;
            nextBtn[0].classList.remove("disabled");
        }
    }
    //temp solution to disable next button when required fields not filled

    return (
        <>
            <div id='OLClaimsContainer'>
                {/* <Text.H3>Claim Type</Text.H3> */}
                <Label>
                    <Text.H3>
                        <Radio className='radio' name="claimType" value={Props.currentState.claimTypeDetail.claimType === 'Auto' ? Props.currentState.claimTypeDetail.claimType : 'Auto'}
                            checked={Props.currentState.claimTypeDetail.claimType === 'Auto'} onChange={(e) => { Props.handleChange(e, 'claimTypeDetail'); }} /> <span>Auto</span>
                    </Text.H3>
                </Label>
                <Label>
                    <Text.H3>
                        <Radio className='radio' name="claimType" value={Props.currentState.claimTypeDetail.claimType === 'GeneralLiability' ? Props.currentState.claimTypeDetail.claimType : 'GeneralLiability'}
                            checked={Props.currentState.claimTypeDetail.claimType === 'GeneralLiability'} onChange={(e) => Props.handleChange(e, 'claimTypeDetail')} /> <span>General Liability</span>
                    </Text.H3>
                </Label>
                <Label>
                    <Text.H3>
                        <Radio className='radio' name="claimType" value={Props.currentState.claimTypeDetail.claimType === 'Property' ? Props.currentState.claimTypeDetail.claimType : 'Property'}
                            checked={Props.currentState.claimTypeDetail.claimType === 'Property'} onChange={(e) => Props.handleChange(e, 'claimTypeDetail')} /> <span>Property</span>
                    </Text.H3>
                </Label>
                <Label>
                    <Text.H3>
                        <Radio className='radio' name="claimType" value={Props.currentState.claimTypeDetail.claimType === 'WorkersCompensation' ? Props.currentState.claimTypeDetail.claimType : 'WorkersCompensation'}
                            checked={Props.currentState.claimTypeDetail.claimType === 'WorkersCompensation'} onChange={(e) => Props.handleChange(e, 'claimTypeDetail')} /> <span>Workers Compensation</span>
                    </Text.H3>
                </Label>
            </div>
        </>
    );
}

export default ClaimType;