import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { Select } from "rbx";
import { format, parseISO } from "date-fns";

import Section from "../../../components/Section";
import Text from "../../../components/Text";
import DataTable from "../../../components/DataTable";
import Button from "../../../components/Button";
import { startCase } from "../../../utils";
import { useAuth } from "../../../context/AuthContext";
import PolicyService from "../../../services/PolicyService";
import { DocumentService } from "../../../services/index";
import { groupBy } from "lodash";
import "./RenewalDocumentation.scss";

import RenewalDocUploadPage from "./RenewalDocUploadPage";




const RenewalDocumentationPage = () => {

    const { state: authState, permissions } = useAuth();
    const [trueFileName, setTrueFileName] = useState("");
    const [docName, setDocName] = useState("");
    const [docName2, setDocName2] = useState("");
    const [loadingBool, setLoadingBool] = useState(false);

    const [selectedRenewalTerm, setSelectedRenewalTerm] = useState("");
    const [inUploadPage, setInUploadPage] = useState(false);

    const style = loadingBool ? { color: "grey" } : { color: "#f47e20" };

    const COLUMNS = [
        {
            Header: "Document",
            accessor: "DocumentId",
            id: "DocumentId",
            Cell: ({ value }) =>
                value ? value.slice(value.lastIndexOf("_"))
                    .replace(/_/i, "") : "--"

        },
        {
            Header: "Category",
            accessor: "DocumentId",
            id: "Category",
            Cell: ({ value }) =>
                value ? value.split('_')[value.split('_').length - 2].replace(/_/i, "") : "--"

        },

        {
            Header: "Date",
            accessor: "CreatedDateTime",
            id: "CreatedDateTime",
            Cell: ({ value }) =>
                value ? format(parseISO(value), "MM/dd/yyyy") : "--"
        },

        {
            Header: "Submitter",
            accessor: "CreatedBy",
            id: "CreatedBy",

        },

        {
            Header: "Download",
            accessor: "FileName",
            id: "RenewalDocument",
            Cell: ({ value }) =>
                value ? (
                    <Button.ExternalText
                        //target="_blank"
                        disabled={!permissions.includes("002-003-005-002")}
                    >
                        {"View"}
                    </Button.ExternalText>
                ) : (
                    ""
                )
        }

        /*
        
                {
                    Header: "Download",
                    accessor: "FileName",
                    id: "RenewalDocument",
                    Cell: ({ value }) =>
                        value ? (
                            <span disabled={loadingBool} style={style}>
                                {value === docName ? "Downloading..." : "View"}
                            </span>
                        ) : (
                            ""
                        )
                },*/

    ];

    const {
        data: policies = [],
        isLoading,
        error
    } = useQuery(
        "policiesByClientNoFilter",
        () => PolicyService.getPoliciesByClientNoFilter(authState.clientId),
        //{ fetchPolicy: "no-cache", cacheTime: 0 }
        { cacheTime: 2000 }
    );

    const { data: documents = [], isLoadingDocs, refetchDocs } = useQuery(
        "documentsByClient",
        () => DocumentService.getDocumentsByClient(authState.clientId),
        { cacheTime: 1000, enabled: !inUploadPage }
        //{ fetchPolicy: "no-cache", cacheTime: 0 } casue mobile device issue that not loading properly 10/14/2022
    );

    const {
        data: File = [],
        isLoadingFile,
        refetch,
        errorFile
    } = useQuery(
        "documentDownload",
        () =>
            DocumentService.DocumentDownload(trueFileName, `${docName2} ${docName}`),
        { enabled: false, fetchPolicy: "no-cache", cacheTime: 0 }
    );

    useEffect(() => {
        if (docName.length > 2) {
            if (trueFileName.length > 2) {
                refetch().then(() => {
                    setDocName("");
                    setTrueFileName("");
                    setLoadingBool(false);
                });
            } else {
                toast.error("No Document Found");
                setLoadingBool(false);
            }
        }
    }, [docName, trueFileName, refetch]);

    let sortedDocuments = documents.filter(doc =>
        doc.DocumentId.startsWith("Member Kiosk_Renewal Documentation")
    );
    // BASING SECONDARY DOCUMENT MATCH ON THE FILENAME ATTRIBUTE instead of DocumentId which is not unique
    const fileDownloadSetup = (fileName, DocName) => {
        setLoadingBool(true);
        if (sortedDocuments.length >= 1) {
            sortedDocuments.forEach(document => {
                setDocName2(DocName);
                setDocName(fileName);
                if (document.FileName === fileName) {
                    const filePath = document.FilePath;
                    setTrueFileName(
                        filePath.slice(filePath.lastIndexOf("/")).replace(/\//i, "")
                    );
                }
            });
        }
    };


    const sortedPolicies = policies.filter(f => f.LOB == 'Commercial Auto').sort((a, b) => new Date(b?.ExpirationDate) - new Date(a?.ExpirationDate));

    const RecentRenewalTerm = sortedPolicies[0]?.ExpirationDate ? new Date(sortedPolicies[0]?.ExpirationDate).toLocaleDateString() : null;
    //get the renewal docs by renewal date
    let renewalTerms = [];
    sortedDocuments.sort((a, b) => b.Year - a.Year).forEach(doc => {
        doc.RenewalDate = `${doc.Month}/${doc.Day}/${doc.Year}`;
        renewalTerms.push(doc.RenewalDate);
    })
    renewalTerms = [...new Set(renewalTerms)];

    //group twice on year and documentid to overwrite same doc type per term
    var groupedYear = groupBy(sortedDocuments, "Year");
    var tempGrouped = []
    Object.keys(groupedYear).forEach(key => {

        var groupedDocId = groupBy(groupedYear[key], "DocumentId");

        Object.keys(groupedDocId).forEach(key => {

            tempGrouped.push(groupedDocId[key].sort((a, b) => new Date(b.CreatedDateTime) - new Date(a.CreatedDateTime))[0]);
        });
    });

    sortedDocuments = tempGrouped;
    //group twice on year and documentid to overwrite same doc per term

    const filterTerm = selectedRenewalTerm ? selectedRenewalTerm : RecentRenewalTerm;

    let sortedYearDocuments = sortedDocuments.filter(f => f.Year == filterTerm?.split('/')[2]);

    /*
    sortedYearDocuments = sortedYearDocuments.sort((a, b) => {
        var aDoc = a.DocumentId.slice(a.DocumentId.lastIndexOf("_")).replace(/_/i, "");
        var bDoc = b.DocumentId.slice(b.DocumentId.lastIndexOf("_")).replace(/_/i, "");


        //return aDoc - bDoc;
        return bDoc.toString().trim() - aDoc.toString().trim();

    });*/

    const changeTerm = (e, name, value) => {

        e.preventDefault();

        sortedYearDocuments = sortedDocuments.filter(f => f.Year == value.split('/')[2]);

        setSelectedRenewalTerm(value);
    }
    const onCellClick = cell => {
        // added second qualifier to make undefined cells do nothing
        // add third to disable download while other download running
        if (cell.column.id === "RenewalDocument" && cell.value && !loadingBool) {//TODO: change later for renewal
            //fileDownloadSetup(cell.value, "Policy");
            fileDownloadSetup(cell.value, authState.clientName);
        }
    };

    const changeUploadState = () => {
        if (inUploadPage) {
            setInUploadPage(false);
        }
        else {
            setInUploadPage(true);
        }
    }

    const abbrevCode = code => {
        return startCase(
            code.slice(code.lastIndexOf("_")).replace(/_/i, "").replace(/_/i, " ")
        );
    };



    return (
        <div>
            <div>
                <Text.H1>
                    Renewal Term
                </Text.H1>
                <div className="RenewalAndUpload">
                    <Section>
                        <Text.H3>{RecentRenewalTerm ? RecentRenewalTerm : 'No Policy Found.'}</Text.H3>

                    </Section>
                    {(!inUploadPage && RecentRenewalTerm && permissions.includes('002-003-005-001')) && (<div className="buttonHolder">
                        <Button.Round
                            onClick={changeUploadState}
                            //disabled={inUploadPage}
                            className={
                                !inUploadPage
                                    ? "roundButton primary"
                                    : "roundButton primary"
                            }
                        >
                            {inUploadPage ? 'Close' : 'Upload'}
                        </Button.Round>
                    </div>)}
                </div>
                {/* <Section>
                <Text.H3>Renewal Term: {RecentRenewalTerm}</Text.H3>
            </Section> */}
            </div>


            {/* <div>
                <Text.H1>Renewal Information & Financial Statements</Text.H1>
                <Section>
                    <div className="renewalDesc">

                        <div className="renewalDescIndividual">
                            <span>
                                Loss runs need to be valued within 90 days for all lines of business being quoted and require all years not written through eMaxx's program.
                            </span>
                        </div>
                        <div className="renewalDescIndividual">
                            <span>
                                Surety Bonds are an acceptable form of collateral only for active Members. If a Member terminates participation, any collateral posted by Surety Bond must be converted (no later than thirty (30) days prior to the date of termination) to either Cash or to a clean and irrevocable Letter of Credit (in a form and upon conditions/terms acceptable to the Company).
                                Surety Bond or Letter of Credit shall be in place at or prior to binding. Collateral shall be added to the Finance Agreement until the Surety Bond or Letter of Credit is provided.

                            </span>
                        </div>

                        <div className="renewalDescIndividual">
                            <span>
                                Surety Bond or Letter of Credit shall be in place at or prior to binding. Collateral shall be added to the Finance Agreement until the Surety Bond or Letter of Credit is provided.
                            </span>
                        </div>
                    </div>

                </Section>
            </div> */}

            <div>

                {
                    inUploadPage && (
                        <Section >
                            {/* <div style={{ display: inUploadPage ? '' : 'none' }}> */}
                            <div>
                                <RenewalDocUploadPage RecentRenewalTerm={RecentRenewalTerm} InUploadPage={setInUploadPage} />
                            </div>

                        </Section>)
                }
            </div>

            <div>
                <Text.H1>Renewal Documentation History</Text.H1>
                <Section>
                    <div className='RenewalDocSection'>
                        {/* <div style={{ textAlign: 'center' }}><Text.H3>Documents Previously Submitted</Text.H3></div> */}
                        <div className="policyTerm">
                            <div className="policyTerm">

                                <Text.H3>
                                    <span style={{ paddingRight: 15 }}>Policy Term: </span>

                                    <Select.Container size="medium">

                                        <Select onChange={e => changeTerm(e, e.target.name, e.target.value)}>
                                            {renewalTerms
                                                //.sort((a, b) => b - a)
                                                .map(p => (
                                                    <Select.Option key={p} value={p}>{p}</Select.Option>
                                                ))}
                                        </Select>
                                    </Select.Container>
                                </Text.H3>
                            </div>

                        </div>
                        <div id="RenewalDocContainer">
                            <div className="headerContainer">

                                {loadingBool && (
                                    <span>***Larger files may take a moment to download</span>
                                )}
                            </div>
                            {/* table for insurance policies by client */}
                            {sortedYearDocuments?.length > 0 ? (
                                <DataTable
                                    columns={COLUMNS}
                                    data={sortedYearDocuments?.sort((a, b) =>
                                        abbrevCode(a.DocumentId).localeCompare(abbrevCode(b.DocumentId))
                                    )}
                                    //data={sortedYearDocuments}
                                    onCellClick={permissions.includes('002-003-005-002') ? onCellClick : () => { }}

                                />
                            ) : (
                                <Section>No Documents Found.</Section>
                            )}


                        </div>
                    </div>

                </Section >
            </div>
        </div >






    );
};
export default RenewalDocumentationPage;