import React, { useEffect, useState } from "react";

import { useQuery } from "react-query";
import { useParams, useHistory } from "react-router-dom";

import ClientService from "../../../services/ClientService";
import PageContainer from "../../../components/PageContainer/PageContainer";
import DataTable from "../../../components/DataTable";
import Section from "../../../components/Section";
import Text from "../../../components/Text";
import Loading from "../../../components/Loading";
import SearchBar from "../../../components/SearchBar";
import COLUMNS from "./columns";
// import { useAuth } from "../../../context/AuthContext";
import { startCase } from "../../../utils";
import "./ClientSelect.scss";

const ClientSelectPage = () => {
  const history = useHistory();
  // const { state: authState, permissions } = useAuth();
  const { groupName } = useParams();
  const [search, setSearch] = useState("");
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);

  const { data, isLoading, error } = useQuery("clients", () =>
    ClientService.fetchClientData()
  );

  const handleRowSelect = (clientId) => {
    history.push(`/user-settings/${groupName}/${clientId}`);
  };

  const handleSearchChange = (value) => {
    setSearch(value);
  };

  useEffect(() => {
    if (data?.length) {
      setLoading(true);
      // filter clients by client type (group)
      let group = groupName;
      if (groupName === "expired member") {
        group = "XPolicyholder";
      }
      if (groupName === "member") {
        group = "Policyholder";
      }

      const filteredByGroup = data.filter(
        (client) => client.ClientType.toLowerCase() === group.toLowerCase());


      if (search) {
        const filteredData = filteredByGroup.filter((client) =>
          client.ClientName.toLowerCase()
            .replace(/[^A-Z0-9]/gi, "")
            .includes(search.toLowerCase().replace(/[^A-Z0-9]/gi, ""))
        );
        setClients([...filteredData]);
        setLoading(false);
      } else {
        setClients([...filteredByGroup]);
        setLoading(false);
      }
    }
  }, [search, data, groupName]);

  if (isLoading || loading) {
    return (
      <PageContainer>
        <Loading.SmallLoading />
      </PageContainer>
    );
  }

  if (error) {
    return null;
  }

  return (
    <div id="clientSelectPage">
      <div className="headerContainer">
        <Text.H1>{startCase(groupName)}</Text.H1>
      </div>
      <SearchBar value={search} onSearch={handleSearchChange} />
      {clients.length > 0 ? (
        <DataTable
          columns={COLUMNS}
          data={clients}
          onRowClick={(rowData) => handleRowSelect(rowData.ClientId)}
          sortBy={[
            {
              id: "ClientName",
              desc: false,
            },
          ]}
        />
      ) : (
        <Section>None Found.</Section>
      )}
    </div>
  );
};

export default ClientSelectPage;
