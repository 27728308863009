import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Field, Control, Label, Input } from "rbx";

const ConfirmPasswordInput = ({ password, required, onChange }) => {
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setIsValid(
      password &&
        password.length &&
        confirmPassword.length &&
        password === confirmPassword
    );
  }, [password, confirmPassword]);

  // do not add onChange to the list of dependencies or if you do make it a useMemo so that it will not loop endlessly
  useEffect(() => {
    onChange("isValid", isValid);
  }, [isValid]);

  return (
    <>
      <Field kind="group">
        <Control expanded>
          <Label size="large" style={{ color: "white" }}>
            Password
          </Label>
          <Input
            autocomplete="new-password"
            type="password"
            name="password"
            placeholder="Password"
            color={isValid ? "success" : "danger"}
            value={password}
            onChange={(e) => {
              onChange(e.target.name, e.target.value);
            }}
            required={required}
          />
        </Control>
      </Field>
      <Field kind="group">
        <Control expanded>
          <Label size="large" style={{ color: "white" }}>
            Confirm Password
          </Label>
          <Input
            autocomplete="new-password"
            type="password"
            placeholder="Confirm Password"
            name="confirmPassword"
            color={isValid ? "success" : "danger"}
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
              onChange(e.target.name, e.target.value);
            }}
            required={required}
          />
        </Control>
      </Field>
    </>
  );
};

ConfirmPasswordInput.propTypes = {
  password: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};
ConfirmPasswordInput.defaultProps = {
  password: "",
  required: true,
  onChange: () => null,
};
export default ConfirmPasswordInput;
