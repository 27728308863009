import React from "react";
import { useQuery } from "react-query";
import { Column } from "rbx";
import { Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import Section from "../../../components/Section";
import Text from "../../../components/Text";
import Button from "../../../components/Button";
// import ColorDivider from "../../../components/Divider";
// import StatusBadge from "../../../components/StatusBadge";
import data from "./ELiveStaticData";
import { useAuth } from "../../../context/AuthContext";
import eLiveConnectService from "../../../services/eLiveConnectService"
import BarGraphGeneral from "../../../components/BarGraphGeneral";
import Loading from "../../../components/Loading";
import "./ELive.scss";


import {
  closestIndexTo,
  startOfToday,
  differenceInCalendarDays,
  isAfter,
  add,
  format
} from "date-fns";


const ELivePage = () => {

  const dateIsValid = (date) => {
    return !Number.isNaN(new Date(date).getTime());
  }

  const { state: authState, permissions } = useAuth();

  const {
    data: eLiveSummary = [],
    isLoading: isLoadingELive,
    errorELiveSummary,
  } = useQuery("eLiveSummary", () =>
    eLiveConnectService.getELiveSummary(authState.clientId),
    { cacheTime: 5000 }

  );

  const employeesInRollingMonths_Course = [];
  const employeesInRollingMonths_Content = [];

  let lastActivityTime = null;
  let numOfRollingMo = 6;

  let barChartDataKeys = ['Courses', 'Media']
  let barChartData = []


  if (eLiveSummary && eLiveSummary.length > 0) {

    //create rolling months
    let rollingMonths = [];
    let allTimeStamp = [];

    for (let i = numOfRollingMo; i >= 1; i--) {
      let today = new Date();

      let month = add(today, { months: i * -1 })
      rollingMonths.push(month);
    }
    //fill in the count for rolling months
    for (let j = 0; j < rollingMonths.length; j++) {

      if (!dateIsValid(rollingMonths[j])) { console.log("skip"); continue; }

      let numOfEmp_Course = 0;
      let numOfEmp_Content = 0;
      let mo = format(new Date(rollingMonths[j]), 'MM/yyyy');

      eLiveSummary.forEach(item => {//there is case one insured has 2 supervisor in eLive, need to have more than one keys to get all course data. change the response to array for data of each key
        item.forEach(user => {
          //course count
          user.courses.forEach(course => {
            if (dateIsValid(course.timestamp)) {
              if (course.status === 'complete') {
                let courseTS = format(new Date(Date.parse(course.timestamp)), 'MM/yyyy');

                if (mo === courseTS) {
                  numOfEmp_Course++;
                }
              }
              allTimeStamp.push(new Date(Date.parse(course.timestamp)));//last activity
            }
          });
          //content count
          user.content.forEach(content => {
            if (dateIsValid(content.timestamp)) {
              if (content.type === 'video' || content.type === 'training' || content.type === 'webinar' ||
                content.type === 'podcast') {
                let contentTS = format(new Date(Date.parse(content.timestamp)), 'MM/yyyy');

                if (mo === contentTS) {
                  numOfEmp_Content++;
                }
                allTimeStamp.push(new Date(Date.parse(content.timestamp)));//last activity
              }
            }
          })
        })
      });

      //employeesInRollingMonths_Course.push({ x: mo, y: numOfEmp_Course });
      //employeesInRollingMonths_Content.push({ x: mo, y: numOfEmp_Content });
      employeesInRollingMonths_Course.push({ label: mo, Courses: numOfEmp_Course });
      employeesInRollingMonths_Content.push({ label: mo, Media: numOfEmp_Content });

      barChartData.push({ label: mo, Courses: numOfEmp_Course, Media: numOfEmp_Content })
    }
    //lineChartData_Course.push({ id: 'Course', color: '#48B5FF', data: employeesInRollingMonths_Course });
    //lineChartData_Content.push({ id: 'Content', color: '#124A80', data: employeesInRollingMonths_Content });

    //last activity
    lastActivityTime = allTimeStamp.sort((a, b) => b - a)[0];

    //this version nivo will filter out 0 value. if all 0, the x axis will disappear.  future upgrade nivo, will require some tweak on the Bar component
    let totalNumCour = barChartData.reduce((accum, curr) => {
      return accum + curr.Courses;
    }, 0);
    let totalNumMedia = barChartData.reduce((accum, curr) => {
      return accum + curr.Media;
    }, 0);

    if (totalNumCour === 0 && totalNumMedia === 0) {
      barChartData.map((item) => {
        item.Courses = null;
        item.Media = null;

      })
    }
    //this version nivo will filter out 0 value. if all 0, the x axis will disappear.
  }



  return (
    <div id="eLiveContainer">
      {/* Training PHASE 2 */}
      {/* <Text.H1>Training Report</Text.H1>   */}
      <div className="trainingReport">
        {/* <Section>
            <div className="tableHeader">
                <Text.H2>Course Name</Text.H2>
                <Text.H2>Status</Text.H2>
            </div>
            <ColorDivider />
                {data[0].items.map((item) => (
                    <div className={`${data[0].title}-List-item`}>
                        <div>{item.topText}</div>
                        <StatusBadge type={item.status}>{item.statusText}</StatusBadge>
                    </div>  
                ))}
            </Section> */}
        {permissions.includes("003-003-001") && (
          <a
            href="https://www.eliveconnect.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button.Round>eLive Connect</Button.Round>
          </a>
        )}
      </div>


      {/* <Text.H1>Most Popular Courses</Text.H1>
      <div className="recCourses">
        <Column.Group multiline>
          {data[1].items.map((item) => (
            <Column desktop={{ size: 6 }} size={12}>
              <Section className={`${data[1].title}-List-item`}>
                {item.topText}
              </Section>
            </Column>
          ))}
        </Column.Group>
      </div> */}
      <div style={{ paddingBottom: 15 }}>
        {permissions.includes("003-003-004") && (
          <>
            <Text.H1>Last Employee Activity Date</Text.H1>
            <div className='lastActivity'>
              {lastActivityTime ? format(lastActivityTime, 'MMMM dd, yyyy') : 'No Data Found'}
            </div>
            {/* <Section style={{ height: 'auto', fontSize: '1.1em' }}>
              {lastActivityTime ? format(lastActivityTime, 'MMMM dd, yyyy') : 'No Data Found'}
            </Section> */}
          </>
        )}
      </div>

      {/* opition 2 */}
      <div className='barChartDiv'>
        {permissions.includes("003-003-003") && (
          <>
            <div style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "baseline"
            }}>
              <Text.H1>Employees Completing Courses & Media</Text.H1>
              <Icon
                tooltip={
                  'Courses – Displays number of employees each month that have completed a course.  Does not include pending courses. ' +
                  ' Media – Displays number of employees each month that have taken a Media.  Media refers to Videos, Webinars, Trainings and Podcasts.'}

                tooltipMultiline
                tooltipPosition="right"
                id="iconTooltip"
              >
                <FontAwesomeIcon icon={faQuestionCircle} />
              </Icon>
            </div>
            <Section style={{ height: !(eLiveSummary || isLoadingELive) && 'auto' }}>
              {(eLiveSummary && !isLoadingELive)
                ? (<BarGraphGeneral data={barChartData} dataKeyArr={barChartDataKeys} colors={['#48B5FF', '#124A80']} ></BarGraphGeneral>)
                : (isLoadingELive ? (<div style={{ height: 150, width: 150 }}><Loading.SmallLoading /> </div>) : (<div>No Data Found</div>))}
            </Section>
          </>
        )}
      </div>

      <div className="videoContainer">
        <Text.H1>eLive Connect Tutorial Video</Text.H1>
        <Section style={{ height: "100%" }}>
          <iframe
            src="https://www.youtube.com/embed/SDP27RTzxWo"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Section>
      </div>
    </div >
  );
};

ELivePage.propTypes = {};

export default ELivePage;
