export default {
  name: "Communication",
  code: "005",
  links: [
    {
      name: "Message Boards & Forum",
      code: "005-001",
      to: "/communication/message-boards-&-forum",
    },
    {
      name: "eMaxx Directory",
      code: "005-002",
      to: "/communication/emaxx-directory",
    },
    // {
    //   name: "Calendar",
    //   code: "005-003",
    //   to: "/communication/calendar",
    // },
  ],
};
