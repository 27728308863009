import React, { useState } from "react";

import { useHistory } from "react-router-dom";

import { useAuth } from "../../../context/AuthContext";

import Login from "../../../components/Login";

const LoginPage = (props) => {
  const history = useHistory();
  const { state: AuthState, handleLogin } = useAuth();
  const [state, setState] = useState({ email: "", password: "" });
  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin(state.email, state.password);
  };
  const handleChange = (name, value) => {
    setState((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="login">
      <Login.Form
        loading={AuthState.isLoginPending}
        onSubmit={handleSubmit}
        onChange={handleChange}
        onCancel={() => history.push("/")}
      />
    </div>
  );
};

LoginPage.propTypes = {};

export default LoginPage;
