import React from "react";
import { Link } from "react-router-dom";
import Section from "../../../components/Section";
import Text from "../../../components/Text";
import Button from "../../../components/Button";

import "./MotorVehicleRecords.scss";

const MotorVehicleRecordsPage = () => {
  // HAVE A LINK HERE BUT NEED TO KNOW HOW TO TIE IT TO LOGIN FORM
  return (
    <div id="MotorVehicleRecordsPageContainer">
      <div className="headerContainer">
        <Text.H2>Embark Safety</Text.H2>
        <div className="headerButtonContainer">
          <a
            href="https://connect.embarksafety.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button.Round>Embark Safety</Button.Round>
          </a>
          <Link
            to="/risk-management/motor-vehicle-records/get-started"
          >
            <Button.Round>Get Started</Button.Round>
          </Link>
        </div>
      </div>
      <div className="videoContainer">
        <Section style={{ height: "100%" }}>
          <iframe
            title="Embark Safety MVR Monitoring DEMO"
            src="https://www.youtube.com/embed/ve9AT4M5Pao"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Section>
      </div>
      {/* <Section>
        <form action="Submit">
          <Text.H2>Username / Email</Text.H2>
          <input id="form-text" type="text" />
          <Text.H2>Password</Text.H2>
          <input id="form-text" type="text" />
        </form>
      </Section>
      <div className="checkbox-holder">
        <input type="checkbox" name="" id="checkbox" />
        <div>I am Not A Robot</div>
      </div>
      <div className="buttonHolder">
        <Button.Round>Submit</Button.Round>
        <Button.Round type="secondary">Forgot Password</Button.Round>
      </div> */}
    </div>
  );
};

export default MotorVehicleRecordsPage;
