import React, { useEffect, useState, useMemo } from "react";
import { useQuery } from "react-query";
import { groupBy } from "lodash";
import { useAuth } from "../../../context/AuthContext";
import GovernanceService from "../../../services/GovernanceService";
import Section from "../../../components/Section";
import Text from "../../../components/Text";
import Loading from "../../../components/Loading";

import "./MembershipDirectory.scss";

const MemberReferencesPage = ({ passDownCurrentProg, changeCurrentProgram }) => {
  const { state: authState } = useAuth();

  // gets all data for membership
  const {
    data: membershipReferences = [],
    isLoadingMembershipReferences,
    errorMembershipReferences,
    refetch: refetchMembershipReferences
  } = useQuery("membershipReferences", () =>
    GovernanceService.getMembershipReferences(passDownCurrentProg)
  );
  // groups into section by type
  const memberReferencesData = groupBy(membershipReferences, "Type");

  useMemo(() => {
    refetchMembershipReferences();// Prop changed did not rerender instead of using key for the child component, use useMemo to detect and retech docs with new program

  }, [passDownCurrentProg]);


  if (isLoadingMembershipReferences) {
    return (
      <div className="headerContainer">
        <Text.H1>Claims</Text.H1>
        <Loading.SmallLoading />
      </div>
    );
  }
  if (errorMembershipReferences) {
    return null;
  }

  return (
    <div id="MemDirectoryContainer">
      {Object.keys(memberReferencesData).length ? (
        Object.keys(memberReferencesData).map((group) => (
          <div className="tableSection" key={group}>
            <Text.H1>{group}</Text.H1>
            <Section id="memberReferences">
              <div className="tableContainer">
                {memberReferencesData[group].map((person) => (
                  <div className="table-item">
                    <div>{`${person.FirstName} ${person.LastName}`}</div>
                    {/* <div>{person.Title}</div>  Megan only wanted to display name and email 10-23-2023*/}
                    {/* <div>{person.company}</div> */}
                    {/* <div>{`${person.BusinessCity}, ${person.BusinessState}`}</div> */}
                    <div>{person.BusinessEmail}</div>
                    {/* <div>{person.BusinessPhone?.formatted || ""}</div> */}
                  </div>
                ))}
              </div>
            </Section>
          </div>
        ))
      ) : (
        <Section>No References Found.</Section>
      )}
    </div>
  );
};

export default MemberReferencesPage;
