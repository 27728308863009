import React from "react";
import PropTypes from "prop-types";

import "./StatusBadge.scss";

export default function StatusBadge({ children, type, ...props }) {
  return (
    <div className="statusBadge">
      <div {...props} className={`badge ${type}`}>
        {children}
      </div>
    </div>
  );
}

StatusBadge.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string
};
