import React from "react";
import PropTypes from "prop-types";

import "./Form.scss";

export default function Form({ children, ...props }) {
  return (
    <span {...props} className="formText">
      {children}
      <span className="form-star"> *</span>
    </span>
  );
}

Form.propTypes = {
  children: PropTypes.node.isRequired,
};

Form.displayName = "Form";