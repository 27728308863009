import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// import { Button } from "rbx";
import Button from "../Button";

import Text from "../Text";

const propTypes = {
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

const defaultProps = {
  loading: false,
  onChange: () => {},
  onSubmit: () => {},
  onCancel: () => {},
};

const LoginForm = ({ loading, onChange, onSubmit, onCancel }) => (
  <div className="LoginPageContent">
    <div className="titleHolder">
      <Text.H1>Log in</Text.H1>
    </div>
    <form id="login-form" className="form" onSubmit={onSubmit}>
      <div className="form-control">
        <Text.H3 style={{ marginBottom: 4 }}>Email</Text.H3>
        <input
          className="input"
          type="email"
          id="email"
          name="email"
          autoComplete="email"
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </div>
      <div className="form-control">
        <Text.H3 style={{ marginBottom: 4 }}>Password</Text.H3>
        <input
          className="input"
          id="password"
          name="password"
          type="password"
          autoComplete="password"
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </div>
      <Link to="/forgot" className="smallOrange">
        Forgot Password?
      </Link>
      <div className="button-container">
        {/* <Button.Round onClick={onCancel}>
          Cancel
        </Button.Round> */}
        <Button.Round
          // rounded
          type="submit"
          // color="link"
          onClick={onSubmit}
          disabled={loading}
        >
          Login
        </Button.Round>
      </div>
    </form>
  </div>
);

LoginForm.propTypes = propTypes;

LoginForm.defaultProps = defaultProps;
export default LoginForm;
