import React, { useState } from "react";

import { useHistory } from "react-router-dom";

import { toast } from "react-toastify";

import Text from "../../../components/Text";

import { AuthService } from "../../../services";

import ForgotForm from "../../../components/Login/ForgotForm";

const ForgotPage = () => {
  const history = useHistory();
  const [state, setState] = useState({ email: "" });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    success: false,
    text: "",
  });

  const handleChange = (name, value) => {
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const body = {
        email: state.email,
        origin: window.location.origin,
      };
      await AuthService.forgotPassword(body);

      setMessage({
        text:
          "Please check your email for further instructions on how to reset your password.",
        success: true,
      });
      toast.success("Password Reset Request Sent");
    } catch (err) {
      setMessage({
        text: "There was an error processing your request. Please try again.",
        success: false,
        error: err.message,
      });
      toast.error("Error Processing Password Reset Request");
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    history.go(-1);
  };

  return (
    <div className="forgot">
      <div className="title-holder">
        <Text.H1>Forgot Password</Text.H1>
      </div>
      <ForgotForm
        loading={loading}
        message={message}
        onChange={handleChange}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        inputs={state}
      />
    </div>
  );
};

ForgotPage.propTypes = {};

export default ForgotPage;
