import React from "react";
import PropTypes from "prop-types";

import "./H3.scss";

export default function H3({ children, classNames, ...props }) {
  return (
    <h3 className={["heading3", classNames].join(" ")} {...props}>
      {children}
    </h3>
  );
}

H3.propTypes = {
  children: PropTypes.node.isRequired,
  classNames: PropTypes.array,
};

H3.defaultProps = {
  classNames: [],
};

H3.displayName = "H3";
