import React, { useState, useEffect, useContext } from "react";
import { Prompt } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation, faInbox, faUpload, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import lodash from "lodash";
import { toast } from "react-toastify";
import { Field, Label, Control, Input, Checkbox, Select, Textarea, File } from "rbx";
import Section from "../../../components/Section";
import Text from "../../../components/Text";
import Button from "../../../components/Button";
import RoundButton from "../../../components/Button/Round";
import { StateSelect, TextFieldInput } from "../../../components/inputs";
import STATES from "../../../components/inputs/StateSelect/states"; //"../../../ states";
import { useAuth } from "../../../context/AuthContext";
import ClaimsService from "../../../services/ClaimsService";
import { DocumentService } from "../../../services/index";
import Loading from "../../../components/Loading";
import "./EmailClaim.scss";
import ConfirmationPage from "./Confirmation";




const EmailClaimsPage = () => {

	const currentClient = useAuth();
	//add confirmation number to email 06202023
	const [confirmation, setConfirmation] = useState({
		confirming: '',
		confirmationNum: ''
	});
	const [loading, setLoading] = useState(false);
	const [emailState, setEmailState] = useState({
		//check if login clientId is same as clientId to determin if pre populate the email and phone and correct address(not eMaxx admin or Broker address)
		company: currentClient.state.clientName,
		email: currentClient.state.clientId === currentClient.state.loginClientId ? currentClient.state.userEmail : "",
		street: currentClient.state.clientId === currentClient.state.loginClientId ? currentClient.state.loggedInClientAddress : currentClient.state.clientAddress,
		city: currentClient.state.clientId === currentClient.state.loginClientId ? currentClient.state.loggedInClientCity : currentClient.state.clientCity,
		state: currentClient.state.clientId === currentClient.state.loginClientId ? currentClient.state.loggedInClientState : currentClient.state.clientState,
		zip: currentClient.state.clientId === currentClient.state.loginClientId ? currentClient.state.loggedInClientZipCode : currentClient.state.clientZipCode,
		comment: '',
		upload: []
	});
	const [fileSize, setFileSize] = useState(0);

	const canSubmit =
		emailState.company &&
		emailState.email &&
		emailState.street &&
		emailState.city &&
		emailState.state &&
		emailState.zip &&
		emailState.comment;


	// Use the submitted data to set the state
	const handleChange = (name, value, key, ob) => {
		setEmailState({ ...emailState, [key]: value });
	}

	let files = emailState.upload;

	const onFileChange = event => {

		if (event?.target?.files[0]) {
			//setFile({ file: event.target.files[0] });
			//check current file size + files in the array
			let sizeLimit = 19.8 * 1024 * 1024;
			let totalDocSize = 0;
			files.map(item => {
				totalDocSize += item.size
			});
			totalDocSize += event?.target?.files[0].size;

			if (event?.target?.files[0].size > sizeLimit) {
				toast.error("Document size can not go over 20MB")
				return;
			}
			if (totalDocSize > sizeLimit) {
				toast.error("Total Documents size can not go over 20MB")
				return;
			}

			let newFiles = files.concat(event?.target?.files[0]);
			handleChange('', newFiles, 'upload', newFiles);// this is modified for array here

			setFileSize(Math.round((totalDocSize / 1024 / 1024) * 10) / 10);
		}
	};

	const deleteFile = (file) => {
		let newFiles = [].concat(files);
		newFiles = newFiles.filter(item => {
			return item.name !== file.name
		});

		handleChange('', newFiles, 'upload', newFiles);

		if (newFiles.length === 0) {
			setFileSize(0);
		}
		else {
			let convertFileSize = fileSize * 10 / 10 * 1024 * 1024;
			setFileSize(Math.round((convertFileSize - file.size) / 1024 / 1024) * 10 / 10);
		}
	}

	const uint8ToString = (buf) => {
		var i, length, out = '';
		for (i = 0, length = buf.length; i < length; i += 1) {
			out += String.fromCharCode(buf[i]);
		}
		return out;
	}

	const handleSubmit = async (e) => {
		setLoading(true);
		e.preventDefault();

		let data = JSON.parse(JSON.stringify(emailState));
		data.upload = [].concat(emailState.upload);//after stringfy file in the array is gone, so do another assignment to new data obj

		let base64Files = [];
		for (let j = 0; j < data.upload.length; j++) {
			let fileToConvert = data.upload[j];
			let buffer = await fileToConvert.arrayBuffer();
			const bufferByteLength = buffer.byteLength;
			const bufferUint8Array = new Uint8Array(buffer, 0, bufferByteLength);

			let blob = btoa(uint8ToString(bufferUint8Array));

			//upload to S3 to get filePath
			let formData = new FormData();
			formData.append("file", data.upload[j]);
			let itemUrl = await DocumentService.DocumentUpload(formData);
			//upload to S3 to get filePath

			base64Files.push({ name: fileToConvert.name, base64: blob, filePath: itemUrl.data.Payload });
		}
		data.upload = base64Files;


		const cur = new Date();
		const mm = cur.getMonth() + 1 <= 9 ? '0' + (cur.getMonth() + 1) : cur.getMonth() + 1;
		const dd = cur.getDate() <= 9 ? '0' + cur.getDate() : cur.getDate();
		const yy = cur.getFullYear().toString().substring(2);
		const ss = cur.getSeconds();
		const confirmationNum = 'CN' + mm + dd + yy + ss + (Math.floor(1000000 + Math.random() * 9000000)).toString() + '_E';

		data.confirmationNum = confirmationNum;

		let template = '<table width="800" cellpadding="3" cellspacing="3">';

		template += `<tr><td style='font-weight:bold'>Confirmation Number : </td> <td>${confirmationNum}</td></tr>`;
		template += `<tr><td>Company : </td> <td>${emailState.company}</td></tr>`;
		template += `<tr><td>Email : </td> <td>${emailState.email}</td></tr>`;
		template += `<tr><td>Address : </td> <td>${emailState.street}</td></tr>`;
		template += `<tr><td>City : </td> <td>${emailState.city}</td></tr>`;
		template += `<tr><td>State : </td> <td>${emailState.state}</td></tr>`;
		template += `<tr><td>Comments : </td> <td>${emailState.comment}</td></tr>`;

		data.upload.forEach((item, key) => {
			template += `<tr><td>AWS File Path for eTech only ${key + 1} : </td> <td>${item.filePath}</td></tr>`;
		});
		template += '</table>';


		data.template = template;

		let clientId = currentClient.state.clientId;
		let reportingClientId = currentClient.state.loginClientId;

		let rep = await ClaimsService.fnolEmail(clientId, reportingClientId, 'email', data).then(
			result => {
				if (result.data.Error.ErrorStatus === 0) {
					toast.success("Claim has been Emailed. A claim representative will contact you shortly.");
					//setConfirmation('confirming');
					setConfirmation({ ...confirmation, confirming: 'confirming', confirmationNum: confirmationNum });
				}
				else {
					toast.error("Email Claim Failed");
				}

				//reset the currentState after submitting and go back to first page
				setEmailState({
					//...emailState,
					company: '',
					email: '',
					street: '',
					city: '',
					state: '',
					zip: '',
					comment: '',
					upload: []
				})
				setLoading(false);
			}
		);
	}
	if (confirmation.confirming === 'confirming') {
		return (
			<ConfirmationPage confirmation={confirmation} />
		);
	}

	return (
		<>
			<Prompt when={emailState.company} message='Email has not been sent. Do you want to leave the page?' />
			<Text.H2>Email</Text.H2>
			<Section>
				<div id='EmailClaimContainer'>
					<Field horizontal>
						<Field.Label size="normal">
							<Label>Company <span className="requiredStar"> *</span></Label>
						</Field.Label>
						<Field.Body>
							<Field>
								<Input type="text" name="company" value={emailState.company} onChange={(e) => { handleChange(e.target.name, e.target.value, e.target.name, { [e.target.name]: e.target.value }) }} />
							</Field>
						</Field.Body>
					</Field>
					<Field horizontal>
						<Field.Label size="normal">
							<Label>Email <span className="requiredStar"> *</span></Label>
						</Field.Label>
						<Field.Body>
							<Field>
								<Input type="text" name="email" value={emailState.email} onChange={(e) => { handleChange(e.target.name, e.target.value, e.target.name, { [e.target.name]: e.target.value }) }} />
							</Field>
						</Field.Body>
					</Field>

					<Field horizontal>
						<Field.Label size="normal">
							<Label>Address<span className="requiredStar"> *</span></Label>
						</Field.Label>

						<Field.Body>
							<Field>
								<Input type="text" name="street" placeholder="Street" value={emailState.street} onChange={(e) => { handleChange(e.target.name, e.target.value, e.target.name, { [e.target.name]: e.target.value }) }} />
							</Field>
						</Field.Body>
					</Field>
					<Field horizontal>
						<Field.Label size="normal">
							<Label>City <span className="requiredStar"> *</span></Label>
						</Field.Label>
						<Field.Body>
							<Field>
								<Input type="text" name="city" placeholder="City" value={emailState.city} onChange={(e) => { handleChange(e.target.name, e.target.value, e.target.name, { [e.target.name]: e.target.value }) }} />
							</Field>
						</Field.Body>

						<Field.Label size="normal">
							<Label>State <span className="requiredStar"> *</span></Label>
						</Field.Label>
						<Field.Body>
							<Field>
								<Select.Container fullwidth>
									<Select
										name='state'
										value={emailState.state}
										onChange={(e) => { handleChange(e.target.name, e.target.value, e.target.name, { [e.target.name]: e.target.value }) }}
										//disabled={disabled || readOnly}
										className="selectField">
										<Select.Option value="">State</Select.Option>
										{Object.keys(STATES).map((key) => (
											<Select.Option key={key} value={key}>
												{key}
											</Select.Option>
										))}
									</Select>
								</Select.Container>
							</Field>
						</Field.Body>

						<Field.Label size="normal">
							<Label>Zip Code<span className="requiredStar"> *</span></Label>
						</Field.Label>
						<Field.Body>
							<Field>
								<Input type="text" name="zip" placeholder="Zip" value={emailState.zip} onChange={(e) => { handleChange(e.target.name, e.target.value, e.target.name, { [e.target.name]: e.target.value }) }} />
							</Field>
						</Field.Body>
					</Field>
					<Field horizontal>
						<Field.Label size="normal">
							<Label>Comments <span className="requiredStar"> *</span></Label>
						</Field.Label>
						<Field.Body>
							<Field>
								<Control>
									<Textarea
										name='comment'
										value={emailState.comment}
										onChange={(e) => { handleChange(e.target.name, e.target.value, e.target.name, { [e.target.name]: e.target.value }) }}
									/>
								</Control>
							</Field>
						</Field.Body>
					</Field>
				</div>
			</Section>

			<Section>
				<div id='EmailClaimUpload'>
					<Text.H3>Upload Documents</Text.H3>
					{fileSize > 0 && (
						<p style={{ fontWeight: 700, fontSize: '1em' }}>{`${fileSize}MB / 20MB`}</p>)}
					<Field horizontal className="input-holder">
						<Field.Body>
							<Field>
								<Control>
									<File hasName fullwidth>
										<File.Label>
											<File.Input
												name="resume"
												//accept="application/pdf"
												onChange={e => onFileChange(e)}
											/>
											<File.CTA>
												<File.Icon>
													<FontAwesomeIcon icon={faUpload} />
												</File.Icon>
												<File.Label as="span">Choose a File</File.Label>
											</File.CTA>
											{/* <File.Name>{file?.file?.name}</File.Name> */}

										</File.Label>
									</File>

								</Control>
							</Field>
							<Field>
								{files.map(item => <File.Name style={{ 'maxWidth': '30em' }}>{item.name} <FontAwesomeIcon icon={faTimesCircle} onClick={() => deleteFile(item)} /></File.Name>)}
							</Field>
						</Field.Body>
					</Field>

					{/* {fileSize > 0 && (
						<p style={{ fontWeight: 700, fontSize: '1em' }}>{`${fileSize}MB / 20MB`}</p>)} */}

					{/* <p style={{ fontWeight: 700, paddingTop: 20 }}>Share Larger Files and Dash Carmera Footage</p> */}
					{/* <RoundButton onClick={() => window.open(DropboxInstructionPDF)}>Dropbox</RoundButton> */}
					<p style={{ fontWeight: 700, fontSize: '1em' }}>For Larger Files and Dash Camera Footage, a link will be provided after the claim is submitted.</p>
					{/* <button onClick={() => window.open(DropboxInstructionPDF)}><span style={{ alignItems: 'center', marginRight: '0.5em' }}><FontAwesomeIcon icon={faInbox} /></span>Dropbox</button> */}

					{/* <a
						href="https://www.emaxxgroup.com/dashcam-upload"
						target="_blank"
						rel="noopener noreferrer"
					>
						<button><span style={{ alignItems: 'center', marginRight: '0.5em' }}><FontAwesomeIcon icon={faInbox} /></span>Dropbox</button>
					</a> */}
				</div>

			</Section>
			<div>
				{loading ? <Loading.SmallLoading /> :
					<RoundButton
						disabled={!canSubmit}
						className={
							!loading && canSubmit ? "roundButton primary" : "roundButton primary disabled"}
						onClick={(e) => handleSubmit(e)}>{loading ? 'Sending' : 'Send'} </RoundButton>}
			</div>

		</>
	);
}

export default EmailClaimsPage;




