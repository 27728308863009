import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";

import { Field, Label, Control, Input, Checkbox, Select, Radio, Textarea } from "rbx";
import RoundButton from "../../../../components/Button/Round";
import ExternalText from "../../../../components/Button/ExternalText";
import Section from "../../../../components/Section";
import Text from "../../../../components/Text";
import Button from "../../../../components/Button";

import { StateSelect, TextFieldInput } from "../../../../components/inputs";
import STATES from "../../../../components/inputs/StateSelect/states"; //"../../../ states";
import OtherVehicles from "./OtherVehicles";
import validator from 'validator';

const AdditionalInfo_WC = (Props) => {

  //   if (Props.currentState.currentStep !== 3) {
  //     return null;
  //   }
  console.log("render AdditionalInfo_wc");
  const [additionalInfoWCState, setAdditionalInfoWCState] = useState(Props.currentState.lossInfoDetail.additionalInfo_WC);
  //const [otherVehicleCount, setOtherVehicleCount] = useState(0);
  // const [otherVehicleCount, setOtherVehicleCount] = useState(Props.currentState.lossInfoDetail.otherVehicles.length);
  //const [otherVehicleLst, setOtherVehicleLst] = useState(Props.currentState.lossInfoDetail.otherVehicles);


  const additionalInfoWCControls = Props.additionalInfo_WCControls;

  const controlDisplayValues = Props.controlDisplayValues;
  const controlDependencies = Props.controlDependencies;

  // Use the submitted data to set the state
  const handleChange = (name, value, key, ob) => {

    setAdditionalInfoWCState({ ...additionalInfoWCState, ...ob });
  }

  const handleChangeRadio = (e, key, ob) => {

    let name = e.target.name;
    let value = e.target.value;

    setAdditionalInfoWCState({ ...additionalInfoWCState, ...ob });

  }


  //useEffect to update the parent state after additionalInfo state is finished the change due to asyn useState
  useEffect(() => {
    Props.handleChange('', '', 'lossInfoDetail', { 'additionalInfo_WC': additionalInfoWCState })



    //temp  solution for cross-field checking
    if (Props.currentState.lossInfoDetail.DateOfLoss && additionalInfoWCState.DateEmployerHadKnowledgeOfInjury) {
      //if (new Date(additionalInfoWCState.DateOfInjury) > new Date(additionalInfoWCState.DateEmployerHadKnowledgeOfInjury)) {

      // 02052024 remove Date of Injury filed, use Date of Loss for comparison
      if (new Date(Props.currentState.lossInfoDetail.DateOfLoss) > new Date(additionalInfoWCState.DateEmployerHadKnowledgeOfInjury)) {
        alert("Date of Loss can not be later than Date Employer Had Knowledge Of Injury");
        //additionalInfoWCState.DateEmployerHadKnowledgeOfInjury
        setAdditionalInfoWCState({ ...additionalInfoWCState, ...{ "DateEmployerHadKnowledgeOfInjury": '' } });
      }
    }

  }, [additionalInfoWCState, Props.currentState.lossInfoDetail.DateOfLoss]);






  return (
    <>
      {/* <Text.H3>Loss Info</Text.H3> */}
      {/* <TextFieldInput
                label="Company"
                name="company"
                value={Props.currentState.personalInfoDetail.company}
                onChange={Props.handleChange}
            /> */}
      <Text.H3>Additional Info</Text.H3>
      <h4 style={{ 'fontWeight': 800 }}>  </h4>
      {/* <div id='OLAdditionalInfoContainer'> */}
      <div>
        {

          additionalInfoWCControls.filter(f => f.ControlTypeCode === 'section').map(
            section => {
              return (
                <>
                  <h4 style={{ 'fontWeight': 700, 'fontSize': '1.3em' }}>{section.ControlLabel}</h4>
                  <br />
                  <div id='OLAdditionalInfoContainer'>
                    {
                      additionalInfoWCControls.filter(f => f.ParentControlsRecId == section.RecId).map(item => {
                        const controlDisplays = controlDisplayValues.filter(f => f.ControlsRecId === item.RecId);
                        const controlDepdnt = controlDependencies.find(f => f.ControlsRecId === item.RecId);

                        const controllingControl = additionalInfoWCControls.find(f => f.RecId == controlDepdnt?.ControlingControlsRecId)?.ControlLabelCode;


                        if (item.ControlTypeCode === 'textbox' && item.ControlDataTypeCode.toLowerCase() === 'string') {
                          return (
                            <>
                              {(!controlDepdnt ||
                                (controlDepdnt && Props.currentState.lossInfoDetail.additionalInfo_WC[controllingControl] == controlDepdnt.ControlingValue)

                              ) && (
                                  <Field horizontal>
                                    <Field.Label size="normal">
                                      <Label>{item.ControlLabel} {item.IsRequired && (<span className="requiredStar"> *</span>)}</Label>
                                    </Field.Label>

                                    <Field.Body>
                                      <Field>

                                        <Input
                                          className={(item.IsRequired && !Props.currentState.lossInfoDetail.additionalInfo_WC[item.ControlLabelCode])
                                            ? 'unfilledField' : ''}
                                          type="text" name={item.ControlLabelCode} value={Props.currentState.lossInfoDetail.additionalInfo_WC[item.ControlLabelCode]}
                                          onChange={(e) => { handleChange(e.target.name, e.target.value, 'additionalInfo_WC', { [e.target.name]: e.target.value }) }} />

                                        {item.ControlLabelCode.toLowerCase().includes('phone') &&
                                          (<><br />
                                            <span
                                              style={{
                                                color: "red"
                                              }}
                                            >
                                              {!Props.currentState.lossInfoDetail.additionalInfo_WC[item.ControlLabelCode] ||
                                                validator.isMobilePhone(Props.currentState.lossInfoDetail.additionalInfo_WC[item.ControlLabelCode], 'en-US')
                                                ? '' : 'Please enter vaild phone.'}
                                            </span></>)
                                        }
                                        {item.ControlLabelCode.toLowerCase().includes('ssn') &&
                                          (<><br />
                                            <span
                                              style={{
                                                color: "red"
                                              }}
                                            >
                                              {!Props.currentState.lossInfoDetail.additionalInfo_WC[item.ControlLabelCode] ||
                                                (/^\d{3}-?\d{2}-?\d{4}$/).test(Props.currentState.lossInfoDetail.additionalInfo_WC[item.ControlLabelCode])
                                                ? '' : 'Please enter vaild SSN.'}
                                            </span></>)
                                        }
                                      </Field>
                                    </Field.Body>
                                  </Field>)}
                            </>)
                        }
                        else if (item.ControlTypeCode === 'textbox' && item.ControlDataTypeCode.toLowerCase() === 'number') {
                          return (
                            <>
                              {(!controlDepdnt ||
                                (controlDepdnt && Props.currentState.lossInfoDetail.additionalInfo_WC[controllingControl] == controlDepdnt.ControlingValue)

                              ) && (
                                  <Field horizontal>
                                    <Field.Label size="normal">
                                      <Label>{item.ControlLabel} {item.IsRequired && (<span className="requiredStar"> *</span>)}</Label>
                                    </Field.Label>

                                    <Field.Body>
                                      <Field>

                                        <Input
                                          className={(item.IsRequired && !Props.currentState.lossInfoDetail.additionalInfo_WC[item.ControlLabelCode])
                                            ? 'unfilledField' : ''}
                                          type="text" name={item.ControlLabelCode} value={Props.currentState.lossInfoDetail.additionalInfo_WC[item.ControlLabelCode]}
                                          onChange={(e) => { handleChange(e.target.name, e.target.value, 'additionalInfo_WC', { [e.target.name]: e.target.value }) }} />
                                        <br />
                                        <span
                                          style={{
                                            color: "red"
                                          }}
                                        >
                                          {!Props.currentState.lossInfoDetail.additionalInfo_WC[item.ControlLabelCode] ||
                                            validator.isNumeric(Props.currentState.lossInfoDetail.additionalInfo_WC[item.ControlLabelCode])
                                            ? '' : 'Please enter vaild number.'}
                                        </span>

                                      </Field>
                                    </Field.Body>
                                  </Field>
                                )}
                            </>
                          )
                        }
                        else if (item.ControlTypeCode === 'dropdown') {
                          return (
                            <>
                              {(!controlDepdnt ||
                                (controlDepdnt && Props.currentState.lossInfoDetail.additionalInfo_WC[controllingControl] == controlDepdnt.ControlingValue)

                              ) && (
                                  <Field horizontal>

                                    <Field.Label size="normal">
                                      <Label>{item.ControlLabel} {item.IsRequired && (<span className="requiredStar"> *</span>)}</Label>
                                    </Field.Label>
                                    <Field.Body>
                                      <Field>
                                        <div className={(item.IsRequired && !Props.currentState.lossInfoDetail.additionalInfo_WC[item.ControlLabelCode])
                                          ? 'unfilledField' : ''}>
                                          {item.ControlLabelCode.toLowerCase().includes('state') ?
                                            (

                                              <Select.Container fullwidth>
                                                <Select
                                                  name={item.ControlLabelCode}
                                                  value={Props.currentState.lossInfoDetail.additionalInfo_WC[item.ControlLabelCode]}
                                                  onChange={(e) => { handleChange(e.target.name, e.target.value, 'additionalInfo_WC', { [e.target.name]: e.target.value }) }}
                                                  className="selectField">
                                                  <Select.Option value="">State</Select.Option>
                                                  {Object.keys(STATES).map((key) => (
                                                    <Select.Option key={key} value={key}>
                                                      {key}
                                                    </Select.Option>
                                                  ))}
                                                </Select>
                                              </Select.Container>

                                            ) :
                                            (<Select.Container fullwidth>
                                              <Select
                                                name={item.ControlLabelCode}
                                                value={Props.currentState.lossInfoDetail.additionalInfo_WC[item.ControlLabelCode]}
                                                onChange={(e) => { handleChange(e.target.name, e.target.value, 'additionalInfo_WC', { [e.target.name]: e.target.value }) }}
                                                //disabled={disabled || readOnly}
                                                className="selectField"
                                              >
                                                <Select.Option value="">Select Option</Select.Option>
                                                {controlDisplays.map((item) => (
                                                  <Select.Option key={item.DisplayValue} value={item.DisplayValue}>
                                                    {item.DisplayLabel}
                                                  </Select.Option>
                                                ))}
                                              </Select>
                                            </Select.Container>)}
                                        </div>
                                      </Field>
                                    </Field.Body>
                                  </Field>
                                )}
                            </>
                          )
                        }
                        else if (item.ControlTypeCode === 'radio') {
                          return (
                            <>
                              {(!controlDepdnt ||
                                (controlDepdnt && Props.currentState.lossInfoDetail.additionalInfo_WC[controllingControl] == controlDepdnt.ControlingValue)

                              ) && (
                                  <Field horizontal>

                                    <Field.Label size="normal">
                                      <Label>{item.ControlLabel} {item.IsRequired && (<span className="requiredStar"> *</span>)}</Label>
                                    </Field.Label>
                                    <Field.Body>
                                      <Field>

                                        <div className={(item.IsRequired && !Props.currentState.lossInfoDetail.additionalInfo_WC[item.ControlLabelCode])
                                          ? 'unfilledField' : ''}>
                                          {controlDisplays.length > 0 && controlDisplays.sort((a, b) => a.Order - b.Order).map(
                                            m => {
                                              return (
                                                <>
                                                  <span>{m.DisplayValue}</span>
                                                  <Radio
                                                    className='radio'
                                                    name={item.ControlLabelCode} style={{ 'width': 'auto', 'marginRight': '5%', 'marginLeft': '5%' }}
                                                    checked={Props.currentState.lossInfoDetail.additionalInfo_WC[item.ControlLabelCode] && Props.currentState.lossInfoDetail.additionalInfo_WC[item.ControlLabelCode] == m.DisplayValue}
                                                    //value={Props.currentState.lossInfoDetail.additionalInfo_WC[item.ControlLabelCode]}
                                                    value={m.DisplayValue}
                                                    onChange={(e) => { handleChangeRadio(e, 'additionalInfo_WC', { [e.target.name]: e.target.value }) }}
                                                  />

                                                </>
                                              )
                                            }
                                          )}
                                        </div>




                                        {/* <Radio className='radio' name={item.ControlLabelCode}
                                                                checked={Props.currentState.lossInfoDetail.additionalInfo_WC[item.ControlLabelCode]}
                                                                value={Props.currentState.lossInfoDetail.additionalInfo_WC[item.ControlLabelCode]}
                                                                onChange={(e) => { handleChange(e.target.name, e.target.value, 'additionalInfo_WC', { [e.target.name]: e.target.value }) }}
                                                            /> <span>Yes</span>
                                                            <Radio className='radio' name={item.ControlLabelCode}
                                                                checked={Props.currentState.lossInfoDetail.additionalInfo_WC[item.ControlLabelCode]}
                                                                value={Props.currentState.lossInfoDetail.additionalInfo_WC[item.ControlLabelCode]}
                                                                onChange={(e) => { handleChange(e.target.name, e.target.value, 'additionalInfo_WC', { [e.target.name]: e.target.value }) }}
                                                            /> <span>No</span> */}
                                      </Field>
                                    </Field.Body>
                                  </Field>
                                )}
                            </>)
                        }
                        else if (item.ControlTypeCode === 'calendar') {
                          return (
                            <>
                              {(!controlDepdnt ||
                                (controlDepdnt && Props.currentState.lossInfoDetail.additionalInfo_WC[controllingControl] === controlDepdnt.ControlingValue)

                              ) && (
                                  <Field horizontal>
                                    <Field.Label size="normal">
                                      <Label>{item.ControlLabel} {item.IsRequired && (<span className="requiredStar"> *</span>)}</Label>
                                    </Field.Label>
                                    <Field.Body>
                                      <Field>
                                        <Control>
                                          <Input className={(item.IsRequired && !Props.currentState.lossInfoDetail.additionalInfo_WC[item.ControlLabelCode])
                                            ? 'unfilledField' : ''}
                                            max={new Date().toISOString().split('T')[0]}
                                            type="date"
                                            name={item.ControlLabelCode}
                                            value={Props.currentState.lossInfoDetail.additionalInfo_WC[item.ControlLabelCode]}
                                            //onChange={(e) => { Props.handleChange(e.target.name, e.target.value, 'additionalInfo_WC', { [e.target.name]: e.target.value }) }}

                                            onChange={(e) => { handleChange(e.target.name, e.target.value, 'additionalInfo_WC', { [e.target.name]: e.target.value }) }}

                                            onKeyDown={(e) => e.preventDefault()}
                                          />


                                        </Control>
                                      </Field>
                                    </Field.Body>
                                  </Field>)}
                            </>)
                        }
                        else if (item.ControlTypeCode === 'textarea') {
                          return (
                            <>
                              {(!controlDepdnt ||
                                (controlDepdnt && Props.currentState.lossInfoDetail.additionalInfo_WC[controllingControl] == controlDepdnt.ControlingValue)

                              ) && (
                                  <Field horizontal>

                                    <Field.Label size="normal">
                                      <Label>{item.ControlLabel} {item.IsRequired && (<span className="requiredStar"> *</span>)}</Label>
                                    </Field.Label>
                                    <Field.Body>
                                      <Field>
                                        <Control>
                                          <Textarea
                                            className={(item.IsRequired && !Props.currentState.lossInfoDetail.additionalInfo_WC[item.ControlLabelCode])
                                              ? 'unfilledField' : ''}
                                            name={item.ControlLabelCode}
                                            value={Props.currentState.lossInfoDetail.additionalInfo_WC[item.ControlLabelCode]}
                                            onChange={(e) => { handleChange(e.target.name, e.target.value, 'additionalInfo_WC', { [e.target.name]: e.target.value }) }}
                                          />
                                        </Control>
                                      </Field>
                                    </Field.Body>
                                  </Field>
                                )}
                            </>
                          )
                        }
                      }
                      )
                    }
                  </div>
                  <br />

                </>
              )
            }
          )
        }

      </div>
      <div>
        {/* <Text.H3> Other Party Info</Text.H3> */}


      </div>





    </>
  );
}

export default AdditionalInfo_WC;




