import React from "react";
import { useQuery } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
// eslint-disable-next-line no-unused-vars
import WeatherIcons from "weather-underground-icons";

import Text from "../../../components/Text";
import Section from "../../../components/Section";
import "./WeatherDashboard.scss";
import WeatherService from "../../../services/WeatherService";
import { useAuth } from "../../../context/AuthContext";
import Loading from "../../../components/Loading";

// const icons = [
//   { skyDescriptions: ["chance of flurries"], icon: "chanceflurries" },
//   { skyDescriptions: ["flurries"], icon: "flurries" },
//   { skyDescriptions: ["chance of freezing rain"], icon: "chancesleet" },
//   { skyDescriptions: ["freezing rain"], icon: "sleet" },
//   { skyDescriptions: ["chance of rain"], icon: "chancerain" },
//   { skyDescriptions: ["rain"], icon: "rain" },
//   { skyDescriptions: ["chance of thunderstorms"], icon: "chancestorms" },
//   { skyDescriptions: ["thunderstorms"], icon: "tstorms" },
//   { skyDescriptions: ["chance of snow"], icon: "chancesnow" },
//   { skyDescriptions: ["snow"], icon: "snow" },
//   { skyDescriptions: ["mostly cloudy"], icon: "mostlycloudy" },
//   { skyDescriptions: ["partly cloudy", "clouds"], icon: "partlycloudy" },
//   { skyDescriptions: ["cloudy", "overcast", "cloudiness"], icon: "cloudy" },
//   { skyDescriptions: ["mostly sunny"], icon: "mostlysunny" },
//   { skyDescriptions: ["partly sunny"], icon: "partlysunny" },
//   { skyDescriptions: ["sunny"], icon: "sunny" },
//   { skyDescriptions: ["fog"], icon: "fog" },
//   { skyDescriptions: ["haze"], icon: "haze" },
//   { skyDescriptions: ["clear"], icon: "clear" },
// ];

// const findIcon = (skyDescription) => {
//   let iconName = "unknown";
//   console.log("1", skyDescription);
//   iconName = icons.find((icon) => {
//     const match = icon.skyDescriptions.filter((description) => {
//       skyDescription.includes(description);
//     });

//     if (match.length) {
//       console.log("!#@#$%", icon);
//       return icon.icon;
//     }
//   });
//   console.log("!!!!", iconName);
//   return iconName;
// };

const findIcon = skyDescription => {
  let icon = "unknown";
  if (skyDescription.includes("chance of flurries")) {
    icon = "chanceflurries";
  } else if (skyDescription.includes("chance of rain")) {
    icon = "chancerain";
  } else if (skyDescription.includes("chance of freezing rain")) {
    icon = "chancesleet";
  } else if (skyDescription.includes("chance of snow")) {
    icon = "chancesnow";
  } else if (skyDescription.includes("chance of thunderstorms")) {
    icon = "chancestorms";
  } else if (skyDescription.includes("clear")) {
    icon = "clear";
  } else if (
    skyDescription.includes("cloudy") ||
    skyDescription.includes("overcast") ||
    skyDescription.includes("cloudiness")
  ) {
    icon = "cloudy";
  } else if (skyDescription.includes("flurries")) {
    icon = "flurries";
  } else if (skyDescription.includes("fog")) {
    icon = "fog";
  } else if (skyDescription.includes("haze")) {
    icon = "haze";
  } else if (skyDescription.includes("mostly cloudy")) {
    icon = "mostlycloudy";
  } else if (skyDescription.includes("mostly sunny")) {
    icon = "mostlysunny";
  } else if (
    skyDescription.includes("partly cloudy") ||
    skyDescription.includes("clouds")
  ) {
    icon = "partlycloudy";
  } else if (
    skyDescription.includes("partly sunny") ||
    skyDescription.includes("breaks of sun")
  ) {
    icon = "partlysunny";
  } else if (skyDescription.includes("rain")) {
    icon = "rain";
  } else if (skyDescription.includes("freezing rain")) {
    icon = "sleet";
  } else if (skyDescription.includes("snow")) {
    icon = "snow";
  } else if (skyDescription.includes("sunny")) {
    icon = "sunny";
  } else if (skyDescription.includes("thunderstorms")) {
    icon = "tstorms";
  }
  return icon;
};

const WeatherDashboard = () => {
  const { state: authState, permissions } = useAuth();
  // const userClientId = authState.clientId;
  // const userZipCode = authState.clientZipCode;

  // fetch weather data
  const { data: weatherObj = [], isLoading, error } = useQuery(
    "weather",
    () =>
      WeatherService.getWeatherData(
        authState.clientId,
        authState.clientZipCode
      )
    //{ fetchPolicy: "no-cache", cacheTime: 0 } casue mobile device issue that not loading properly 10/14/2022
  );

  if (isLoading) {
    return (
      <>
        <Text.H1 style={{ marginBottom: 0 }}>Weather</Text.H1>
        <Loading.SmallLoading />
      </>
    );
  }

  if (error || !weatherObj?.current || !weatherObj?.tomorrow) {
    return null;
  }

  // find alert(s) and only display risk section if one exists
  const alerts = [
    weatherObj.current.skyInfoAlert,
    weatherObj.current.temperatureAlert,
    weatherObj.current.percipitationAlert
  ];

  const hasAlert = alerts[0] || alerts[1] || alerts[3];
  let todayIcon = "unknown";
  let tomorrowIcon = "unknown";

  // sets underground weather icon to correct icon
  if (weatherObj !== []) {
    todayIcon = findIcon(weatherObj.current.skyDescription.toLowerCase());
    tomorrowIcon = findIcon(weatherObj.tomorrow.skyDescription.toLowerCase());
  }

  return (
    weatherObj && (
      <>
        <Text.H1 style={{ marginBottom: 0 }}>Weather</Text.H1>
        <div className="weatherLocation">
          {`${weatherObj.current.city}, ${weatherObj.current.state}`}
        </div>
        <div className="weatherContainer">
          {permissions.includes("001-001") && (
            <Section>
              <div className="pictureHeader">
                <i className={`wu wu-black wu-128 wu-${todayIcon}`} />
                <div>
                  {Math.floor(
                    parseFloat(weatherObj.current.temperature) * 1.8 + 32
                  )}
                  °
                </div>
              </div>
              <div className="weatherLabel">Today</div>
              <div className="weatherDescription">
                {`${weatherObj.current.skyDescription}, ${weatherObj.current.temperatureDesc
                  }, ${Math.floor(
                    parseFloat(weatherObj.current.temperature) * 1.8 + 32
                  )}°F`}
              </div>
            </Section>
          )}
          {permissions.includes("001-002") && (
            <Section>
              <div className="pictureHeader">
                <i className={`wu wu-black wu-128 wu-${tomorrowIcon}`} />
                <div>
                  {Math.floor(
                    parseFloat(weatherObj.tomorrow.highTemperature) * 1.8 + 32
                  )}
                  °
                </div>
              </div>
              <div className="weatherLabel">Tomorrow</div>
              {`${weatherObj.tomorrow.skyDescription}, ${weatherObj.tomorrow.temperatureDesc
                }, ${Math.floor(
                  parseFloat(weatherObj.tomorrow.lowTemperature) * 1.8 + 32
                )}°F - ${Math.floor(
                  parseFloat(weatherObj.tomorrow.highTemperature) * 1.8 + 32
                )}°F`}
            </Section>
          )}
          {hasAlert && permissions.includes("001-003") && (
            <Section>
              <div className="pictureHeader">
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  className="fileIcon"
                  size="5x"
                />
              </div>
              <div className="weatherLabel">Risk Management Action</div>
              {alerts.map(alert => {
                if (alert) {
                  return (
                    <div className="weatherDescription" key="alert">
                      {alert}
                    </div>
                  );
                }
                return null;
              })}
              {/* PHASE 2 */}
              {/* <TextButton style={{ margin: "4em 0 0 0" }}>
              Notify All Employees
            </TextButton> */}
            </Section>
          )}
        </div>
      </>
    )
  );
};

export default WeatherDashboard;
