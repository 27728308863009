import React from "react";
import PropTypes from "prop-types";

import { Field, Control, Input, Label, Help } from "rbx";
import Button from "../Button";

const ForgotForm = ({
  inputs,
  loading,
  message,
  onChange,
  onSubmit,
  onCancel,
}) => {
  return (
    <form id="forgot-password-form" onSubmit={onSubmit}>
      <Field>
        <Label size="large" style={{ color: "white" }}>
          Email Address
        </Label>
        <Control>
          <Input
            type="email"
            name="email"
            color={message.success ? "success" : "danger"}
            placeholder="Email Address"
            value={inputs.email}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
        <Help color={message.success ? "success" : "danger"}>
          {message.text}
        </Help>
      </Field>
      <div className="button-container">
        <Button.Round
          type="button"
          classNames={["secondary"]}
          onClick={onCancel}
        >
          Cancel
        </Button.Round>

        <Button.Round
          form="forgot-password-form"
          type="submit"
          disabled={loading}
          className={
            loading ? "roundButton primary disabled" : "roundButton primary"
          }
          state={loading ? "loading" : ""}
        >
          Submit
        </Button.Round>
      </div>
    </form>
  );
};

ForgotForm.propTypes = {
  inputs: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  message: PropTypes.object,
};

ForgotForm.defaultProps = {
  inputs: {},
  loading: false,
  message: {},
  onSubmit: () => null,
  onCancel: () => null,
  onChange: () => null,
};

export default ForgotForm;
