import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";

import { Field, Label, Control, Input, Checkbox, Select } from "rbx";
import Section from "../../../../components/Section";
import Text from "../../../../components/Text";
import Button from "../../../../components/Button";
import { StateSelect, TextFieldInput } from "../../../../components/inputs";
import STATES from "../../../../components/inputs/StateSelect/states"; //"../../../ states";
import validator from 'validator';


var goNext = () => { }
const PersonalInfo = (Props) => {

	const [isAllValid, setIsAllValid] = useState(true);


	useEffect(() => {

		//07-26-2023 add validation
		if (!validator.isEmail(Props.currentState.personalInfoDetail.email)) {
			setIsAllValid(false)
		}

		else if (!validator.isMobilePhone(Props.currentState.personalInfoDetail.phone, 'en-US')) {
			setIsAllValid(false)
		}
		else {
			setIsAllValid(true)
		}
		//07-26-2023 add validation



		goNext();
	}, [Props.currentState.personalInfoDetail]);

	if (Props.currentState.currentStep !== 2) {
		return null;
	}

	//temp solution to disable next button when required fields not filled
	var nextBtn = document.getElementsByTagName('button');
	nextBtn = Array.from(nextBtn).filter(item => item.innerHTML === 'Next');


	let anyEmpty = Object.keys(Props.currentState.personalInfoDetail)
		.find(f => Props.currentState.personalInfoDetail[f] === null ||
			Props.currentState.personalInfoDetail[f] === '' || Props.currentState.personalInfoDetail[f] === undefined || !Props.currentState.personalInfoDetail[f]);

	if ((nextBtn.length > 0 && anyEmpty) || !isAllValid) {//07-26-2023 add validation
		nextBtn[0].disabled = true;
		nextBtn[0].classList.add("disabled");
	}
	else if (!anyEmpty && isAllValid) {//07-26-2023 add validation
		nextBtn[0].disabled = false;
		nextBtn[0].classList.remove("disabled");
	}

	goNext = () => {
		if (!anyEmpty && isAllValid) {//07-26-2023 add validation
			if (!nextBtn[0]) return;
			nextBtn[0].disabled = false;
			nextBtn[0].classList.remove("disabled");
		}
	}
	//temp solution to disable next button when required fields not filled


	return (
		<>
			{/* <Text.H3>Personal Info</Text.H3> */}


			{/* <TextFieldInput
                    label="Company"
                    name="company"
                    value={Props.currentState.personalInfoDetail.company}
                    onChange={Props.handleChange}
                /> */}
			<div id='OLPersonalInfoContainer'>
				<Text.H3>Personal Info</Text.H3>

				<Field horizontal>
					<Field.Label size="normal">
						<Label>Company <span className="requiredStar"> *</span></Label>
					</Field.Label>
					<Field.Body>
						<Field>
							<Input type="text" name="company" value={Props.currentState.personalInfoDetail.company} onChange={(e) => { Props.handleChange(e.target.name, e.target.value, 'personalInfoDetail', { [e.target.name]: e.target.value }) }} />
						</Field>
					</Field.Body>
				</Field>
				<Field horizontal>
					<Field.Label size="normal">
						<Label>Email <span className="requiredStar"> *</span></Label>
					</Field.Label>
					<Field.Body>
						<Field>
							<Input type="email"
								style={{ borderColor: !Props.currentState.personalInfoDetail.email || validator.isEmail(Props.currentState.personalInfoDetail.email) ? '' : 'red' }}
								name="email" value={Props.currentState.personalInfoDetail.email}
								onChange={(e) => { Props.handleChange(e.target.name, e.target.value, 'personalInfoDetail', { [e.target.name]: e.target.value }) }}
							/>
							<br />
							<span
								style={{
									//fontWeight: "bold",
									color: "red"
								}}
							>
								{!Props.currentState.personalInfoDetail.email || validator.isEmail(Props.currentState.personalInfoDetail.email)
									? ''
									: 'Please enter vaild email.'}
							</span>
						</Field>
					</Field.Body>
				</Field>
				<Field horizontal>

					<Field.Label size="normal">
						<Label>Phone <span className="requiredStar"> *</span></Label>
					</Field.Label>
					<Field.Body>
						<Field>
							<Input type="text" name="phone"
								style={{ borderColor: !Props.currentState.personalInfoDetail.phone || validator.isMobilePhone(Props.currentState.personalInfoDetail.phone, 'en-US') ? '' : 'red' }}
								value={Props.currentState.personalInfoDetail.phone}
								onChange={(e) => { Props.handleChange(e.target.name, e.target.value, 'personalInfoDetail', { [e.target.name]: e.target.value }) }} />
							<br />
							<span
								style={{
									//fontWeight: "bold",
									color: "red"
								}}
							>
								{!Props.currentState.personalInfoDetail.phone || validator.isMobilePhone(Props.currentState.personalInfoDetail.phone, 'en-US') ? '' : 'Please enter vaild phone.'}
							</span>

						</Field>
					</Field.Body>
					<Field.Label size="normal">
						<Label>Policy Number <span className="requiredStar"> *</span></Label>
					</Field.Label>


					{Props.currentState.claimTypeDetail.claimType === 'Property' ?
						(<Field.Body>
							<Field>
								<Input type="text" name="policyNumber"
									value={Props.currentState.personalInfoDetail.policyNumber}
									onChange={(e) => { Props.handleChange(e.target.name, e.target.value, 'personalInfoDetail', { [e.target.name]: e.target.value }) }} />
							</Field>
						</Field.Body>) :
						(<Field.Body>
							<Field>
								<Select.Container fullwidth>
									<Select
										className="selectField"
										name="policyNumber"
										value={Props.currentState.personalInfoDetail.policyNumber}
										onChange={(e) => { Props.handleChange(e.target.name, e.target.value, 'personalInfoDetail', { [e.target.name]: e.target.value }) }}
									>
										{Props.currentState.personalInfoDetail.policyList.length > 0 && Props.currentState.personalInfoDetail.policyList.map((item) => (
											<Select.Option key={item.toString()} value={item.toString()}>
												{item.toString()}
											</Select.Option>
										))}
									</Select>
								</Select.Container>
							</Field>
						</Field.Body>)

					}

				</Field>
				<Field horizontal>
					<Field.Label size="normal">
						<Label>Insured Mailing Address <span className="requiredStar"> *</span></Label>
					</Field.Label>

					<Field.Body>
						<Field>
							<Input type="text" name="street" placeholder="Street" value={Props.currentState.personalInfoDetail.street} onChange={(e) => { Props.handleChange(e.target.name, e.target.value, 'personalInfoDetail', { [e.target.name]: e.target.value }) }} />
						</Field>
					</Field.Body>
				</Field>
				<Field horizontal>
					<Field.Label size="normal">
						<Label>City <span className="requiredStar"> *</span></Label>
					</Field.Label>
					<Field.Body>
						<Field>
							<Input type="text" name="city" placeholder="City" value={Props.currentState.personalInfoDetail.city} onChange={(e) => { Props.handleChange(e.target.name, e.target.value, 'personalInfoDetail', { [e.target.name]: e.target.value }) }} />
						</Field>
					</Field.Body>

					<Field.Label size="normal">
						<Label>State <span className="requiredStar"> *</span></Label>
					</Field.Label>
					<Field.Body>
						<Field>
							{/* <Input type="text" name="state" placeholder="State" value={Props.currentState.personalInfoDetail.state} onChange={(e) => { Props.handleChange(e.target.name, e.target.value, 'personalInfoDetail', { [e.target.name]: e.target.value }) }} /> */}
							<Select.Container fullwidth>
								<Select
									name='state'
									value={Props.currentState.personalInfoDetail.state}
									onChange={(e) => { Props.handleChange(e.target.name, e.target.value, 'personalInfoDetail', { [e.target.name]: e.target.value }) }}
									//disabled={disabled || readOnly}
									className="selectField">
									<Select.Option value="">State</Select.Option>
									{Object.keys(STATES).map((key) => (
										<Select.Option key={key} value={key}>
											{key}
										</Select.Option>
									))}
								</Select>
							</Select.Container>
						</Field>
					</Field.Body>

					<Field.Label size="normal">
						<Label>Zip Code <span className="requiredStar"> *</span></Label>
					</Field.Label>
					<Field.Body>
						<Field>
							<Input type="text" name="zip" placeholder="Zip" value={Props.currentState.personalInfoDetail.zip} onChange={(e) => { Props.handleChange(e.target.name, e.target.value, 'personalInfoDetail', { [e.target.name]: e.target.value }) }} />
						</Field>
					</Field.Body>
				</Field>
				<div>
					<Field horizontal>
						<Label style={{ textAlign: 'center' }}>
							<Checkbox className="inputCheckBox"
								name='confirmed'
								onChange={(e) => { Props.handleChange(e.target.name, e.target.value, 'personalInfoDetail', { [e.target.name]: e.target.checked }) }}
								checked={Props.currentState.personalInfoDetail.confirmed} /> <span className='inputCheckBoxLabel'>YES, I CONFIRM THAT THE ABOVE INFORMATION IS CORRECT</span><span className="requiredStar"> *</span>
						</Label>
					</Field>

					{/* <Label>
						<Checkbox className="inputCheckBox"
							name='confirmed'
							onChange={(e) => { Props.handleChange(e.target.name, e.target.value, 'personalInfoDetail', { [e.target.name]: e.target.checked }) }}
							checked={Props.currentState.personalInfoDetail.confirmed} /> <span>YES, I CONFIRM THAT THE ABOVE INFORMATION IS CORRECT</span>
					</Label> */}
				</div>
			</div>
			{/* <Field.Label size="normal">
                <Label>
                    {label}
                    <span className="requiredStar"> *</span>
                </Label>
                </Field.Label>
                <Field.Body>
                <Field>
                    <Control>
                    <Input
                        type={type}
                        disabled={disabled}
                        name={name}
                        value={value}
                        onChange={(e) => onChange(e.target.name, e.target.value)}
                    />
                    </Control>
                </Field>
            </Field.Body> */}
		</>
	);
}

export default PersonalInfo;




