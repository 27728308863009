import React from "react";
import PropTypes from "prop-types";

import "./Notifications.scss";
// Notifications buttons on div with onClick property given to link
export default function Notifications({ children, ...props }) {
 
  return (
    <div{...props} className="notificationsButton">
      <div class="dot"></div>
      {children}
    </div>
  );
}

Notifications.propTypes = {
  children: PropTypes.node.isRequired,
};

Notifications.displayName = "NotificationsButton";