import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ClaimsPage from "./ClaimsPage";
import ClaimsResourcesPage from "./ClaimsResourcesPage";
import ClaimsNotificationPage from "./ClaimsNotificationPage";
import ClaimsNotificationEMaxxPage from "./ClaimsNotificationPage_eMaxx";
import { useAuth } from "../../context/AuthContext";

const ClaimsRouter = () => {
  const { permissions } = useAuth();
  return (
    <Switch>
      {/* it has to be before the /claims router to prevent from claims polisid and claimsId url logic to kick Li */}
      {/* permission check will bring to the home page after refresh Li*/}
      {permissions.includes("004-001") && (
        <Route path="/claims/claims-notifications">
          <ClaimsNotificationPage />
        </Route>)}
      {permissions.includes("004-001") && (
        <Route path="/claims/claims-notifications-emaxx">
          <ClaimsNotificationEMaxxPage />
        </Route>)}
      {permissions.includes("004-002") && (
        <Route exact path="/claims/claim-resources">
          <ClaimsResourcesPage />
        </Route>
      )}
      {permissions.includes("004-001") && (
        <Route path="/claims">
          <ClaimsPage />
        </Route>
      )}

      <Redirect to="/" />
    </Switch>
  );
};

export default ClaimsRouter;
