import APIService from "./index";

const LPSService = {
  getLossControlTracker: (clientId) =>
    APIService.get(`/GetLossControlTracker?clientId=${clientId}`).then(
      (res) => res.data.Payload
    ),
  getBestPracticesByClientId: (clientId) =>
    APIService.get(`/GetBestPracticesByClientId?clientId=${clientId}`).then(
      (res) => res.data.Payload
    ),
  getBestPracticeScoresByProgram: (ProgramsRecId) =>
    ProgramsRecId
      ? APIService.get(
        `/GetBestPracticeScoresByProgram?ProgramsRecId=${ProgramsRecId}`
      ).then((res) => res.data.Payload)
      : [],
  //Li add for warranties and recommdations
  getWarrantiesRecommdationsByClientId: (clientId) =>
    APIService.get(`/GetWarrantiesRecommdationsByClientId?clientId=${clientId}`).then(
      (res) => res.data.Payload
    ),
  //Li Add Loss Control Retainer services
  getLossControlRetainerServices: (clientId) =>
    APIService.get(`/GetLossControlRetainerServices?clientId=${clientId}`).then(
      (res) => res.data.Payload
    )
};

export default LPSService;
