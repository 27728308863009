/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
//import { Link, useHistory, useLocation } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import { format, parseISO } from "date-fns";
import Section from "../../components/Section";
import Button from "../../components/Button";
import Text from "../../components/Text";
import { Table, Generic, Field, Control, Checkbox, Icon } from "rbx";
import StatusBadge from "../../components/StatusBadge";
import DataTable from "../../components/DataTable";
import Loading from "../../components/Loading";
import ColorDivider from "../../components/Divider";
import ClaimsService from "../../services/ClaimsService";
import ClientService from "../../services/ClientService";
import { useAuth } from "../../context/AuthContext";
import COLUMNS from "./columns";

import ClaimsPanelTest from "./ClaimsPanelTest";

import "./claimsTables.scss";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

const ClaimsTables = ({
  data,
  policyId,
  claimId,
  history,
  location,
  noteId,
  brokerId,
  clientName,
  setClaimNumber,
  setPolicyNumber,
}) => {
  const [activeKey, setActiveKey] = useState("Commercial Auto");
  const { state: authState, permissions } = useAuth();
  // manually triggered when pathname changes state and will then complete query and also the same feature will cause
  // the data table to be rendered instead of the policy table below
  // this is all based on claimId and policyId being passed in above

  // variables to keep track of values for claims passed down from policy without making another call to policy
  const [LOB, setLOB] = useState("--");
  const [EffectivePolicyRange, setEffectivePolicyRange] = useState("--");
  const [CarrierName, setCarrierName] = useState("--");
  // const [notes, setNotes] = useState("--");
  const [lossRunsId, setLossRunsId] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [loadingBool, setLoadingBool] = useState(false);
  /// /////

  //Li claim notification
  const [fromNotification, setFromNotification] = useState(location.state?.fromNotification);
  //const fromNotification = location.state?.fromNotification;

  useMemo(() => {
    if (location.state?.fromNotification) {

      let policyId = location.pathname.split('/')[2];

      let found;
      for (let lob of data) {
        found = lob.items.find((f) => f.PolicyId.toString() === policyId.toString())

        if (found) break;
      }

      if (!found) return;

      setLOB(found.LOB);
      setCarrierName(found.CarrierName);
      setEffectivePolicyRange(
        `${format(parseISO(found.EffectiveDate), "MM/dd/yyyy")} - ${format(
          parseISO(found.ExpirationDate),
          "MM/dd/yyyy"
        )}`
      );
    }
    //console.log(fromNotification)
    //setFromNotification(fromNotification);

  }, [fromNotification]);
  //Li claim notification


  // Loss Runs
  const { data: lossRuns = [], loading, refetch } = useQuery(
    "lossRunReport",
    () => ClaimsService.getLossRunReport([lossRunsId], authState.clientId),
    { enabled: false, fetchPolicy: "no-cache" }
  );


  // CLAIMS
  const {
    data: claims = [],
    isLoadingClaims,
    errorClaims,
  } = useQuery(
    "claimsByPolicy",
    () => ClaimsService.getClaimsByPolicy(policyId),
    { enabled: policyId, fetchPolicy: "no-cache", cacheTime: 0 }
  );

  // CLAIM
  const { data: claim = [], isLoadingClaim, errorClaim } = useQuery(
    "claim",
    () => ClaimsService.getClaimByRecID(claimId),
    { enabled: claimId, fetchPolicy: "no-cache", cacheTime: 0 }
  );

  // Broker
  const { data: broker = [], isLoadingBroker, errorBroker } = useQuery(
    "clientByClientId",
    () => ClientService.getBroker(brokerId),
    {
      enabled: claimId,
      fetchPolicy: "no-cache",
      cacheTime: 0,
    }
  );

  // NOTES
  const {
    data: notes = [],
    loading: isLoadingNotes,
    error: errorNotes,
  } = useQuery("notesByClaim", () => ClaimsService.getNotesByClaim(noteId), {
    enabled: noteId,
    fetchPolicy: "no-cache",
    cacheTime: 0,
  });

  const sortedNotes = notes.sort(
    (a, b) =>
      Date.parse(b.NoteEffDate.valueOf()) - Date.parse(a.NoteEffDate.valueOf())
  );

  //  BASE 64 stuff
  // The Base64 string of a simple PDF file
  // separated into two getlossRuns becasue there was a timing issue with the querys the the window.atob; works now and staying away for now

  const getLossRuns2 = async (byteCharacters) => {
    setToggle(false);
    /// //////////////////////////////////////////////////////////////////////////////
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const file = new Blob([byteArray], { type: "application/pdf;base64" });
    const fileURL = URL.createObjectURL(file);

    setLoadingBool(false);

    window.open(fileURL);
    /// //////////////////////////////////////////////////////////////////////////////
  };

  const getLossRuns = async (b64) => {
    // eslint-disable-next-line prefer-const

    const promise1 = new Promise((resolve, reject) => {
      resolve(window.atob(b64));
    });

    promise1.then((value) => {
      getLossRuns2(value);
    });
  };

  const handleLossRuns = async (item) => {
    const itemArr = item.split("/");
    setLossRunsId(itemArr[2]);
  };

  useEffect(() => {
    if (
      typeof lossRuns.Base64 === "string" &&
      !loading &&
      toggle &&
      lossRunsId
    ) {
      getLossRuns(lossRuns.Base64);
    }
  }, [lossRuns, toggle]);

  useMemo(async () => {
    if (lossRunsId) {
      setLoadingBool(true);
      await refetch();
    }
    await setToggle(true);
  }, [lossRunsId]);

  const handleRowClick = (row) => {
    // console.log(row);
    setClaimNumber(row.ClaimNumber);
    history.push(`${location.pathname}/${row.RecId}`);
  };

  const handleViewClick = (item) => {
    setFromNotification(location.state?.fromNotification);//Li claim notification

    setLOB(item.LOB);
    setCarrierName(item.CarrierName);
    setEffectivePolicyRange(
      `${format(parseISO(item.EffectiveDate), "MM/dd/yyyy")} - ${format(
        parseISO(item.ExpirationDate),
        "MM/dd/yyyy"
      )}`
    );
    setPolicyNumber(item.PolicyNumber);
    history.push(`${item.link}`);
  };



  if (loadingBool) {
    return (
      <div className="headerContainer">
        <Loading.SmallLoading />
      </div>
    );
  }
  if (errorClaims || errorClaim || errorBroker || errorNotes) {
    return null;
  }

  return (
    <Section id="claimsTabs">
      {/* ** search has not yet been set up */}
      {/* <ClaimsSearch /> */}

      {/* claims */}
      {policyId &&
        !claimId &&
        !noteId &&
        (claims.length >= 1 ? (
          <DataTable
            columns={COLUMNS}
            data={claims}
            onRowClick={handleRowClick}
            id="claimsForPolicy"
            sortBy={[
              {
                id: "LOB",
                desc: false,
              },
            ]}
          />
        ) : (
          <div className="headerContainer">
            <Text.H2>No Claims</Text.H2>
          </div>
        ))}
      {/* single claim */}
      {claimId && !Array.isArray(claim) && !noteId && (
        <>
          <Section>
            <h1>{`Carrier: ${CarrierName}`}</h1>
            <div className="gridContainer">
              <div className="leftColumn">
                <ColorDivider />
                <p className="boldText">{`Policy: ${claim.PolicyNumber}`}</p>
                <p className="boldText">{`Line: ${LOB}`}</p>
                <p className="boldText">{`Agent: ${broker?.ClientName ? broker?.ClientName : ''}`}</p>
                <p className="boldText">{`Effective: ${EffectivePolicyRange}`}</p>
                <p className="boldText bottomMarginLarge">{`eCaptiv Premium Year: ${claim.eCaptivPremiumYear}`}</p>

                <p className="boldText">{`Loss Type: ${claim.LossType}`}</p>
                <p className="">{`Loss Location: ${claim.LossLocation}`}</p>
                <p className="">
                  {`Date of Loss: ${claim.LossDate
                    ? format(parseISO(claim.LossDate), "MM/dd/yyyy")
                    : "--"
                    }`}
                </p>
                <p className="">
                  {`Reported: ${claim.ReportedDate
                    ? format(parseISO(claim.ReportedDate), "MM/dd/yyyy")
                    : "--"
                    }`}
                </p>
                <p className="bottomMarginLarge">
                  {`Valuation: ${claim.ValuationDate
                    ? format(parseISO(claim.ValuationDate), "MM/dd/yyyy")
                    : "--"
                    }`}
                </p>
                <p className="boldText">{`Claimant Name: ${claim.ClaimantName}`}</p>
                <p className="boldText bottomMarginLarge">{`Driver Name: ${claim.DriverName}`}</p>
              </div>
              <div className="rightColumn">
                <ColorDivider />
                <p className="flex-spaceBetween">
                  <span>Recovery Amount:</span>
                  <span>
                    {claim.Recovery || claim.Recovery === 0
                      ? formatter.format(claim.Recovery)
                      : "--"}
                  </span>
                </p>
                <p className="flex-spaceBetween">
                  <span>{LOB === 'Workers Compensation' ? 'Indemnity Reserve:' : 'Loss Reserve:'}</span>
                  <span>
                    {claim.LossReserve || claim.LossReserve === 0
                      ? formatter.format(claim.LossReserve)
                      : "--"}
                  </span>
                </p>
                <p className="flex-spaceBetween">
                  <span>{LOB === 'Workers Compensation' ? 'Indemnity Paid:' : 'Loss Paid:'}</span>
                  <span>
                    {claim.LossPaid || claim.LossPaid === 0
                      ? formatter.format(claim.LossPaid)
                      : "--"}
                  </span>
                </p>
                {/* <p className="flex-spaceBetween">
                  <span>Expense Reserve:</span>
                  <span>
                    {claim.ExpenseReserve || claim.ExpenseReserve === 0
                      ? formatter.format(claim.ExpenseReserve)
                      : "--"}
                  </span>
                </p>
                <p className="flex-spaceBetween">
                  <span>Expense Paid:</span>
                  <span>
                    {claim.ExpensePaid || claim.ExpensePaid === 0
                      ? formatter.format(claim.ExpensePaid)
                      : "--"}
                  </span>
                </p> */}
                {LOB === 'Workers Compensation' ?
                  (
                    <div>
                      <p className="flex-spaceBetween boldText">
                        <span>Expense Reserve</span>
                        <span>
                          {/* {claim.ExpensePaid || claim.ExpensePaid === 0
                      ? formatter.format(claim.ExpensePaid)
                      : "--"} */}
                        </span>
                      </p>
                      <p className="flex-spaceBetween">
                        <span style={{ textIndent: 15 }}>Expense Reserve excl. Medical:</span>
                        <span>
                          {claim.ExpExclMedReserveAmt || claim.ExpExclMedReserveAmt === 0
                            ? formatter.format(claim.ExpExclMedReserveAmt)
                            : "--"}
                        </span>
                      </p>
                      <p className="flex-spaceBetween">
                        <span style={{ textIndent: 15 }}>Medical Reserve:</span>
                        <span>
                          {claim.MedReserveAmt || claim.MedReserveAmt === 0
                            ? formatter.format(claim.MedReserveAmt)
                            : "--"}
                        </span>
                      </p>

                      <p className="flex-spaceBetween boldText">
                        <span>Expense Paid</span>
                        <span>
                          {/* {claim.ExpensePaid || claim.ExpensePaid === 0
                      ? formatter.format(claim.ExpensePaid)
                      : "--"} */}
                        </span>
                      </p>
                      <p className="flex-spaceBetween">
                        <span style={{ textIndent: 15 }}>Expense Paid excl. Medical:</span>
                        <span>
                          {claim.ExpExclMedPaidAmt || claim.ExpExclMedPaidAmt === 0
                            ? formatter.format(claim.ExpExclMedPaidAmt)
                            : "--"}
                        </span>
                      </p>

                      <p className="flex-spaceBetween">
                        <span style={{ textIndent: 15 }}>Medical Paid:</span>
                        <span>
                          {claim.MedPaidAmt || claim.MedPaidAmt === 0
                            ? formatter.format(claim.MedPaidAmt)
                            : "--"}
                        </span>
                      </p>
                    </div>
                  ) :
                  (
                    <div>
                      <p className="flex-spaceBetween">
                        <span>Expense Reserve:</span>
                        <span>
                          {claim.ExpenseReserve || claim.ExpenseReserve === 0
                            ? formatter.format(claim.ExpenseReserve)
                            : "--"}
                        </span>
                      </p>
                      <p className="flex-spaceBetween">
                        <span>Expense Paid:</span>
                        <span>
                          {claim.ExpensePaid || claim.ExpensePaid === 0
                            ? formatter.format(claim.ExpensePaid)
                            : "--"}
                        </span>
                      </p>
                    </div>
                  )
                }
                <p className="flex-spaceBetween bottomMarginLarge">
                  <span>Total Incurred:</span>
                  <span>
                    {claim.TotalIncurred || claim.TotalIncurred === 0
                      ? formatter.format(claim.TotalIncurred)
                      : "--"}
                  </span>
                </p>
                <Button.Round
                  onClick={() =>
                    history.push(
                      `${location.pathname}/Notes/${claim.ClaimNumber}`
                    )}
                >
                  View Notes
                </Button.Round>
              </div>
            </div>
            <p className="boldText">Description:</p>
            <p>{claim.FactsofLoss}</p>
            <div className="gridContainer">
              <div className="leftColumn">
                <ColorDivider />
                <p className="boldText">
                  {`Closed: ${claim.ClosedDate
                    ? format(parseISO(claim.ClosedDate), "MM/dd/yyyy")
                    : "--"
                    }`}
                </p>
                <p className="boldText">{`Adjuster: ${claim.AdjusterFirstName} ${claim.AdjusterLastName}`}</p>
                <p className="boldText">{`AdjusterEmail: ${claim.AdjusterEmail}`}</p>
                <p className="boldText">{`Litigation  Manager: ${claim.LitigationManagerNm}`}</p>
              </div>
              <div className="rightColumn">
                <div />
              </div>
            </div>
          </Section>
        </>
      )}
      {/* Notes */}
      {noteId && Array.isArray(notes) && notes.length >= 1 && (
        <ClaimsPanelTest data={sortedNotes} />
      )}

      {/* policies */}
      {!policyId && !claimId && !noteId && (
        <Tabs
          className="tabs"
          activeKey={activeKey}
          onSelect={(key) => setActiveKey(key)}
        >
          {/* data mapping will be in this format for policies and then claims, individual claim will accordian.  */}
          {data.map((group) => (
            <Tab className="tab" eventKey={group.title} title={group.title}>
              {group.items
                .sort((a, b) => {
                  return (
                    new Date(b.ExpirationDate) - new Date(a.ExpirationDate)
                  );
                })
                .map((item) => (
                  <li className="list-item">
                    <div className="id">
                      {item.PolicyNumber}
                    </div>
                    <StatusBadge
                      type={
                        new Date(item.ExpirationDate) > new Date()
                          ? "Active"
                          : "Expired"
                      }
                    >
                      {new Date(item.ExpirationDate) > new Date()
                        ? "Active"
                        : "Expired"}
                    </StatusBadge>
                    <div className="dateRange">
                      {format(parseISO(item.EffectiveDate), "MM/dd/yyyy")}
                      {' '}
                      -
                      {" "}
                      {format(parseISO(item.ExpirationDate), "MM/dd/yyyy")}
                    </div>
                    <div className="buttonHolder">
                      {permissions.includes("004-001-002") && (
                        <>
                          <Button.Text
                            onClick={() => {
                              handleViewClick(item);
                            }}
                          >
                            View Claims
                          </Button.Text>
                          <br />
                        </>
                      )}
                      {permissions.includes("004-001-003") && (
                        <Button.Text
                          onClick={() => {
                            handleLossRuns(item.link);
                          }}
                        >
                          View Loss Runs
                        </Button.Text>
                      )}
                    </div>
                  </li>
                ))}
            </Tab>
          ))}
        </Tabs>
      )}
    </Section>
  );
};

export default ClaimsTables;
