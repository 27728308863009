import React from "react";
import PropTypes from "prop-types";

import "./H2.scss";

export default function H2({ children, ...props }) {
 

  return (
    <h3 {...props} className="heading2">
      {children}
    </h3>
  );
}

H2.propTypes = {
  children: PropTypes.node.isRequired,
};

H2.displayName = "H2";
