import React from "react";
import PropTypes from "prop-types";

import "./Section.scss";

function Section({ children, ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...props} className="section">
      {children}
    </div>
  );
}

Section.propTypes = {
  children: PropTypes.node,
};

Section.defaultProps = {
  children: null,
};

export default Section;
