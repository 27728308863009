import APIService from "./index";

const PolicyService = {
  getPoliciesByClient: (clientId) => {
    if (!clientId) {
      return [];
    }
    const result = APIService.get("/getPoliciesByClient", {
      clientId,
    }).then((res) =>
      res.data.Payload.reduce(
        (acc, curr) =>
          curr.Policies ? [...acc, ...curr.Policies] : [...acc, []],
        []
      ).filter((policy) => new Date(policy.ExpirationDate) > new Date())
    );
    return result;
  },
  getPoliciesByClientNoFilter: (clientId) =>
    APIService.get("/getPoliciesByClient", { clientId }).then((res) => {
      // added condition for bad data
      if (res.data.Payload[0].LOBName) {
        return res.data.Payload.reduce(
          (acc, curr) => [...acc, ...curr.Policies],
          []
        );
      }
      return [];
    }),
};

export default PolicyService;
