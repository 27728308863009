/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";

import "./Round.scss";

export default function Round({
  children,
  onClick,
  type,
  classNames,
  ...props
}) {
  return (
    <button
      type={type}
      onClick={onClick}
      className={["roundButton", ...classNames].join(" ")}
      {...props}
    >
      {children}
    </button>
  );
}

Round.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  classNames: PropTypes.array,
  type: PropTypes.string,
};

Round.defaultProps = {
  type: "button",
  classNames: ["primary"],
  onClick: null,
};

Round.displayName = "RoundButton";
