import { format, parseISO } from "date-fns";
import React from "react";
// need parse ISO to convert the value given by sql into a date
const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
})

export default
    [
        {
            Header: "FirstName",
            accessor: "FirstName",
            id: "FirstName"
        },
        {
            Header: "LastName",
            accessor: "LastName",
            id: "LastName"
        },
        {
            Header: "Role",
            accessor: "roleName",
            id: "roleName"
        },
        {
            Header: "Email",
            accessor: "Email",
            id: "Email",
            Cell: ({ value }) => (
                <span style={{ overflow: "wrap", wordWrap: "break-word" }}>
                    {value}
                </span>
            )
        },
        {
            Header: "Phone",
            accessor: "Phone",
            id: "Phone"
        },
        {
            Header: "Notifications",
            accessor: "RecId",
            id: "RecId",
            Cell: ({ value }) => value ? (<span style={{ color: 'rgb(244, 126, 32)' }}>View</span>) : "--",

        }
    ];
