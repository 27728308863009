import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import "./Landing.scss";

import { TransitionGroup, CSSTransition } from "react-transition-group";

import LandingPage from "./LandingPage";
import LoginPage from "./LoginPage";
import ForgotPage from "./ForgotPage";
import ResetPage from "./ResetPage";

const LandingRouter = () => {
  const location = useLocation();
  return (
    <div className="LandingPageContainer">
      <TransitionGroup>
        <CSSTransition key={location.key} classNames="fade" timeout={300}>
          <Switch location={location}>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/forgot">
              <ForgotPage />
            </Route>
            <Route path="/reset/:token">
              <ResetPage />
            </Route>
            <Route path="/setup/:token">
              <ResetPage />
            </Route>
            <Route path="/">
              <LandingPage />
            </Route>
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default LandingRouter;
