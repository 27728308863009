import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Field, Control, Label, Input, Textarea, Help } from "rbx";
import Section from "../../components/Section";
import Text from "../../components/Text";
import Button from "../../components/Button";
import { useAuth } from "../../context/AuthContext";
import EmailService from "../../services/EmailService";
import Loading from "../../components/Loading";
import "./ContactUs.scss";

const ContactUsPage = () => {
  const { state: authState } = useAuth();
  // const { toggleModal } = useContext(ModalContext);
  // const history = useHistory();

  const [inputState, setinputState] = useState({
    FirstName: authState.FirstName,
    LastName: authState.LastName,
    Email: authState.userEmail,
    Body: "",
  });

  const [loading, setLoading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);

  const handleChange = (name, value) => {
    // e.preventDefault();
    // console.log("name", name, "value", value);
    setinputState((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    setCanSubmit(
      inputState.FirstName.length >= 1 &&
      inputState.LastName.length >= 1 &&
      inputState.Email.length >= 1 &&
      inputState.Body.length >= 1
    );
  }, [inputState]);

  const sendEmail = async (data) => {
    setLoading(true);
    let body = `<p> Form: Contact Us</p>`;
    Object.keys(data).forEach((key) => {
      body += `<p>${key}: ${data[key]}</p>`;
    });
    const to = "memberportal@emaxxgroup.com";
    //const to = "lliu@etechnologyservices.com";
    const subject = "Contact Us";
    await EmailService.SendEmail(to, subject, encodeURIComponent(body));
    // const today = new Date();
    const entryObj = {
      Origin: "Contact Us",
      User: authState.userEmail,
      Content: body,
    };
    const response = await EmailService.CreateEntry(entryObj).then(() =>
      toast.success("Message Sent Successfully")
    );
    // console.log("Response", response);
    setinputState({
      FirstName: authState.FirstName,
      LastName: authState.LastName,
      Email: authState.userEmail,
      Body: "",
    });
    setLoading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(e.target.value)
    // console.log(inputState);
    sendEmail(inputState);
  };

  if (loading) {
    return (
      <div id="ContactUsPageContainer">
        <Text.H1>Contact Us</Text.H1>
        <Loading.SmallLoading />
      </div>
    );
  }

  return (
    <div id="ContactUsPageContainer">
      <Text.H1>Contact Us</Text.H1>
      <Section>
        <form id="contact-us-form" className="form">
          <Field horizontal className="input-holder">
            <Field.Label>
              <Label>
                <Text.H3Form>Name</Text.H3Form>
              </Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    className="form-text"
                    id="small"
                    // color="danger"
                    type="text"
                    value={inputState.FirstName}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)}
                    readOnly
                    name="FirstName"
                  />
                </Control>
                <Help>First Name</Help>
              </Field>
              <Field>
                <Control>
                  <Input
                    className="form-text"
                    id="small"
                    // color="danger"
                    type="text"
                    value={inputState.LastName}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)}
                    readOnly
                    name="LastName"
                  />
                </Control>
                <Help>Last Name</Help>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal className="input-holder">
            <Field.Label>
              <Label>
                <Text.H3Form>Email</Text.H3Form>
              </Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    className="form-text"
                    id="large"
                    // color="danger"
                    type="text"
                    value={inputState.Email}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)}
                    readOnly
                    name="Email"
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal className="input-holder">
            <Field.Label>
              <Label>
                <Text.H3Form>What can we help you with?</Text.H3Form>
              </Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Textarea
                    className="form-textarea"
                    type="textbox"
                    value={inputState.Body}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)}
                    name="Body"
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>
          <div className="buttonHolder">
            <Button.Round
              onClick={handleSubmit}
              disabled={!canSubmit}
              className={
                canSubmit
                  ? "roundButton primary"
                  : "roundButton primary disabled"
              }
            >
              Submit
            </Button.Round>
          </div>
        </form>
      </Section>
    </div>
  );
};

export default ContactUsPage;
