import React, { useEffect, useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useQuery } from "react-query";

import Text from "../../../components/Text";
import Loading from "../../../components/Loading";
import UserService from "../../../services/UserService";
import ClaimsService from "../../../services/ClaimsService";
import RoundButton from "../../../components/Button/Round";
import { ModalContext } from "../../../components/Modals/ModalProvider";
import ConfirmationModal from "../../../components/Modals";
import UserForm from "./UserForm";
import "./Users.scss";

const UserEditPage = () => {
  const { userId } = useParams();
  const history = useHistory();
  const [inputs, setInputs] = useState({});
  const { toggleModal } = useContext(ModalContext);

  const { data: user, isLoading, error } = useQuery(["user", userId], () =>
    UserService.getUser(userId)
  );

  useEffect(() => {
    setInputs(user);
  }, [user]);

  // updates user and if nessesary, makes userRoleGroup inactive and creates another
  const handleSubmit = async (roleGroupsRecId) => {
    try {
      const {
        CreatedBy,
        CreatedDateTime,
        ModifiedBy,
        ModifiedComments,
        ModifiedDateTime,
        ...rest
      } = inputs;
      const { data: userData } = await UserService.updateUser(userId, {
        ...rest,
      });

      if (userData.Error.ErrorMessage !== null) {
        throw new Error(userData.Error.ErrorMessage);
      }

      if (roleGroupsRecId) {
        const {
          data: { Payload: originalRoleGroup = false },
        } = await UserService.getUserRoleGroup(user.RecId);

        if (roleGroupsRecId !== originalRoleGroup.RoleGroupsRecId) {
          const {
            data: updatedRoleGroup,
          } = await UserService.updateUserRoleGroup(originalRoleGroup.RecId, {
            UsersRecId: user.RecId,
            RoleGroupsRecId: originalRoleGroup.RoleGroupsRecId,
            IsActive: 0,
          });

          if (updatedRoleGroup.Error.ErrorMessage !== null) {
            throw new Error(updatedRoleGroup.Error.ErrorMessage);
          }

          const userRoleGroup = {
            UsersRecId: userData.Payload.RecId,
            RoleGroupsRecId: roleGroupsRecId,
            IsActive: 1,
          };

          const {
            data: userRoleGroupData,
          } = await UserService.createUserRoleGroup({ ...userRoleGroup });

          if (userRoleGroupData.Error.ErrorMessage) {
            throw new Error(userRoleGroupData.Error.ErrorMessage);
          }
        }
      }

      toast.success("User Edited Successfully");
      history.goBack();
    } catch (err) {
      console.log(err);
      toast.error("Whoops! We couldn't edit your user");
    }
  };

  const handleInputChange = (name, val) => {
    let value = val;
    if (val === true) {
      value = 1;
    } else if (val === false) {
      value = 0;
    }
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleDelete = async () => {
    try {

      // delete user,then find current userRoleGroup and make inactive
      await UserService.deleteUser(userId);
      const {
        data: { Payload: originalRoleGroup = false },
      } = await UserService.getUserRoleGroup(userId);
      await UserService.updateUserRoleGroup(originalRoleGroup.RecId, {
        UsersRecId: userId,
        RoleGroupsRecId: originalRoleGroup.RoleGroupsRecId,
        IsActive: 0,
      });

      //when deleting the user, update all user selected claim notification selections 04/25/2023
      const selections = await ClaimsService.getClaimNotificationSelectionsByUser(userId);

      if (selections && selections.length > 0) {
        selections.forEach(sel => {
          sel.IsSelected = false;
          sel.SendEmail = false;
          sel.ModifiedComments = 'User is deleted. unCheck the selections and SendEmail option'

        });

        const updateSelections = await ClaimsService.updateClaimNotificationSelectionsByUser(selections);
      }
      //when deleting the user, update all user selected claim notification selections


      toggleModal(false, "");
      history.go(-1);
    } catch (err) {
      console.log(err);
      toast.error("Whoops! We couldn't delete your user");
    }
  };

  if (isLoading) {
    return <Loading.SmallLoading />;
  }
  if (error) {
    return null;
  }
  return (
    <div id="user-edit-page">
      <div className="headerContainer">
        <Text.H1>Edit User</Text.H1>
        <RoundButton
          onClick={() =>
            toggleModal(
              true,
              <ConfirmationModal
                entity="user"
                toggleModal={toggleModal}
                handleAction={handleDelete}
                actionText="DELETE"
              />
            )
          }
        >
          Delete
        </RoundButton>
      </div>
      <UserForm
        user={inputs}
        onChange={handleInputChange}
        onSubmit={handleSubmit}
        onCancel={() => history.goBack()}
      />
    </div>
  );
};

export default UserEditPage;
