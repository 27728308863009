import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { Select, Field, Control, Label, Input, Textarea, File } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import Section from "../../components/Section";
import Text from "../../components/Text";
import Button from "../../components/Button";
import { DocumentService } from "../../services/index";
import ClientService from "../../services/ClientService";
import ProgramService from "../../services/ProgramService";
import Loading from "../../components/Loading";
import { startCase } from "../../utils";
import { groupBy } from "lodash";
import "./DocumentUpload.scss";

import PolicyService from "../../services/PolicyService";



const DocumentUploadPage = () => {
  const [loading, setLoading] = useState(false);
  // having issues with adding the File Object into this combined state separated out
  const [inputState, setInputState] = useState({
    Category: "",
    Member: "",
    Program: "",
    Tier: false,
    DocumentsRecId: "",
    Body: "",
    Date: ""
  });

  const [canSubmit, setCanSubmit] = useState(false);
  const [memberPrograms, setMemberPrograms] = useState([]);//Li program and client specific

  const [file, setFile] = useState({
    file: { name: "No File Added" }
  });

  // Document Types
  const { data: categories = [] } = useQuery("getDocumentTypes", () =>
    DocumentService.getDocumentTypes()
  );

  // Programs
  const { data: programs = [] } = useQuery(
    "getPrograms",
    () => ProgramService.getPrograms(),
    {
      //enabled: inputState.Tier === "Program"
    }
  );

  //get client history for the programs change
  let { data: clientsHistory = [] } = useQuery("clientsHistory", () =>
    ClientService.getClientsChangeHistory()
  );

  // Insured/Members
  const { data: members = [] } = useQuery(
    "getClients",
    () => ClientService.getClients(),
    {
      enabled: inputState.Tier === "Insured"
    }
  );

  //mix pc8,9(policy program) to regular program list by getting from the polciies
  // const { data: Policies = [] } = useQuery("policies", () =>
  //   PolicyService.getPoliciesByClientNoFilter( clientId),
  //   { enabled: false }
  // );

  useEffect(() => {

    setCanSubmit(
      inputState.Category?.Name?.length >= 1 &&
      ((inputState.Member.length >= 1 && inputState.Program.length >= 1) || inputState.Program.length >= 1) &&  // add checking program when Member is selected for client program specific
      inputState.Date.length >= 1 &&
      file &&
      file.file.size
    );
  }, [inputState, file]);

  const handleCategory = (name, valueObjJSON) => {
    const valueObj = JSON.parse(valueObjJSON);
    // category will be the Documents RecID
    setInputState(prev => ({
      ...prev,
      Category: valueObj,
      Tier: valueObj.Tier,
      DocumentsRecId: valueObj.RecId,
      Member: '', //Li program and client specific
      Program: '', //Li program and client specific
      Date: '' //Li program and client specific
    }));

    setMemberPrograms([]);//Li program and client specific
  };


  const handleChange = async (name, value) => {
    // resets Program upon Member change and vice et versa
    if (name === "Member") {
      //upload Insured specific with Program id
      let tempHistory = [];
      let currentProgram = members.find(f => f.ClientId === Number(value))?.ProgramsRecId;
      tempHistory.push(currentProgram);

      clientsHistory = clientsHistory.filter(f => f.ProgramsRecId && f.ClientId === Number(value) && f.ProgramsRecId !== currentProgram);
      let grouped = groupBy(clientsHistory, 'ProgramsRecId');

      Object.keys(grouped).forEach(item => {
        if (grouped[item].length > 0) {
          tempHistory.push(grouped[item][0].ProgramsRecId)
        }
      });




      let memberPrograms = [];
      tempHistory.forEach(item => {
        memberPrograms.push(programs.find(f => f.RecId === item));
      })


      //02-21-2024 mix Pc8,9 policy program to drop down

      let clientId = members.find(f => f.ClientId === Number(value))?.ClientId;
      let Policies = await PolicyService.getPoliciesByClientNoFilter(clientId)

      // add policy program to the drop down
      let groupedPolicyPrograms = groupBy(Policies, 'CategoryCode')

      Object.keys(groupedPolicyPrograms).forEach(key => {
        var existing = memberPrograms.find(f => f?.Code === key)
        if (!existing) {

          let prog = programs.find(f => f.Code === key);

          memberPrograms.push(prog);
        }
      });
      // add policy program to the drop down


      setMemberPrograms(memberPrograms);
      //upload Insured specific with Program id

      setInputState(prev => ({ ...prev, [name]: value, Program: "" }));
      //setInputState(prev => ({ ...prev, [name]: value, Program: "" }));
    } else if (name === "Program") {
      setInputState(prev => ({ ...prev, [name]: value, Member: "" }));
    } else {
      setInputState(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleChangeClientAndProgram = (name, value) => {
    // resets Program upon Member change and vice et versa
    if (name === "Program") {
      setInputState(prev => ({ ...prev, [name]: value }));
    }
  };

  // have codes send in full object, split the Name on _ and grab second to last,  use the second furthest back section as key in the hash pointing to the full code object in an array
  // have this be called throughout all codes array,
  // creates labels for the docTypes; make this more robust so that other parts can be easier

  const abbrevCodeHash = {};

  const codeToHash = codeObj => {
    let header;
    const codeArr = codeObj.Code.split("_");
    if (
      !codeObj.Code.startsWith("Documents Library_Insurance Policies_PolicyPDF")
      //&&
      //!codeObj.Code.startsWith("PC8") //separate out for PC8 series documentTypes
    ) {
      if (codeObj.Code.startsWith("Governance_Organization_Meetings")) {
        // 4th section which should follow the above
        header = codeArr[3].replace(/_/i, "");
      } else if (codeObj.Code.startsWith("Governance_Organization")) {
        // 3rd section which should follow the above
        header = codeArr[2].replace(/_/i, "");
      } else {
        header = codeArr[codeArr.length - 2].replace(/_/i, "");
      }
      if (Object.keys(abbrevCodeHash).includes(header)) {
        abbrevCodeHash[header].push(codeObj);
      } else {
        abbrevCodeHash[header] = [codeObj];
      }
    }

    /*
        //separate out for PC8 series documentTypes
        else if (
          !codeObj.Code.startsWith("Documents Library_Insurance Policies_PolicyPDF") &&
          codeObj.Code.startsWith("PC8")
        ) {
          if (codeObj.Code.startsWith("Governance_Organization_Meetings")) {
            // 4th section which should follow the above
            header = 'PC8-' + codeArr[3].replace(/_/i, "").replace('PC8-', '');// replace PC8 in any level header and concatenate in front 
          } else if (codeObj.Code.startsWith("Governance_Organization")) {
            // 3rd section which should follow the above
            header = 'PC8-' + codeArr[2].replace(/_/i, "").replace('PC8-', '');
          } else {
            header = 'PC8-' + codeArr[codeArr.length - 2].replace(/_/i, "").replace('PC8-', '');
          }
          if (Object.keys(abbrevCodeHash).includes(header)) {
            abbrevCodeHash[header].push(codeObj);
          } else {
            abbrevCodeHash[header] = [codeObj];
          }
        }
        //separate out for PC8 series documentTypes
    */
  };

  const abbrevCode = code => {
    return startCase(
      code.slice(code.lastIndexOf("_")).replace(/_/i, "").replace(/_/i, " ")
    );
  };

  const abbrevCode2 = code => {
    const codeArr = code.split("_");
    const searchCodes = [codeArr[3], codeArr[4]];
    const searchTerm = searchCodes
      .join(" ")
      .replace(/_/i, "")
      .replace(/_/i, " ");
    return searchTerm;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file.file);
    const url = await DocumentService.DocumentUpload(formData);

    const dateArr = inputState.Date.split("-");

    const docYear = dateArr[0];
    const docMonth = dateArr[1];
    const docDay = dateArr[2];

    // not actual month but helper to get quarter
    const currentMonth = Number(docMonth) / 4;
    let currentQuarter;
    switch (true) {
      case currentMonth < 0.8:
        currentQuarter = 1;
        break;

      case currentMonth >= 1 && currentMonth < 1.6:
        currentQuarter = 2;
        break;

      case currentMonth >= 1.75 && currentMonth < 2.3:
        currentQuarter = 3;
        break;

      case currentMonth > 2.4:
        currentQuarter = 4;
        break;

      default:
        currentQuarter = 0;
        break;
    }

    const docObj = {
      DocumentId: `${inputState.Category.Code}`,
      DocumentTypesRecId: `${inputState.DocumentsRecId}`,
      FilePath: url.data.Payload,
      FileName: `${file.file.name}`,
      Description: `${inputState.Body}`,
      ClientId: `${inputState.Member}`,
      ProgramsRecId: `${inputState.Program}`,
      Year: docYear,
      Month: docMonth,
      Day: docDay,
      Quarter: currentQuarter
    };
    const doc = await DocumentService.createDocument(docObj).then(() =>
      toast.success("Document Uploaded Successfully")
    );

    // reset state
    setInputState({
      Category: "",
      Member: "",
      Program: "",
      Tier: false,
      DocumentsRecId: "",
      Body: "",
      Date: ""
    });
    setFile({
      file: { name: "No File Added" }
    });
    setLoading(false);
  };


  // Fields: FilePath: Link to S3, DocumentTypesRecId: good, member or program RecId: good,
  const sortedCategories = categories.filter(f => f.IsActive && !f.ClientAllowUpload).sort((a, b) =>
    abbrevCode(a.Code).localeCompare(abbrevCode(b.Code))
  );
  const sortedMembers = members.sort((a, b) =>
    a.ClientName.localeCompare(b.ClientName)
  );
  const sortedPrograms = programs.sort((a, b) => a.Name.localeCompare(b.Name));

  const categoryHashCreate = async () => {
    await sortedCategories.forEach(element => {
      codeToHash(element);
    });
  };
  categoryHashCreate();

  const onFileChange = event => {
    if (event?.target?.files[0]) {
      setFile({ file: event.target.files[0] });
    }
  };

  if (loading) {
    return (
      <div id="DocumentUploadContainer">
        <Loading.SmallLoading />
      </div>
    );
  }

  return (
    <div id="DocumentUploadContainer">
      <Section>
        <form
          id="document-upload-form"
          className="form"
          onSubmit={handleSubmit}
        >
          {/* Category */}
          <Field horizontal className="input-holder">
            <Field.Body>
              <Field>
                <Field.Label>
                  <Label>
                    <Text.H3Form>Category</Text.H3Form>
                  </Label>
                </Field.Label>
                <Select.Container>
                  <Select
                    value={JSON.stringify(inputState.Category)}
                    id="small"
                    name="Category"
                    onChange={e =>
                      handleCategory(e.target.name, e.target.value)}
                  >
                    <Select.Option value="" style={{ display: "none" }}>
                      Category
                    </Select.Option>
                    {Object.keys(abbrevCodeHash)
                      .sort((a, b) => a.localeCompare(b))
                      .map(label => (
                        <optgroup label={label} key={label}>
                          {label === "Standing Committees" ||
                            label === "Organizational Documentation"
                            ? abbrevCodeHash[label]
                              .sort((a, b) =>
                                abbrevCode2(a.Code).localeCompare(
                                  abbrevCode2(b.Code)
                                )
                              )
                              .map(categoryObj => (
                                <Select.Option
                                  key={categoryObj.Code}
                                  value={JSON.stringify(categoryObj)}
                                >
                                  {abbrevCode2(categoryObj.Code)}
                                </Select.Option>
                              ))
                            : abbrevCodeHash[label]
                              .sort((a, b) =>
                                abbrevCode2(a.Code).localeCompare(
                                  abbrevCode2(b.Code)
                                )
                              )
                              .map(categoryObj => (
                                <Select.Option
                                  key={categoryObj.Code}
                                  value={JSON.stringify(categoryObj)}
                                >
                                  {abbrevCode(categoryObj.Code)}
                                </Select.Option>
                              ))}
                        </optgroup>
                      ))}
                  </Select>
                </Select.Container>
              </Field>
            </Field.Body>
          </Field>

          {/* Program optional */}
          {sortedPrograms.length > 0 && inputState.Tier === "Program" && (
            <Field horizontal className="input-holder" id="flexInput">
              <Field.Body>
                <Field>
                  <Field.Label>
                    <Label>
                      <Text.H3Form>Program</Text.H3Form>
                    </Label>
                  </Field.Label>

                  <Select.Container>
                    <Select
                      value={inputState.Program}
                      id="small"
                      name="Program"
                      onChange={e =>
                        handleChange(e.target.name, e.target.value)}
                    >
                      <Select.Option value="" style={{ display: "none" }}>
                        Program
                      </Select.Option>
                      {sortedPrograms.map(program => (
                        <Select.Option value={program.RecId}>
                          {program.Name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Select.Container>
                </Field>
                <Field>
                  <Field.Label>
                    <Label>
                      <Text.H3Form>Date</Text.H3Form>
                    </Label>
                  </Field.Label>
                  <Control>
                    <Input
                      type="date"
                      onChange={e =>
                        handleChange(e.target.name, e.target.value)}
                      name="Date"
                      value={inputState.Date}
                    />
                  </Control>
                </Field>
              </Field.Body>
            </Field>
          )}

          {/* Member Optional */}
          {sortedMembers.length > 0 && inputState.Tier === "Insured" && (
            <Field horizontal className="input-holder" id="flexInput">
              <Field.Body>
                <Field>
                  <Field.Label>
                    <Label>
                      <Text.H3Form>Member</Text.H3Form>
                    </Label>
                  </Field.Label>
                  <Select.Container>
                    <Select
                      value={inputState.Member}
                      id="small"
                      name="Member"
                      onChange={e =>
                        handleChange(e.target.name, e.target.value)}
                    >
                      <Select.Option value="" style={{ display: "none" }}>
                        Member
                      </Select.Option>
                      {sortedMembers.filter(f => f.ClientType === 'Policyholder').map(member => (
                        <Select.Option value={member.ClientId}>
                          {member.ClientName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Select.Container>
                </Field>

                <Field>
                  <Field.Label>
                    <Label>
                      <Text.H3Form>Program</Text.H3Form>
                    </Label>
                  </Field.Label>

                  <Select.Container>
                    <Select
                      value={inputState.Program}
                      id="small"
                      name="Program"
                      onChange={e =>
                        handleChangeClientAndProgram(e.target.name, e.target.value)}
                    >
                      <Select.Option value="" style={{ display: "none" }}>
                        Program
                      </Select.Option>
                      {memberPrograms.map(program => (
                        <Select.Option value={program.RecId}>
                          {program.Name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Select.Container>
                </Field>


                <Field>
                  <Field.Label>
                    <Label>
                      <Text.H3Form>Date</Text.H3Form>
                    </Label>
                  </Field.Label>
                  <Control>
                    <Input
                      type="date"
                      onChange={e =>
                        handleChange(e.target.name, e.target.value)}
                      name="Date"
                      value={inputState.Date}
                    />
                  </Control>
                </Field>
              </Field.Body>
            </Field>
          )}

          {/* Description */}
          <Field horizontal>
            <Field.Body>
              <Field className="input-holder">
                <Field.Label>
                  <Label>
                    <Text.H3>Description</Text.H3>
                  </Label>
                </Field.Label>
                <Control>
                  <Textarea
                    className="form-textarea"
                    type="textbox"
                    value={inputState.Body}
                    onChange={e => handleChange(e.target.name, e.target.value)}
                    name="Body"
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>
          {/* File Upload */}
          <Field horizontal className="input-holder">
            <Field.Body>
              <Field>
                <Control>
                  <File hasName fullwidth>
                    <File.Label>
                      <File.Input
                        name="resume"
                        accept="application/pdf"
                        onChange={e => onFileChange(e)}
                        multiple
                      />
                      <File.CTA>
                        <File.Icon>
                          <FontAwesomeIcon icon={faUpload} />
                        </File.Icon>
                        <File.Label as="span">Choose a File</File.Label>
                      </File.CTA>
                      <File.Name>{file?.file?.name}</File.Name>
                    </File.Label>
                  </File>
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <div className="buttonHolder">
            <Button.Round
              onClick={handleSubmit}
              disabled={!canSubmit}
              className={
                canSubmit
                  ? "roundButton primary"
                  : "roundButton primary disabled"
              }
            >
              Upload
            </Button.Round>
          </div>
        </form>
      </Section>
    </div>
  );
};

export default DocumentUploadPage;
