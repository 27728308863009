import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faArrowLeft,
  faArrowRight,
  faGlobe,
  faUpload,
  faDownload,
  faEnvelope,
  faLock,
  faCreditCard,
  faUser,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faArrowLeft,
  faArrowRight,
  faGlobe,
  faUpload,
  faDownload,
  faEnvelope,
  faLock,
  faCreditCard,
  faUser,
  faCheck,
);
