import APIService from "./index";

const eMaxxDirectoryService = {
  getEmaxxDirectory: () =>
    APIService.get(`/GetEmaxxDirectory`).then(
      (res) => res.data.Payload
    ),
    getTechnologyVendors: () =>
    APIService.get(`/GetTechnologyVendors`).then(
      (res) => res.data.Payload
    ),
};

export default eMaxxDirectoryService;