import React from "react";
import { Switch, Route } from "react-router-dom";

import PageContainer from "../components/PageContainer/PageContainer";
import DashboardPage from "./Dashboard";
import ClaimsRouter from "./Claims";
import InsuranceRouter from "./Insurance";
import RiskManagementRouter from "./RiskManagement";
import CommunicationRouter from "./Communication";
import UserSettingsRouter from "./Settings";
import FooterLinksRouter from "./FooterLinks";
import ClientLocatorPage from "./ClientLocator";
import DocumentUploadPage from "./DocumentUpload";
import ActivityLogPage from "./ActivityLog";
import ClaimsReportPage from "./Claims/ClaimsReport";
import { useAuth } from "../context/AuthContext";
import OnlineClaimsPage from "./Claims/ReportClaims/OnlineClaims/OnlineClaims";
import EmailClaimsPage from "./Claims/ReportClaims/EmailClaims";
import ClaimsNotificationPage from "./Claims/ClaimsNotificationPage";

function Pages() {
  const { permissions } = useAuth();
  // on different page load will set the scroll postion to the top of the page instead of saving last state
  // eslint-disable-next-line no-multi-assign
  document.body.scrollTop = document.documentElement.scrollTop = 0;
  return (
    <PageContainer>
      <Switch>
        <Route path="/insurance">
          <InsuranceRouter />
        </Route>
        <Route path="/claims">
          <ClaimsRouter />
        </Route>
        <Route exact path="/report-claim">
          <ClaimsReportPage />
        </Route>
        <Route path="/report-claim/online-claim">
          <OnlineClaimsPage />
        </Route>
        <Route path="/report-claim/email-claim">
          <EmailClaimsPage />
        </Route>

        {/* <Route path="/claim-notification">
          <ClaimsNotificationPage />
        </Route> */}

        <Route path="/risk-management">
          <RiskManagementRouter />
        </Route>
        <Route path="/communication">
          <CommunicationRouter />
        </Route>
        {(permissions.includes("000-001-002") ||
          permissions.includes("000-001-003")) && (
            <Route path="/user-settings">
              <UserSettingsRouter />
            </Route>
          )}
        <Route path="/footer">
          <FooterLinksRouter />
        </Route>
        {permissions.includes("000-001-001") && (
          <Route path="/select-company">
            <ClientLocatorPage />
          </Route>
        )}
        {permissions.includes("000-001-005") && (
          <Route path="/settings/activity-log">
            <ActivityLogPage />
          </Route>
        )}
        {permissions.includes("000-001-004") && (
          <Route path="/settings/document-upload">
            <DocumentUploadPage />
          </Route>
        )}
        <Route path="/">
          <DashboardPage />
        </Route>


      </Switch>
    </PageContainer>
  );
}

export default Pages;
