import React from "react";

import { Doughnut } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import "chartjs-plugin-doughnutlabel";

export default function DoughnutChart({ ...props }) {
  let metrics = "";
  // percent should come in as a prop called score
  const percent = props.score;
  const data = {
    datasets: [
      {
        data: [percent, (100 - percent)],
        backgroundColor: ["#f47e20", "#f0f6fc"],
        hoverBorderWidth: 4,
        borderColor: "#f0f6fc",
        hoverBorderColor: "#f0f6fc",
        borderWidth: 4
      }
    ]
  };

  const options = {
    maintainAspectRatio: true,
    responsive: false,
    cutoutPercentage: 75,
    animation: { animateScale: true, animateRotate: true },
    layout: {
      padding: {
        left: 5,
        right: 5
      }
    },
    legend: false,
    tooltips: {
      enabled: false
    },
    plugins: {
      datalabels: {
        display: false
      },
      doughnutlabel: {
        labels: [
          {
            text: `${percent}%`,
            font: {
              size: "60",
              family: "Montserrat, sans-serif",
              weight: 'bold'
            },
            color: "#124a80"
          }
        ]
      }
    }
  };

  metrics = <Doughnut height={180} data={data} options={options} />;

  return metrics;
};

DoughnutChart.displayName = "Doughnut";