import APIService from "../services";

// ** Build Client data and permissions ** //

const buildUserPermissions = async (userRecId) => {
  // find userRoleGroup
  const {
    data: { Payload: userRoleGroup = false },
  } = await APIService.get(`/GetUserRoleGroup?UsersRecId=${userRecId}`);

  const {
    data: { Payload: roleGroupsObjectAccess = false },
  } = await APIService.get(
    `/GetRoleGroupObjectAccess?RoleGroupsRecId=${userRoleGroup.RoleGroupsRecId}`
  );

  // fetch objectRecIds
  const allObjectIds = [];
  await roleGroupsObjectAccess.forEach((item) => {
    if (!item.IsActive) return;//Li added here to filter out not active rolegroupObjectAccess for user 8/18/2021
    const ObjectRecIdIndex = allObjectIds.indexOf(item.ObjectsRecId);
    if (ObjectRecIdIndex === -1) {
      allObjectIds.push(item.ObjectsRecId);
    }
  });

  // fetch objectCodes to reference for permissions
  const allObjectCodes = await Promise.all(
    allObjectIds.map(async (objectId) => {
      const {
        data: { Payload: object = false },
      } = await APIService.get(`/GetObject?RecId=${objectId}`);
      return object.Code;
    })
  );

  return { allObjectCodes, userRoleGroup: userRoleGroup.RoleGroupsRecId };
};

export const buildClientAuth = async (userEmail) => {
  // find User for clientId
  const {
    data: { Payload: user = false },
  } = await APIService.get(`/GetUserByEmail?email=${userEmail}`);

  const clientId = user.ClientId;

  const rolePermissions = await buildUserPermissions(user.RecId);

  // stop here if user does not have clientId
  if (!clientId) {
    const state = {
      userEmail,
      FirstName: user.FirstName,
      LastName: user.LastName,
    };
    return {
      state,
      permissions: rolePermissions.allObjectCodes,
      roleGroup: rolePermissions.userRoleGroup,
    };
  }

  // else find Client data
  const {
    data: { Payload: client = false },
  } = await APIService.get(`/GetClientByClientId?ClientId=${clientId}`);

  // find Client Address for zip code
  const {
    data: { Payload: clientAddress = false },
  } = await APIService.get(`/GetClientAddress?ClientId=${clientId}`);
  const state = {
    userId: user.RecId, //Claim notification by user
    userEmail,
    FirstName: user.FirstName,
    LastName: user.LastName,
    clientId,
    clientBrokerId: client.BrokerClientId,
    clientName: client.ClientName,
    clientZipCode: clientAddress.ZipCode,
    programRecId: client.ProgramsRecId,
    userPhone: user.Phone, //Li pre fill FNOL 

    loggedInClientName: client.ClientName,//Li endoresment requestor client
    loggedInClientAddress: clientAddress.Line1,//Li pre fill FNOL 
    loggedInClientCity: clientAddress.City,//Li pre fill FNOL 
    loggedInClientState: clientAddress.State,//Li pre fill FNOL 
    loggedInClientZipCode: clientAddress.ZipCode,//Li pre fill FNOL 
  };

  return {
    state,
    permissions: rolePermissions.allObjectCodes,
    roleGroup: rolePermissions.userRoleGroup,
  };
};

export const updateClientAuth = async (selectedClientId) => {
  if (selectedClientId) {
    // find Client
    const {
      data: { Payload: client = false },
    } = await APIService.get(
      `/GetClientByClientId?ClientId=${selectedClientId}`
    );

    // find Client Address for zip code
    const {
      data: { Payload: clientAddress = false },
    } = await APIService.get(`/GetClientAddress?ClientId=${selectedClientId}`);
    return {
      clientId: client.ClientId,
      clientBrokerId: client.BrokerClientId,
      clientName: client.ClientName,
      clientZipCode: clientAddress.ZipCode,
      programRecId: client.ProgramsRecId,
      clientAddress: clientAddress.Line1,//Li pre fill FNOL 
      clientCity: clientAddress.City,//Li pre fill FNOL 
      clientState: clientAddress.State,//Li pre fill FNOL 
    };
  }
  return { clientId: "", clientName: "", clientZipCode: "", programRecId: "" };
};
