import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
// need parse ISO to convert the value given by sql into a date
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
})

export default [
  {
    Header: "Criteria Type",
    accessor: 'CriteriaType',
    id: "CriteriaType",
    Cell: row => {

      return (<div>{row.row.original.CriteriaName + ' ' + (row.row.original.CriteriaValue ? (!isNaN(row.row.original.CriteriaValue) ? formatter.format(row.row.original.CriteriaValue) : row.row.original.CriteriaValue) : row.row.original.CriteriaValue)}</div>)
    }
  },
  {
    Header: "Notified Date",
    accessor: "DateCriteriaMet",
    id: "DateCriteriaMet",
    Cell: ({ value }) => (value ? format(parseISO(value), "MM/dd/yyyy") : "--"),
  },
  {
    Header: "Coverage",
    accessor: "LOB",
    id: "LOB",
  },
  {
    Header: "Policy Number",
    accessor: "PolicyNumber",
    id: "PolicyNumber",
  },
  {
    Header: "Claim Number",
    accessor: "ClaimNumber",
    id: "ClaimNumber",
  },
  {
    Header: "Effective Date",
    accessor: "EffectiveDate",
    id: "Effective Date",
    Cell: ({ value }) => (value ? format(parseISO(value), "MM/dd/yyyy") : "--"),
  },
  {
    Header: "Details",
    accessor: "ClaimDetail",
    id: "ClaimDetail",
    Cell: ({ value }) => value ? (<span style={{ color: 'rgb(244, 126, 32)' }}>View</span>) : "--",

  }

];