import React from "react";
import { Switch, Route } from "react-router-dom";

import PrivacyPolicyPage from "./PrivacyPolicyPage";
import ContactUsPage from "./ContactUsPage";
import LiveChatPage from "./LiveChatPage";

const FooterLinksRouter = () => (
  <Switch>
    <Route path="/footer/privacy-policy">
      <PrivacyPolicyPage />
    </Route>
    <Route path="/footer/contact-us">
      <ContactUsPage />
    </Route>
    <Route path="/footer/live-chat">
      <LiveChatPage />
    </Route>
  </Switch>
);

export default FooterLinksRouter;