import React from "react";
import PropTypes from "prop-types";

import "./H1.scss";

export default function H1({ children, ...props }) {
 

  return (
    <h1 {...props} className="heading1">
      {children}
      <span className="heading-dot">.</span>
    </h1>
  );
}

H1.propTypes = {
  children: PropTypes.node.isRequired,
};

H1.displayName = "H1";
