/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";

import ColorDivider from "../Divider"

import "./CalendarSquare.scss"

export default function CalendarSquare({ month, day}) {
 
  return (
    <div id="calendarSquare">
      <div className="titleText">{month}</div>
      <ColorDivider id="orangeLine" />
      <div className="bigText">{day}</div>
    </div>
  );
}

CalendarSquare.propTypes = {
  month: PropTypes.node.isRequired,
  day: PropTypes.node.isRequired,
};

CalendarSquare.displayName = "ColorDivider";