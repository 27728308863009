import React, { useEffect, useMemo, useState, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import Text from "../../components/Text";
import { useAuth } from "../../context/AuthContext";
import PolicyService from "../../services/PolicyService";
import ClaimsService from "../../services/ClaimsService";
import DataTable from "../../components/DataTable";
import Section from "../../components/Section";
import { Table, Generic, Field, Control, Checkbox, Icon } from "rbx";
import RoundButton from "../../components/Button/Round";
import Button from "../../components/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

import Columns from './columnsNotification';
import userColumns from './userColumnsNotification';
import { toast } from "react-toastify";
import "./ClaimsNotification.scss";
import { formatCurrency } from "../../utils";

import UserService from "../../services/UserService";
import { ModalContext } from "../../components/Modals/ModalProvider";
import ConfirmationModal from "../../components/Modals";
import CustomCheckBox from '../../components/CustomCheckBox';






const ClaimsNotificationEMaxxPage = () => {

    const { state: authState, permissions } = useAuth();
    const location = useLocation();
    const history = useHistory();
    const [combinedCriteriaAndSelection, setCombinedCriteriaAndSelection] = useState([]);
    const [saving, setSaving] = useState("");
    const [selecting, setSelecting] = useState("");
    const { toggleModal } = useContext(ModalContext);

    const policies = location.policies;//get poclies from claimsPage through

    const { data: notifications = [], isLoading } = useQuery(
        "notificationsByUser",
        () => ClaimsService.getClaimNotificationsByUser(authState.userId),
        { fetchPolicy: "no-cache", cacheTime: 0 }
    );

    const { data: claims = [], isLoadingClaims } = useQuery(
        "claimsByClient",
        () => ClaimsService.getClaimsByClient(authState.clientId),
        { fetchPolicy: "no-cache", cacheTime: 0 }
    );

    const { data: criteria = [], isLoadingCriteria } = useQuery(
        "notificationCriteria",
        () => ClaimsService.getClaimNotificationCriteria().then(
            result => {
                return result.filter(f => f.IsActive === true)
            }
        ),
        { fetchPolicy: "no-cache", cacheTime: 0 }
    );

    const { data: selections, isLoadingSelections } = useQuery(//works better when selections is initialized not as an array for the repeatedly re-render
        "notificationSelections",
        () => ClaimsService.getClaimNotificationSelectionsByUser(authState.userId),
        { fetchPolicy: "no-cache", cacheTime: 0 }
    );


    //if eMaxx access and edit on each of the user under client is needed
    const [sortedNotificationsEMaxx, setSortedNotificationsEMaxx] = useState([]);
    const [clientUser, setClientUser] = useState('');

    const { data: users = [], isLoadingUsers } = useQuery("users", () =>
        UserService.getUsersByGroup(
            undefined,
            authState.loginClientId.toString(),// this service take clientId as string,
            authState.roleGroup.RoleName,
            authState.roleGroup.GroupName
        ),
        { enabled: authState.roleGroup.GroupName === 'eMaxx', fetchPolicy: "no-cache", cacheTime: 0 }
    );

    const handleUserNotiEMaxxView = async (cell) => {

        if (cell.column.id === "RecId" && cell.value) {

            setClientUser({ UsersRecId: cell.value, UserEmail: cell.row.values?.Email?.toString() });

            let notificationEMaxxView = await ClaimsService.getClaimNotificationsByUser(cell.value);

            //let selectionEMaxxView = await ClaimsService.getClaimNotificationSelectionsByUser(cell.value);
            //addressCriteriaAndSelections(criteria, selectionEMaxxView);

            if (notificationEMaxxView.length > 0 && policies.length > 0 && claims.length > 0) {
                //console.log(notifications)
                notificationEMaxxView.forEach(item => {
                    item.EffectiveDate = policies.find(f => f.PolicyId === item.PolicyId)?.EffectiveDate;
                    item.ClaimDetail = `/Claims/${item.PolicyId}/${claims.find(f => f.ClaimNumber === item.ClaimNumber)?.RecId}`;
                    //add Criteria Name
                    item.CriteriaName = criteria.find(f => f.CriteriaType === item.CriteriaType)?.CriteriaName;
                });

                //only display claims within 90 days
                const date = new Date();
                date.setDate(date.getDate() - 90);
                const _90Days = date
                //06-06-2023 add forClientId and authState clientId checking since emaxx group user can set the notification on different insured
                setSortedNotificationsEMaxx(notificationEMaxxView.filter(f => new Date(f.ReportedDate) > _90Days && f.ForClientId === authState.clientId));
            }
        }
    }

    useMemo(async () => {
        if (!clientUser) return;
        let selectionEMaxxView = await ClaimsService.getClaimNotificationSelectionsByUser(clientUser.UsersRecId);
        addressCriteriaAndSelections(criteria, selectionEMaxxView);
    }, [clientUser])
    //if eMaxx access and edit on each of the user under client is needed




    const addressCriteriaAndSelections = (criteria, selections) => {
        //combined criteria and selection, for the case that new criteria added to the db, the user has the selection already can select on a new list.
        let combinedCriteriaAndSelectionTemp = []

        if (criteria.length > 0 && selections) {

            selections = selections.filter(f => f.ForClientId === authState.clientId);//for Broker and eMxx group case, only give the specfic insured at a time for Broker user

            if (selections.length === 0) {// for no selection user to display
                combinedCriteriaAndSelectionTemp = JSON.parse(JSON.stringify(criteria));
                combinedCriteriaAndSelectionTemp.forEach((item) => {
                    item.IsSelected = false;
                    item.SendEmail = false;
                    item.ClaimNotificationCriteriaRecId = item.RecId;
                    item.UsersRecId = clientUser ? clientUser.UsersRecId : authState.userId;

                    //get the Selection name from Critera list
                    item.CriteriaName = criteria.find(f => f.CriteriaType === item.CriteriaType)?.CriteriaName;

                })

                setCombinedCriteriaAndSelection(combinedCriteriaAndSelectionTemp);
            }
            else if (selections.length > 0 && criteria.length > 0)// for users have selections and may need to update from new criteria with new options
            {
                if (criteria.length > selections.length) {
                    let criteriaCopy = JSON.parse(JSON.stringify(criteria));
                    criteriaCopy = criteriaCopy.filter(f => f.CriteriaType !== selections.find(ff => ff.CriteriaType === f.CriteriaType)?.CriteriaType)
                    let newOptions = criteriaCopy;


                    newOptions.forEach((item) => {
                        item.IsSelected = false;
                        item.SendEmail = selections[0].SendEmail;
                        item.ClaimNotificationCriteriaRecId = item.RecId;
                        item.UsersRecId = clientUser ? clientUser.UsersRecId : authState.userId; //authState.userId;
                    })
                    combinedCriteriaAndSelectionTemp = selections.concat(newOptions);

                    //get the Selection name from Critera list
                    combinedCriteriaAndSelectionTemp.forEach((c) => {
                        c.CriteriaName = criteria.find(f => f.CriteriaType === c.CriteriaType)?.CriteriaName;
                    })
                    setCombinedCriteriaAndSelection(combinedCriteriaAndSelectionTemp);
                }
                else if (criteria.length === selections.length) {
                    combinedCriteriaAndSelectionTemp = JSON.parse(JSON.stringify(selections));

                    combinedCriteriaAndSelectionTemp.forEach((c) => {
                        c.CriteriaName = criteria.find(f => f.CriteriaType === c.CriteriaType)?.CriteriaName;
                    })
                    setCombinedCriteriaAndSelection(combinedCriteriaAndSelectionTemp);
                }
                //add a condition that some of the criteria is retired, need to remove the retired critria out of the existing selection?
                else if (criteria.length < selections.length) {
                    combinedCriteriaAndSelectionTemp = JSON.parse(JSON.stringify(selections));
                    //remove the retired criteria

                    combinedCriteriaAndSelectionTemp = combinedCriteriaAndSelectionTemp.filter(f =>
                        f.CriteriaType === criteria.find(ff => ff.CriteriaType === f.CriteriaType)?.CriteriaType
                    )
                    //get the Selection name from Critera list
                    combinedCriteriaAndSelectionTemp.forEach((c) => {
                        c.CriteriaName = criteria.find(f => f.CriteriaType === c.CriteriaType)?.CriteriaName;
                    })
                    setCombinedCriteriaAndSelection(combinedCriteriaAndSelectionTemp);
                }
            }
        }
    }

    useEffect(() => {
        addressCriteriaAndSelections(criteria, selections);

    }, [selections, criteria]);

    const handleView = (cell) => {
        if (cell.column.id === "ClaimDetail" && cell.value) {
            history.push({ pathname: cell.value, state: { 'fromNotification': true } });
        }
    }

    const handleOnChange = (name, val) => {
        if (name === 'SendEmail') {
            let updating = JSON.parse(JSON.stringify(combinedCriteriaAndSelection));
            updating = updating.map((item) => {
                item.SendEmail = val;
                return item;
            });
            setCombinedCriteriaAndSelection(updating);
        }
        else {
            let updating = JSON.parse(JSON.stringify(combinedCriteriaAndSelection));

            updating = updating.map((item) => {

                if (item.CriteriaType === name) {
                    item.IsSelected = val;
                }

                return item;
            });

            setCombinedCriteriaAndSelection(updating);
        }
    }

    const handleSave = (e) => {

        e.preventDefault();
        setSaving(true);

        let savingSelections = JSON.parse(JSON.stringify(combinedCriteriaAndSelection));

        savingSelections.forEach((item => {
            item.ClientId = authState.loginClientId;
            item.ForClientId = authState.clientId;
            item.UserEmail = authState.userEmail;
            item.ModifiedComments = null;

            /*
            if (authState.roleGroup.GroupName === 'eMaxx') {
                item.ClientId = authState.clientId;
                item.ForClientId = authState.clientId;
                item.UserEmail = clientUser.UserEmail;
                item.ModifiedComments = "eMaxx role on behalf of selected client user to update";
            }*/
        }));

        ClaimsService.updateClaimNotificationSelectionsByUser(savingSelections).then(result => {

            setSaving(false);

            if (result.data.Error.ErrorStatus === 0) {
                toast.success("Your request is complete and will take effective within the next 24 hours!");
            }
            else {
                toast.error("Save Selections Failed");
                // need to refresh to original selections
                if (clientUser) {
                    ClaimsService.getClaimNotificationSelectionsByUser(clientUser.UsersRecId).then(result => {
                        addressCriteriaAndSelections(criteria, result);
                    });
                }
                else {
                    addressCriteriaAndSelections(criteria, selections);
                }
                //addressCriteriaAndSelections();
            }
        });

        toggleModal(false, "");//for eMaxx Admin only
    }

    if (!permissions.includes('004-001-007')) {
        return (//todo: creater new object with permission only for eMaxx group later 
            <>
                <Section>You do not have permission to view this page.</Section>
            </>
        )
    }

    if (authState.roleGroup.GroupName === 'eMaxx' && !clientUser) {
        return (
            <>
                {/* eMaxx user select insured user for notification and selections */}
                {
                    users.length > 0 ? (
                        <div id='NotificationUserContainer'>
                            <DataTable
                                columns={userColumns}
                                data={users}
                                onCellClick={handleUserNotiEMaxxView}
                                sortBy={[
                                    {
                                        //id: "DateInitiated",
                                        //desc: false,
                                    },
                                ]}
                            />
                        </div>
                    ) :
                        (
                            <Section>No Users Found.</Section>
                        )
                }
            </>
        )
    }

    return (
        <>
            <div id='ClaimNotificationContainer'>
                {!selecting ?
                    (<div style={{ paddingBottom: 20 }} >
                        {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}> */}
                        <div id='NotificationHeader'>
                            <div className='leftSideContainer'>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    alignItems: "baseline"
                                }}>
                                    <Text.H1>Claims Notifications</Text.H1>
                                    <Icon
                                        tooltip="This grid displays all claims within the last 90 days that met your claim notification criteria"
                                        tooltipMultiline
                                        tooltipPosition="bottom"
                                        id="iconTooltip"
                                    >
                                        <FontAwesomeIcon icon={faQuestionCircle} />
                                    </Icon></div>
                            </div>
                            <div className="buttonContainer">
                                <Button.Round onClick={() => setSelecting(true)}
                                // disable for eMaxx roles for now, could open in future
                                // className={
                                // 	authState.roleGroup.GroupName === 'eMaxx' ? "roundButton primary disabled" : "roundButton primary"
                                // }
                                // disabled={authState.roleGroup.GroupName === 'eMaxx'}
                                >
                                    Notifications Options
                                </Button.Round>
                            </div>
                        </div>
                        {sortedNotificationsEMaxx.length > 0 ? (
                            <div>
                                <DataTable
                                    columns={Columns}
                                    data={sortedNotificationsEMaxx}
                                    onCellClick={handleView}
                                    sortBy={[
                                        {
                                            //id: "DateInitiated",
                                            //desc: false,
                                        },
                                    ]}
                                />
                            </div>
                        ) : (
                            <Section>No Notifications Found.</Section>
                        )}
                    </div>
                    ) :
                    (
                        <div id='SelectiontableContainer'>
                            <div className="tableSection">
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    alignItems: "baseline"
                                }}>
                                    <Text.H1>Notifications Options</Text.H1>
                                    <Icon
                                        tooltip="This feature provides the ability to select how you want to be notified and what types of claims you want to be notified on. These options can be changed at any time. PS: If you are the Broker, you will get the notifications for this specific Insured only."
                                        tooltipMultiline
                                        tooltipPosition="bottom"
                                        id="iconTooltip"
                                    >
                                        <FontAwesomeIcon icon={faQuestionCircle} />
                                    </Icon></div>
                                <Section>
                                    <Text.H3>Select notifications type</Text.H3>
                                    <div className="tableContainer" style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className="table-item">
                                            <label>
                                                <Checkbox className="inputCheckBox" checked disabled />
                                                <span>eMaxx Member Portal </span>
                                            </label>
                                        </div>
                                        <div className="table-item">
                                            <label>
                                                <Checkbox className="inputCheckBox"
                                                    checked={combinedCriteriaAndSelection[0]?.SendEmail || false} name='SendEmail' onChange={(e) => handleOnChange(e.target.name, e.target.checked)}

                                                    disabled={clientUser.UsersRecId !== authState.userId}
                                                />
                                                <span>Email Notifications</span>
                                            </label>
                                        </div>
                                    </div>
                                    <Text.H3>Select which notifications you would like to receive</Text.H3>
                                    {combinedCriteriaAndSelection.length ? (
                                        <div className="tableContainer">
                                            {combinedCriteriaAndSelection.map((selection) => (
                                                <div className="table-item" key={selection.RecId}>
                                                    {/* <label>
														<Checkbox className="inputCheckBox" checked={selection.IsSelected} name={selection.CriteriaType} onChange={(e) => handleOnChange(e.target.name, e.target.checked)} />
														<span>{selection.CriteriaName + ' ' + (selection.CriteriaValue ? (!isNaN(selection.CriteriaValue) ? formatCurrency.format(selection.CriteriaValue) : selection.CriteriaValue) : selection.CriteriaValue)}</span>
													</label> use the component created below*/}
                                                    <CustomCheckBox checked={selection.IsSelected}
                                                        labelInSpanName={selection.CriteriaName + ' ' + (selection.CriteriaValue ? (!isNaN(selection.CriteriaValue) ? formatCurrency.format(selection.CriteriaValue) : selection.CriteriaValue) : selection.CriteriaValue)}
                                                        name={selection.CriteriaType} onChange={handleOnChange}
                                                        disabled={clientUser.UsersRecId !== authState.userId}
                                                    ></CustomCheckBox>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        "No Selections Found."
                                    )}

                                    <div>
                                        {permissions.includes('004-001-005') &&
                                            (<RoundButton
                                                type="submit"
                                                id="saveButton"
                                                disabled={saving || clientUser.UsersRecId !== authState.userId}
                                                className={
                                                    saving || clientUser.UsersRecId !== authState.userId ? "roundButton primary disabled noPermButton" : "roundButton primary"
                                                }
                                                //onClick={(e) => handleSave(e)}
                                                onClick={() =>
                                                    toggleModal(
                                                        true,
                                                        <ConfirmationModal
                                                            optionalText={clientUser ? 'You will save the selections for you as an eMaxx employee and only you will receive the notifications.' :
                                                                'Are you sure you would like to Save the Selections?'}
                                                            //entity="user"
                                                            toggleModal={toggleModal}
                                                            handleAction={(e) => handleSave(e)}
                                                            actionText="SAVE"
                                                        />
                                                    )
                                                }
                                            >
                                                Save
                                            </RoundButton>)}
                                    </div>
                                </Section>
                            </div>
                        </div>)}
            </div>
        </>
    );

}

export default ClaimsNotificationEMaxxPage;