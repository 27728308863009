import { format, parseISO } from "date-fns";
// need parse ISO to convert the value given by sql into a date
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
})

export default [
  {
    Header: "Claim",
    accessor: "ClaimNumber",
    id: "ClaimNumber",
  },
  {
    Header: "Loss Date",
    accessor: "LossDate",
    id: "LossDate",
    Cell: ({ value }) => (value ? format(parseISO(value), "MM/dd/yyyy") : "--"),
  },
  {
    Header: "Claimant",
    accessor: "ClaimantName",
    id: "ClaimantName",
  },
  {
    Header: "Loss Type",
    accessor: "LossType",
    id: "LossType",
  },
  {
    Header: "Status",
    accessor: "StatusCode",
    id: "StatusCode"
  },
  {
    Header: "Total Incurred",
    accessor: "TotalIncurred",
    id: "TotalIncurred",
    Cell: ({ value }) => (value ? formatter.format(value) : "--"),
  },
];