import React from "react";
import { Switch, Route } from "react-router-dom";

import GroupSelectPage from "./GroupSelectPage";
import UsersPage from "./Users";
import UserEditPage from "./Users/UserEditPage";
import UserAddPage from "./Users/UserAddPage";
import ClientSelectPage from "./ClientSelect";

const UserSettingsRouter = () => (
  <Switch>
    <Route path="/user-settings/emaxx/:clientId/add">
      <UserAddPage />
    </Route>
    <Route path="/user-settings/emaxx/:clientId/:userId">
      <UserEditPage />
    </Route>
    <Route path="/user-settings/emaxx/:clientId">
      <UsersPage />
    </Route>

    <Route path="/user-settings/:groupName/:clientId/add">
      <UserAddPage />
    </Route>
    <Route path="/user-settings/:groupName/:clientId/:userId">
      <UserEditPage />
    </Route>

    <Route path="/user-settings/:groupName/:clientId">
      <UsersPage />
    </Route>
    <Route path="/user-settings/:groupName">
      <ClientSelectPage />
    </Route>

    <Route path="/user-settings">
      <GroupSelectPage />
    </Route>
  </Switch>
);

export default UserSettingsRouter;
