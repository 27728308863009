import { format, parseISO } from "date-fns";

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
})
// need parse ISO to convert the value given by sql into a date

export default [
  {
    Header: "Policy Year",
    accessor: "EffDate",
    id: "EffDate",
    Cell: ({ value }) => (value ? `${format(parseISO(value), "yyyy")} - ${parseInt(format(parseISO(value), "yyyy")) + 1}` : "--"),
  },
  {
    Header: "Program",
    accessor: "CategoryCode",
    id: "CategoryCode",
  },
  {
    Header: "Type",
    accessor: "FormOfCollateral",
    id: "FormOfCollateral",
  },
  {
    Header: "Amount",
    accessor: "CollateralAmt",
    id: "CollateralAmt",
    Cell: ({ value }) => (formatter.format(value)),
  }
];

// {
//   Header: "Expiration Date",
//   accessor: "ExpDate",
//   id: "ExpDate",
//   Cell: ({ value }) => (value ? format(parseISO(value), "MM/dd/yyyy") : "--"),
// },