import React from "react";
import { useQuery } from "react-query";

import ActivityLogService from "../../services/ActivityLogService";
import DataTable from "../../components/DataTable";
import Text from "../../components/Text";
import Loading from "../../components/Loading";
import COLUMNS from "./columns";
import "./ActivityLogPage.scss"

const ActivityLogPage = () => {
  const { data: activity = [], isLoading, error } = useQuery("activity", () =>
    ActivityLogService.getActivityLog()
  );

  if (isLoading) {
    return (
      <div className="headerContainer">
        <Text.H1>Activity Log</Text.H1>
        <Loading.SmallLoading />
      </div>
    );
  }

  if (error) {
    return null;
  }

  return (
    <div id="activityLog">
      <div className="headerContainer">
        <Text.H1>Activity Log</Text.H1>
      </div>
      <DataTable
        columns={COLUMNS}
        data={activity}
        sortBy={[
          {
            id: "CreatedDateTime",
            desc: true,
          },
        ]}
      />
    </div>
  );
};

ActivityLogPage.propTypes = {};

export default ActivityLogPage;
