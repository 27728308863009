import APIService from "./index";


const eLiveConnectService = {
    getELiveSummary: (clientId) =>
        APIService.get(`/GetELiveSummary?ClientId=${clientId}`).then(
            (res) => res.data.Payload
        ),
};

export default eLiveConnectService;