import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import MessageBoardPage from "./MessageBoard";
import DirectoryPage from "./Directory";
// import Calendar from "./Calendar";
import { useAuth } from "../../context/AuthContext";

const CommunicationRouter = () => {
  const { permissions } = useAuth();
  return (
    <Switch>
      {permissions.includes("005-001") && (
        <Route path="/communication/message-boards-&amp;-forum">
          <MessageBoardPage />
        </Route>
      )}
      {permissions.includes("005-002") && (
        <Route path="/communication/emaxx-directory">
          <DirectoryPage />
        </Route>
      )}
      {/* <Route path="/communication/calendar">
      <Calendar />
    </Route> */}
      <Redirect to="/" />
    </Switch>
  );
};

export default CommunicationRouter;
