import React, { useState } from "react";
import { useQuery } from "react-query";
import { groupBy } from "lodash";
import { useAuth } from "../../../context/AuthContext";
import Text from "../../../components/Text";
import Section from "../../../components/Section";
import ColorDivider from "../../../components/Divider";
import MemberFundAnalysisService from "../../../services/MemberFundAnalysisService";
import ClientService from "../../../services/ClientService";
import ProgramService from "../../../services/ProgramService";
import Loading from "../../../components/Loading";
import Carousel from "../../../components/Carousel";
import { formatCurrency } from "../../../utils";
import { Select } from "rbx";
import PolicyService from "../../../services/PolicyService";

import "./FinancialProfitability.scss";

const FinancialSummary = ({ passDownCurrentProg, changeCurrentProgram }) => {
  const { state: authState } = useAuth();
  //debugger;
  //const [currentProgram, setCurrentProgram] = useState(passDownCurrentProg);//useState(authState.programRecId);

  //get client history for the programs change
  const { data: clientsHistory = [] } = useQuery("clientsHistory", () =>
    ClientService.getClientsChangeHistory()
  );
  const { data: programs = [] } = useQuery("programs", () =>
    ProgramService.getPrograms()
  );

  //mix pc8,9(policy program) to regular program list by getting from the polciies
  const { data: Policies = [] } = useQuery("policies", () =>
    PolicyService.getPoliciesByClientNoFilter(authState.clientId)
  );




  let { data: funds = [], isLoading, error, refetch: refetchMFA } = useQuery(
    "memberFundAnalysis",
    () => MemberFundAnalysisService.getMemberFundAnalysis(authState.clientId)
  );

  funds = funds.filter(f => Number(f.ProgramCd ? f.ProgramCd?.toString().match(/(\d+)/)[0] : 0) === passDownCurrentProg);
  let currentQuarterFunds = funds;


  if (isLoading) {
    return (
      <div className="headerContainer">
        <Text.H1>Financial Summary</Text.H1>
        <Loading.SmallLoading />
      </div>
    );
  }
  if (error) {
    return null;
  }

  // console.log("funds:", funds);

  if (clientsHistory.filter(f => f.ProgramsRecId !== authState.programRecId && f.ClientId === authState.clientId).length > 0
    && passDownCurrentProg === authState.programRecId) {
    let clientHistory = clientsHistory.filter(f => f.ClientId === authState.clientId && f.ProgramsRecId);

    clientHistory.sort((a, b) => {
      let aa = new Date(a.EndDate);
      let bb = new Date(b.EndDate);
      return bb - aa;
    }).map(item => {
      item.ChangeOnYearQuarter = Number(new Date(item.CreatedDateTime).getFullYear() + '0' + Math.floor(new Date(item.CreatedDateTime).getMonth() / 3 + 1));
      item.StartYearQuarter = Number(new Date(item.StartDate).getFullYear() + '0' + Math.floor(new Date(item.StartDate).getMonth() / 3 + 1));
      item.EndYearQuarter = Number(new Date(item.EndDate).getFullYear() + '0' + Math.floor(new Date(item.EndDate).getMonth() / 3 + 1));
    })

    const historyPrograms = clientHistory;
    // later on need to find a way to present to client for the quarter they changed for both Programs of MFA
    let lastHistoryProgram = historyPrograms[0];

    /*
    let lookupProgram = historyProgram ? historyProgram : authState.programRecId;
    const programName = programs.find(f => f.RecId === lookupProgram)?.Name;
    const programCode = programs.find(f => f.RecId === lookupProgram)?.Code;*/

    //for first load filter out history fund

    /* comment this because of using the ProgramCd from MemberFundAnalysis table
    let sameProgramInHistoryAsCurrent = historyPrograms.filter(f => f.ProgramsRecId === currentProgram);
    const currentFund = funds.filter(f => f.YearQuarter >= lastHistoryProgram?.EndYearQuarter);
    let sameProgramInHistoryAsCurrentFunds = [];
    sameProgramInHistoryAsCurrent.map(m => {
      sameProgramInHistoryAsCurrentFunds = sameProgramInHistoryAsCurrentFunds.concat(funds.filter(f => f.YearQuarter >= m.StartYearQuarter && f.YearQuarter < m.EndYearQuarter));
    });*/

    const currentProgramFund = funds.filter(f => Number(f.ProgramCd ? f.ProgramCd?.toString().match(/(\d+)/)[0] : 0) === passDownCurrentProg);

    //find all funds in the hitory same as current program and concatenate with current fund not in the history start and end quarter range
    funds = currentProgramFund;//sameProgramInHistoryAsCurrentFunds.concat(currentFund);

    currentQuarterFunds = funds;
    //give client mfa for the program based on the history Li

  }
  else if (clientsHistory.filter(f => f.ProgramsRecId !== authState.programRecId && f.ClientId === authState.clientId).length > 0
    && passDownCurrentProg !== authState.programRecId) {

    let clientHistory = clientsHistory.filter(f => f.ClientId === authState.clientId && f.ProgramsRecId);

    clientHistory.sort((a, b) => {
      let aa = new Date(a.EndDate);
      let bb = new Date(b.EndDate);
      return bb - aa;
    }).map(item => {
      item.ChangeOnYearQuarter = Number(new Date(item.CreatedDateTime).getFullYear() + '0' + Math.floor(new Date(item.CreatedDateTime).getMonth() / 3 + 1));
      item.StartYearQuarter = Number(new Date(item.StartDate).getFullYear() + '0' + Math.floor(new Date(item.StartDate).getMonth() / 3 + 1));
      item.EndYearQuarter = Number(new Date(item.EndDate).getFullYear() + '0' + Math.floor(new Date(item.EndDate).getMonth() / 3 + 1));
    })

    let historyPrograms = clientHistory;
    // later on need to find a way to present to client for the quarter they changed for both Programs of MFA
    //let lastHistoryProgram = historyPrograms[0];

    /*
    let lookupProgram = historyProgram ? historyProgram : authState.programRecId;
    const programName = programs.find(f => f.RecId === lookupProgram)?.Name;
    const programCode = programs.find(f => f.RecId === lookupProgram)?.Code;*/

    //for first load filter out history fund

    //let historySelectedPrograms = historyPrograms.filter(f => f.ProgramsRecId === currentProgram);
    //const currentFund = funds.filter(f => f.yearQuarter >= lastHistoryProgram?.EndYearQuarter);


    const historyFunds = funds.filter(f => Number(f.ProgramCd ? f.ProgramCd?.toString().match(/(\d+)/)[0] : 0) === passDownCurrentProg);

    //find all funds in the hitory same as current program and concatenate with current fund not in the history start and end quarter range
    funds = historyFunds
    currentQuarterFunds = funds;
    //give client mfa for the program based on the history Li
  }


  // sets up the data into object
  const test = funds.map(fund => {
    const dataObject = {
      year: fund.YearQuarter.toString().substr(0, 4),
      quarter: fund.YearQuarter.toString().substr(5, 6),
      label: `Q${fund.YearQuarter.toString().substr(
        5,
        6
      )} ${fund.YearQuarter.toString().substr(0, 4)}`,
      AFundBalance: fund.AFundBalance,
      BFundBalance: fund.BFundBalance,
      PremiumYearFrom: fund.PremiumYearFrom,
      PremiumYearTo: fund.PremiumYearTo
    };
    return dataObject;
  });

  // possibly need a new sort here to make the quarters align
  // groups data by quarter
  const graphData = groupBy(test, "label");

  const barDataArrA = [];
  const barDataArrB = [];

  Object.keys(graphData).forEach(fundArrKey => {
    const fundArrObjA = { label: fundArrKey };
    const fundArrObjB = { label: fundArrKey };
    graphData[fundArrKey].forEach(fund => {
      const dataKey = `${fund.PremiumYearFrom}-${fund.PremiumYearTo}`;
      fundArrObjA[dataKey] = fund.AFundBalance;
      fundArrObjB[dataKey] = fund.BFundBalance;
    });
    barDataArrA.push(fundArrObjA);
    barDataArrB.push(fundArrObjB);
  });

  // change this to dynamically look for the month where there is data and also return an object with keys for the original array and current year and month as well

  let closestQuarter = 0;
  // this is good for quarters and graph but not for the summary pages
  let closestYear = 0;
  // possibly may need to use this if PremiumYearFrom is not working
  let closestPolicyYear = 0;
  if (funds.length >= 1) {
    const sortedDateFunds = funds.sort((a, b) => b.YearQuarter - a.YearQuarter);
    closestYear = sortedDateFunds[0].YearQuarter.toString().slice(0, 4);
    closestPolicyYear = sortedDateFunds[0].PremiumYearTo.toString().slice(0, 4);
    closestQuarter = sortedDateFunds[0].YearQuarter.toString().slice(4);
  }

  // first conditional checks if the data comes from the current quarter 
  // spliting this check to take 3 most recent years regardless of current date
  // Set will add the first three premiumYearFrom values it recieves
  const yearSet = new Set();
  const currentYearQuarterFund = currentQuarterFunds.sort((a, b) => (a.PremiumYearTo < b.PremiumYearTo ? 1 : -1)).filter(fund => {
    if (yearSet.size < 3) {
      yearSet.add(Number(fund.PremiumYearFrom));
    }
    return (
      fund.YearQuarter === Number(closestYear) * 100 + Number(closestQuarter) && yearSet.has(Number(fund.PremiumYearFrom))
    );
  })


  // sets up matching data keys to object above that will only take three most recent years *at most
  const dataKeyArrTemp = [...yearSet];
  const dataKeyArr = dataKeyArrTemp.map((year) =>
    `${year}-${year + 1}`
  )


  //give the MFA summary data for the current program and history based up dropdwon value
  let ProgramLst = [];
  let clientProHis = clientsHistory.filter(f => f.ProgramsRecId && f.ProgramsRecId !== authState.programRecId && f.ClientId === authState.clientId);
  if (clientProHis.length > 0 && programs.length > 0) {
    ProgramLst = [];
    let grouping = clientProHis;//clientsHistory.filter(f => f.ProgramRecId !== authState.programRecId && f.ClientId === authState.clientId);
    let grouped = groupBy(grouping, 'ProgramsRecId');

    Object.keys(grouped).forEach(key => ProgramLst.push(programs.find(f => f.RecId.toString() === key.toString())));

  }

  if (programs.length > 0) ProgramLst.push(programs.find(f => f.RecId === authState.programRecId));

  // add policy program to the drop down
  if (programs.length > 0 && Policies.length > 0) {

    let groupedPolicyPrograms = groupBy(Policies, 'CategoryCode')

    Object.keys(groupedPolicyPrograms).forEach(key => {
      var existing = ProgramLst.find(f => f?.Code === key)
      if (!existing) {

        let prog = programs.find(f => f.Code === key);

        ProgramLst.push(prog);
      }
    });
  }
  // add policy program to the drop down


  const programChange = e => {
    e.persist();
    // prevents below function from changing year once the user has interacted with the select
    //setToggleReached(true);

    const { value } = e.target;

    //setCurrentProgram(Number(value));
    changeCurrentProgram(Number(value));
    refetchMFA();
  };




  return (
    <div id="financialSummary">
      <div className="headerContainer">
        <Text.H1>Financial Summary</Text.H1>
      </div>
      <div style={{ paddingBottom: '0.8rem' }}>
        <Select.Container size="medium">
          {/* <Select value={currentProgram} onChange={programChange}> */}
          <Select value={passDownCurrentProg} onChange={programChange}>
            {ProgramLst
              //.sort((a, b) => b - a)
              .map(p => (
                <Select.Option key={p?.RecId} value={p?.RecId}>{p?.Code}</Select.Option>
              ))}
          </Select>
        </Select.Container>
      </div>
      {funds.length >= 1 ? (
        <div className="summarySection">
          {(passDownCurrentProg === 1 || passDownCurrentProg === 3 || passDownCurrentProg === 6
            || passDownCurrentProg === 7) && (
              <>
                <Section>
                  <div className="sectionTitles">
                    <Text.H1>
                      {/* {authState.programRecId === 1 || authState.programRecId === 3 */}
                      A Fund Balance
                    </Text.H1>
                  </div>
                  <div className="FPGridContainer">
                    <div className="leftColumn">
                      <div className="tableTitles">
                        <span>Year</span>
                        {' '}
                        <span>Balance</span>
                      </div>

                      <ColorDivider />
                      {currentYearQuarterFund.map(fund => (
                        <div className="tableData" key={fund.PremiumYearFrom}>
                          <span>
                            {fund.PremiumYearFrom}
                            -
                            {fund.PremiumYearTo}
                          </span>
                          {" "}
                          <span>
                            {currentYearQuarterFund.length >= 1
                              ? formatCurrency.format(fund.AFundBalance)
                              : "--"}
                          </span>
                        </div>
                      ))}
                    </div>
                    <div className="rightColumn">
                      <div className="graphHolder">
                        {graphData.length !== 0 ? (
                          <Carousel
                            data={barDataArrA}
                            dataKeyArr={dataKeyArr}
                            currentYear={closestYear}
                          />
                        ) : (
                          <Loading.SmallLoading />
                        )}
                      </div>
                    </div>
                  </div>
                </Section>
                <Section>
                  <div className="sectionTitles">
                    <Text.H1>B Fund Balance</Text.H1>
                  </div>
                  <div className="FPGridContainer">
                    <div className="leftColumn">
                      <div className="tableTitles">
                        <span>Year</span>
                        {' '}
                        <span>Balance</span>
                      </div>
                      <ColorDivider />
                      {currentYearQuarterFund.map(fund => (
                        <div className="tableData" key={fund.PremiumYearFrom}>
                          <span>
                            {fund.PremiumYearFrom}
                            -
                            {fund.PremiumYearTo}
                          </span>
                          {" "}
                          <span>
                            {currentYearQuarterFund.length >= 1
                              ? formatCurrency.format(fund.BFundBalance)
                              : "--"}
                          </span>
                        </div>
                      ))}
                    </div>
                    <div className="rightColumn">
                      <div className="graphHolder">
                        {graphData.length !== 0 ? (
                          <Carousel
                            data={barDataArrB}
                            dataKeyArr={dataKeyArr}
                            currentYear={closestYear}
                          />
                        ) : (
                          <Loading.SmallLoading />
                        )}
                      </div>
                    </div>
                  </div>
                </Section>
              </>
            )}

          {(passDownCurrentProg === 2 || passDownCurrentProg === 4) && (
            <Section>
              <div className="sectionTitles">
                <Text.H1>
                  Group Fund Balance
                </Text.H1>
              </div>
              <div className="FPGridContainer">
                <div className="leftColumn">
                  <div className="tableTitles">
                    <span>Year</span>
                    {' '}
                    <span>Balance</span>
                  </div>

                  <ColorDivider />
                  {currentYearQuarterFund.map(fund => (
                    <div className="tableData" key={fund.PremiumYearFrom}>
                      <span>
                        {fund.PremiumYearFrom}
                        -
                        {fund.PremiumYearTo}
                      </span>
                      {" "}
                      <span>
                        {currentYearQuarterFund.length >= 1
                          ? formatCurrency.format(fund.AFundBalance)
                          : "--"}
                      </span>
                    </div>
                  ))}
                </div>
                <div className="rightColumn">
                  <div className="graphHolder">
                    {graphData.length !== 0 ? (
                      <Carousel
                        data={barDataArrA}
                        dataKeyArr={dataKeyArr}
                        currentYear={closestYear}
                      />
                    ) : (
                      <Loading.SmallLoading />
                    )}
                  </div>
                </div>
              </div>
            </Section>)}
        </div>
      ) : (
        <Section>No Member Fund Data Found.</Section>
      )}
    </div>
  );
};


export default FinancialSummary;



// return (
//   <div id="financialSummary">
//     <div className="headerContainer">
//       <Text.H1>Financial Summary</Text.H1>
//     </div>
//     <div style={{ paddingBottom: '0.8rem' }}>
//       <Select.Container size="medium">
//         <Select value={currentProgram} onChange={programChange}>
//           {ProgramLst
//             //.sort((a, b) => b - a)
//             .map(p => (
//               <Select.Option key={p?.RecId} value={p?.RecId}>{p?.Code}</Select.Option>
//             ))}
//         </Select>
//       </Select.Container>
//     </div>
//     {funds.length >= 1 ? (
//       <div className="summarySection">
//         <Section>
//           <div className="sectionTitles">
//             <Text.H1>
//               {/* {authState.programRecId === 1 || authState.programRecId === 3 */}
//               {currentProgram === 1 || currentProgram === 3
//                 ? `A Fund Balance`
//                 : `Group Fund Balance`}
//             </Text.H1>
//           </div>
//           <div className="FPGridContainer">
//             <div className="leftColumn">
//               <div className="tableTitles">
//                 <span>Year</span>
//                 {' '}
//                 <span>Balance</span>
//               </div>

//               <ColorDivider />
//               {currentYearQuarterFund.map(fund => (
//                 <div className="tableData" key={fund.PremiumYearFrom}>
//                   <span>
//                     {fund.PremiumYearFrom}
//                     -
//                     {fund.PremiumYearTo}
//                   </span>
//                   {" "}
//                   <span>
//                     {currentYearQuarterFund.length >= 1
//                       ? formatCurrency.format(fund.AFundBalance)
//                       : "--"}
//                   </span>
//                 </div>
//               ))}
//             </div>
//             <div className="rightColumn">
//               <div className="graphHolder">
//                 {graphData.length !== 0 ? (
//                   <Carousel
//                     data={barDataArrA}
//                     dataKeyArr={dataKeyArr}
//                     currentYear={closestYear}
//                   />
//                 ) : (
//                   <Loading.SmallLoading />
//                 )}
//               </div>
//             </div>
//           </div>
//         </Section>
//         {(currentProgram === 1 || currentProgram === 3) && (
//           <Section>
//             <div className="sectionTitles">
//               <Text.H1>B Fund Balance</Text.H1>
//             </div>
//             <div className="FPGridContainer">
//               <div className="leftColumn">
//                 <div className="tableTitles">
//                   <span>Year</span>
//                   {' '}
//                   <span>Balance</span>
//                 </div>
//                 <ColorDivider />
//                 {currentYearQuarterFund.map(fund => (
//                   <div className="tableData" key={fund.PremiumYearFrom}>
//                     <span>
//                       {fund.PremiumYearFrom}
//                       -
//                       {fund.PremiumYearTo}
//                     </span>
//                     {" "}
//                     <span>
//                       {currentYearQuarterFund.length >= 1
//                         ? formatCurrency.format(fund.BFundBalance)
//                         : "--"}
//                     </span>
//                   </div>
//                 ))}
//               </div>
//               <div className="rightColumn">
//                 <div className="graphHolder">
//                   {graphData.length !== 0 ? (
//                     <Carousel
//                       data={barDataArrB}
//                       dataKeyArr={dataKeyArr}
//                       currentYear={closestYear}
//                     />
//                   ) : (
//                     <Loading.SmallLoading />
//                   )}
//                 </div>
//               </div>
//             </div>
//           </Section>
//         )}
//       </div>
//     ) : (
//       <Section>No Member Fund Data Found.</Section>
//     )}
//   </div>
// );