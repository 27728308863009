import React, { useState, useEffect } from "react";
import { Select, Field, Control, Checkbox, Label, Input } from "rbx";
import { toast } from "react-toastify";
import Section from "../../../components/Section";
import Text from "../../../components/Text";
import Button from "../../../components/Button";
import { useAuth } from "../../../context/AuthContext";
import EmailService from "../../../services/EmailService";
import Loading from "../../../components/Loading";

import "./EndorsementsPage.scss";

const EndorsementsPage = () => {
  const { state: authState } = useAuth();

  const [form, setForm] = useState("Add Vehicle");
  const [canSubmit, setCanSubmit] = useState(false);
  const [state, setState] = useState({
    COMPANY: authState.clientName, //Li
    "EFFECTIVE DATE": "",
    MAKE: "",
    YEAR: "",
    MODEL: "",
    GVW: "",
    VIN: "",
    "COST NEW": "",
    "GARAGE LOCATION": "",
    "REGISTRATION(PLATE)": "",
    "LOSS PAYEE NAME": "",
    "LOSS PAYEE ADDRESS": "",
    LIABILITY: false,
    COLLISIONANDCOMPREHENSIVE: false,
    CONFIRM: false,
  });
  const [loading, setLoading] = useState(false);

  // simple validation
  useEffect(() => {
    if (form === "Add Vehicle") {
      setCanSubmit(
        state.COMPANY.length >= 1 && //Li
        state["EFFECTIVE DATE"].length >= 1 &&
        state.MAKE.length >= 1 &&
        state.YEAR.length >= 1 &&
        state.MODEL.length >= 1 &&
        state.GVW.length >= 1 &&
        state.VIN.length >= 1 &&
        state["COST NEW"].length >= 1 &&
        state["GARAGE LOCATION"].length >= 1 &&
        (state.LIABILITY || state.COLLISIONANDCOMPREHENSIVE) &&
        state.CONFIRM
      );
    } else {
      setCanSubmit(
        state.COMPANY.length >= 1 && //Li
        state["EFFECTIVE DATE"].length >= 1 &&
        state.MAKE.length >= 1 &&
        state.YEAR.length >= 1 &&
        state.MODEL.length >= 1 &&
        state.VIN.length >= 1 &&
        (state.LIABILITY || state.COLLISIONANDCOMPREHENSIVE) &&
        state.CONFIRM
      );
    }
  }, [state, form]);

  /// ///

  const formChange = (e) => {
    e.persist();
    const { value } = e.target;

    if (value === "Add Vehicle") {
      setState({
        COMPANY: state.COMPANY, //Li
        "EFFECTIVE DATE": "",
        MAKE: "",
        YEAR: "",
        MODEL: "",
        GVW: "",
        VIN: "",
        "COST NEW": "",
        "GARAGE LOCATION": "",
        "REGISTRATION(PLATE)": "",
        LIABILITY: false,
        COLLISIONANDCOMPREHENSIVE: false,
        CONFIRM: false,
      });
    }

    if (value === "Remove Vehicle") {
      setState({
        COMPANY: state.COMPANY, //Li
        "EFFECTIVE DATE": "",
        MAKE: "",
        YEAR: "",
        MODEL: "",
        GVW: "",
        VIN: "",
        LIABILITY: false,
        COLLISIONANDCOMPREHENSIVE: false,
        CONFIRM: false,
      });
    }

    setForm(value);
  };

  const handleChange = (name, value) => {
    // e.preventDefault();
    setState((prev) => ({ ...prev, [name]: value }));
  };

  // const handleCheckbox = (name, value) => {
  //   // e.preventDefault();
  //   console.log("name", name, "value", value);
  //   setState((prev) => ({ ...prev, [name]: value }));
  // };

  const sendEmail = async (data) => {

    setLoading(true);
    let body = `<p> FORM: ${form}</p>`;
    Object.keys(data).forEach((key) => {
      body += `<p>${key}: ${data[key]}</p>`;
    });
    //Li add the requester info
    const requestorEmail = authState.userEmail;
    body += `<p>REQUESTOR: ${authState.loginClientId === authState.clientId ? `${authState.userEmail} (${authState.clientName})` : `${authState.userEmail} (${authState.loggedInClientName})`}</p>`;

    //body = body.replace('&', '%26amp;');// replace the ampersand for rest api query string. the content should be in the body of post. but can not change just incase others using it for now.
    body = encodeURIComponent(body);


    let to = "endorsements@emaxxgroup.com";
    //let to = "lliu@etechnologyservices.com";//test
    //const to = "lltfly@hotmail.com";//test
    let subject = "Endorsement Form";
    await EmailService.SendEmail(to, subject, body);
    // const today = new Date();
    const entryObj = {
      Origin: form,
      User: authState.userEmail,
      Content: decodeURIComponent(body),
    };
    const response = await EmailService.CreateEntry(entryObj).then(() =>
      toast.success("Thank you for the request")
    );
    // console.log("Response", response);

    //04/05/2022 Li add Requestor confirmation email with different subject and body
    to = requestorEmail;
    subject = "Endorsement Request Confirmation";

    let confirmContent = '<br><h4>The endorsement request through the Member Portal was received by eMaxx. Please note that all endorsements are processed within 45 days of receipt and not all endorsement requests will generate a hard copy endorsement form.</h4>';
    confirmContent += '<p>The Client Services Team</p>';
    confirmContent += '<br><h4>Endorsement Request</h4>';
    confirmContent += decodeURIComponent(body);
    confirmContent = encodeURIComponent(confirmContent)

    await EmailService.SendEmail(to, subject, confirmContent);
    //04/05/2022 Li add Requestor confirmation email with different subject and body


    form === "Add Vehicle"
      ? setState({
        COMPANY: authState.clientName, //Li
        "EFFECTIVE DATE": "",
        MAKE: "",
        YEAR: "",
        MODEL: "",
        GVW: "",
        VIN: "",
        "COST NEW": "",
        "GARAGE LOCATION": "",
        "REGISTRATION(PLATE)": "",
        LIABILITY: false,
        COLLISIONANDCOMPREHENSIVE: false,
        CONFIRM: false,
      })
      : setState({
        COMPANY: authState.clientName, //Li
        "EFFECTIVE DATE": "",
        MAKE: "",
        YEAR: "",
        MODEL: "",
        GVW: "",
        VIN: "",
        LIABILITY: false,
        COLLISIONANDCOMPREHENSIVE: false,
        CONFIRM: false,
      });
    setLoading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendEmail(state);
  };

  if (loading) {
    return (
      <div id="EndorsementsPageContainer">
        <Select.Container size="medium">
          <Select onChange={formChange}>
            <Select.Option>Add Vehicle</Select.Option>
            <Select.Option>Remove Vehicle</Select.Option>
          </Select>
        </Select.Container>
        <Text.H1>{form}</Text.H1>
        <Loading.SmallLoading />
      </div>
    );
  }

  return (
    <div id="EndorsementsPageContainer">
      <Select.Container size="medium">
        <Select onChange={formChange}>
          <Select.Option>Add Vehicle</Select.Option>
          <Select.Option>Remove Vehicle</Select.Option>
        </Select>
      </Select.Container>
      {/* <Text.H1>{form}</Text.H1> */}
      {form === "Add Vehicle" && (
        <Section>
          <form id="add-vehicle-form" className="form">
            <Field horizontal className="input-holder">
              <Field.Label>
                <Label>
                  <Text.H3Form>COMPANY</Text.H3Form>
                </Label>
              </Field.Label>
              <Field.Body>
                <Field>
                  <Control>
                    <Input
                      value={state.COMPANY}
                      className="form-text"
                      id="small"
                      name="COMPANY"
                      color="danger"
                      type="text"
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)}
                    />
                  </Control>
                </Field>
              </Field.Body>
            </Field>

            <Field horizontal className="input-holder">
              <Field.Label>
                <Label>
                  <Text.H3Form>EFFECTIVE DATE</Text.H3Form>
                </Label>
              </Field.Label>
              <Field.Body>
                <Field>
                  <Control>
                    <Input
                      className="form-text"
                      id="small"
                      name="EFFECTIVE DATE"
                      color="danger"
                      type="text"
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)}
                    />
                  </Control>
                </Field>
              </Field.Body>
            </Field>

            <Field horizontal className="input-holder">
              <Field.Label>
                <Label>
                  <Text.H3Form>YEAR</Text.H3Form>
                </Label>
              </Field.Label>
              <Field.Body>
                <Field>
                  <Control>
                    <Input
                      className="form-text"
                      id="small"
                      name="YEAR"
                      color="danger"
                      type="text"
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)}
                    />
                  </Control>
                </Field>
              </Field.Body>
            </Field>

            <Field horizontal className="input-holder">
              <Field.Label>
                <Label>
                  <Text.H3Form>MAKE</Text.H3Form>
                </Label>
              </Field.Label>
              <Field.Body>
                <Field>
                  <Control>
                    <Input
                      className="form-text"
                      id="large"
                      name="MAKE"
                      color="danger"
                      type="text"
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)}
                    />
                  </Control>
                </Field>
              </Field.Body>
            </Field>

            <Field horizontal className="input-holder">
              <Field.Label>
                <Label>
                  <Text.H3Form>MODEL</Text.H3Form>
                </Label>
              </Field.Label>
              <Field.Body>
                <Field>
                  <Control>
                    <Input
                      className="form-text"
                      id="large"
                      name="MODEL"
                      color="danger"
                      type="text"
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)}
                    />
                  </Control>
                </Field>
              </Field.Body>
            </Field>

            <Field horizontal className="input-holder" id="in-line">
              <Field.Label>
                <Label>
                  <Text.H3Form>VIN</Text.H3Form>
                </Label>
              </Field.Label>
              <Field.Body>
                <Field>
                  <Control>
                    <Input
                      className="form-text"
                      id="small"
                      name="VIN"
                      color="danger"
                      type="text"
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)}
                    />
                  </Control>
                </Field>
              </Field.Body>
              {/* <Field.Label i>  */}
              <Field.Label>
                <Label>
                  <Text.H3Form>GVW</Text.H3Form>
                </Label>
              </Field.Label>
              <Field.Body>
                <Field>
                  <Control>
                    <Input
                      className="form-text"
                      id="small"
                      name="GVW"
                      color="danger"
                      type="text"
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)}
                    />
                  </Control>
                </Field>
              </Field.Body>
            </Field>

            <Field horizontal className="input-holder">
              <Field.Label>
                <Label>
                  <Text.H3Form>COST NEW</Text.H3Form>
                </Label>
              </Field.Label>
              <Field.Body>
                <Field>
                  <Control>
                    <Input
                      className="form-text"
                      id="large"
                      name="COST NEW"
                      color="danger"
                      type="text"
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)}
                    />
                  </Control>
                </Field>
              </Field.Body>
            </Field>

            <Field horizontal className="input-holder">
              <Field.Label>
                <Label>
                  <Text.H3Form>GARAGE LOCATION</Text.H3Form>
                </Label>
              </Field.Label>
              <Field.Body>
                <Field>
                  <Control>
                    <Input
                      className="form-text"
                      id="large"
                      name="GARAGE LOCATION"
                      color="danger"
                      type="text"
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)}
                    />
                  </Control>
                </Field>
              </Field.Body>
            </Field>

            <Field horizontal className="input-holder">
              <Field.Label>
                <Label>
                  <Text.H3>REGISTRATION(PLATE)</Text.H3>
                </Label>
              </Field.Label>
              <Field.Body>
                <Field>
                  <Control>
                    <Input
                      className="form-text"
                      id="large"
                      name="REGISTRATION(PLATE)"
                      color="danger"
                      type="text"
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)}
                    />
                  </Control>
                </Field>
              </Field.Body>
            </Field>

            <Field horizontal className="input-holder">
              <Field.Label>
                <Label>
                  <Text.H3>LOSS PAYEE NAME</Text.H3>
                </Label>
              </Field.Label>
              <Field.Body>
                <Field>
                  <Control>
                    <Input
                      className="form-text"
                      id="large"
                      name="LOSS PAYEE NAME"
                      color="danger"
                      type="text"
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)}
                    />
                  </Control>
                </Field>
              </Field.Body>
            </Field>

            <Field horizontal className="input-holder">
              <Field.Label>
                <Label>
                  <Text.H3>LOSS PAYEE ADDRESS</Text.H3>
                </Label>
              </Field.Label>
              <Field.Body>
                <Field>
                  <Control>
                    <Input
                      className="form-text"
                      id="large"
                      name="LOSS PAYEE ADDRESS"
                      color="danger"
                      type="text"
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)}
                    />
                  </Control>
                </Field>
              </Field.Body>
            </Field>

            <Text.H2Form>Coverage</Text.H2Form>
            <div className="sub-heading">(Check all that apply)</div>

            <Field horizontal className="input-holder">
              <Field.Label>
                <Label>
                  <Text.H3>LIABILITY</Text.H3>
                </Label>
              </Field.Label>
              <Field.Body>
                <Field>
                  <Control>
                    <Checkbox
                      name="LIABILITY"
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.checked)}
                    />
                  </Control>
                </Field>
              </Field.Body>
            </Field>

            <Field horizontal className="input-holder">
              <Field.Label>
                <Label>
                  <Text.H3>COLLISION & COMPREHENSIVE</Text.H3>
                </Label>
              </Field.Label>
              <Field.Body>
                <Field>
                  <Control>
                    <Checkbox
                      name="COLLISIONANDCOMPREHENSIVE"
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.checked)}
                    />
                  </Control>
                </Field>
              </Field.Body>
            </Field>

            <p>
              By checking this box you confirm that insurance coverage cannot be
              bound, altered or cancelled via online forms. No insurance binder,
              policy or change request is effective until approved by the
              insurance carrier and confirmed in writing by a licensed agent or
              authorized carrier representative. Automated responses regarding
              receipt of the request is not confirmation in writing that the
              request has been approved. All coverages are subject to the terms,
              conditions and exclusions of the policy issued.
            </p>

            <Field horizontal className="input-holder">
              <Field.Label>
                <Label>
                  <Text.H3Form>YES, I CONFIRM</Text.H3Form>
                </Label>
              </Field.Label>
              <Field.Body>
                <Field>
                  <Control>
                    <Checkbox
                      name="CONFIRM"
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.checked)}
                    />
                  </Control>
                </Field>
              </Field.Body>
            </Field>

            <div className="buttonHolder">
              <Button.Round
                disabled={!canSubmit}
                className={
                  canSubmit
                    ? "roundButton primary"
                    : "roundButton primary disabled"
                }
                onClick={handleSubmit}
              >
                Submit
              </Button.Round>
            </div>
          </form>
        </Section>
      )}

      {form === "Remove Vehicle" && (
        <Section>
          <form id="remove-vehicle-form" className="form">
            <Field horizontal className="input-holder">
              <Field.Label>
                <Label>
                  <Text.H3Form>COMPANY</Text.H3Form>
                </Label>
              </Field.Label>
              <Field.Body>
                <Field>
                  <Control>
                    <Input
                      value={state.COMPANY}
                      className="form-text"
                      id="small"
                      name="COMPANY"
                      color="danger"
                      type="text"
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)}
                    />
                  </Control>
                </Field>
              </Field.Body>
            </Field>
            <Field horizontal className="input-holder">
              <Field.Label>
                <Label>
                  <Text.H3Form>EFFECTIVE DATE</Text.H3Form>
                </Label>
              </Field.Label>
              <Field.Body>
                <Field>
                  <Control>
                    <Input
                      className="form-text"
                      id="small"
                      name="EFFECTIVE DATE"
                      color="danger"
                      type="text"
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)}
                    />
                  </Control>
                </Field>
              </Field.Body>
            </Field>

            <Field horizontal className="input-holder">
              <Field.Label>
                <Label>
                  <Text.H3Form>YEAR</Text.H3Form>
                </Label>
              </Field.Label>
              <Field.Body>
                <Field>
                  <Control>
                    <Input
                      className="form-text"
                      id="small"
                      name="YEAR"
                      color="danger"
                      type="text"
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)}
                    />
                  </Control>
                </Field>
              </Field.Body>
            </Field>

            <Field horizontal className="input-holder">
              <Field.Label>
                <Label>
                  <Text.H3Form>MAKE</Text.H3Form>
                </Label>
              </Field.Label>
              <Field.Body>
                <Field>
                  <Control>
                    <Input
                      className="form-text"
                      id="large"
                      name="MAKE"
                      color="danger"
                      type="text"
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)}
                    />
                  </Control>
                </Field>
              </Field.Body>
            </Field>

            <Field horizontal className="input-holder">
              <Field.Label>
                <Label>
                  <Text.H3Form>MODEL</Text.H3Form>
                </Label>
              </Field.Label>
              <Field.Body>
                <Field>
                  <Control>
                    <Input
                      className="form-text"
                      id="large"
                      name="MODEL"
                      color="danger"
                      type="text"
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)}
                    />
                  </Control>
                </Field>
              </Field.Body>
            </Field>

            <Field horizontal className="input-holder">
              <Field.Label>
                <Label>
                  <Text.H3Form>VIN</Text.H3Form>
                </Label>
              </Field.Label>
              <Field.Body>
                <Field>
                  <Control>
                    <Input
                      className="form-text"
                      id="small"
                      name="VIN"
                      color="danger"
                      type="text"
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)}
                    />
                  </Control>
                </Field>
              </Field.Body>
            </Field>
            <Text.H2Form>Coverage</Text.H2Form>
            <div className="sub-heading">(Check all that apply)</div>

            <Field horizontal className="input-holder">
              <Field.Label>
                <Label>
                  <Text.H3>LIABILITY</Text.H3>
                </Label>
              </Field.Label>
              <Field.Body>
                <Field>
                  <Control>
                    <Checkbox
                      name="LIABILITY"
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.checked)}
                    />
                  </Control>
                </Field>
              </Field.Body>
            </Field>

            <Field horizontal className="input-holder">
              <Field.Label>
                <Label>
                  <Text.H3>COLLISION & COMPREHENSIVE</Text.H3>
                </Label>
              </Field.Label>
              <Field.Body>
                <Field>
                  <Control>
                    <Checkbox
                      name="COLLISIONANDCOMPREHENSIVE"
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.checked)}
                    />
                  </Control>
                </Field>
              </Field.Body>
            </Field>

            <p>
              By checking this box you confirm that insurance coverage cannot be
              bound, altered or cancelled via online forms. No insurance binder,
              policy or change request is effective until approved by the
              insurance carrier and confirmed in writing by a licensed agent or
              authorized carrier representative. Automated responses regarding
              receipt of the request is not confirmation in writing that the
              request has been approved. All coverages are subject to the terms,
              conditions and exclusions of the policy issued.
            </p>

            <Field horizontal className="input-holder">
              <Field.Label>
                <Label>
                  <Text.H3Form>YES, I CONFIRM</Text.H3Form>
                </Label>
              </Field.Label>
              <Field.Body>
                <Field>
                  <Control>
                    <Checkbox
                      name="CONFIRM"
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.checked)}
                    />
                  </Control>
                </Field>
              </Field.Body>
            </Field>

            <div className="buttonHolder">
              <Button.Round
                onClick={handleSubmit}
                disabled={!canSubmit}
                className={
                  canSubmit
                    ? "roundButton primary"
                    : "roundButton primary disabled"
                }
              >
                Submit
              </Button.Round>
            </div>
          </form>
        </Section>
      )}
    </div>
  );
};

export default EndorsementsPage;
