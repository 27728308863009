export default {
  name: "Insurance",
  code: "002",
  links: [
    {
      //name: "Documents Library", 06-21-2024, Megan requested to change the name of the Menue Item
      name: "Policy Library",
      code: "002-001",
      //to: "/insurance/documents-library",  06-21-2024, Megan requested to change the name of the Menue Item
      to: "/insurance/policy-library",
    },
    {
      name: "Governance",
      code: "002-002",
      to: "/insurance/governance",
    },
    {
      name: "Member Kiosk",
      code: "002-003",
      to: "/insurance/member-kiosk",
    },
    {
      name: "Financial & Profitability",
      code: "002-004",
      to: "/insurance/financial-&-profitability",
    },
    {
      name: "Premium Payment & Collateral",
      code: "002-005",
      to: "/insurance/premium-payment-&-collateral",
    },
    {
      name: "Program Information",
      code: "002-006",
      to: "/insurance/program-information",
    },
  ],
};
