import React, { useEffect, useState, useMemo } from "react";
import { useQuery } from "react-query";
import { useAuth } from "../../../context/AuthContext";
import ClientService from "../../../services/ClientService";
import Section from "../../../components/Section";
import Loading from "../../../components/Loading";

import "./MembershipDirectory.scss";

const MembershipDirectoryPage = ({ passDownCurrentProg, changeCurrentProgram }) => {
  const { state: authState } = useAuth();

  // gets all data for membership
  // gets all clients from program set in authState
  // filters by ClientType === "PolicyHolder"
  const {
    data: clientList = [],
    isLoadingClientList,
    errorClientList,
    refetch: refetchClientsByProgram
  } = useQuery("clientsByProgram", () =>
    ClientService.fetchClientsByProgramData(passDownCurrentProg)
  );

  useMemo(() => {
    refetchClientsByProgram();// Prop changed did not rerender instead of using key for the child component, use useMemo to detect and retech docs with new program

  }, [passDownCurrentProg]);


  if (isLoadingClientList) {
    return (
      <div className="headerContainer">
        <Loading.SmallLoading />
      </div>
    );
  }
  if (errorClientList) {
    return null;
  }

  return (
    <div id="MemDirectoryContainer">
      {/* <Section>
        <Text.H3>Member Information</Text.H3>
        <Button.Text to="/insurance/governance/membership/membership-directory/member-information">
          View
        </Button.Text>
      </Section> */}
      <div className="tableSection">
        <Section>
          {clientList.length ? (
            <div className="tableContainer">
              {clientList.map((client) => (
                <div className="table-item" key={client.RecId}>
                  <div>{client.ClientName}</div>
                  <div>
                    {client.Address?.City || "--"}
                    {", "}
                    {client.Address?.State || "--"}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            "No Members Found."
          )}
        </Section>
      </div>
    </div>
  );
};

export default MembershipDirectoryPage;
