/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import PropTypes from "prop-types";

import { useTable, usePagination, useSortBy, useRowSelect } from "react-table";
import { Table, Generic, Field, Control } from "rbx";

import Pagination from "./Pagination";
import Loading from "../Loading";

import "./DataTable.scss";

const renderFunc = (cell) => {
  if (cell.value === null) return null;
  if (typeof cell.value === "boolean") {
    return (
      <Generic textColor={cell.value ? "success" : "danger"}>
        {cell.value ? "Yes" : "No"}
      </Generic>
    );
  }
  return cell.render("Cell");
};

const renderTableHeaderSort = (column) => {
  if (column.isSorted) {
    return column.isSortedDesc ? " ▼" : " ▲";
  }
  return "";
};

const defaultPropGetter = () => { };
function DataTable({
  columns,
  data,
  loading = false,
  onRowClick = () => { },
  onCellClick = () => { },
  sortBy = [],
  isFixedWidth = true,
  getRowProps = defaultPropGetter,
  updateData,
  id
}) {
  const [, setDefaultPageSize] = useState(20);

  const tableState = {
    pageIndex: 0,
    sortBy,
    pageSize: 20,
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { ...tableState },
      updateData,
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  const handleSetPageSize = (n) => {
    setDefaultPageSize(n);
    setPageSize(n);
  };

  const showPaginationControls = !!pageOptions.length && pageOptions.length > 1;

  return (
    <div className="data-table-container" id={id}>
      <div className="table-container">
        {loading ? (
          <Loading.Loading />
        ) : (
          <Table {...getTableProps()} narrow hoverable fullwidth bordered>
            <Table.Head>
              {headerGroups.map((headerGroup) => (
                <Table.Row {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <Table.Heading
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <span>{renderTableHeaderSort(column)}</span>
                    </Table.Heading>
                  ))}
                </Table.Row>
              ))}
            </Table.Head>
            <Table.Body {...getTableBodyProps()}>
              {page.map(
                (row, i) =>
                  prepareRow(row) || (
                    <Table.Row
                      {...row.getRowProps(getRowProps(row))}
                      onClick={() => onRowClick(row.original)}
                    >
                      {row.cells.map((cell, j) => (
                        <Table.Cell
                          {...cell.getCellProps()}
                          onClick={() => onCellClick(cell)}
                          key={`row-${i}-cell-${j}`}
                        >
                          {renderFunc(cell)}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  )
              )}
            </Table.Body>
          </Table>
        )}
      </div>
      {showPaginationControls && (
        <Field kind="group" align="right">
          <Control>
            <Pagination
              page={pageIndex + 1}
              gotoPage={gotoPage}
              canNextPage={canNextPage}
              canPreviousPage={canPreviousPage}
              pages={pageOptions.length}
              onClickPrev={previousPage}
              onClickNext={nextPage}
              setPageSize={handleSetPageSize}
              pageSize={pageSize}
            />
          </Control>
        </Field>
      )}
    </div>
  );
}

DataTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  loading: PropTypes.bool,
  pageCount: PropTypes.number,
  pageSize: PropTypes.number,
  onRowClick: PropTypes.func,
  onCellClick: PropTypes.func,
  sortBy: PropTypes.array,
  isFixedWidth: PropTypes.bool,
  getRowProps: PropTypes.func,
  defaultColumn: PropTypes.object,
  updateData: PropTypes.func,
  defaultPageSize: PropTypes.number,
  id: PropTypes.string
};

DataTable.defaultProps = {
  data: [],
  loading: false,
  pageCount: 1,
  pageSize: 20,
  onRowClick: () => { },
  onCellClick: () => { },
  sortBy: [],
  isFixedWidth: true,
  getRowProps: () => { },
  defaultColumn: {},
  updateData: () => { },
  defaultPageSize: 20,
  id: ""
};
export default DataTable;
