import React from "react";
import PropTypes from "prop-types";

import "./H2Form.scss";

export default function H2({ children, ...props }) {
  return (
    <h2 {...props} className="heading2">
      {children}
      <span className="form-star"> *</span>
    </h2>
  );
}

H2.propTypes = {
  children: PropTypes.node.isRequired,
};

H2.displayName = "H2";
