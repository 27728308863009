

const MedicalProviderLinks =
	[
		{
			program: 1,
			links: [
				{
					providerType: 'California Medical Provider Network',
					link: 'https://www-sf.talispoint.com/gb/gbmpn/',
				},
				{
					providerType: 'Medical Provider Directory',
					link: 'https://www-lv.talispoint.com/gb/gbcare1/'
				}
			]
		},
		{
			program: 2,
			links: [
				{
					providerType: 'California Medical Provider Network',
					link: 'https://www-sf.talispoint.com/gb/gbmpn/',
				},
				{
					providerType: 'Medical Provider Directory',
					link: 'https://www-lv.talispoint.com/gb/gbcare1/'
				}
			]
		},
		{
			program: 3,
			links: [
				{
					providerType: 'California Medical Provider Network',
					link: 'https://www-sf.talispoint.com/gb/gbmpn/',
				},
				{
					providerType: 'Medical Provider Directory',
					link: 'https://www-lv.talispoint.com/gb/gbcare1/'
				}
			]

		},
		{
			program: 6,
			links: [
				{
					providerType: 'California Medical Provider Network',
					link: 'https://www-sf.talispoint.com/gb/gbmpn/',
				},
				{
					providerType: 'Medical Provider Directory',
					link: 'https://www-lv.talispoint.com/gb/gbcare1/'
				}
			]
		},
		{
			program: 7,
			links: [
				{
					providerType: 'California Medical Provider Network',
					link: 'https://www-sf.talispoint.com/gb/gbmpn/',
				},
				{
					providerType: 'Medical Provider Directory',
					link: 'https://www-lv.talispoint.com/gb/gbcare1/'
				}
			]
		},
		{
			program: 600,
			links: [
				{
					providerType: 'California Medical Provider Network',
					link: 'https://www-sf.talispoint.com/gb/gbmpn/',
				},
				{
					providerType: 'Medical Provider Directory',
					link: 'https://www-lv.talispoint.com/gb/gbcare1/'
				}
			]
		},
	]

export default MedicalProviderLinks