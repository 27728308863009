import APIService from "../services";

export const startCase = (str) =>
  str.replace(/\b[a-z]/g, (char) => char.toUpperCase());

export const round = (n, decimals = 0) =>
  Number(`${Math.round(`${n}e${decimals}`)}e-${decimals}`);

export const formatCurrency = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
});

export const formatCurrency2Digits = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

export const formatPhoneNumber = (str) => {
  const clean = `${str}`.replace(/\D/g, "");
  const match = clean.match(/^(\d{3})(\d{3})(\d{4})$/);
  return {
    value: clean,
    formatted: match ? `(${match[1]}) ${match[2]}-${match[3]}` : "",
  };
};

export const getRoleGroupData = async (selectedGroupName) => {
  const {
    data: { Payload: groups = false },
  } = await APIService.get(`/GetGroups`);

  const {
    data: { Payload: roles = false },
  } = await APIService.get(`/GetRoles`);

  const {
    data: { Payload: fullRoleGroups = false },
  } = await APIService.get(`/GetRoleGroups`);

  const {
    data: { Payload: userRoleGroups = false },
  } = await APIService.get(`/GetUserRoleGroups`);

  const roleGroups = fullRoleGroups.map((roleGroup) => {
    const GroupName = groups.find(
      (group) => group.RecId === roleGroup.GroupsRecId
    ).Name;
    const RoleName = roles.find((role) => role.RecId === roleGroup.RolesRecId)
      .Name;

    const result = {
      RecId: roleGroup.RecId,
      GroupsRecId: roleGroup.GroupsRecId,
      GroupName,
      RolesRecId: roleGroup.RolesRecId,
      RoleName,
    };

    return result;
  });

  const groupsWithRoleGroups = groups.map((group) => {
    const linkedRoleGroups = roleGroups.filter(
      (roleGroup) => roleGroup.GroupsRecId === group.RecId
    );
    return { ...group, linkedRoleGroups };
  });

  const emaxxGroup = groups.find((group) => group.Code === "eMaxx");

  const emaxxRoleGroupIds = roleGroups
    .filter((roleGroup) => roleGroup.GroupsRecId === emaxxGroup.RecId)
    .map((roleGroup) => roleGroup.RecId);

  const emaxxUserRoleGroups = userRoleGroups.filter(
    (userRoleGroup) =>
      userRoleGroup.IsActive &&
      emaxxRoleGroupIds.includes(userRoleGroup.RoleGroupsRecId)
  );

  let selectedGroupRoleGroups = {};
  if (selectedGroupName === "member") {
    const policyholderGroup = groups.find(
      (group) => group.Code === "Policyholder"
    );

    const policyholderRoleGroupIds = roleGroups
      .filter((roleGroup) => roleGroup.GroupsRecId === policyholderGroup.RecId)
      .map((roleGroup) => roleGroup.RecId);

    selectedGroupRoleGroups = userRoleGroups.filter((userRoleGroup) =>
      userRoleGroup.IsActive &&//07/19/2021 Li added the check for is active, it was originally missing
      policyholderRoleGroupIds.includes(userRoleGroup.RoleGroupsRecId)
    );
  } else if (selectedGroupName === "expired member") {
    const expiredGroup = groups.find((group) => group.Code === "XPolicyholder");

    const expiredRoleGroupIds = roleGroups
      .filter((roleGroup) => roleGroup.GroupsRecId === expiredGroup.RecId)
      .map((roleGroup) => roleGroup.RecId);

    selectedGroupRoleGroups = userRoleGroups.filter((userRoleGroup) =>
      expiredRoleGroupIds.includes(userRoleGroup.RoleGroupsRecId)
    );
  } else if (selectedGroupName === "prospect") {
    const prospectGroup = groups.find((group) => group.Code === "Prospect");

    const prospectRoleGroupIds = roleGroups
      .filter((roleGroup) => roleGroup.GroupsRecId === prospectGroup.RecId)
      .map((roleGroup) => roleGroup.RecId);

    selectedGroupRoleGroups = userRoleGroups.filter((userRoleGroup) =>
      prospectRoleGroupIds.includes(userRoleGroup.RoleGroupsRecId)
    );
  } else if (selectedGroupName === "broker") {
    const brokerGroup = groups.find((group) => group.Code === "Broker");

    const brokerRoleGroupIds = roleGroups
      .filter((roleGroup) => roleGroup.GroupsRecId === brokerGroup.RecId)
      .map((roleGroup) => roleGroup.RecId);

    selectedGroupRoleGroups = userRoleGroups.filter((userRoleGroup) =>
      brokerRoleGroupIds.includes(userRoleGroup.RoleGroupsRecId)
    );
  }

  const result = {
    emaxxUserRoleGroups,
    groupsWithRoleGroups,
    emaxxGroup,
    roleGroups,
    selectedGroupRoleGroups,
  };

  return result;
};

export const getUserRoleGroupDetails = async (assignedRoleGroup) => {
  const {
    data: { Payload: groups = false },
  } = await APIService.get(`/GetGroups`);

  const {
    data: { Payload: roles = false },
  } = await APIService.get(`/GetRoles`);

  const {
    data: { Payload: fullRoleGroups = false },
  } = await APIService.get(`/GetRoleGroups`);

  const fullRoleGroup = fullRoleGroups.find(
    (roleGroup) => roleGroup.RecId === assignedRoleGroup
  );

  const GroupName = groups.find(
    (group) => group.RecId === fullRoleGroup.GroupsRecId
  ).Name;
  const RoleName = roles.find((role) => role.RecId === fullRoleGroup.RolesRecId)
    .Name;

  const emaxxGroup = groups.find((group) => group.Code === "eMaxx");
  const brokerGroup = groups.find((group) => group.Code === "Broker");

  const result = {
    RecId: fullRoleGroup.RecId,
    GroupsRecId: fullRoleGroup.GroupsRecId,
    GroupName,
    RolesRecId: fullRoleGroup.RolesRecId,
    RoleName,
    clientSelectable:
      fullRoleGroup.GroupsRecId === emaxxGroup.RecId ||
      fullRoleGroup.GroupsRecId === brokerGroup.RecId,
  };

  return result;
};
