import React from "react";
import { Modal, Box } from "rbx";

import "./Modal.scss";

const defaultContext = {
  isModalOpen: false,
  modalContent: "",
  toggleModal: (open = true) => ({ isModalOpen: open }),
};

export const ModalContext = React.createContext(defaultContext);

export const ModalProvider = () => (
  <ModalContext.Consumer>
    {({ isModalOpen, modalContent, toggleModal }) => (
      <Modal
        clipped
        closeOnEsc
        active={isModalOpen}
        onClose={() => toggleModal(false, "")}
      >
        <Modal.Background />
        <Modal.Content>
          <Box>{modalContent}</Box>
        </Modal.Content>
        <Modal.Close />
      </Modal>
    )}
  </ModalContext.Consumer>
);

ModalProvider.propTypes = {};

export default ModalProvider;
