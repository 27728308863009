import React from "react";
import PropTypes from "prop-types";
import { ResponsiveBar } from "@nivo/bar";

import { formatCurrency } from "../../utils";

const Bargraph = ({ data, dataKeyArr, width }) => (
  <ResponsiveBar
    data={data}
    keys={dataKeyArr}
    groupMode="grouped"
    indexBy="label"
    margin={{ top: 20, right: 10, bottom: 20, left: 60 }}
    colors={["#48B5FF", "#124A80", "#16204A"]}
    height={300}
    // width={width}
    enableLabel={false}
    enableGridX={false}
    enableGridY
    borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 0,
      tickPadding: 5,
      tickRotation: 0,
    }}
    padding={0.15}
    valueScale={{ type: "linear" }}
    indexScale={{ type: "band", round: true }}
    axisLeft={{
      format: v => {
        if (v >= 1000000) {
          return `$${v / 1000000}M`
        } if (v >= 1000) {
          return `$${v / 1000}K`
        }
        return `$${v}`
      },
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      tickValues: 5
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
    animate
    motionStiffness={90}
    motionDamping={15}
    tooltip={({ id, value }) => (
      <span style={{ color: "#48B5FF", width: "50px", paddingBottom: "10px" }}>
        <p>{`${id}: `}</p>
        {formatCurrency.format(value)}
      </span>
    )}
    theme={{
      tooltip: {
        container: {
          background: "#333",
          margin: 0
        },
      },
    }}
  />
);

Bargraph.propTypes = {
  data: PropTypes.array.isRequired,
  dataKeyArr: PropTypes.array.isRequired,
};

export default Bargraph;
