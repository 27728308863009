// may have to use a cdn here if we arent using docker
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { filter } from "lodash";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import BarGraph from "../BarGraph";

import "./Carousel.scss";

const arrowStyles: CSSProperties = {
  position: "absolute",
  zIndex: 2,
  top: "calc(50% - 15px)",
  cursor: "pointer",
};

const GraphCarousel = ({ data, dataKeyArr, currentYear }) => {
  // console.log("data", data);
  // console.log("dataKeyArr", dataKeyArr);

  const twoYearsPriorData = filter(data, (o) => {
    return o.label.includes((currentYear - 2).toString());
  });
  const oneYearPriorData = filter(data, (o) => {
    return o.label.includes((currentYear - 1).toString());
  });
  const currentYearData = filter(data, (o) => {
    return o.label.includes(currentYear.toString());
  });

  // console.log("2020 Data", currentYearData);
  // console.log("2019 Data", oneYearPriorData);
  // console.log("2018 Data", twoYearsPriorData);

  if (twoYearsPriorData.length > 0 && oneYearPriorData.length > 0) {
    return (
      <Carousel
        showIndicators={false}
        showStatus={false}
        showThumbs={false}
        infiniteLoop={false}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <button
              className="sideButton"
              type="button"
              onClick={onClickHandler}
              title={label}
              style={{ ...arrowStyles, left: 0 }}
            >
              <FontAwesomeIcon
                icon={faChevronLeft}
                className="leftChevron"
                size="3x"
              />
            </button>
          )}
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <button
              className="sideButton"
              type="button"
              onClick={onClickHandler}
              title={label}
              style={{ ...arrowStyles, right: 0 }}
            >
              <FontAwesomeIcon
                icon={faChevronRight}
                className="rightChevron"
                size="3x"
              />
            </button>
          )}
      >
        <div>
          <BarGraph data={currentYearData} dataKeyArr={dataKeyArr} />
          <h3>{currentYear}</h3>
        </div>
        <div>
          <BarGraph data={oneYearPriorData} dataKeyArr={dataKeyArr} />
          <h3>{currentYear - 1}</h3>
        </div>
        <div>
          <BarGraph data={twoYearsPriorData} dataKeyArr={dataKeyArr} />
          <h3>{currentYear - 2}</h3>
        </div>

      </Carousel>
    );
  }
  if (twoYearsPriorData.length > 0 && !(oneYearPriorData.length > 0)) {
    return (
      <Carousel
        showIndicators={false}
        showStatus={false}
        showThumbs={false}
        infiniteLoop={false}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <button
              className="sideButton"
              type="button"
              onClick={onClickHandler}
              title={label}
              style={{ ...arrowStyles, left: 0 }}
            >
              <FontAwesomeIcon
                icon={faChevronLeft}
                className="leftChevron"
                size="3x"
              />
            </button>
          )}
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <button
              className="sideButton"
              type="button"
              onClick={onClickHandler}
              title={label}
              style={{ ...arrowStyles, right: 0 }}
            >
              <FontAwesomeIcon
                icon={faChevronRight}
                className="rightChevron"
                size="3x"
              />
            </button>
          )}
      >
        <div>
          <BarGraph data={currentYearData} dataKeyArr={dataKeyArr} />
          <h3>{currentYear}</h3>
        </div>
        <div>
          <BarGraph data={twoYearsPriorData} dataKeyArr={dataKeyArr} />
          <h3>{currentYear - 2}</h3>
        </div>
      </Carousel>
    );
  }
  if (oneYearPriorData.length > 0 && !(twoYearsPriorData.length > 0)) {
    return (
      <Carousel
        showIndicators={false}
        showStatus={false}
        showThumbs={false}
        infiniteLoop={false}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <button
              className="sideButton"
              type="button"
              onClick={onClickHandler}
              title={label}
              style={{ ...arrowStyles, left: 0 }}
            >
              <FontAwesomeIcon
                icon={faChevronLeft}
                className="leftChevron"
                size="3x"
              />
            </button>
          )}
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <button
              className="sideButton"
              type="button"
              onClick={onClickHandler}
              title={label}
              style={{ ...arrowStyles, right: 0 }}
            >
              <FontAwesomeIcon
                icon={faChevronRight}
                className="rightChevron"
                size="3x"
              />
            </button>
          )}
      >
        <div>
          <BarGraph data={currentYearData} dataKeyArr={dataKeyArr} />
          <h3>{currentYear}</h3>
        </div>
        <div>
          <BarGraph data={oneYearPriorData} dataKeyArr={dataKeyArr} />
          <h3>{currentYear - 1}</h3>
        </div>

      </Carousel>
    );
  }
  if (!(oneYearPriorData.length > 0) && !(twoYearsPriorData.length > 0)) {
    return (
      <Carousel
        showIndicators={false}
        showStatus={false}
        showThumbs={false}
        infiniteLoop={false}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <button
              className="sideButton"
              type="button"
              onClick={onClickHandler}
              title={label}
              style={{ ...arrowStyles, left: 0 }}
            >
              <FontAwesomeIcon
                icon={faChevronLeft}
                className="leftChevron"
                size="3x"
              />
            </button>
          )}
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <button
              className="sideButton"
              type="button"
              onClick={onClickHandler}
              title={label}
              style={{ ...arrowStyles, right: 0 }}
            >
              <FontAwesomeIcon
                icon={faChevronRight}
                className="rightChevron"
                size="3x"
              />
            </button>
          )}
      >
        <div>
          <BarGraph data={currentYearData} dataKeyArr={dataKeyArr} />
          <h3>{currentYear}</h3>
        </div>
      </Carousel>
    );
  }
};

export default GraphCarousel;
