import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { Select, Field, Control, Label, Input, Textarea, File } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload, faTimesCircle, faInbox } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { DocumentService } from "../../../../services/index";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import Section from "../../../../components/Section";
import Text from "../../../../components/Text";
import Button from "../../../../components/Button";
import { StateSelect, TextFieldInput } from "../../../../components/inputs";
import RoundButton from "../../../../components/Button/Round";
import DropboxInstructionPDF from "../../../../assets/dropbox/How to Upload Dashcam Footage.pdf";

const UploadClaimDocs = (Props) => {

  const [fileSize, setFileSize] = useState(0);


  if (Props.currentState.currentStep !== 4) {
    return null;
  }

  let files = Props.currentState.upload;

  const onFileChange = event => {

    if (event?.target?.files[0]) {
      //setFile({ file: event.target.files[0] });
      //check current file size + files in the array
      let sizeLimit = 19.8 * 1024 * 1024;
      let totalDocSize = 0;
      files.map(item => {
        totalDocSize += item.size
      });
      totalDocSize += event?.target?.files[0].size;

      if (event?.target?.files[0].size > sizeLimit) {
        toast.error("Document size can not go over 20MB")
        return;
      }
      if (totalDocSize > sizeLimit) {
        toast.error("Total Documents size can not go over 20MB")
        return;
      }

      let newFiles = files.concat(event?.target?.files[0]);
      Props.handleChange('', '', 'upload', newFiles);

      setFileSize(Math.round((totalDocSize / 1024 / 1024) * 10) / 10);
    }
  };

  const deleteFile = (file) => {
    let newFiles = [].concat(files);
    newFiles = newFiles.filter(item => {

      return item.name !== file.name
    });

    Props.handleChange('', '', 'upload', newFiles);

    if (newFiles.length === 0) {
      setFileSize(0);
    }
    else {
      let convertFileSize = fileSize * 10 / 10 * 1024 * 1024;
      setFileSize(Math.round((convertFileSize - file.size) / 1024 / 1024) * 10 / 10);
    }
  }



  return (
    <>
      <Text.H3>Upload Documents</Text.H3>
      <div id='ClaimDocumentUploadContainer'>
        {/* <p>Upload Documents</p> */}
        {fileSize > 0 && (
          <p style={{ fontWeight: 700, fontSize: '1em' }}>{`${fileSize}MB / 20MB`}</p>)}
        <Field horizontal className="input-holder">
          <Field.Body>
            <Field>
              <Control>
                <File hasName fullwidth>
                  <File.Label>
                    <File.Input
                      name="resume"
                      //accept="application/pdf"
                      onChange={e => onFileChange(e)}
                    />
                    <File.CTA>
                      <File.Icon>
                        <FontAwesomeIcon icon={faUpload} />
                      </File.Icon>
                      <File.Label as="span">Choose a File</File.Label>
                    </File.CTA>
                    {/* <File.Name>{file?.file?.name}</File.Name> */}

                  </File.Label>
                </File>

              </Control>
            </Field>
            <Field>
              {files.map(item => <File.Name style={{ 'maxWidth': '30em' }}>{item.name} <FontAwesomeIcon icon={faTimesCircle} onClick={() => deleteFile(item)} /></File.Name>)}
            </Field>
          </Field.Body>
        </Field>
        {/* {fileSize > 0 && (
          <p style={{ fontWeight: 700, fontSize: '1em' }}>{`${fileSize}MB / 20MB`}</p>)} */}
        {/* <p style={{ fontWeight: 700, paddingTop: 20 }}>Share Larger Files and Dash Carmera Footage</p>
        <RoundButton onClick={() => window.open(DropboxInstructionPDF)}>Dropbox</RoundButton> */}
        <p style={{ fontWeight: 700, fontSize: '1em' }}>For Larger Files and Dash Camera Footage, a link will be provided after the claim is submitted.</p>
        {/* <button onClick={() => window.open(DropboxInstructionPDF)}><span style={{ alignItems: 'center', marginRight: '0.5em' }}><FontAwesomeIcon icon={faInbox} /></span>Dropbox</button> */}
        {/* <a
          href="https://www.emaxxgroup.com/dashcam-upload"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button><span style={{ alignItems: 'center', marginRight: '0.5em' }}><FontAwesomeIcon icon={faInbox} /></span>Dropbox</button>
        </a> */}
      </div>

    </>
  );
}

export default UploadClaimDocs;




