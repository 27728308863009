import React from "react";
import PropTypes from "prop-types";
import { Field, Label, Control, Input } from "rbx";

const TextFieldInput = ({ label, name, value, onChange, type, disabled }) => (
  <>
    <Field.Label size="normal">
      <Label>
        {label}
        <span className="requiredStar"> *</span>
      </Label>
    </Field.Label>
    <Field.Body>
      <Field>
        <Control>
          <Input
            type={type}
            disabled={disabled}
            name={name}
            value={value}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
      </Field>
    </Field.Body>
  </>
);

TextFieldInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};
TextFieldInput.defaultProps = {
  value: "",
  type: "text",
  disabled: false,
};

export default TextFieldInput;
