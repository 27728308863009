import APIService from "./index";

const ClientService = {


  // fetch address and program code for each client
  fetchClientData: async () => {

    //call once and look up locally 09/30/2022 Li
    const { data: { Payload: programs = [] } }
      = await APIService.get("/GetPrograms");

    const { data: { Payload: addresses = [] } }
      = await APIService.get("/GetClientAddresses");


    // fetch clients
    const {
      data: { Payload: clients = false },
    } = await APIService.get("/getClients");
    // fetch address and program code for each client
    const clientData = await Promise.all(
      clients.map(async (client) => {
        const fullClient = {
          ...client,
        };
        /*call once and look up locally 09/30/2022 Li commented
        const {
          data: { Payload: clientAddress = false },
        } = await APIService.get(
          `/GetClientAddress?ClientId=${client.ClientId}`
        ); */

        const clientAddress = addresses.find(f => f.ClientId === client.ClientId);

        fullClient.Address = clientAddress;
        if (client.ProgramsRecId) {

          /* call once and look up locally 09/30/2022 Li commented
          const {
            data: { Payload: clientProgram = false },
          } = await APIService.get(`/GetProgram?RecId=${client.ProgramsRecId}`);
          // synced data from prod may be missing a program here -> they have more than 4 options 
          */
          const clientProgram = programs.find(f => f.RecId === client.ProgramsRecId);

          if (clientProgram) {
            fullClient.Program = clientProgram.Code;
          }
        }
        return fullClient;
      })
    );

    return clientData;
  },
  fetchClientsByProgramData: async (programsRecId) => {
    // fetch clients
    const {
      data: { Payload: clients = false },
    } = programsRecId
        ? await APIService.get(
          `/GetClientsByProgram?ProgramsRecId=${programsRecId}`
        )
        : [];

    const filteredClientList = clients.filter((client) => client.ClientType === "Policyholder").sort((a, b) => a.ClientName.localeCompare(b.ClientName));
    // fetch address for each client
    const clientData = await Promise.all(
      filteredClientList.map(async (client) => {
        const fullClient = {
          ...client,
        };
        const {
          data: { Payload: clientAddress = false },
        } = await APIService.get(
          `/GetClientAddress?ClientId=${client.ClientId}`
        );
        fullClient.Address = clientAddress;
        return fullClient;
      })
    );
    return clientData;
  },
  getBroker: (brokerId) =>
    APIService.get(`/GetClientByClientId?ClientId=${brokerId}`).then(
      (res) => res.data.Payload
    ),
  getClientsByProgram: (programsRecId) =>
    programsRecId
      ? APIService.get(
        `/GetClientsByProgram?ProgramsRecId=${programsRecId}`
      ).then((res) => res.data.Payload)
      : [],
  getClients: () =>
    APIService.get(`/getClients`).then((res) => res.data.Payload),
  getClientsChangeHistory: () =>
    APIService.get(`/GetClientsChangeHistory`).then((res) => res.data.Payload),

  getClientMemberNumber: (clientId) =>
    APIService.get(`/GetClientMemberNumber?ClientId=${clientId}`).then((res) => res.data.Payload),

  getClientPolicyPowerUnit: (clientId) =>
    APIService.get(`/GetClientPolicyPowerUnit?ClientId=${clientId}`).then((res) => res.data.Payload),

  getClientTEVehicleCount: (clientId, yearMonth) =>
    APIService.get(`/GetClientTEVehicleCount?ClientId=${clientId}&yearMonth=${yearMonth}`).then((res) => res.data.Payload),
  getClientTEStatus: (clientId) =>
    APIService.get(`/GetClientTEStatus?ClientId=${clientId}`).then((res) => res.data.Payload),
  getClientTSPs: (clientId) =>
    APIService.get(`/GetClientTSPs?ClientId=${clientId}`).then((res) => res.data.Payload),

};

export default ClientService;
