import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import PropTypes from "prop-types";

import { useLocation, useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import { AuthService } from "../services";
import { buildClientAuth, updateClientAuth } from "../utils/authClientData";
import { getUserRoleGroupDetails } from "../utils";

export const AuthContext = createContext(null);

const initialState = {
  isLoggedIn: false,
  isLoginPending: false,
  loginError: null,
};

export const AuthProvider = ({ children }) => {
  // refresh will cause these state values to be set to initialState and ""
  const [state, setState] = useState(initialState);
  const [permissions, setPermissions] = useState([]);
  const [lastPathname, setLastPathname] = useState("");
  const location = useLocation();
  const history = useHistory();

  const updateClientId = useCallback(async (selectedClientId) => {
    // update authState with selected client
    const newClientData = await updateClientAuth(selectedClientId);
    setState((prev) => ({
      ...prev,
      ...newClientData,
    }));
  }, []);

  const handleLogin = async (email, password) => {
    try {
      setState((prev) => ({ ...prev, isLoginPending: true }));
      const { data } = await AuthService.login({
        username: email,
        password,
      });
      window.localStorage.setItem("emaxx_token", data.access_token);

      // fetch client info, permissions, and roleGroup info
      const authData = await buildClientAuth(email);
      const roleGroup = await getUserRoleGroupDetails(authData.roleGroup);
      // for broker, it will allow useAuth to retain a value on login to be used at clientSelectPage
      setState((prev) => ({
        ...prev,
        ...authData.state,
        roleGroup,
        loginClientId: authData?.state?.clientId,
        isLoggedIn: true,
      }));
      setPermissions([...authData.permissions]);
      if (roleGroup?.clientSelectable) {
        history.push("/select-company");
      } else {
        history.push("/");
      }
    } catch (err) {
      toast.error(`Invalid Username or Password`);

      setState((prev) => ({ ...prev, loginError: err.message }));
    } finally {
      setState((prev) => ({ ...prev, isLoginPending: false }));
    }
  };

  const handleLogout = async () => {
    await AuthService.logout();
    setState(initialState);
    history.push("/");
  };

  useEffect(() => {
    const handleCheckAuth = async () => {
      try {
        setState((prev) => ({ ...prev, isLoginPending: true }));
        const {
          data: { Payload = false },
        } = await AuthService.checkAuth();
        // Payload should be the email of the logged in user
        if (Payload) {
          // fetch client info, permissions, and roleGroup info
          const authData = await buildClientAuth(Payload);
          const loginClientId = authData.state.clientId;
          const roleGroup = await getUserRoleGroupDetails(authData.roleGroup);
          if (
            roleGroup.clientSelectable &&
            !location.pathname.includes("settings")
          ) {
            delete authData.state.clientId;
            delete authData.state.clientBrokerId;
            delete authData.state.clientName;
            delete authData.state.clientZipCode;
            delete authData.state.programRecId;

            setState((prev) => ({
              ...prev,
              ...authData.state,
              roleGroup,
              loginClientId,
              isLoggedIn: Payload,
            }));
          } else {
            setState((prev) => ({
              ...prev,
              ...authData.state,
              roleGroup,
              loginClientId,
              isLoggedIn: Payload,
            }));
          }
          setPermissions([...authData.permissions]);
          if (!authData.state.clientId && !state.clientId) {
            history.push("/select-company");
          }
        } else {
          setState((prev) => ({
            ...prev,
            isLoggedIn: Payload,
          }));
        }
      } catch (err) {
        setState((prev) => ({ ...prev, loginError: err.message }));
      } finally {
        setState((prev) => ({ ...prev, isLoginPending: false }));
      }
    };
    // console.log("lastPathname", lastPathname);
    // console.log("location.pathname", location.pathname);

    if (lastPathname !== location.pathname) {
      handleCheckAuth();
      setLastPathname(location.pathname);
    }
    // console.log("state", state);
    // debugger;
  }, [lastPathname, location.pathname, state, history]);

  return (
    <AuthContext.Provider
      value={{ state, handleLogin, handleLogout, permissions, updateClientId }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
