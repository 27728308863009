import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { Select, Field, Control, Label, Input, Textarea, File } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import Section from "../../../components/Section";
import Text from "../../../components/Text";
import Button from "../../../components/Button";
import { DocumentService } from "../../../services/index";
import { useAuth } from "../../../context/AuthContext";
import ClientService from "../../../services/ClientService";
import ProgramService from "../../../services/ProgramService";
import Loading from "../../../components/Loading";
import { startCase } from "../../../utils";
import EmailService from "../../../services/EmailService";





const RenewalDocUploadPage = (Props) => {

    const { state: authState, permissions } = useAuth();
    const [loading, setLoading] = useState(false);
    // having issues with adding the File Object into this combined state separated out
    const [inputState, setInputState] = useState({
        Category: "",
        Member: authState.clientId.toString(),
        Program: "",
        Tier: false,
        DocumentsRecId: "",
        Body: "",
        Date: Props.RecentRenewalTerm
    });

    const [canSubmit, setCanSubmit] = useState(false);
    const [fileSize, setFileSize] = useState(0);

    const [file, setFile] = useState({
        file: { name: "No File Added" }
    });

    // Document Types
    const { data: categories = [] } = useQuery("getDocumentTypes", () =>
        DocumentService.getDocumentTypes()
    )

    //mix pc8,9(policy program) to regular program list by getting from the polciies
    // const { data: Policies = [] } = useQuery("policies", () =>
    //   PolicyService.getPoliciesByClientNoFilter( clientId),
    //   { enabled: false }
    // );

    useEffect(() => {

        setCanSubmit(
            inputState.Category?.Name?.length >= 1 &&
            inputState.Member.length >= 1 &&  // add checking program when Member is selected for client program specific
            inputState.Date.length >= 1 &&
            file &&
            file.file.size
        );
    }, [inputState, file]);

    const handleCategory = (name, valueObjJSON) => {
        const valueObj = JSON.parse(valueObjJSON);
        // category will be the Documents RecID
        setInputState(prev => ({
            ...prev,
            Category: valueObj,
            Tier: valueObj.Tier,
            DocumentsRecId: valueObj.RecId,
            //Member: '', //Li program and client specific
            //Program: '', //Li program and client specific
            Date: Props.RecentRenewalTerm //Li program and client specific
        }));

        //setMemberPrograms([]);//Li program and client specific
    };


    const handleChange = async (name, value) => {

        setInputState(prev => ({ ...prev, [name]: value }));
    };

    const handleChangeClientAndProgram = (name, value) => {
        // resets Program upon Member change and vice et versa
        if (name === "Program") {
            setInputState(prev => ({ ...prev, [name]: value }));
        }
    };

    // have codes send in full object, split the Name on _ and grab second to last,  use the second furthest back section as key in the hash pointing to the full code object in an array
    // have this be called throughout all codes array,
    // creates labels for the docTypes; make this more robust so that other parts can be easier

    const abbrevCodeHash = {};

    const codeToHash = codeObj => {
        let header;
        const codeArr = codeObj.Code.split("_");
        if (
            !codeObj.Code.startsWith("Documents Library_Insurance Policies_PolicyPDF")
            //&&
            //!codeObj.Code.startsWith("PC8") //separate out for PC8 series documentTypes
        ) {
            if (codeObj.Code.startsWith("Governance_Organization_Meetings")) {
                // 4th section which should follow the above
                header = codeArr[3].replace(/_/i, "");
            } else if (codeObj.Code.startsWith("Governance_Organization")) {
                // 3rd section which should follow the above
                header = codeArr[2].replace(/_/i, "");
            } else {
                header = codeArr[codeArr.length - 2].replace(/_/i, "");
            }
            if (Object.keys(abbrevCodeHash).includes(header)) {
                abbrevCodeHash[header].push(codeObj);
            } else {
                abbrevCodeHash[header] = [codeObj];
            }
        }

    };

    const abbrevCode = code => {
        return startCase(
            code.slice(code.lastIndexOf("_")).replace(/_/i, "").replace(/_/i, " ")
        );
    };

    const abbrevCode2 = code => {
        const codeArr = code.split("_");
        const searchCodes = [codeArr[3], codeArr[4]];
        const searchTerm = searchCodes
            .join(" ")
            .replace(/_/i, "")
            .replace(/_/i, " ");
        return searchTerm;
    };

    const handleSubmit = async e => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append("file", file.file);
        const url = await DocumentService.DocumentUpload(formData);

        const dateArr = inputState.Date.split("/");

        const docYear = dateArr[2];
        const docMonth = dateArr[0];
        const docDay = dateArr[1];

        // not actual month but helper to get quarter
        const currentMonth = Number(docMonth) / 4;
        let currentQuarter;
        switch (true) {
            case currentMonth < 0.8:
                currentQuarter = 1;
                break;

            case currentMonth >= 1 && currentMonth < 1.6:
                currentQuarter = 2;
                break;

            case currentMonth >= 1.75 && currentMonth < 2.3:
                currentQuarter = 3;
                break;

            case currentMonth > 2.4:
                currentQuarter = 4;
                break;

            default:
                currentQuarter = 0;
                break;
        }

        const docObj = {
            DocumentId: `${inputState.Category.Code}`,
            DocumentTypesRecId: `${inputState.DocumentsRecId}`,
            FilePath: url.data.Payload,
            FileName: `${file.file.name}`,
            Description: `${inputState.Body}`,
            ClientId: `${inputState.Member}`,
            ProgramsRecId: `${inputState.Program}`,
            Year: docYear,
            Month: docMonth,
            Day: docDay,
            Quarter: currentQuarter
        };
        const doc = await DocumentService.createDocument(docObj).then(() =>
            toast.success("Document Uploaded Successfully")
        );

        var docType = inputState.Category.Code.slice(inputState.Category.Code.lastIndexOf("_")).replace(/_/i, "").replace(/_/i, " ");
        var emailBody = 'A Renewal Document, ' + docType + ', has been uploaded under ' + authState.clientName + ' by ' +
            authState.isLoggedIn + ' from ' + authState.loggedInClientName + ' on ' + new Date() + '. Please review through eMaxx Portal.';


        //['lliu@etechnologyservices.com', 'kseth@etechnologyservices.com'];
        //var toLst = ['mpinto@emaxxgroup.com', 'ldobie@emaxxgroup.com', 'lliu@etechnologyservices.com', 'kseth@etechnologyservices.com'];
        var toLst = ['lliu@etechnologyservices.com'];
        toLst.forEach(item => EmailService.SendEmail(item, 'Renewal Documentation Upload', emailBody));

        // reset state
        setInputState(prev => ({
            ...prev,
            Category: "",
            //Member: "",
            //Program: "",
            Tier: false,
            DocumentsRecId: "",
            Body: "",
            //Date: ""
        }));
        setFile({
            file: { name: "No File Added" }
        });
        setLoading(false);



        Props.InUploadPage(false);
    };

    // Fields: FilePath: Link to S3, DocumentTypesRecId: good, member or program RecId: good,
    //&& f.ClientAllowUpload && f.Name.includes('Renewal')
    const sortedCategories = categories.filter(f => f.IsActive && f.ClientAllowUpload && f.Name.startsWith('Member Kiosk_Renewal Documentation')).sort((a, b) =>
        abbrevCode(a.Code).localeCompare(abbrevCode(b.Code))
    );
    /*
    const sortedMembers = members.sort((a, b) =>
        a.ClientName.localeCompare(b.ClientName)
    );
    const sortedPrograms = programs.sort((a, b) => a.Name.localeCompare(b.Name));
    */
    const categoryHashCreate = async () => {
        await sortedCategories.forEach(element => {
            codeToHash(element);
        });
    };
    categoryHashCreate();

    const onFileChange = event => {

        if (event?.target?.files[0]) {
            //setFile({ file: event.target.files[0] });
            //check current file size + files in the array
            let sizeLimit = 19.8 * 1024 * 1024;
            let totalDocSize = 0;

            totalDocSize += event?.target?.files[0].size;

            if (event?.target?.files[0].size > sizeLimit) {
                toast.error("Document size can not go over 20MB")
                return;
            }
            if (totalDocSize > sizeLimit) {
                toast.error("Total Documents size can not go over 20MB")
                return;
            }

            setFile({ file: event.target.files[0] });
            setFileSize(Math.round((totalDocSize / 1024 / 1024) * 10) / 10);
        }
    };

    /*
        const onFileChange = event => {
            if (event?.target?.files[0]) {
                setFile({ file: event.target.files[0] });
            }
        };*/

    if (loading) {
        return (
            <div id="DocumentUploadContainer">
                <Loading.SmallLoading />
            </div>
        );
    }

    return (

        <div id="RenewalDocumentUploadContainer">
            <Section>
                <form
                    id="document-upload-form"
                    className="form"
                    onSubmit={handleSubmit}
                >
                    {/* Category */}
                    <Field horizontal className="input-holder">
                        <Field.Body>
                            <Field>
                                <Field.Label>
                                    <Label>
                                        <Text.H3>Category</Text.H3>
                                    </Label>
                                </Field.Label>
                                <Select.Container>
                                    <Select
                                        value={JSON.stringify(inputState.Category)}
                                        id="small"
                                        name="Category"
                                        onChange={e =>
                                            handleCategory(e.target.name, e.target.value)}
                                    >
                                        <Select.Option value="" style={{ display: "none" }}>
                                            Category
                                        </Select.Option>
                                        {Object.keys(abbrevCodeHash)
                                            .sort((a, b) => a.localeCompare(b))
                                            .map(label => (
                                                <optgroup label={label} key={label}>
                                                    {label === "Standing Committees" ||
                                                        label === "Organizational Documentation"
                                                        ? abbrevCodeHash[label]
                                                            .sort((a, b) =>
                                                                abbrevCode2(a.Code).localeCompare(
                                                                    abbrevCode2(b.Code)
                                                                )
                                                            )
                                                            .map(categoryObj => (
                                                                <Select.Option
                                                                    key={categoryObj.Code}
                                                                    value={JSON.stringify(categoryObj)}
                                                                >
                                                                    {abbrevCode2(categoryObj.Code)}
                                                                </Select.Option>
                                                            ))
                                                        : abbrevCodeHash[label]
                                                            .sort((a, b) =>
                                                                abbrevCode2(a.Code).localeCompare(
                                                                    abbrevCode2(b.Code)
                                                                )
                                                            )
                                                            .map(categoryObj => (
                                                                <Select.Option
                                                                    key={categoryObj.Code}
                                                                    value={JSON.stringify(categoryObj)}
                                                                >
                                                                    {abbrevCode(categoryObj.Code)}
                                                                </Select.Option>
                                                            ))}
                                                </optgroup>
                                            ))}
                                    </Select>
                                </Select.Container>
                            </Field>
                        </Field.Body>
                    </Field>
                    {/* Description */}
                    <Field horizontal>
                        <Field.Body>
                            <Field className="input-holder">
                                <Field.Label>
                                    <Label>
                                        <Text.H3>Description</Text.H3>
                                    </Label>
                                </Field.Label>
                                <Control>
                                    <Textarea
                                        className="form-textarea"
                                        type="textbox"
                                        value={inputState.Body}
                                        onChange={e => handleChange(e.target.name, e.target.value)}
                                        name="Body"
                                    />
                                </Control>
                            </Field>
                        </Field.Body>
                    </Field>
                    {/* File Upload */}
                    {fileSize > 0 && (
                        <p style={{ fontWeight: 700, fontSize: '1em' }}>{`${fileSize}MB / 20MB`}</p>)}
                    <Field horizontal className="input-holder">
                        <Field.Body>
                            <Field>
                                <Control>
                                    <File hasName fullwidth>
                                        <File.Label>
                                            <File.Input
                                                name="resume"
                                                // accept="application/pdf"
                                                onChange={e => onFileChange(e)}
                                                multiple
                                            />
                                            <File.CTA>
                                                <File.Icon>
                                                    <FontAwesomeIcon icon={faUpload} />
                                                </File.Icon>
                                                <File.Label as="span">Choose a File</File.Label>
                                            </File.CTA>
                                            <File.Name>{file?.file?.name}</File.Name>
                                        </File.Label>
                                    </File>
                                </Control>
                            </Field>
                        </Field.Body>
                    </Field>

                    <div className="buttonHolder">
                        <Button.Round
                            onClick={handleSubmit}
                            disabled={!canSubmit}
                            className={
                                canSubmit
                                    ? "roundButton primary"
                                    : "roundButton primary disabled"
                            }
                        >
                            Upload
                        </Button.Round>
                        <Button.Round
                            onClick={() => Props.InUploadPage(false)}
                            className='roundButton primary'
                        >
                            Cancel
                        </Button.Round>
                    </div>
                </form>
            </Section>

            <Section>
                <div className="renewalDesc">

                    <div className="renewalDescIndividual">
                        <span>
                            Loss runs need to be valued within 90 days for all lines of business being quoted and require all years not written through eMaxx's program.
                        </span>
                    </div>
                    <div className="renewalDescIndividual">
                        <span>
                            Surety Bonds are an acceptable form of collateral only for active Members. If a Member terminates participation, any collateral posted by Surety Bond must be converted (no later than thirty (30) days prior to the date of termination) to either Cash or to a clean and irrevocable Letter of Credit (in a form and upon conditions/terms acceptable to the Company).
                            Surety Bond or Letter of Credit shall be in place at or prior to binding. Collateral shall be added to the Finance Agreement until the Surety Bond or Letter of Credit is provided.

                        </span>
                    </div>

                    <div className="renewalDescIndividual">
                        <span>
                            Surety Bond or Letter of Credit shall be in place at or prior to binding. Collateral shall be added to the Finance Agreement until the Surety Bond or Letter of Credit is provided.
                        </span>
                    </div>
                </div>

            </Section>
        </div>








    );
};

export default RenewalDocUploadPage;
