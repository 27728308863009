import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";

import { useAuth } from "../../context/AuthContext";

const actions = [
  { label: "Report Claim", link: "/report-claim" },
  // { label: "Privacy Policy", link: "/footer/privacy-policy" },
  { label: "Contact Us", link: "/footer/contact-us" },
  // { label: "Live Chat", link: "/footer/live-chat" },
];

function Header() {
  const { state, handleLogout } = useAuth();
  return (
    <div className="footer">
      {actions.map((action, i) => (
        <Link key={action.label} className="footerItem" to={action.link}>
          {action.label}
        </Link>
      ))}
      {state.isLoggedIn && (
        <button
          className="footerItem footer-item-logout"
          type="button"
          onClick={handleLogout}
        >
          Log Out
        </button>
      )}
    </div>
  );
}

Header.propTypes = {};

export default Header;
