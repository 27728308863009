import { format, parseISO } from "date-fns";

export default [
  {
    Header: "User",
    accessor: "User",
    id: "User",
  },
  {
    Header: "Timestamp",
    accessor: "CreatedDateTime",
    id: "CreatedDateTime",
    Cell: ({ value }) =>
      value ? format(parseISO(value), "MM/dd/yyyy, h:mm:ss a") : "--",
  },
  {
    Header: "Action",
    accessor: "Action",
    id: "Action",
  },
];
