import React, { useEffect, useMemo, useState } from "react";
import { Table, Generic, Field, Control, Checkbox, Icon } from "rbx";
import PropTypes from "prop-types";

import './CustomCheckBox.scss';
import { propTypes } from "react-bootstrap/esm/Image";

const CustomCheckBox = ({ name, labelInSpanName, checked, onChange, disabled }) => {

    const stopLabelPropagationDown = (e) => { //prevent from checking the checkbox when clicking on the label 
        if (e.target.localName === 'label') e.preventDefault();
    }


    return (
        <div id='CstCBox'>
            <label onClick={(e) => stopLabelPropagationDown(e)}>
                <Checkbox className="inputCheckBox" name={name} checked={checked} onChange={(e) => onChange(e.target.name, e.target.checked)}
                    disabled={disabled} />
                <span>{labelInSpanName}</span>
            </label>
        </div>
    )
}

CustomCheckBox.propTypes = {
    name: PropTypes.string,
    labelInSpanName: PropTypes.string,
    checked: propTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool
};


export default CustomCheckBox;


