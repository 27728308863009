import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";

import ClientService from "../../services/ClientService";
import { useAuth } from "../../context/AuthContext";
import DataTable from "../../components/DataTable";
import Section from "../../components/Section";
import Text from "../../components/Text";
import Loading from "../../components/Loading";
import SearchBar from "../../components/SearchBar";
import COLUMNS from "./columns";
import "./ClientLocator.scss";

const ClientLocatorPage = () => {
  const { updateClientId, state: authState } = useAuth();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);

  const { data, isLoading, error } = useQuery(
    "clients",
    () => ClientService.fetchClientData(),
    { fetchPolicy: "no-cache", cacheTime: 0 }
  );
  const group = authState.roleGroup.GroupName;
  let filteredByGroup = [];
  // loginClientId used for the broker to not lose its associated clientId on a refresh
  if (group === "Broker" && Array.isArray(data)) {
    filteredByGroup = data.filter(
      client => client.BrokerClientId === authState.loginClientId
    );
  } else {
    filteredByGroup = data;
  }

  const handleRowClick = async rowData => {
    await updateClientId(rowData.ClientId);
    history.push("/");
  };

  const handleSearchChange = value => {
    setSearch(value);
  };

  useEffect(() => {
    if (filteredByGroup?.length) {
      if (search) {
        setLoading(true);
        const filteredData = filteredByGroup.filter(client =>
          client.ClientName.toLowerCase()
            .replace(/[^A-Z0-9]/gi, "")
            .includes(search.toLowerCase().replace(/[^A-Z0-9]/gi, ""))
        );
        setClients([...filteredData]);
        setLoading(false);
      } else {
        setLoading(true);
        setClients([...filteredByGroup]);
        setLoading(false);
      }
    }
  }, [search, data]);

  if (isLoading || loading) {
    return (
      <div className="headerContainer">
        <Text.H1>Select Company</Text.H1>
        <Loading.SmallLoading />
      </div>
    );
  }

  if (error) {
    return null;
  }

  return (
    <div id="clientLocatorPage">
      <div className="headerContainer">
        <Text.H1>Select Company</Text.H1>
      </div>
      <SearchBar value={search} onSearch={handleSearchChange} />
      {clients.map(c => {//Li add type
        if (c.ClientType === 'Policyholder') { c.TypeName = 'Member' }
        else if (c.ClientType === 'XPolicyholder') { c.TypeName = 'XMember' }
        else { c.TypeName = c.ClientType }
      })}

      {clients.length > 0 ? (
        <DataTable
          columns={COLUMNS}
          data={clients}
          onRowClick={rowData => handleRowClick(rowData)}
          sortBy={[
            {
              id: "ClientName",
              desc: false
            }
          ]}
        />
      ) : (
        <Section>No Companies Found.</Section>
      )}
    </div>
  );
};

export default ClientLocatorPage;
