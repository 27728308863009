import React, { useEffect, useState, useMemo, useContext } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import Button from "../../components/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faTrash } from "@fortawesome/free-solid-svg-icons";
import { ModalContext } from "../../components/Modals/ModalProvider";
import ConfirmationModal from "../../components/Modals";

import { DocumentService } from "../../services/index";

import PropTypes from "prop-types";

import './DocumentDelete.scss';
import { propTypes } from "react-bootstrap/esm/Image";

const DocumentDelete = ({ name, fileName, docObj, isIcon, handleRefresh }) => {

    const { toggleModal } = useContext(ModalContext);



    const deleteDocument = (fileName, docObj) => {

        fileName = docObj.FilePath.slice(docObj.FilePath.lastIndexOf("/")).replace(/\//i, "")
        //setDeletingFile(fileName);

        DocumentService.DocumentDelete(fileName, docObj.RecId).then(r => {
            if (r.data.Payload) {
                // refetchDocumentsByProgram();
                handleRefresh();
                toggleModal(false, "");
            }
        })
    }


    return (
        <>
            {isIcon ? (
                <div id='deleteDoc'>
                    <FontAwesomeIcon
                        icon={faTrash}
                        className="downloadIcon"
                        onClick={(e) => {

                            toggleModal(
                                true,
                                <ConfirmationModal
                                    entity="Doc"
                                    toggleModal={toggleModal}
                                    handleAction={() => deleteDocument(
                                        fileName,
                                        docObj
                                    )}

                                    actionText="DELETE"
                                />
                            )
                        }}
                    />

                </div>) : (<div>
                    <Button.ExternalText
                        onClick={e => {
                            toggleModal(
                                true,
                                <ConfirmationModal
                                    entity="Doc"
                                    toggleModal={toggleModal}
                                    handleAction={() => deleteDocument(
                                        fileName,
                                        docObj
                                    )}

                                    actionText="DELETE"
                                />
                            )
                        }}
                    >
                        Delete
                    </Button.ExternalText>
                </div>)}

        </>
    )
}

DocumentDelete.propTypes = {
    name: PropTypes.string,
    fileName: PropTypes.string,
    docObj: PropTypes.object,
    isIcon: PropTypes.bool,
    handleRefresh: PropTypes.func.isRequired
};


export default DocumentDelete;


