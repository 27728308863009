import React from "react";
import PropTypes from "prop-types";
import { ResponsiveBar } from "@nivo/bar";

import { formatCurrency } from "../../utils";


const BarGraphGeneral = ({ data, dataKeyArr, width, colors }) => (
  <ResponsiveBar
    // label={(d) =>
    //   d.value === 0 ? <tspan y="-15">{d.value}</tspan> : d.value
    // }
    data={data}
    keys={dataKeyArr}
    groupMode="grouped"
    indexBy="label"
    margin={{ top: 20, right: 10, bottom: 60, left: 40 }}
    //colors={["#48B5FF", "#124A80", "#16204A"]}
    colors={colors}
    height={350}
    // width={width}
    enableLabel={false}
    enableGridX={false}
    enableGridY
    borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
    borderRadius={1}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 0,
      tickPadding: 5,
      tickRotation: 0,
    }}
    padding={0.1}
    valueScale={{ type: "linear" }}
    indexScale={{ type: "band", round: true }}
    axisLeft={{
      // format: v => {
      //   if (v >= 1000000) {
      //     return `$${v / 1000000}M`
      //   } if (v >= 1000) {
      //     return `$${v / 1000}K`
      //   }
      //   return `$${v}`
      // },
      legend: '# Employees',
      legendOffset: -35,
      legendPosition: 'middle',
      legendRotation: 5,
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      tickValues: 5,
      format: e => Math.floor(e) === e && e
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
    animate
    motionStiffness={90}
    motionDamping={15}
    tooltip={({ id, value, color }) => (
      <span style={{ color: color, width: 50, paddingBottom: 10 }}>
        <p>{`${id}: `}</p>
        {value}
      </span>
    )}
    theme={{
      tooltip: {
        container: {
          //background: "#333",
          margin: 0
        },
      },
      axis: {
        legend: {
          text: {
            fontWeight: 'bold'
          }
        }
      }
    }}
    legends={[{
      dataFrom: 'keys',
      direction: 'row',
      anchor: 'bottom-left',
      translateX: -15,
      translateY: 50,
      itemsSpacing: 2,
      itemWidth: 100,
      itemHeight: 20,
      itemDirection: 'left-to-right',
      itemOpacity: 0.85,
      symbolSize: 18,
      effects: [
        {
          on: 'hover',
          style: {
            itemOpacity: 1,
            itemTextColor: '#16204a',
          }
        }
      ]
    }]}
  />
);

BarGraphGeneral.propTypes = {
  data: PropTypes.array.isRequired,
  dataKeyArr: PropTypes.array.isRequired,
};

export default BarGraphGeneral;
