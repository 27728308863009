import APIService from "./index";

const ClaimsService = {
  getClaimsByPolicy: (PolicyId) =>
    APIService.get(`/GetClaimsByPolicy?PolicyId=${PolicyId}`).then(
      (res) => res.data.Payload
    ),
  getClaimByRecID: (RecId) =>
    APIService.get(`/GetClaim?RecId=${RecId}`).then((res) => res.data.Payload),
  getNotesByClaim: (ClaimNumber) =>
    APIService.get(`/GetNotesByClaim?ClaimNumber=${ClaimNumber}`).then(
      (res) => res.data.Payload
    ),
  getLossRunReport: (policyId, clientId, doctype = "pdf") =>
    //policyId = policyId.join('&PolicyId=');
    APIService.get(
      `/GetLossRunReport?PolicyId=${policyId = policyId.join('&PolicyId=')}&ClientId=${clientId}&DocType=${doctype}`
    ).then((res) =>
      res.data.Payload

    )
  ,

  fnolSubmit: (clientId, reportingClientId, type, data) =>
    APIService.post(`/FNOLSubmit?ClientId=${clientId}&ReportingClientId=${reportingClientId}&Type=${type}`, data)
      .then(
        (res) => res
      ),
  fnolEmail: (clientId, reportingClientId, type, data) =>
    APIService.post(`/FNOLEmail?ClientId=${clientId}&ReportingClientId=${reportingClientId}&Type=${type}`, data)
      .then(
        (res) => res
      ),
  getFNOLControls: () =>
    APIService.get(
      `/GetFNOLControls`
    ).then((res) => res.data.Payload),
  fnolSubmit2: (data) =>
    APIService.post('/FNOLSubmit2', data)
      .then(
        (res) => res
      ),
  getClaimsByClient: (ClientId) =>
    APIService.get(`/GetClaimsByClient?ClientID=${ClientId}`).then(
      (res) => res.data.Payload
    ),

  getClaimNotificationsByUser: (UsersRecId) =>
    APIService.get(`/GetClaimNotificationsByUser?UsersRecId=${UsersRecId}`).then(
      (res) => res.data.Payload
    ),
  getClaimNotificationCriteria: () =>
    APIService.get('/GetClaimNotificationCriteria').then(
      (res) => res.data.Payload
    ),
  getClaimNotificationSelectionsByUser: (UsersRecId) =>
    APIService.get(`/GetClaimNotificationSelectionsByUser?UsersRecId=${UsersRecId}`).then(
      (res) => res.data.Payload
    ),
  updateClaimNotificationSelectionsByUser: (data) =>
    APIService.put('/UpdateClaimNotificationSelectionsByUser', data),
  getFNOLControlDisplayValues: () =>
    APIService.get(
      `/GetFNOLControlDisplayValues`
    ).then((res) => res.data.Payload),
  getFNOLControlDependencies: () =>
    APIService.get(
      `/GetFNOLControlDependencies`
    ).then((res) => res.data.Payload),
};

export default ClaimsService;
