import APIService from "./index";

const TelematicsService = {
  getSpeedingMonthlySummaries: (clientId) =>
    APIService.get(`/GetSpeedingMonthlySummaries?clientId=${clientId}`).then(
      (res) => res.data.Payload
    ),
  getSpeedingMonthlySummariesData: async (otherClients) => {
    // get the speeding monthlyData for all clients in program
    const {
      data: { Payload: ranks = false },
    } = await APIService.get(`/GetSpeedingMonthlySummaries?clientIds=${otherClients}`);

    // change this to dynamically look for the month where there is data and also return an object with keys for the original array and current year and month as well
    const sortedDateRanks = ranks.sort((a, b) => b.YearMonth - a.YearMonth);

    const closestYear = sortedDateRanks[0].YearMonth.toString().slice(0, 4);
    const closestMonth = sortedDateRanks[0].YearMonth.toString().slice(4);

    const returnArr = sortedDateRanks.map((rank) => {
      const clientIdInnerObj = {};
      if (`${rank.YearMonth}` === `${closestYear}${closestMonth}`) {
        const totalSpeedingPercentage = ((rank.Speeding_5_10mph_ByTime + rank.Speeding_10_15mph_ByTime + rank.Speeding_Over_15mph_ByTime + rank.Speeding_Under_5mph_ByTime) / 3600) / rank.TotalMovingTime_Hr;
        const key = rank.ClientId;
        clientIdInnerObj[key] = totalSpeedingPercentage;
      }
      return clientIdInnerObj;
    })
    return { returnArr, month: closestMonth, year: closestYear };
  },
};

export default TelematicsService;