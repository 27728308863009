import React, { useState, useEffect, useContext } from "react";
import { Prompt } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation, faInbox, faUpload, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import lodash from "lodash";
import { toast } from "react-toastify";
import { Field, Label, Control, Input, Checkbox, Select, Textarea, File } from "rbx";
import Section from "../../../components/Section";
import Text from "../../../components/Text";
import Button from "../../../components/Button";
import RoundButton from "../../../components/Button/Round";
import { StateSelect, TextFieldInput } from "../../../components/inputs";
import "./Confirmation.scss";


const ConfirmationPage = (Props) => {


    return (
        <div className='confirmationPage'>
            <Section>
                <Text.H3>CONFIRMATION</Text.H3>
                <p style={{ paddingTop: 15, fontWeight: 'bold' }}>{`Confirmation Number: ${Props?.confirmation?.confirmationNum}`}</p>
                <p style={{ paddingTop: 15 }}>You have submitted the claim to eMaxx.</p>
                <p>A Claim Acknowledgement and Adjuster contact information including Claim Number will be sent within 24 business hours.  Please email <a href="mailto:ClaimsOperations@emaxxgroup.com">ClaimsOperations@emaxxgroup.com</a> if there are any questions.</p>
                <p style={{ fontWeight: 'bold', paddingTop: 15, paddingBottom: 5 }}>Upload Larger Files and Dash Camera Footage</p>
                <a
                    href="https://www.emaxxgroup.com/dashcam-upload"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <button><span><FontAwesomeIcon icon={faInbox} /></span>Dropbox</button>
                </a>
            </Section>
        </div>
    );
}

export default ConfirmationPage


