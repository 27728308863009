import React from "react";
import { Switch, Route } from "react-router-dom";

import MemberKioskPage from "./MemberKioskPage";
import EndorsementsPage from "./EndorsementsPage"
import RenewalDocumentationPage from "./RenewalDocumentation";
import { useAuth } from "../../../context/AuthContext";

// order matters so the current governance page should go last or have an exact path
const MemberKioskRouter = () => {
  const { permissions } = useAuth();
  return (
    <Switch>
      {permissions.includes("002-003") && (
        <Route exact path="/insurance/member-kiosk">
          <MemberKioskPage />
        </Route>
      )}
      {permissions.includes("002-003-002") && (
        <Route path="/insurance/member-kiosk/endorsements">
          <EndorsementsPage />
        </Route>
      )}
      {permissions.includes("002-003-005") && (
        <Route path="/insurance/member-kiosk/renewal-Documentation">
          <RenewalDocumentationPage />
        </Route>
      )}
    </Switch>
  )
};

export default MemberKioskRouter;