import React from "react";

import { useHistory } from "react-router-dom";

import  Button from "../../components/Button";
import logo from "../../assets/images/eMaxxLogo2021.png";

const LandingPage = () => {
  const history = useHistory();
  return (
    <div className="landingPageContent">
      <img src={logo} alt="eMaxx-logo" />
      <div className="text-container">
        <h1>MAXIMIZING INSURANCE THROUGH</h1>
        <h1>
          TECHNOLOGY
          <span className="animated-plus" />
          RISK MANAGEMENT
        </h1>
      </div>
      <div className="button-container">
        <Button.Round onClick={() => history.push("/login")} color="link">
          Login
        </Button.Round>
      </div>
    </div>
  );
};

LandingPage.propTypes = {};

export default LandingPage;
