import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { Select } from "rbx";

import Section from "../../../components/Section";
import Text from "../../../components/Text";
import Button from "../../../components/Button";
import { useAuth } from "../../../context/AuthContext";
import { DocumentService } from "../../../services/index";
import ProgramService from "../../../services/ProgramService";
import ClientService from "../../../services/ClientService";
import PolicyService from "../../../services/PolicyService";
import "./ProgramInformation.scss";
import ExcessStateBroker from "./ExcessStateBroker";
import { groupBy } from "lodash";

const data = [
  {
    title: "",
    items: [
      {
        label: "Overview Summary",
        action: "View",
        docName: "Program Information_Overview Summary",
        code: "002-006-001",
      },
      {
        label: "Information Circular",
        action: "View",
        docName: "Program Information_Information Circular",
        code: "002-006-002",
      },
      {
        label: "Program Highlights",
        action: "View",
        docName: "Program Information_Program Highlights",
        code: "002-006-003",
      },
      {
        label: "Supplemental Applications",
        action: "View",
        docName: "Program Information_Supplemental Applications",
        code: "002-006-004",
      },
      { ///* remove PC8 specific docs . mix policy program to the program dropdown
        label: "Binding Documents",
        action: "View",
        docName: "Program Information_Binding Documents",
        code: "002-006-010",
      }
    ],
  },
];

/* remove PC8 specific docs . mix policy program to the program dropdown
const dataExcess = [
  {
    title: "",
    items: [
      {
        label: "Information Circular",
        action: "View",
        docName: "PC8-Program Information_Information Circular",
        code: "002-006-007",
      },
      {
        label: "Program Highlights",
        action: "View",
        docName: "PC8-Program Information_Program Highlights",
        code: "002-006-006",
      },
      //{
       // label: "Terms & Conditions",
        //action: "View",
        //docName: "PC8-Program Information_Terms and Conditions",
        //code: "002-006-008",
      //},
      {
        label: "Binding Documents",
        action: "View",
        docName: "PC8-Program Information_Binding Documents",
        code: "002-006-009",
      }
    ],
  },
];
 remove PC8 specific docs . mix policy program to the program dropdown
*/

const ProgramInformationPage = () => {
  const { state: authState, permissions } = useAuth();
  const [currentProgram, setCurrentProgram] = useState(authState.programRecId);

  // gets all documents associated with current program
  const { data: documentsProgram = [], refetch: refetchDocumentsByProgram } = useQuery("documentsByProgram", () =>
    DocumentService.getDocumentsByProgram(currentProgram)
  );

  // gets all documents associated with Client /*Li docs Insured specific */
  const { data: documentsClient = [] } = useQuery("documentsByClient", () =>
    DocumentService.getDocumentsByClient(authState.clientId)
  );

  const { data: programs = [] } = useQuery("programs", () =>
    ProgramService.getPrograms()
  );

  const { data: clientsHistory = [] } = useQuery("clientsHistory", () =>
    ClientService.getClientsChangeHistory()
  );

  //mix pc8,9(policy program) to regular program list by getting from the polciies
  const { data: Policies = [] } = useQuery("policies", () =>
    PolicyService.getPoliciesByClientNoFilter(authState.clientId)
  );



  /*Li */
  //combine program docs and client docs
  const documents = documentsProgram.filter(f => f.ClientId == null).concat(documentsClient.filter(f => f.ProgramsRecId === currentProgram));

  // downloads file
  const { data: File = [], refetch } = useQuery(
    "documentDownload",
    () =>
      DocumentService.DocumentDownload(
        trueFileName,
        `${authState.clientName} ${docName}.pdf`
      ),
    { enabled: false }
  );

  const [trueFileName, setTrueFileName] = useState("");
  const [docName, setDocName] = useState("");

  const fileDownloadSetup = (documentName) => {
    if (documents.length >= 1) {

      let fileLst = documents.sort((a, b) => new Date(b.CreatedDateTime) - new Date(a.CreatedDateTime));
      for (let document of fileLst) {
        if (document.DocumentId === documentName) {
          //setDocName2(DocName);
          setDocName(documentName);

          const fileName = document.FilePath;
          setTrueFileName(
            fileName.slice(fileName.lastIndexOf("/")).replace(/\//i, "")
          );
          break;//find the most recent one and break out

        }
      }

      /*
      documents.forEach((document) => {
        setDocName(documentName);
        if (document.DocumentId === documentName) {
          const fileName = document.FilePath;
          setTrueFileName(
            fileName.slice(fileName.lastIndexOf("/")).replace(/\//i, "")
          );
        }
      });*/
      // handleGetFile();
    }
  };

  // calls refetch for the document to be downloaded, changes when trueFileName state is updated after button click, this trueFileName is used in the download query
  // after a successful refetch teh file the state is reset
  // if statements separated so that toast not called at beginning
  useEffect(() => {
    if (docName.length > 2) {
      if (trueFileName.length > 2) {
        refetch();
        setDocName("");
        setTrueFileName("");
      } else {
        toast.error("No Document Found");
      }
    }
  }, [docName, trueFileName, refetch]);


  useMemo(() => {
    refetchDocumentsByProgram();// state change supposed to rerender the componment, this page does not do that. add the refetch here Li

  }, [currentProgram])

  if (!documents?.length) {
    return <Section>No Documents Found.</Section>;
  }

  const sortedDocuments = documents.filter((doc) =>
    doc.DocumentId.startsWith("Program Information")
  );

  const sortedDocHash = {};
  // after checking that sorted documents has a length, goes through and makes a hash with headers for the documents to be used in array of object keys for UI
  // also assigning the document to an array beneath the header (in this case should always be one document)
  // helps to not display if not available
  if (sortedDocuments.length >= 1) {
    sortedDocuments.forEach((document) => {
      const codeArr = document.DocumentId.split("_");
      // only need end here "-2" in most sections
      const header = codeArr[codeArr.length - 1].replace(/_/i, "");
      if (Object.keys(sortedDocHash).includes(header)) {
        sortedDocHash[header].push(document);
      } else {
        sortedDocHash[header] = [document];
      }
    });
  }

  /* add policy program to the drop down
  const sortedDocumentsExcess = documents.filter((doc) =>
    doc.DocumentId.includes("PC8-Program Information")
  );

  //for Excess section, seprate it out since the document sub name is same
  const sortedDocHashExcess = {};
  // after checking that sorted documents has a length, goes through and makes a hash with headers for the documents to be used in array of object keys for UI
  // also assigning the document to an array beneath the header (in this case should always be one document)
  // helps to not display if not available
  if (sortedDocumentsExcess.length >= 1) {
    sortedDocumentsExcess.forEach((document) => {
      const codeArr = document.DocumentId.split("_");
      // only need end here "-2" in most sections
      const header = codeArr[codeArr.length - 1].replace(/_/i, "");
      if (Object.keys(sortedDocHashExcess).includes(header)) {
        sortedDocHashExcess[header].push(document);
      } else {
        sortedDocHashExcess[header] = [document];
      }
    });
  }*/

  let ProgramLst = [];
  ProgramLst.push(programs.find(f => f.RecId === authState.programRecId));//insert the current

  let clientProHis = clientsHistory.filter(f => f.ProgramsRecId && f.ProgramsRecId !== authState.programRecId && f.ClientId === authState.clientId);

  if (clientProHis.length > 0 && programs.length > 0) {

    let grouping = clientProHis;//clientsHistory.filter(f => f.ProgramRecId !== authState.programRecId && f.ClientId === authState.clientId);
    let grouped = groupBy(grouping, 'ProgramsRecId');

    Object.keys(grouped).forEach(key => ProgramLst.push(programs.find(f => f.RecId.toString() === key.toString())));
  }

  // add policy program to the drop down
  if (programs.length > 0 && Policies.length > 0) {

    let groupedPolicyPrograms = groupBy(Policies, 'CategoryCode')

    Object.keys(groupedPolicyPrograms).forEach(key => {
      var existing = ProgramLst.find(f => f?.Code === key)
      if (!existing) {

        let prog = programs.find(f => f.Code === key);

        ProgramLst.push(prog);
      }
    });
  }
  // add policy program to the drop down




  const changeCurrentProgram = e => {
    //e.persist();

    const { value } = e.target;
    setCurrentProgram(Number(value));
  }


  return (
    <>
      <div style={{ paddingBottom: '0.8rem' }}>
        <Select.Container size="medium">
          {/* <Select value={currentProgram} onChange={programChange}> */}
          <Select onChange={changeCurrentProgram}>
            {ProgramLst
              //.sort((a, b) => b - a)
              .map(p => (
                <Select.Option key={p?.RecId} value={p?.RecId}>{p?.Code}</Select.Option>
              ))}
          </Select>
        </Select.Container>
      </div>
      <Text.H1>{`eCaptiv ${programs.find((f) => f.RecId === currentProgram)?.Code.replace('IC', '-IC')}`}</Text.H1>
      {/* <Text.H1>{`eCaptiv PC${authState.programRecId}-IC`}</Text.H1> */}
      {data.map((group, i) => (
        <React.Fragment key={i}>
          {group.items.map(
            (item) =>
              Object.keys(sortedDocHash).includes(
                item.docName
                  .slice(item.docName.lastIndexOf("_"))
                  .replace(/_/i, "")
              ) && (
                <Section key={item.code}>
                  <Text.H3
                    classNames={
                      !permissions.includes(item.code) ? ["isDisabled"] : [""]
                    }
                  >
                    {item.label}
                  </Text.H3>
                  <Button.ExternalText
                    onClick={(e) => {
                      fileDownloadSetup(item.docName);
                    }}
                    disabled={!permissions.includes(item.code)}
                  >
                    {item.action}
                  </Button.ExternalText>
                </Section>
              )
          )}
        </React.Fragment>
      ))}

      {/* PC8 is not an actual program, hard code the name here*/}
      {/*remove PC8 specific docs . mix policy program to the program dropdown
      <Text.H1>eCaptiv PC8-IC</Text.H1>
      {dataExcess.map((group, i) => (
        <React.Fragment key={i}>
          {group.items.map(
            (item) =>
              Object.keys(sortedDocHashExcess).includes(
                item.docName
                  .slice(item.docName.lastIndexOf("_"))
                  .replace(/_/i, "")
              ) && (
                <Section key={item.code}>
                  <Text.H3
                    classNames={
                      (!permissions.includes(item.code) || (authState.clientBrokerId === authState.loginClientId && !ExcessStateBroker[authState.clientState])) ? ["isDisabled"] : [""]
                    }
                  // (!permissions.includes(item.code) || (authState.clientBrokerId === authState.loginClientId && !ExcessStateBroker[authState.clientState])
                  //     || (authState.programRecId !== 1 && authState.programRecId !== 2))
                  //     ? ["isDisabled"] : [""]
                  >
                    {item.label}
                  </Text.H3>
                  <Button.ExternalText
                    onClick={(e) => {
                      fileDownloadSetup(item.docName);
                    }}
                    disabled={!permissions.includes(item.code) || (authState.clientBrokerId === authState.loginClientId && !ExcessStateBroker[authState.clientState])}>

                    {item.action}
                  </Button.ExternalText>
                </Section>
              )
          )}
        </React.Fragment>
      ))}
      remove PC8 specific docs . mix policy program to the program dropdown*/}


    </>
  );
};


export default ProgramInformationPage;
