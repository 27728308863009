import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";

import { Field, Label, Control, Input, Checkbox, Select, Radio, Textarea } from "rbx";
import RoundButton from "../../../../components/Button/Round";
import ExternalText from "../../../../components/Button/ExternalText";
import Section from "../../../../components/Section";
import Text from "../../../../components/Text";
import Button from "../../../../components/Button";

import { StateSelect, TextFieldInput } from "../../../../components/inputs";
import STATES from "../../../../components/inputs/StateSelect/states"; //"../../../ states";
import OtherVehicles from "./OtherVehicles";
import validator from 'validator';


const AdditionalInfo = (Props) => {

  //   if (Props.currentState.currentStep !== 3) {
  //     return null;
  //   }
  console.log("render AdditionalInfo");
  const [additionalInfoState, setAdditionalInfoState] = useState(Props.currentState.lossInfoDetail.additionalInfo);
  //const [otherVehicleCount, setOtherVehicleCount] = useState(0);
  const [otherVehicleCount, setOtherVehicleCount] = useState(Props.currentState.lossInfoDetail.otherVehicles.length);
  const [otherVehicleLst, setOtherVehicleLst] = useState(Props.currentState.lossInfoDetail.otherVehicles);

  let additionalInfoControls = Props.additionalInfoControls;

  // Use the submitted data to set the state
  const handleChange = (name, value, key, ob) => {
    setAdditionalInfoState({ ...additionalInfoState, ...ob });
  }

  //useEffect to update the parent state after additionalInfo state is finished the change due to asyn useState
  useEffect(() => {
    Props.handleChange('', '', 'lossInfoDetail', { 'additionalInfo': additionalInfoState })

  }, [additionalInfoState]);

  const addOtherVehicle = () => {

    //setOtherVehicleLst([...otherVehicleLst, { 'otherVehicleId': otherVehicleCount }]);
    //add to top state otherVehicles that has most recent updated value and will force this component to re render, important
    setOtherVehicleLst([...Props.currentState.lossInfoDetail.otherVehicles, { 'otherVehicleId': otherVehicleCount }]);
    setOtherVehicleCount(otherVehicleCount + 1);

  }
  const removeOtherVehicle = () => {

    let afterRemove = [...Props.currentState.lossInfoDetail.otherVehicles];
    afterRemove.pop();

    setOtherVehicleLst(afterRemove);
    setOtherVehicleCount(otherVehicleCount - 1);
  }


  useEffect(() => {
    //add to top state of the otherVehicle list, very important
    Props.handleChange('', '', 'lossInfoDetail', { 'otherVehicles': otherVehicleLst });
  }, [otherVehicleLst]);


  return (
    <>
      {/* <Text.H3>Loss Info</Text.H3> */}
      {/* <TextFieldInput
                label="Company"
                name="company"
                value={Props.currentState.personalInfoDetail.company}
                onChange={Props.handleChange}
            /> */}
      <Text.H3>Additional Info</Text.H3>
      <h4 style={{ 'fontWeight': 700, 'fontSize': '1.3em' }}>Insured Vehicle</h4>
      <div id='OLAdditionalInfoContainer'>
        {
          additionalInfoControls.map(item => {
            if (item.ControlTypeCode === 'textbox' && item.ControlDataTypeCode.toLowerCase() === 'string') {
              return (<Field horizontal>
                <Field.Label size="normal">
                  <Label>{item.ControlLabel}</Label>
                </Field.Label>
                <Field.Body>
                  <Field>

                    <Input type="text" name={item.ControlLabelCode} value={Props.currentState.lossInfoDetail.additionalInfo[item.ControlLabelCode]}
                      onChange={(e) => { handleChange(e.target.name, e.target.value, 'additionalInfo', { [e.target.name]: e.target.value }) }} />
                    {item.ControlLabelCode.toLowerCase().includes('phone') &&
                      (<><br />
                        <span
                          style={{
                            color: "red"
                          }}
                        >
                          {!Props.currentState.lossInfoDetail.additionalInfo[item.ControlLabelCode] ||
                            validator.isMobilePhone(Props.currentState.lossInfoDetail.additionalInfo[item.ControlLabelCode], 'en-US')
                            ? '' : 'Please enter vaild phone.'}
                        </span></>)
                    }
                  </Field>
                </Field.Body>
              </Field>)
            }
            else if (item.ControlTypeCode === 'textbox' && item.ControlDataTypeCode.toLowerCase() === 'number') {
              return (<Field horizontal>
                <Field.Label size="normal">
                  <Label>{item.ControlLabel}</Label>
                </Field.Label>
                <Field.Body>
                  <Field>

                    <Input type="text" name={item.ControlLabelCode} value={Props.currentState.lossInfoDetail.additionalInfo[item.ControlLabelCode]}
                      onChange={(e) => { handleChange(e.target.name, e.target.value, 'additionalInfo', { [e.target.name]: e.target.value }) }} />

                    <br />
                    <span
                      style={{
                        color: "red"
                      }}
                    >
                      {!Props.currentState.lossInfoDetail.additionalInfo[item.ControlLabelCode] ||
                        validator.isNumeric(Props.currentState.lossInfoDetail.additionalInfo[item.ControlLabelCode])
                        ? '' : 'Please enter vaild number.'}
                    </span>
                  </Field>
                </Field.Body>
              </Field>)
            }
            else if (item.ControlTypeCode === 'dropdown') {
              return (
                <Field horizontal>
                  <Field.Label size="normal">
                    <Label>{item.ControlLabel}</Label>
                  </Field.Label>
                  <Field.Body>
                    <Field>
                      <Select.Container fullwidth>
                        <Select
                          name={item.ControlLabelCode}
                          value={Props.currentState.lossInfoDetail[item.ControlLabelCode]}
                          onChange={(e) => { handleChange(e.target.name, e.target.value, 'additionalInfo', { [e.target.name]: e.target.value }) }}
                          //disabled={disabled || readOnly}
                          className="selectField"
                        >
                          <Select.Option value="">State</Select.Option>
                          {Object.keys(STATES).map((key) => (
                            <Select.Option key={key} value={key}>
                              {key}
                            </Select.Option>
                          ))}
                        </Select>
                      </Select.Container>
                    </Field>
                  </Field.Body>
                </Field>
              )
            }
            else if (item.ControlTypeCode === 'radioButton') {
              return (<Field horizontal>
                <Field.Label size="normal">
                  <Label>{item.ControlLabel}</Label>
                </Field.Label>
                <Field.Body>
                  <Field>
                    <Radio className='radio' name={item.ControlLabelCode}
                      checked={Props.currentState.lossInfoDetail.additionalInfo[item.ControlLabelCode]}
                      value='Yes'
                      onChange={(e) => { handleChange(e.target.name, e.target.value, 'additionalInfo', { [e.target.name]: e.target.value }) }}
                    /> <span>Yes</span>
                  </Field>
                </Field.Body>
              </Field>)
            }
            else if (item.ControlTypeCode === 'calendar') {
              return (
                <Field horizontal>
                  <Field.Label size="normal">
                    <Label>{item.ControlLabel} {item.IsRequired && (<span className="requiredStar"> *</span>)}</Label>
                  </Field.Label>
                  <Field.Body>
                    <Field>
                      <Control>
                        <Input
                          type="date"
                          name={item.ControlLabelCode}
                          value={Props.currentState.lossInfoDetail.additionalInfo[item.ControlLabelCode]}
                          onChange={(e) => { Props.handleChange(e.target.name, e.target.value, 'additionalInfo', { [e.target.name]: e.target.value }) }}
                        />
                      </Control>
                    </Field>
                  </Field.Body>
                </Field>)
            }
            else if (item.ControlTypeCode === 'textarea') {
              return (
                <Field horizontal>
                  <Field.Label size="normal">
                    <Label>{item.ControlLabel} {item.IsRequired && (<span className="requiredStar"> *</span>)}</Label>
                  </Field.Label>
                  <Field.Body>
                    <Field>
                      <Control>
                        <Textarea
                          name={item.ControlLabelCode}
                          value={Props.currentState.lossInfoDetail.additionalInfo[item.ControlLabelCode]}
                          onChange={(e) => { Props.handleChange(e.target.name, e.target.value, 'additionalInfo', { [e.target.name]: e.target.value }) }}
                        />
                      </Control>
                    </Field>
                  </Field.Body>
                </Field>)
            }
          }
          )
        }

      </div>
      <div>
        <Text.H3> Other Party Info</Text.H3>

        {otherVehicleLst.length > 0 ? otherVehicleLst.map((vehicle, index) => (
          <OtherVehicles key={index} currentState={Props.currentState} handleChange={Props.handleChange} otherVehiclesControls={Props.otherVehiclesControls} count={index} />
        )) : null}

        {/* <OtherVehicles currentState={Props.currentState} handleChange={Props.handleChange} otherVehiclesControls={Props.otherVehiclesControls} count = {otherVehicleCount} /> */}
        <ExternalText style={{ marginRight: 4 }} onClick={() => addOtherVehicle()}> Add Vehicle</ExternalText>
        {otherVehicleLst.length > 0 && <ExternalText onClick={() => removeOtherVehicle()}>Remove</ExternalText>}
        {/* {addOtherVehicle()} */}
      </div>





    </>
  );
}

export default AdditionalInfo;




