import APIService from "./index";

const MemberFundAnalysisService = {
  getMemberFundAnalysis: (clientId) =>
    clientId
      ? APIService.get(`/GetMemberFundAnalysis?clientId=${clientId}`).then(
        (res) => res.data.Payload
      )
      : [],
  getMemberFundAnalysisPDF: (clientId, body) =>
    clientId
      ? APIService.post(`/GetMemberFundAnalysisPDF?ClientId=${clientId}`, body).then(
        (res) => res.data.Payload
      )
      : '',
  getMemberFundAnalysisAll: () =>
    APIService.get('/GetMemberFundAnalysisAll').then(
      (res) => res.data.Payload
    )

};

export default MemberFundAnalysisService;