import { format, parseISO } from "date-fns";

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
})
// need parse ISO to convert the value given by sql into a date

export default [
    {
        Header: "Item",
        accessor: "WarrantyNm2",
        id: "WarrantyNm2",
        //Cell: ({ value }) => (value ? `${format(parseISO(value), "yyyy")} - ${parseInt(format(parseISO(value), "yyyy")) + 1}` : "--"),
    },
    {
        Header: "Date Initiated",
        accessor: "DateInitiated",
        id: "DateInitiated",
        Cell: ({ value }) => (value ? format(parseISO(value), "MM/dd/yyyy") : "--")
    },
    {
        Header: "Status",
        accessor: "WarrantyStatusCd",
        id: "WarrantyStatusCd",
        //Cell: ({ value }) => (formatter.format(value)),
    }
];

// {
//   Header: "Expiration Date",
//   accessor: "ExpDate",
//   id: "ExpDate",
//   Cell: ({ value }) => (value ? format(parseISO(value), "MM/dd/yyyy") : "--"),
// },