import React from "react";

import "./ColorDivider.scss";

export default function ColorDivider({ ...props }) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <hr {...props} className="colorDivider" />;
}

ColorDivider.displayName = "ColorDivider";
