import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import Text from "../../components/Text";
import ClaimsTables from "./ClaimsTables";
import { useAuth } from "../../context/AuthContext";
import PolicyService from "../../services/PolicyService";
import RoundButton from "../../components/Button/Round";
import Button from "../../components/Button";

import { ModalContext } from "../../components/Modals/ModalProvider";
import LossrunBatchModal from "./LossrunBatchModal";



import "./Claims.scss";


const ClaimsPage = () => {
  const [policyId, setPolicyId] = useState(null);
  const [claimId, setClaimId] = useState(null);
  const [noteId, setNoteId] = useState(false);
  const [claimNumber, setClaimNumber] = useState(false);
  const [policyNumber, setPolicyNumber] = useState(false);
  const { state: authState, permissions } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const { toggleModal } = useContext(ModalContext);
  let historyArr = history.location.pathname.split("/");

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    historyArr = history.location.pathname.split("/");

    if (historyArr.length === 3) {
      setPolicyId(historyArr[2]);
      setClaimId(null);
      setNoteId(false);
    } else if (historyArr.length === 4) {
      setPolicyId(historyArr[2]);
      setClaimId(historyArr[3]);
      setNoteId(false);
    } else if (historyArr.length === 6) {
      setPolicyId(historyArr[2]);
      setClaimId(historyArr[3]);
      setNoteId(historyArr[5]);
    } else if (historyArr.length <= 2) {
      setPolicyId(null);
      setClaimId(null);
      setNoteId(false);
    }

  }, [history.location.pathname, historyArr]);

  // Policies Data
  const { data: policies = [], isLoading } = useQuery(
    "policiesByClient",
    () => PolicyService.getPoliciesByClientNoFilter(authState.clientId),
    { fetchPolicy: "no-cache", cacheTime: 0 }
  );

  const commercialAuto = [];
  const generalLiability = [];
  const workersCompensation = [];
  const excess = [];


  policies.forEach((policy) => {
    // eslint-disable-next-line no-param-reassign
    policy.link = `/Claims/${policy.PolicyId}`;
    switch (policy.LOB) {
      case "Commercial Auto":
        commercialAuto.push(policy);
        break;
      case "General Liability":
        generalLiability.push(policy);
        break;
      case "Workers Compensation":
        workersCompensation.push(policy);
        break;
      case "Excess":
        excess.push(policy);
        break;
      default:
        break;
    }
  });


  const data = [
    { title: "Commercial Auto", items: commercialAuto },
    { title: "General Liability", items: generalLiability },
    { title: "Workers' Compensation", items: workersCompensation },
    { title: "Excess", items: excess },
  ];

  const handleLossRunBatch = () => { }

  if (isLoading || policies.length < 1) {
    return (
      <div className="headerContainer">
        <Text.H2>
          <p>No Claims Data Found for:</p>
          <span style={{ color: "#1871ad" }}>{authState.clientName}</span>
        </Text.H2>
      </div>
    );
  }

  return (
    <div id="claimsContainer">
      {/* heading and buttons and breadcrumbs */}
      <div className="claimsHeader">
        <div className="leftSideContainer">
          {historyArr.length > 3 && <Text.H2>{claimNumber}</Text.H2>}
          {historyArr.length === 3 && <Text.H2>{policyNumber}</Text.H2>}
          {historyArr.length <= 2 && <Text.H2>{authState.clientName}</Text.H2>}
          <div className="breadcrumb ui">
            <>
              <Link
                to="/Claims"
                className={!policyId && !claimId && !noteId ? "active" : ""}
                id="breadCrumbSection"
              >
                <p className="bigLabel">Policies</p>
              </Link>
              <h3 className="slash">{" / "}</h3>
            </>
            {historyArr.length > 2 && (
              <>
                <Link
                  to={`/Claims/${policyId}`}
                  className={policyId && !claimId && !noteId ? "active" : ""}
                  id="breadCrumbSection"
                >
                  {" "}
                  <p className="bigLabel">Claims</p>
                </Link>
                <h3 className="slash">{" / "}</h3>
              </>
            )}
            {historyArr.length > 3 && (
              <>
                <Link
                  to={`/Claims/${policyId}/${claimId}`}
                  className={policyId && claimId && !noteId ? "active" : ""}
                  id="breadCrumbSection"
                >
                  {" "}
                  <p className="bigLabel">Claim Details</p>
                </Link>
                <h3 className="slash">{" / "}</h3>
              </>
            )}
            {historyArr.length > 4 && (
              <>
                <Link
                  to={`/Claims/${policyId}/${claimId}/Notes/${noteId}`}
                  className={noteId ? "active" : ""}
                  id="breadCrumbSection"
                >
                  {" "}
                  <p className="bigLabel">Notes</p>
                </Link>
                <h3 className="slash">{" / "}</h3>
              </>
            )}
          </div>
        </div>
        <div className="buttonContainer" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
          <Button.Round
            //className={!permissions.includes('004-001-008') ? "roundButton primary disabled" : "roundButton primary"}
            classNames={!permissions.includes('004-001-004') ? ['disabled', 'noPermButton'] : ['primary']}
          >
            <Button.ExternalText style={{ borderLeft: 'none', fontWeight: 600, fontSize: '1.3rem' }} disabled={!permissions.includes('004-001-004')}>
              {/* Uppercase for /Claims to match up and keep Claims menu item highlighted  */}
              <Link style={{ color: '#ffffff', pointerEvents: !permissions.includes('004-001-004') && 'none' }}
                to={{ pathname: '/Claims/claims-notifications', policies: policies }}> Notifications </Link>
            </Button.ExternalText>
          </Button.Round>

          <RoundButton
            style={{ margin: '0 0 0 2px' }}
            type="submit"
            id="saveButton"
            classNames={!permissions.includes('004-001-003') ? ['disabled', 'noPermButton'] : ['primary']}
            disabled={!permissions.includes('004-001-003')} //use same permission as individual lossrun does
            onClick={() =>
              toggleModal(
                true,
                <LossrunBatchModal
                  policies={data}
                  optionalText={'Select Lines of Business.'}
                  entity=""
                  toggleModal={toggleModal}
                  //handleAction={(e) => handleLossRunBatch(e)}
                  actionText="Export"
                />
              )
            }
          >
            Combined Loss Runs
          </RoundButton>

          {/* <Button.Round onClick="">
            Notifications
          </Button.Round>
          {permissions.includes("004-001-001") && (
            <Button.Round onClick="">
              Book a Claim Meeting
            </Button.Round>
          )} */}
        </div>
      </div>

      {/* Table Tabs */}
      {/* Send data in here to display different types of data(policies, claims etc) */}
      <ClaimsTables
        data={data}
        policyId={policyId}
        claimId={claimId}
        history={history}
        location={location}
        noteId={noteId}
        brokerId={authState.clientBrokerId}
        clientName={authState.clientName}
        setClaimNumber={setClaimNumber}
        setPolicyNumber={setPolicyNumber}
      />
      {/* Loss Runs */}
      {/* <Section>
        <Text.H3>Loss Runs</Text.H3>
        <Button.Text to="LossRunsLink">
          View
        </Button.Text>
      </Section> */}
    </div>
  );
};

ClaimsPage.propTypes = {};

export default ClaimsPage;
