import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Field, Select } from "rbx";

import Section from "../../../components/Section";
import Text from "../../../components/Text";
import Loading from "../../../components/Loading";
import { useAuth } from "../../../context/AuthContext";

import { getRoleGroupData } from "../../../utils";
import "./GroupSelectPage.scss";

const GroupSelectPage = () => {
  const history = useHistory();
  const { state: authState, permissions } = useAuth();
  const [groups, setGroups] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const groupData = await getRoleGroupData();
      setGroups(groupData.groupsWithRoleGroups);
    };
    if (!groups) {
      fetchData();
    }
  }, [groups]);

  const handleGroupSelect = async groupRecId => {
    const selectedGroup = await groups.find(
      group => group.RecId.toString() === groupRecId
    );
    // testing change of push here to direct to the eMaxx client instead of /emaxx
    if (selectedGroup.Name.toLowerCase().includes("maxx")) {
      history.push(`/user-settings/emaxx/${authState.clientId}`);
    } else {
      history.push(`/user-settings/${selectedGroup.Name.toLowerCase()}`);
    }
  };
  if (!groups) {
    return <Loading.SmallLoading />;
  }
  return (
    <div id="groupSelectPage">
      <Section>
        <div>
          <Text.H3>Please select a group</Text.H3>
        </div>
        <Field>
          <Select.Container>
            <Select onChange={e => handleGroupSelect(e.target.value)}>
              <Select.Option value="">Select Group</Select.Option>
              {groups.map(group => (
                <Select.Option key={group.RecId} value={group.RecId}>
                  {group.Name}
                </Select.Option>
              ))}
            </Select>
          </Select.Container>
        </Field>
      </Section>
    </div>
  );
};

export default GroupSelectPage;
