import React, { useContext, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { toast } from "react-toastify";


import DataTable from "../../../components/DataTable";
import Text from "../../../components/Text";
import Loading from "../../../components/Loading";
import Section from "../../../components/Section";
import RoundButton from "../../../components/Button/Round";
import UserService from "../../../services/UserService";
import ClaimsService from "../../../services/ClaimsService";
import { EditUserIcon, DeleteUserIcon } from "./UserTableActions";
import { ModalContext } from "../../../components/Modals/ModalProvider";
import { useAuth } from "../../../context/AuthContext";
import "./Users.scss";
import RolesViewsPDF from "../../../assets/permissionGuide/RolesViews.pdf"

const UsersPage = () => {
  const { state: authState } = useAuth();
  const history = useHistory();
  const { groupName, clientId } = useParams();
  const { toggleModal } = useContext(ModalContext);
  const [data, setData] = useState([]);

  const { data: users, isLoading, error } = useQuery("users", () =>
    UserService.getUsersByGroup(
      groupName,
      clientId,
      authState.roleGroup.RoleName,
      authState.roleGroup.GroupName
    )
  );
  useEffect(() => {
    setData(users);
  }, [users]);

  const handleCellClick = cellData => {
    if (cellData.column.id !== "RecId" && cellData.row.original.isEditable) {
      groupName
        ? history.push(
          `/user-settings/${groupName}/${clientId}/${cellData.row.original.RecId}`
        )
        : history.push(
          `/user-settings/emaxx/${clientId}/${cellData.row.original.RecId}`
        );
    }
  };

  const handleAddClick = () => {
    groupName
      ? history.push(`/user-settings/${groupName}/${clientId}/add`)
      : history.push(`/user-settings/emaxx/${clientId}/add`);
  };

  if (isLoading) {
    return <Loading.SmallLoading />;
  }

  if (error) {
    return null;
  }

  const handleDelete = async cellData => {
    try {
      // delete user,then find current userRoleGroup and make inactive
      await UserService.deleteUser(cellData.row.values.RecId);
      const {
        data: { Payload: originalRoleGroup = false }
      } = await UserService.getUserRoleGroup(cellData.row.values.RecId);
      await UserService.updateUserRoleGroup(originalRoleGroup.RecId, {
        UsersRecId: cellData.row.values.RecId,
        RoleGroupsRecId: originalRoleGroup.RoleGroupsRecId,
        IsActive: 0
      });
      const newData = data.filter(
        user => user.RecId !== cellData.row.values.RecId
      );
      toast.success("User Deleted Successfully");

      //when deleting the user, update all user selected claim notification selections 04/25/2023
      const selections = await ClaimsService.getClaimNotificationSelectionsByUser(cellData.row.values.RecId);

      if (selections && selections.length > 0) {
        selections.forEach(sel => {
          sel.IsSelected = false;
          sel.SendEmail = false;
          sel.ModifiedComments = 'User is deleted. unCheck the selections and SendEmail option'

        });

        const updateSelections = await ClaimsService.updateClaimNotificationSelectionsByUser(selections);
      }
      //when deleting the user, update all user selected claim notification selections


      setData(newData);
      toggleModal(false, "");
    } catch (err) {
      console.log(err);
      toast.error("Whoops! We couldn't delete your user");
    }
  };

  const columns = [
    {
      Header: "FirstName",
      accessor: "FirstName",
      id: "FirstName"
    },
    {
      Header: "LastName",
      accessor: "LastName",
      id: "LastName"
    },
    {
      Header: "Role",
      accessor: "roleName",
      id: "roleName"
    },
    {
      Header: "Email",
      accessor: "Email",
      id: "Email",
      Cell: ({ value }) => (
        <span style={{ overflow: "wrap", wordWrap: "break-word" }}>
          {value}
        </span>
      )
    },
    {
      Header: "Phone",
      accessor: "Phone",
      id: "Phone"
    },
    {
      Header: "",
      accessor: "RecId",
      id: "RecId",
      Cell: cell =>
        cell.cell.row.original.isEditable ? (
          <>
            <EditUserIcon cellData={cell.cell} />
            <DeleteUserIcon cellData={cell.cell} handleDelete={handleDelete} />
          </>
        ) : null
    }
  ];

  return (
    <div id="userContainer">
      <div className="headerContainer">
        <Text.H1>Users</Text.H1>
        <RoundButton onClick={() => window.open(RolesViewsPDF)} style={{ margin: '0 2px 0 0' }}>Permissions</RoundButton> {/*under default */}
        <RoundButton onClick={() => handleAddClick()}>Add</RoundButton>
      </div>
      {data?.length > 0 ? (
        <DataTable
          columns={columns}
          data={data}
          onCellClick={cellData => handleCellClick(cellData)}
          sortBy={[
            {
              id: "LastName",
              desc: false
            }
          ]}
        />
      ) : (
        <Section>No Users Found.</Section>
      )}
    </div>
  );
};

export default UsersPage;
