export default {
  name: "Claims Management",
  code: "004",
  links: [
    {
      name: "Claims",
      code: "004-001",
      to: "/Claims",
    },
    {
      name: "Resources",
      code: "004-002",
      to: "/claims/claim-resources",
    },
  ],
};
