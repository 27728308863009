export default [
  {
    Header: "Company Name",
    accessor: "ClientName",
    id: "ClientName",
  },
  {
    Header: "ClientCode",
    accessor: "ClientCode",
    id: "ClientCode",
  },
  {
    Header: "Address",
    accessor: "Address.Line1",
    id: "Address.Line1",
  },
  {
    Header: "City",
    accessor: "Address.City",
    id: "Address.City",
  },
  {
    Header: "State",
    accessor: "Address.State",
    id: "Address.State",
  },
  {
    Header: "ZipCode",
    accessor: "Address.ZipCode",
    id: "Address.ZipCode",
  },
  {
    Header: "Phone",
    accessor: "Phone",
    id: "Phone",
  },
];
