import React from "react";
// import PropTypes from "prop-types";

const FMCSAPage = (props) => {
  // HAVE A LINK HERE BUT IT IS PHASE 2 MAYBE?
  return <div>HELLO WORLD</div>;
};

FMCSAPage.propTypes = {};

export default FMCSAPage;
