import React, { useEffect, useState, useMemo } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";

import { Select } from "rbx";
import { startCase } from "../../../utils";
import { useAuth } from "../../../context/AuthContext";
import { DocumentService } from "../../../services/index";
import Button from "../../../components/Button";
import Text from "../../../components/Text";
import Section from "../../../components/Section";
import DocumentDelete from '../../../components/DocumentDelete/DocumentDelete';
import "./StandingCommittees.scss";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const StandingCommitteesPage = ({ passDownCurrentProg }) => {
  const { state: authState, permissions } = useAuth();

  const [trueFileName, setTrueFileName] = useState("");
  const [docName, setDocName] = useState("");
  const [docName2, setDocName2] = useState("");

  const yearStart = new Date().getFullYear();

  const [currentYear, setCurrentYear] = useState(yearStart);

  const abbrevCode = (Code) => {
    return startCase(
      Code.slice(Code.lastIndexOf("_")).replace(/_/i, "").replace(/_/i, " ")
    );
  };

  // gets all documents associated with current program
  const { data: documents = [], refetch: refetchDocumentsByProgram } = useQuery("documentsByProgram", () =>
    DocumentService.getDocumentsByProgram(passDownCurrentProg)
  );

  //  get all document types
  const { data: documentTypes = [] } = useQuery("documentTypes", () =>
    DocumentService.getDocumentTypes()
  );

  // gets only the documents that will be Organizational Documentation and current year selected by the select
  const sortedDocuments = documents.filter(
    (doc) =>
      doc.DocumentId.startsWith(
        "Governance_Organization_Standing Committees"
      ) && doc.Year === Number(currentYear)
  );
  // gets the titles for the sections
  let sortedDocumentTypes = [];
  if (documentTypes.length >= 1) {
    const filteredDocumentTypes = documentTypes.filter((docType) =>
      docType.Code.startsWith("Governance_Organization_Standing Committees")
    );
    // puts them in alphabetical order
    sortedDocumentTypes = filteredDocumentTypes.sort((a, b) =>
      abbrevCode(a.Code).localeCompare(abbrevCode(b.Code))
    );
  }
  // downloads file
  const { data: File = [], refetch } = useQuery(
    "documentDownload",
    () =>
      DocumentService.DocumentDownload(
        trueFileName,
        `${docName2} ${docName}`
      ),
    { enabled: false }
  );

  // UI HEADER STUFF

  const abbrevCodeHash = {};
  const codeToHash = (codeObj) => {
    const codeArr = codeObj.DocumentId.split("_");
    const header = codeArr[codeArr.length - 2].replace(/_/i, "");
    if (Object.keys(abbrevCodeHash).includes(header)) {
      abbrevCodeHash[header].push(codeObj);
    } else {
      abbrevCodeHash[header] = [codeObj];
    }
  };

  if (sortedDocuments.length >= 1) {
    sortedDocuments.forEach((document) => {
      codeToHash(document);
    });
  }
  // UI HEADER STUFF

  // YEAR stuff
  const sortedDocumentsYear = documents.filter((doc) =>
    doc.DocumentId.startsWith("Governance_Organization_Standing Committees")
  );

  const yearHash = {};
  yearHash[yearStart] = ["blank"];
  if (sortedDocumentsYear.length >= 1) {
    sortedDocumentsYear.forEach((document) => {
      const headerYear = document.Year;
      if (Object.keys(yearHash).includes(headerYear)) {
        yearHash[headerYear].push("blank");
      } else {
        yearHash[headerYear] = ["blank"];
      }
    });
  }
  // year stuff

  const fileDownloadSetup = (fileName, DocName) => {
    if (sortedDocuments.length >= 1) {
      let fileLst = sortedDocuments.sort((a, b) => new Date(b.CreatedDateTime) - new Date(a.CreatedDateTime));
      for (let document of fileLst) {
        //Li 02/15/2023 add checking by year, month, day for most recent upload
        if (document.FileName === fileName && `${monthNames[document.Month - 1]} ${document.Day}, ${document.Year}` === DocName) {
          setDocName2(DocName);
          setDocName(fileName);
          const filePath = document.FilePath;
          setTrueFileName(
            filePath.slice(filePath.lastIndexOf("/")).replace(/\//i, "")
          );
          break;//find the most recent one and break out
        }
      }
      /*
      sortedDocuments.forEach((document) => {
        setDocName2(DocName);
        setDocName(fileName);
        if (document.FileName === fileName) {
          const filePath = document.FilePath;
          setTrueFileName(
            filePath.slice(filePath.lastIndexOf("/")).replace(/\//i, "")
          );
        }
      });*/
    }
  };

  // calls refetch for the document to be downloaded, changes when trueFileName state is updated after button click, this trueFileName is used in the download query
  // after a successful refetch teh file the state is reset
  // if statements separated so that toast not called at beginning
  useEffect(() => {
    if (docName.length > 2) {
      if (trueFileName.length > 2) {
        refetch();
        setDocName("");
        setTrueFileName("");
      } else {
        toast.error("No Document Found");
      }
    }
  }, [docName, trueFileName, refetch]);

  const formChange = (e) => {
    e.persist();
    const { value } = e.target;
    setCurrentYear(value);
  };

  useMemo(() => {

    refetchDocumentsByProgram();
    setCurrentYear(yearStart);// with the year dropdown, need to force the component to refresh with resetting the currentYear state

  }, [passDownCurrentProg]);

  //resolve the document delete issue for the year that no doc left and current year not updated when select element goes back to default Li 04/20/2023
  if (Object.keys(yearHash).length > 0) {
    let curYear = Object.keys(yearHash).find(f => f == currentYear);
    if (!curYear) {
      setCurrentYear(Number(Object.keys(yearHash).sort((a, b) => b - a)[0]));
    }
  }
  //resolve the document delete issue for the year that no doc left

  return (
    <>
      <Select.Container size="medium">
        <Select value={currentYear} onChange={formChange}>
          {Object.keys(yearHash)
            .sort((a, b) => b - a)
            .map((year) => (
              <Select.Option key={year}>{year}</Select.Option>
            ))}
        </Select>
      </Select.Container>
      <div className="statementContainer" id="standingCommitteeContainer">
        {sortedDocuments.length < 1 ? (
          <Text.H3 style={{ margin: "1rem" }}>
            No Files Found For this Year
          </Text.H3>
        ) : (
          <>
            <div className="fileGroup">
              {Object.keys(abbrevCodeHash)
                .sort((a, b) => a.localeCompare(b))
                .map((header) => (
                  <div className="verticalFlex">
                    <Text.H1>{header}</Text.H1>
                    <div className="horizontalFlex">
                      {abbrevCodeHash[header]
                        .sort((a, b) => {
                          const dateA = new Date(a.Year, a.Month, a.Day);
                          const dateB = new Date(b.Year, b.Month, b.Day);
                          return dateB - dateA;
                        })
                        .map((document) => (
                          <div>
                            <Section id="orgDoc">
                              <div className="statementCard">
                                <FontAwesomeIcon
                                  icon={faFileAlt}
                                  className="fileIcon"
                                  size="6x"
                                />
                                <Text.H3>
                                  {`${monthNames[document.Month - 1]} ${document.Day
                                    }, ${document.Year}`}
                                </Text.H3>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    paddingBottom: "0.5rem",
                                  }}
                                >
                                  <Button.ExternalText
                                    onClick={(e) => {
                                      fileDownloadSetup(
                                        document.FileName,
                                        `${monthNames[document.Month - 1]} ${document.Day
                                        }, ${document.Year}`
                                      );
                                    }}
                                  >
                                    {`View ${document.DocumentId.slice(
                                      document.DocumentId.lastIndexOf("_")
                                    ).replace(/_/i, "")}`}
                                  </Button.ExternalText>

                                  {permissions.includes("002-002-008-001") && (<DocumentDelete
                                    name=''
                                    fileName={document.FileName}
                                    docObj={document}
                                    isIcon={false}
                                    handleRefresh={() => refetchDocumentsByProgram()}
                                  />)}
                                </div>
                              </div>
                            </Section>
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default StandingCommitteesPage;
