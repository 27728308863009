import React from "react";
import PropTypes from "prop-types";

import "./H3Form.scss";

export default function H3({ children, ...props }) {
  return (
    <h3 {...props} className="heading3">
      {children}
      <span className="form-star"> *</span>
    </h3>
  );
}

H3.propTypes = {
  children: PropTypes.node.isRequired,
};

H3.displayName = "H3";
