import React from "react";
import { useQuery } from "react-query";
import { groupBy } from "lodash";
import { useAuth } from "../../../context/AuthContext";
import eMaxxDirectoryService from "../../../services/eMaxxDirectoryService";
import Section from "../../../components/Section";
import Text from "../../../components/Text";
import Loading from "../../../components/Loading";

import "./Directory.scss";

const DirectoryPage = () => {
  const { permissions } = useAuth();

  // gets all data for membership
  const {
    data: emaxxDirectory = [],
    isLoadingEmaxxDirectory,
    errorEmaxxDirectory
  } = useQuery(
    "membershipReferences",
    () => eMaxxDirectoryService.getEmaxxDirectory(),
    { fetchPolicy: "no-cache", cacheTime: 0 }
  );

  const {
    data: techVendors = [],
    isLoadingTechVendors,
    errorTechVendors
  } = useQuery(
    "getTechnologyVendors",
    () => eMaxxDirectoryService.getTechnologyVendors(),
    { fetchPolicy: "no-cache", cacheTime: 0 }
  );
  // groups into section by type
  const emaxxDirectoryData = groupBy(emaxxDirectory, "Department");
  const techVendorsData = groupBy(techVendors, "Type");

  // console.log("emaxxDirectory", emaxxDirectory);
  // console.log("techVendors", techVendors);

  if (isLoadingEmaxxDirectory || isLoadingTechVendors) {
    return (
      <div className="headerContainer">
        <Text.H1>Claims</Text.H1>
        <Loading.SmallLoading />
      </div>
    );
  }
  if (errorEmaxxDirectory || errorTechVendors) {
    return null;
  }

  return (
    <div id="MemDirectoryContainer">
      {permissions.includes("005-002-001") && (
        <>
          <Text.H1>eMaxx Directory</Text.H1>
          {Object.keys(emaxxDirectoryData).map(group => (
            <div className="tableSection">
              <Section id="memberReferences">
                <Text.H3 style={{ marginBottom: "1rem" }}>{group}</Text.H3>
                <div className="tableContainer">
                  {emaxxDirectoryData[group].map(person => (
                    <div className="table-item">
                      <div>{`${person.FirstName} ${person.LastName}`}</div>
                      <div>{person.BusinessEmail}</div>
                      <div>{`Work: ${person.BusinessPhone} Ext: ${person.BusinessPhoneExt}`}</div>
                      <div>{`Cell: ${person.BusinessCell}`}</div>
                    </div>
                  ))}
                </div>
              </Section>
            </div>
          ))}
        </>
      )}
      {/* Kevin 06-29-2023 requested to remove this section {permissions.includes("005-002-002") && (
        <>
          <Text.H1>Technology Vendors</Text.H1>
          {Object.keys(techVendorsData).map(group => (
            <div className="tableSection">
              <Section id="memberReferences">
                <Text.H3 style={{ marginBottom: "1rem" }}>{group}</Text.H3>
                <div className="tableContainer">
                  {techVendorsData[group].map(person => (
                    <div className="table-item">
                      <div>
                        {`${person.Company} | ${person.FirstName} ${person.LastName}`}
                      </div>
                      <div>{person.BusinessEmail}</div>
                      <div>{person.BusinessWebsite}</div>
                    </div>
                  ))}
                </div>
              </Section>
            </div>
          ))}
        </>
      )} */}
    </div>
  );
};

export default DirectoryPage;
